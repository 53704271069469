const actualisationErrorCodes = {
  VOID_LINKAGE: "ACT-0002",
  WRITE_OFF: "ACT-0003",
  REMINDER: "ACT-0004",
  FULL_MATCH: "ACT-0005",
};

const actualisationTypes = {
  WRITE_OFF: "write_off",
  REMINDER: "remainder",
  FULL_MATCH: "full_match",
};

const validErrorCodes = Object.values(actualisationErrorCodes);

module.exports = {actualisationErrorCodes, actualisationTypes, validErrorCodes};
