import PropTypes from "prop-types";
import {useCallback, useEffect, useMemo, useState} from "react";
import {useLazyQuery, useQuery} from "@apollo/client";
import {useFormContext, useWatch} from "react-hook-form";
import {
  OP_CONDITIONS,
  ADD_BATCH_FORM,
} from "modules/co-processing/constants/batches";
import {GET_BATCH_IDS, GET_SPLIT_BATCH_IDS} from "graphql/coprocessing/batches";
import {FormGroup, UncontrolledTooltip} from "reactstrap";
import {convertPSTtoUTC} from "modules/co-processing/helpers/formBuilder";
import PIData from "../../Shared/PIData";
import extractBatchIdDetails from "../extractBatchIdDetails";
import {DHDSDateTime, Form} from "../FormElements";

const {keys, labels: fieldLabels} = ADD_BATCH_FORM;
const tankCertDateTooltipId = "tankCertDateTooltip";

export const SplitBatchFields = ({tankNumber, isSplit = false}) => {
  const {control, setValue} = useFormContext();

  const {loading, data} = useQuery(GET_SPLIT_BATCH_IDS, {
    variables: {tankNumber},
    fetchPolicy: "network-only",
    skip: !isSplit || !tankNumber,
  });

  const splitIdOptions = useMemo(
    () =>
      data?.bioLcCoproUsBatchesApi?.body?.split_batch_ids?.map(
        ({next_split, batch_id, tank_certified_datez}) => ({
          value: next_split,
          label: batch_id,
          tank_certified_datez,
        }),
      ) ?? [],
    [data],
  );

  const nextSplitId = useWatch({control, name: keys.batchId});
  const {batchCode, suffix} = extractBatchIdDetails(nextSplitId);

  useEffect(() => {
    const foundItem = splitIdOptions.find(
      (option) => option.value === nextSplitId,
    );

    setValue(keys.batchCode, batchCode);
    setValue(keys.tankCertifiedDate, foundItem?.tank_certified_datez);
  }, [batchCode, setValue, splitIdOptions, nextSplitId]);

  return (
    <div className="row g-6 mb-4">
      <Form.Select
        isLoading={loading}
        label={fieldLabels.ogBatchId}
        options={splitIdOptions}
        name={keys.batchId}
      />
      <Form.Field label={`Split ${suffix} batch ID`}>
        {/* Display currently set split batch ID */}
        <p>{nextSplitId}</p>
      </Form.Field>
    </div>
  );
};

const BypassBatchSelect = ({tankNumber}) => {
  const [tankCertDate, setTankCertDate] = useState(null);
  const [dateValidationError, setDateValidationError] = useState(null);
  const {unregister, setValue} = useFormContext();

  const [fetchBypassBatchIds, {loading, data, error: batchIdsFetchError}] =
    useLazyQuery(GET_BATCH_IDS, {fetchPolicy: "network-only"});

  const handleTankCertDateChange = useCallback(
    (selectedDate) => {
      setTankCertDate(selectedDate);
      setDateValidationError(null);

      if (!selectedDate) {
        setDateValidationError("Please enter a valid date");
        return; // No selected date to process
      }

      unregister("valveOpen");

      fetchBypassBatchIds({
        variables: {
          tankNumber,
          operatingCondition: OP_CONDITIONS.BY_PASSED,
          startTime: convertPSTtoUTC(selectedDate),
        },
      });
    },
    [tankNumber, fetchBypassBatchIds, unregister],
  );

  const bypassBatchOptions = useMemo(() => {
    if (!data?.bioLcCoproUsBatchesApi?.body?.batch_ids) return [];

    return data.bioLcCoproUsBatchesApi.body.batch_ids.map((batch) => ({
      label: batch.batch_id,
      value: `${batch.batch_id}_bypass`,
      batchCode: `${batch.batch_code}_bypass`,
      tankCertifiedDate: batch.tank_certified_date,
      tankVolume: batch.tank_volume,
      sourceSampleCode: batch?.source_sample_code
        ? JSON.parse(batch.source_sample_code) // API returns stringified array
        : [],
    }));
  }, [data]);

  const selectedBatchId = useWatch({name: keys.batchId});

  const selectedBatch = useMemo(
    () => bypassBatchOptions.find((option) => option.value === selectedBatchId),
    [bypassBatchOptions, selectedBatchId],
  );

  useEffect(() => {
    if (selectedBatch) {
      setValue(keys.batchCode, selectedBatch.batchCode);
      setValue(keys.sourceSampleCode, selectedBatch.sourceSampleCode);
      setValue(keys.tankCertifiedDate, selectedBatch.tankCertifiedDate);
      setValue("tank_volume", selectedBatch?.tankVolume);
    }
  }, [selectedBatch, setValue, tankCertDate]);

  if (batchIdsFetchError) {
    return <p>Error loading batch IDs: {batchIdsFetchError.message}</p>;
  }

  return (
    <div className="row g-6 mb-4">
      <FormGroup className="col-md-6">
        <Form.DateTimePicker
          label={
            <>
              Tank Certified Date
              <span
                className="label-info pl-1 text-[#002bbc] cursor-pointer"
                id={tankCertDateTooltipId}
              >
                ⓘ
              </span>
              <UncontrolledTooltip
                popperClassName="tooltip-light"
                placement="top"
                target={tankCertDateTooltipId}
              >
                Approximate Tank Certified Date used to search for sample ID
              </UncontrolledTooltip>
            </>
          }
          error={dateValidationError}
          onChange={handleTankCertDateChange}
          value={tankCertDate}
          className="col-sm-6"
        />
      </FormGroup>
      <Form.Select
        name={keys.batchId}
        label={fieldLabels.batchId}
        isLoading={loading}
        options={bypassBatchOptions}
      />
    </div>
  );
};

const StepThree = () => {
  const {getValues} = useFormContext();

  const [isSplit, tankNo, operatingCondition] = getValues([
    keys.splitBatch,
    keys.tankNo,
    keys.opCondition,
  ]);

  if (isSplit) {
    return <SplitBatchFields isSplit={isSplit} tankNumber={tankNo} />;
  }

  if (operatingCondition === OP_CONDITIONS.BY_PASSED) {
    return <BypassBatchSelect tankNumber={tankNo} />;
  }

  return (
    <div className="row g-6 mb-4">
      <DHDSDateTime />
      <PIData />
    </div>
  );
};

export default StepThree;

SplitBatchFields.propTypes = {
  isSplit: PropTypes.bool,
  tankNumber: PropTypes.string,
};
BypassBatchSelect.propTypes = {tankNumber: PropTypes.string};
