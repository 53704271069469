import {startCase} from "lodash";
import {useAppSetting} from "providers/appSetting/context.js";
import React from "react";
import {useParams} from "react-router-dom";
import {setCountryDetails} from "utils/helpers/getAppSetting.js";
import Layout from "../Layout";

import OutgoingDocTableView from "./OutgoingDocTableView";

const OutgoingDocumentPage = () => {
  const {appSetting} = useAppSetting();
  const {country: countryName} = useParams();
  setCountryDetails(countryName);

  const breadcrumbItems = [
    {text: "BioVerse", link: "/"},
    {
      text: startCase(appSetting?.currentCountry?.toLowerCase() ?? ""),
      link: "/",
    },
    {text: "Outgoing documents", link: ""},
  ];

  return (
    <Layout title="Outgoing documents" breadcrumbs={breadcrumbItems}>
      <div className="flex flex-col justify-stretch w-full items-start px-7">
        <OutgoingDocTableView />
      </div>
    </Layout>
  );
};

export default OutgoingDocumentPage;
