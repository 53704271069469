import {useMutation, useQuery} from "@apollo/client";
import {Filter24} from "@bphxd/ds-core-react/lib/icons";
import {
  actualisationErrorCodes,
  actualisationTypes,
  validErrorCodes,
} from "constants/actualization";
import {NUMBER_OF_DECIMALS} from "constants/common";
import {DIV_CODE_COPRO} from "constants/divisionDetails";
import {
  ACTUALIZATION_MUTATION,
  GLOBAL_GET_INCOMING_DOCUMENTS_ACTUALIZATION,
  GLOBAL_GET_PURCHASE_DOCUMENTS_ACTUALIZATION,
} from "graphql/globalLinkToPurchase/LinkToPurchase";
import _, {isEmpty, startCase} from "lodash";
import ResponseModal from "modules/common/ResponseModal";
import {useAppSetting} from "providers/appSetting";
import {useUserSettings} from "providers/userSettings";
import React, {useEffect, useMemo, useState} from "react";
import {Link, useNavigate, useParams, useSearchParams} from "react-router-dom";
import {toast} from "react-toastify";
import {Button, Spinner} from "reactstrap";
import appendParamsToUrl from "utils/helpers/appendParamsToUrl";
import {
  getDivisionData,
  getSiteDetails,
  setCountryDetails,
} from "utils/helpers/getAppSetting";
import {getFormattedNumberWithCheck} from "utils/numberUtil";
import SettingsModal from "../../common/StatusModals/SettingsModal";
import Layout from "../components/Layout";
import ActualisationToleranceModal from "../components/LinkToPurchaseTable/ActualisationToleranceModal";
import getColumns from "../components/LinkToPurchaseTable/Columns/getColumns";
import CustomizeTable from "../components/LinkToPurchaseTable/CustomizeTable";
import DocumentTable from "../components/LinkToPurchaseTable/DocumentTable";
import {saf_urls} from "../constants/common";
import {getUniqueList, parseDateString} from "../utils";
import "./tableStyle.css";

const LinkToPurchaseLandingPage = () => {
  const {country = "spain", division = "copro"} = useParams();
  const [showFilter, setShowFilter] = useState(false);
  const [filtering, setFiltering] = useState("");
  const [columnFilter, setColumnFilter] = useState([]);
  const [rowSelection, setRowSelection] = useState({});
  const [purchaseRowSelection, setPurchaseRowSelection] = useState({});
  const [incomingTotal, setIncomingTotal] = useState(0);
  const [purchaseTotal, setPurchaseTotal] = useState(0);
  const [receivedTotal, setReceivedTotal] = useState(0);
  const [filteredIncomingRows, setFilteredIncomingRows] = useState([]);
  const [filteredPurchaseRows, setFilteredPurchaseRows] = useState([]);
  const [incomingDocFinalData, setIncomingDocFinalData] = useState([]);
  const [actualizedLocationGroup, setActualizedLocationGroup] = useState("");
  const [actualizedBalanceGroup, setActualizedBalanceGroup] = useState("");

  const {
    userSettings: {dateFormat},
  } = useUserSettings();

  const {appSetting} = useAppSetting();

  setCountryDetails(country);

  const countryId = appSetting?.currentCountryMappingData?.countryId;
  const siteReferenceData = getSiteDetails(countryId);
  const divisionData = getDivisionData(division.toUpperCase());
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const contract_number = searchParams.get("contract_no");
  const encodedContractNumber = searchParams.get("contractNumber");
  const contractNumberReceived = decodeURIComponent(encodedContractNumber);
  const encodedBalanceGroup = searchParams.get("balanceGroup");
  const balanceGroupReceived = decodeURIComponent(encodedBalanceGroup);
  const encodedlocationGroup = searchParams.get("locationGroup");
  const locationGroupReceived = decodeURIComponent(encodedlocationGroup);
  const encodedDocNumberSplit = searchParams.get("docNumberSplit");
  const docNumberSplitReceived = decodeURIComponent(encodedDocNumberSplit);
  const encodedsdNumber = searchParams.get("sdNumber");
  const sdNumberReceived = decodeURIComponent(encodedsdNumber);
  const [showResponseModal, setShowResponseModal] = useState(false);
  const [responseHeader, setResponseHeader] = useState("");
  const [responseBody, setResponseBody] = useState("");
  const [responseFooter, setResponseFooter] = useState([]);
  const [errorCode, setErrorCode] = useState();
  const [showActualisationModal, setShowActualisationModal] = useState(false);
  const selectedId = searchParams.get("selectedId");

  useEffect(() => {
    if (contract_number) {
      const convertedObjects = [
        {id: "deal_number", value: contract_number},
        {id: "contract_number", value: contract_number},
      ];
      setColumnFilter(convertedObjects);
    }
  }, [contract_number]);

  const getSelectedRecords = (data, rowSelected) => {
    return Object.keys(rowSelected).map((item) =>
      data.find((ele) => ele.id === item),
    );
  };

  const purchaseQtyKey = useMemo(() => {
    switch (division.toUpperCase()) {
      case DIV_CODE_COPRO:
        return "purchase_qty_mt";
      default:
        return "quantity_actual";
    }
  }, [division]);

  const getUnitOfMeasure = useMemo(() => {
    switch (division.toUpperCase()) {
      case DIV_CODE_COPRO:
        return "MT";
      default:
        return "m³";
    }
  }, [division]);

  useEffect(() => {
    const calculateSum = (data, rowSelected, field) => {
      if (data.length > 0) {
        const isSelected = Object.keys(rowSelected).length > 0;
        const selectedRecords = isSelected
          ? getSelectedRecords(data, rowSelected)
          : data;

        return selectedRecords.reduce(
          (sum, item) => sum + parseFloat(item?.original[field]),
          0,
        );
      }
      return 0;
    };

    const sumIncoming = calculateSum(
      filteredIncomingRows,
      rowSelection,
      "product_qty",
    );
    const sumPurchase = calculateSum(
      filteredPurchaseRows,
      purchaseRowSelection,
      purchaseQtyKey,
    );
    const sumReceived = calculateSum(
      filteredPurchaseRows,
      purchaseRowSelection,
      "received_qty_mt",
    );

    setIncomingTotal(sumIncoming);
    setPurchaseTotal(sumPurchase);
    setReceivedTotal(sumReceived);
  }, [
    rowSelection,
    purchaseRowSelection,
    filteredIncomingRows,
    filteredPurchaseRows,
    purchaseQtyKey,
  ]);

  const breadcrumbItems = [
    {text: "BioVerse", link: "/"},
    {text: division.toUpperCase(), link: "/"},
    {
      text: startCase(appSetting?.currentCountry?.toLowerCase() ?? ""),
      link: "/",
    },
    {text: "Link to purchase"},
  ];

  const {incomingCols, purchaseCols} = useMemo(
    () =>
      getColumns(
        division.toUpperCase(),
        dateFormat,
        siteReferenceData?.siteReferenceId,
        divisionData?.divisionId,
        encodedContractNumber,
        encodedBalanceGroup,
        encodedlocationGroup,
        encodedDocNumberSplit,
        encodedsdNumber,
      ),
    [
      division,
      dateFormat,
      siteReferenceData?.siteReferenceId,
      divisionData?.divisionId,
      encodedContractNumber,
      encodedBalanceGroup,
      encodedlocationGroup,
      encodedDocNumberSplit,
      encodedsdNumber,
    ],
  );

  const [incomingColumns, setIncomingColumns] = useState(incomingCols);
  const [purchaseColumns, setPurchaseColumns] = useState(purchaseCols);

  useEffect(() => {
    if (incomingCols) {
      setIncomingColumns(incomingCols);
    }
    if (purchaseCols) {
      setPurchaseColumns(purchaseCols);
    }
  }, [incomingCols, purchaseCols, dateFormat]);

  const filteredIncomingColumns = useMemo(() => {
    return [
      {
        header: incomingColumns?.[0]?.header,
        columns: incomingColumns?.[0]?.columns.filter(({visible}) => visible),
      },
    ];
  }, [incomingColumns]);

  const filteredPurchaseColumns = useMemo(() => {
    return [
      {
        header: purchaseColumns?.[0]?.header,
        columns: purchaseColumns?.[0]?.columns.filter(({visible}) => visible),
      },
    ];
  }, [purchaseColumns]);

  const {
    data: incomingData,
    loading: incomingLoading,
    refetch: incomingRefetch,
  } = useQuery(GLOBAL_GET_INCOMING_DOCUMENTS_ACTUALIZATION, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "network-only",
    variables: {
      site_reference_id: siteReferenceData?.siteReferenceId,
      division_id: divisionData?.divisionId,
    },
    skip:
      siteReferenceData?.siteReferenceId === undefined ||
      divisionData?.divisionId === undefined,
  });

  const {
    data: purchaseData,
    loading: purchaseLoading,
    refetch: purchaseRefetch,
  } = useQuery(GLOBAL_GET_PURCHASE_DOCUMENTS_ACTUALIZATION, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "network-only",
    variables: {
      site_reference_id: siteReferenceData?.siteReferenceId,
      division_id: divisionData?.divisionId,
    },
    skip:
      siteReferenceData?.siteReferenceId === undefined ||
      divisionData?.divisionId === undefined,
  });

  const incomingDocData =
    incomingData?.bioLcGetActualizationIncomingDocs?.documents;
  const purchaseDocData =
    purchaseData?.bioLcGetActualizationPurchaseDocs?.documents;

  const incomingTableData = useMemo(() => {
    return (incomingDocData ?? []).map((document) => ({
      contract_number: document.contract_number,
      doc_number_split: document.doc_number_split || "",
      sd_number: document.sd_number || "",
      material_dispatch_datez: parseDateString(
        document.material_dispatch_datez,
      ),
      energy_mj_incoming: document.energy_mj
        ? parseFloat(document.energy_mj)
        : 0,
      ghg_gco2eq_incoming:
        document.ghg_gco2eq && parseFloat(document.ghg_gco2eq),
      supplier_dispatch_address: document.supplier_dispatch_address,
      product_type: document.product_type,
      raw_material: document.raw_material,
      country_of_origin: document.country_of_origin,
      product_qty_uom: document.product_qty_uom,
      certificate_inbound_eu_id: document.certificate_inbound_eu_id,
      incoming_location_group_name: document.location_group_name,
      product_qty: document.product_qty ? parseFloat(document.product_qty) : 0,
      product_qty_m3: document.product_qty_m3
        ? parseFloat(document.product_qty_m3)
        : 0,
      supplier_name: document.supplier_name,
      recipient_address: document.recipient_address,
      incoming_balance_group_name: document.balance_group_name,
    }));
  }, [incomingDocData]);

  const sortTableData = (
    data,
    contractNumber,
    locationGroup,
    balanceGroup,
    docNumberSplitValue,
    sdNumber,
  ) => {
    const checkContractNumber = (received_contract_number, contract_number) => {
      if (received_contract_number === "null") return true;
      return received_contract_number === contract_number;
    };

    const result = _.orderBy(
      data,
      [
        (item) =>
          checkContractNumber(contractNumber, item.contract_number) &&
          item.incoming_location_group_name === locationGroup &&
          item.incoming_balance_group_name === balanceGroup &&
          item.sd_number === sdNumber &&
          (docNumberSplitValue === null ||
            item.doc_number_split === docNumberSplitValue)
            ? 0
            : 1,
        (item) =>
          checkContractNumber(contractNumber, item.contract_number) &&
          item.incoming_location_group_name === locationGroup &&
          item.incoming_balance_group_name === balanceGroup &&
          item.sd_number === sdNumber
            ? 0
            : 1,
        (item) => (item.contract_number === contractNumber ? 0 : 1),
      ],
      ["asc", "asc", "asc"],
    );
    return result;
  };

  const purchaseTableData = useMemo(() => {
    return (purchaseDocData ?? []).map((document) => ({
      po_number: document.po_number,
      purchase_date: parseDateString(document.purchase_date),
      discharge_date: parseDateString(document.discharge_date),
      vendor_name: document.vendor_name,
      plant_name: document.plant_name,
      mb_date: parseDateString(document.mb_date),
      purchase_balance_group_name: document.balance_group_name,
      received_qty_mt: document.received_qty_mt
        ? parseFloat(document.received_qty_mt)
        : 0,
      purchase_qty_mt: document.purchase_qty_mt
        ? parseFloat(document.purchase_qty_mt)
        : 0,
      deal_number: document.deal_number,
      deal_done_date: parseDateString(document.deal_done_date),
      material_number: document.material_code,
      material_description: document.material_description,
      purchase_plant_code: document.purchase_plant_code,
      discharge_plant_code: document.discharge_plant_code,
      source_location_name: document.source_location_name,
      purchase_location_group_name: document.location_group_name,
      destination_location_name: document.destination_location_name,
      purchase_layer_id: document.purchase_layer_id,
      purchase_qty_m3: document.purchase_qty_m3
        ? parseFloat(document.purchase_qty_m3)
        : 0,
      received_qty_m3: document.received_qty_m3
        ? parseFloat(document.received_qty_m3)
        : 0,
      quantity_actual: document.quantity_actual
        ? parseFloat(document.quantity_actual)
        : 0,
      energy_mj_purchase: document.energy_mj
        ? parseFloat(document.energy_mj)
        : 0,
      quantity_m3_sbc: document.quantity_m3_sbc
        ? parseFloat(document.quantity_m3_sbc)
        : 0,
      quantity_m3_hdr: document.quantity_m3_hdr
        ? parseFloat(document.quantity_m3_hdr)
        : 0,
      ghg_gco2eq: document.ghg_gco2eq ? parseFloat(document.ghg_gco2eq) : 0,
      ghg_kgco2eq_mj: document.ghg_kgco2eq_mj
        ? parseFloat(document.ghg_kgco2eq_mj)
        : 0,
    }));
  }, [purchaseDocData]);

  useEffect(() => {
    if (purchaseTableData && selectedId) {
      for (let index = 0; index < purchaseTableData.length; index += 1) {
        const item = purchaseTableData[index];
        if (item.purchase_layer_id === selectedId) {
          setPurchaseRowSelection({[index]: true});
          break;
        }
      }
    }
  }, [purchaseTableData, selectedId]);
  const isIncomingSelected = Object.keys(rowSelection).length > 0;
  const isPurchaseSelected = Object.keys(purchaseRowSelection).length > 0;

  const noData = incomingDocData?.length === 0 && purchaseDocData?.length === 0;

  // Setting value to show/not show shadow at table footer
  const moreData =
    filteredIncomingRows?.length > 14 || filteredPurchaseRows?.length > 14;

  const handleNoLinkage = () => {
    setResponseHeader("Quantities do not match");
    const body = (
      <>
        <span>
          You cannot link a purchased sustainable quantity that is different
          from the document quantity.
        </span>
        <br />
        <br />
        <span>
          Please check the quantities you are trying to link and try again, or
          reject the document if there is an error.
        </span>
      </>
    );
    setResponseBody(body);
    setResponseFooter([
      {
        id: 1,
        text: "Try again",
        buttonClassName: "!text-[#111]",
        action: () => {
          setShowResponseModal(false);
        },
      },
      {
        id: 2,
        text: "Cancel",
        buttonClassName: "!text-[#111111a3]",
        action: () => {
          setRowSelection({});
          setPurchaseRowSelection({});
          setShowResponseModal(false);
        },
      },
    ]);
    setShowResponseModal(true);
  };

  const handleToleranceModal = (errCode) => {
    let text1 = "";
    let text2;

    if (errCode === actualisationErrorCodes.WRITE_OFF) {
      text1 = "Link and write off excess quantity";
    } else if (errCode === actualisationErrorCodes.REMINDER) {
      text1 = "Link and keep remaining quantity";
    } else {
      text1 = "Link and keep remaining quantity";
      text2 = "Link with full quantity";
    }
    let footerArr = [];
    if (text2) {
      footerArr = [
        {
          id: 1,
          text: "Try again",
          buttonClassName: "!text-[#111]",
          action: () => {
            setShowResponseModal(false);
          },
        },
        {
          id: 2,
          text: text1,
          buttonClassName: "!text-[#111111a3]",
          action: () => {
            setShowActualisationModal(true);
            setShowResponseModal(false);
            setErrorCode(actualisationErrorCodes.REMINDER);
          },
        },
        {
          id: 3,
          text: text2,
          buttonClassName: "!text-[#111111a3]",
          action: () => {
            setShowActualisationModal(true);
            setShowResponseModal(false);
          },
        },
      ];
    } else {
      footerArr = [
        {
          id: 1,
          text: "Try again",
          buttonClassName: "!text-[#111]",
          action: () => {
            setShowResponseModal(false);
          },
        },
        {
          id: 2,
          text: text1,
          buttonClassName: "!text-[#111111a3]",
          action: () => {
            setShowActualisationModal(true);
            setShowResponseModal(false);
          },
        },
      ];
    }

    setResponseHeader("Quantities do not match");
    const body = (
      <>
        <span>Quantities are more than 0.5% misaligned.</span>
        <br />
        <br />
        <span>
          Please check the quantities you are trying to link and try again, or
          reject the document if there are any errors.
        </span>
        <br />
        <br />
        <span>
          If you want to proceed, you must provide a reason for matching outside
          of 0.5% tolerance.
        </span>
      </>
    );
    setResponseBody(body);
    setResponseFooter(footerArr);
    setShowResponseModal(true);
  };

  const handleSuccessModal = (isError = undefined, massBalance = undefined) => {
    setResponseHeader("Successfully linked");
    const body = isError
      ? "Successfully linked document quantity  with received quantity within the 0.5% tolerance range as governed by ISCC."
      : "Successfully linked document quantity with received quantity.";
    setResponseBody(body);
    setResponseFooter([
      {
        id: 1,
        text: "Link another",
        buttonClassName: "!text-[#111]",
        action: () => {
          setRowSelection({});
          setPurchaseRowSelection({});
          setShowResponseModal(false);
        },
      },
      {
        id: 2,
        text: "Incoming document",
        buttonClassName: "!text-[#111111a3]",
        action: () => {
          navigate(`/doc-manager/${country}`);
        },
      },
      {
        id: 3,
        text: "Mass balance",
        buttonClassName: "!text-[#111111a3]",
        action: () => {
          navigate(
            appendParamsToUrl("/mass-balance", [
              country,
              division,
              massBalance?.type,
              massBalance?.location,
              massBalance?.period,
              massBalance?.balance,
            ]),
          );
        },
      },
    ]);
    setShowResponseModal(true);
  };

  const [actualizeSplitApi, {loading: mutationLoading}] = useMutation(
    ACTUALIZATION_MUTATION,
    {
      onCompleted: ({bioLcActualization}) => {
        if (bioLcActualization.statusCode === 500) {
          toast.error("Failed to link the documents");
          setShowActualisationModal(false);
        } else if (
          bioLcActualization.statusCode === 400 &&
          !validErrorCodes.includes(bioLcActualization.errorCode)
        ) {
          toast.error(bioLcActualization.error);
          setShowActualisationModal(false);
        } else if (bioLcActualization.statusCode === 400) {
          setShowActualisationModal(false);
          if (
            bioLcActualization.errorCode ===
            actualisationErrorCodes.VOID_LINKAGE
          ) {
            handleNoLinkage();
          } else {
            setErrorCode(bioLcActualization.errorCode);
            handleToleranceModal(bioLcActualization.errorCode);
          }
        } else {
          const massBalanceObjectReceived = {
            country,
            division: divisionData?.divisionCode,
            type: bioLcActualization?.mbBalanceType,
            location: actualizedLocationGroup,
            balance: actualizedBalanceGroup,
            period: bioLcActualization?.mbPeriodName,
          };
          handleSuccessModal(
            bioLcActualization?.splitType && !errorCode,
            massBalanceObjectReceived,
          );
          setRowSelection({});
          setPurchaseRowSelection({});
          incomingRefetch();
          purchaseRefetch();
          setShowActualisationModal(false);
        }
      },
    },
  );

  let pos_ids;
  let purchase_ids;
  const [purchaseIds, setPurchaseIds] = useState([]);
  const [posIds, setPosIds] = useState([]);

  const gettingSelectedValues = () => {
    pos_ids = Object.keys(rowSelection)
      .map((item) => filteredIncomingRows.find((ele) => ele.id === item))
      .map((selectedItem) => selectedItem.original.certificate_inbound_eu_id);
    setPosIds(pos_ids);

    if (pos_ids.length > 0) {
      const firstPosId = pos_ids[0];
      const firstItem = filteredIncomingRows.find(
        (ele) => ele.original.certificate_inbound_eu_id === firstPosId,
      );
      if (firstItem) {
        setActualizedBalanceGroup(
          firstItem.original.incoming_balance_group_name,
        );
        setActualizedLocationGroup(
          firstItem.original.incoming_location_group_name,
        );
      }
    }

    purchase_ids = Object.keys(purchaseRowSelection)
      .map((item) => filteredPurchaseRows.find((ele) => ele.id === item))
      .map((selectedItem) => selectedItem.original.purchase_layer_id);
    setPurchaseIds(purchase_ids);

    if (purchase_ids.length > 0) {
      const firstPurchaseId = purchase_ids[0];
      const firstItem = filteredPurchaseRows.find(
        (ele) => ele.original.purchase_layer_id === firstPurchaseId,
      );
      if (firstItem) {
        if (!isEmpty(firstItem.original.purchase_balance_group_name)) {
          setActualizedBalanceGroup(
            firstItem.original.purchase_balance_group_name,
          );
        }
        if (!isEmpty(firstItem.original.purchase_location_group_name)) {
          setActualizedLocationGroup(
            firstItem.original.purchase_location_group_name,
          );
        }
      }
    }
  };

  const handleLinkage = (type = undefined, reason = undefined) => {
    gettingSelectedValues();
    actualizeSplitApi({
      variables: {
        event: {
          division_id: divisionData?.divisionId,
          site_reference_id: siteReferenceData?.siteReferenceId,
          pos_ids,
          purchase_ids,
          split_type: type,
          remarks: reason,
        },
      },
    });
  };

  const [showSettings, setShowSettings] = useState(false);

  useEffect(() => {
    const checkContractNumber = (received_contract_number, contract_number) => {
      if (received_contract_number === "null") return true;
      return received_contract_number?.trim() === contract_number?.trim();
    };

    if (
      incomingTableData &&
      contractNumberReceived &&
      locationGroupReceived &&
      balanceGroupReceived &&
      sdNumberReceived
    ) {
      const data = sortTableData(
        incomingTableData,
        contractNumberReceived,
        locationGroupReceived,
        balanceGroupReceived,
        docNumberSplitReceived,
        sdNumberReceived,
      );
      setIncomingDocFinalData(data);
      const newRowSelection = {};
      for (let index = 0; index < data.length; index += 1) {
        const item = data[index];
        if (
          checkContractNumber(contractNumberReceived, item.contract_number) &&
          item.incoming_location_group_name?.trim() ===
            locationGroupReceived?.trim() &&
          item.incoming_balance_group_name?.trim() ===
            balanceGroupReceived?.trim() &&
          item.sd_number?.trim() === sdNumberReceived?.trim() &&
          (docNumberSplitReceived === "null" ||
            item.doc_number_split?.trim() === docNumberSplitReceived?.trim())
        ) {
          newRowSelection[index] = true;
        }
      }
      setRowSelection(newRowSelection);
    } else if (incomingTableData) {
      setIncomingDocFinalData(incomingTableData);
    }
  }, [
    incomingTableData,
    contractNumberReceived,
    balanceGroupReceived,
    locationGroupReceived,
    docNumberSplitReceived,
    sdNumberReceived,
  ]);

  const getLinkageType = useMemo(() => {
    if (errorCode === actualisationErrorCodes.WRITE_OFF) {
      return actualisationTypes.WRITE_OFF;
    }
    if (errorCode === actualisationErrorCodes.REMINDER) {
      return actualisationTypes.REMINDER;
    }
    return actualisationTypes.FULL_MATCH;
  }, [errorCode]);

  return (
    <Layout title="Link to purchase" breadcrumbs={breadcrumbItems}>
      <div className="flex flex-col justify-stretch w-full items-start px-7 mt-[32px]">
        <div className=" flex flex-row justify-between w-full p-4 bg-white">
          <div>
            <CustomizeTable
              columns={incomingColumns}
              onColumnChange={(cols) => setIncomingColumns(cols)}
              purchaseColumns={purchaseColumns}
              onPurchaseChange={(cols) => setPurchaseColumns(cols)}
              showApplyButton={false}
            />
          </div>
          <div className="flex flex-none flex-row items-center">
            {columnFilter.length > 0 && (
              <div className="mr-2">
                <Button
                  data-test="copro-filter-btn"
                  color="standard-tertiary rounded-0"
                  onClick={() => setColumnFilter([])}
                >
                  Clear all
                </Button>
              </div>
            )}
            <div>
              <Button
                className={showFilter ? "border-black" : ""}
                data-test="copro-filter-btn"
                color="standard-tertiary rounded-0"
                onClick={() => setShowFilter(!showFilter)}
              >
                Filters
                <Filter24 className="btn-icon-suffix" />
              </Button>
            </div>
          </div>
        </div>
        <div
          className={`flex border-t-[1px] border-[#ededed] w-full overflow-y-auto overflow-x-hidden ${
            noData ? "" : "min-h-[400px] max-h-[850px]"
          }`}
        >
          <div className="min-w-[50%] bg-white">
            <div className="scrollable-container">
              <div className="table-wrapper">
                <DocumentTable
                  columns={filteredIncomingColumns}
                  incomingCols={incomingCols}
                  data={incomingDocFinalData || []}
                  className="incoming-docs-table"
                  loading={incomingLoading}
                  filtering={filtering}
                  setFiltering={setFiltering}
                  columnFilter={columnFilter}
                  setColumnFilter={setColumnFilter}
                  type="Incoming"
                  enableRowSelection
                  rowSelection={rowSelection}
                  setRowSelection={setRowSelection}
                  otherRows={purchaseRowSelection}
                  otherData={purchaseTableData || []}
                  setFilteredIncomingRows={setFilteredIncomingRows}
                  showFilter={showFilter}
                  contractNumbers={getUniqueList(
                    incomingDocFinalData,
                    "contract_number",
                  )}
                  division={division}
                />
              </div>
            </div>
          </div>
          <div
            style={{
              width: "2px",
              margin: "0 0.3px 0 0.7px",
              border: "solid 3px #fafafa",
            }}
          ></div>
          <div className="min-w-[50%] bg-white">
            <div className="scrollable-container">
              <div className="table-wrapper">
                <DocumentTable
                  columns={filteredPurchaseColumns}
                  purchaseCols={purchaseCols}
                  data={purchaseTableData || []}
                  className="incoming-docs-table"
                  loading={purchaseLoading}
                  filtering={filtering}
                  setFiltering={setFiltering}
                  columnFilter={columnFilter}
                  setColumnFilter={setColumnFilter}
                  type="Purchase"
                  enableRowSelection
                  rowSelection={purchaseRowSelection}
                  setRowSelection={setPurchaseRowSelection}
                  otherRows={rowSelection}
                  otherData={incomingDocFinalData || []}
                  setFilteredPurchaseRows={setFilteredPurchaseRows}
                  showFilter={showFilter}
                  dealNumbers={getUniqueList(purchaseTableData, "deal_number")}
                  division={division}
                />
              </div>
            </div>
          </div>
        </div>
        {noData && (
          <div className="w-full text-center h-96 mt-[-15px] bg-white">
            <p className="m-10">
              It appears that you have actualized all available incoming
              documents with purchases.
            </p>
            <p>
              Back to{" "}
              <Link
                to={saf_urls[`${country}DocumentPage`]}
                className="!underline text-black"
              >
                Incoming documents
              </Link>{" "}
              or{" "}
              <Link
                to={saf_urls[`${country}MassBalance`]}
                className="!underline text-black"
              >
                Mass balance
              </Link>
            </p>
          </div>
        )}
        <div
          className={`w-full flex items-center h-16 bg-white z-10 ${
            moreData
              ? "[box-shadow:0_-6px_10px_0_rgba(17,_17,_17,_0.08)]"
              : "mt-[3px]"
          }`}
        >
          <div className="w-1/2 text-end mr-14">
            {!incomingLoading && filteredIncomingRows.length > 0 && (
              <>
                <span className="text-gray-700 mr-[10px]">
                  {Object.keys(rowSelection).length > 0
                    ? "Selected quantity"
                    : "Total quantity"}
                </span>
                <span>
                  {getFormattedNumberWithCheck(
                    incomingTotal,
                    NUMBER_OF_DECIMALS,
                  )}{" "}
                  {getUnitOfMeasure}
                </span>
              </>
            )}
          </div>
          <div className="w-1/2 text-end mr-14">
            {!purchaseLoading && filteredPurchaseRows.length > 0 && (
              <>
                <span className="text-gray-700 mr-[10px]">
                  {Object.keys(purchaseRowSelection).length > 0
                    ? "Selected purchased quantity"
                    : "Total purchased quantity"}
                </span>
                <span>
                  {getFormattedNumberWithCheck(
                    purchaseTotal,
                    NUMBER_OF_DECIMALS,
                  )}{" "}
                  {getUnitOfMeasure}
                </span>
              </>
            )}
          </div>
        </div>
      </div>
      <div className="flex flex-col justify-stretch w-full items-start px-7 mt-6">
        <div className=" flex flex-row justify-end w-full mb-5">
          <Button
            color="tertiary"
            outline
            type="button"
            size="md"
            disabled={!isIncomingSelected && !isPurchaseSelected}
            className="show link-btn rounded-0"
            onClick={() => {
              setRowSelection([]);
              setPurchaseRowSelection([]);
            }}
          >
            Cancel
          </Button>
          <SettingsModal
            title="Settings"
            visible={showSettings}
            onCancel={() => {
              setShowSettings(false);
            }}
          />
          <Button
            color="primary"
            type="secondary"
            className="float-right rounded-0 text-gray-400"
            size="md"
            disabled={
              !isIncomingSelected || !isPurchaseSelected || mutationLoading
            }
            onClick={() => handleLinkage()}
          >
            {mutationLoading && (
              <Spinner size="sm" className="btn-icon-prefix" />
            )}
            Link
          </Button>
        </div>
      </div>
      {showResponseModal && (
        <ResponseModal
          showModal={showResponseModal}
          header={responseHeader}
          body={responseBody}
          footerArr={responseFooter}
        />
      )}
      {showActualisationModal && (
        <ActualisationToleranceModal
          showModal={showActualisationModal}
          setShowModal={setShowActualisationModal}
          linkageType={getLinkageType}
          incomingTotal={incomingTotal}
          purchaseTotal={purchaseTotal}
          receivedTotal={receivedTotal}
          onSubmit={(data) => {
            handleLinkage(getLinkageType, data.reason);
          }}
          loading={mutationLoading}
        />
      )}
    </Layout>
  );
};

export default LinkToPurchaseLandingPage;
