import {convertToNumber} from "providers/userSettings";

export const DDV = "DDV";
export const TDV = "TDV";

export const parseGhgValues = (values) => {
  return values.map((value) => {
    if (value === DDV) {
      return 0;
    }
    const parsedValue = parseFloat(value) ?? 0;
    return Number.isNaN(parsedValue) ? 0 : parsedValue;
  });
};

export const parseToFloatNumber = (value) => {
  if (value === DDV) {
    return 0;
  }
  const parsedValue = parseFloat(value ?? 0);
  return Number.isNaN(parsedValue) ? 0 : parsedValue;
};

export const parseFormattedGhgValues = (values, decimalFormat) => {
  return values.map((value) => {
    if (value === DDV) {
      return 0;
    }

    return convertToNumber(value ?? "0", decimalFormat);
  });
};
