import {Kebab24} from "@bphxd/ds-core-react/lib/icons";
import PropTypes from "prop-types";
import {useNavigate, useParams} from "react-router-dom";
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from "reactstrap";
import appendParamsToUrl from "utils/helpers/appendParamsToUrl";

const ActionDropdown = ({row}) => {
  const {division, country} = useParams();
  const divisionCode = division.toUpperCase();

  const navigate = useNavigate();

  const mbTypeCode = row?.original?.mbBalanceTypeCode;
  const locationGroupName = row?.original?.mbLocationGroupName;
  const periodName = row?.original?.mbPeriodStatusName;
  const balanceGroupName = row?.original?.mbBalanceGroupName;

  const redirectUrl = appendParamsToUrl("/mass-balance", [
    country,
    divisionCode,
    mbTypeCode,
    locationGroupName,
    periodName,
    balanceGroupName,
  ]);

  return (
    <UncontrolledDropdown direction="start">
      <DropdownToggle color="standard-quartenary" className="!px-0" caret>
        <Kebab24 />
      </DropdownToggle>
      <DropdownMenu container="body">
        <DropdownItem
          key="1"
          onClick={() => {
            navigate(redirectUrl);
          }}
          data-test="data-test-mb-list-table-action-menu"
        >
          View mass balance
        </DropdownItem>
      </DropdownMenu>
    </UncontrolledDropdown>
  );
};

ActionDropdown.propTypes = {
  row: PropTypes.object,
};

export default ActionDropdown;
