export const RULE_EDITOR_LABELS = {
  BLOCKS: "Blocks",
  DATA: "Data",
  SELECT_DATA: "Select data",
  WRITE_DATA: "Write data",
  CONVERT_UNITS: "Convert units",
  CALCULATE: "Calculate",
  CALCULATION: "Calculation",
  EMISSIONS: "Emissions",
  CONVERSION: "Conversion",
  COMPLIANCE_REGULATIONS: "Compliance regulations",
  ENERGY_DENSITY: "Energy density",
  CARBON_INTENSITY: "Carbon intensity",
};

export default RULE_EDITOR_LABELS;
