import getColumnsCoproCppSpain from "./columnsCoproCppSpain";
import getColumnsGfTrsSpain from "./columnsGfTrsSpain";
import getColumnsGfTrsUk from "./columnsGfTrsUk";
import getColumnsSafTrTrs from "./columnsSafTrTrs";

const {DIV_CODE_GF, DIV_CODE_COPRO} = require("constants/divisionDetails");
const {massBalanceType} = require("modules/GlobalMassBalance/constants");
const {countryNames} = require("modules/GlobalMassBalance/constants");
const {default: getColumnsCoproTrsSpain} = require("./columnsCoproTrsSpain");

const getColumns = (
  country,
  division,
  dateFormat,
  decimalFormat,
  navigateToAllocation,
  currentFilterValues,
  siteReferenceId,
  divisionId,
  periodStatus,
) => {
  const currentMBType = currentFilterValues?.mbBalanceTypeCode?.toLowerCase();
  switch (division + currentMBType + country.toUpperCase()) {
    case DIV_CODE_GF + massBalanceType.TRS + countryNames.SPAIN:
      return getColumnsGfTrsSpain(
        dateFormat,
        decimalFormat,
        country,
        navigateToAllocation,
        siteReferenceId,
        divisionId,
        currentFilterValues,
        periodStatus,
      );
    case DIV_CODE_GF + massBalanceType.TRS + countryNames.UNITED_KINGDOM:
      return getColumnsGfTrsUk(
        dateFormat,
        decimalFormat,
        country,
        siteReferenceId,
        divisionId,
        currentFilterValues,
        periodStatus,
      );
    case DIV_CODE_COPRO + massBalanceType.CPP + countryNames.SPAIN:
      return getColumnsCoproCppSpain(
        dateFormat,
        decimalFormat,
        country,
        siteReferenceId,
        divisionId,
        currentFilterValues,
        division,
        periodStatus,
      );
    case DIV_CODE_COPRO + massBalanceType.TRS + countryNames.SPAIN:
      return getColumnsCoproTrsSpain(
        dateFormat,
        decimalFormat,
        country,
        siteReferenceId,
        divisionId,
        currentFilterValues,
        division,
        periodStatus,
      );
    default:
      return getColumnsSafTrTrs(
        dateFormat,
        decimalFormat,
        country,
        navigateToAllocation,
        currentFilterValues,
        siteReferenceId,
        divisionId,
        division,
        periodStatus,
      );
  }
};

export default getColumns;
