import {gql} from "@apollo/client";
import axios from "axios";
import {client} from "providers/Apollo";

export const SAVE_USER_FEEDBACK = gql`
  mutation userFeedBack($event: userFeedBackRequest!) {
    userFeedBack(event: $event) {
      statusCode
      adoTicketId
    }
  }
`;

export const GET_USERFEEDBACK_UPLOAD_URL = gql`
  query getPreSignedUrl($bucket: String!, $key: String!) {
    getPreSignedUrl(event: {key: $key, bucket: $bucket}) {
      statusCode
      uploadUrl
    }
  }
`;

export const uploadFile = async (file, fileName) => {
  const isResponseSuccess = (code) => [200, 204].includes(code);

  const buildFormData = (fileData, fields) => {
    const formData = new FormData();
    if (fields) {
      Object.entries(fields).forEach(([key, value]) => {
        formData.append(key, value);
      });
    }
    formData.append("file", fileData);
    return formData;
  };

  let statusCode;

  try {
    // Allocate s3 bucket
    const {
      data: {getPreSignedUrl: responseFileUpload},
    } = await client.query({
      query: GET_USERFEEDBACK_UPLOAD_URL,
      fetchPolicy: "network-only",
      notifyOnNetworkStatusChange: true,
      variables: {
        key: fileName,
        bucket: "user-feedback",
      },
    });

    statusCode = responseFileUpload?.statusCode;
    if (!isResponseSuccess(statusCode)) {
      return statusCode;
    }

    // Upload binary file
    const body = JSON.parse(responseFileUpload?.uploadUrl);

    const formData = buildFormData(file, body.fields, fileName);
    const uploadBinaryResponse = await axios.post(body.url, formData);
    statusCode = uploadBinaryResponse.status;
    if (!isResponseSuccess(statusCode)) {
      return statusCode;
    }

    // All good
    return 200;
  } catch (error) {
    // Handle errors here
    console.error("Error uploading file:", error);
    return error.response?.status || 500;
  }
};

export const GET_USERFEEDBACK_MASTER_DETAILS = gql`
  query getUserFeedbackMasterDetails {
    bioLcGlobNaviGetModuleCatalogue {
      documentManager
      forecasting
      countryName
      ceemas
      coProcessing
      compliance
      configCenter
      countryId
      countryCode
      reporting
      saf
      drawdown
      groundFuels
    }
    bioLcGetSiteReferenceDetails {
      siteReferenceData {
        countryCode
        countryId
        siteName
        siteReferenceId
      }
      statusCode
      error
    }
    bioLcGetDivisionDetails {
      error
      statusCode
      divisionData {
        divisionCode
        divisionId
        divisionName
      }
    }
  }
`;
