import {
  BTU_ID_PREFIX,
  SHIPMENT_TYPE,
} from "modules/co-processing/constants/shipments";
import React from "react";
import {useFormContext} from "react-hook-form";
import {
  BackToUnitFields,
  InventoryTransferFields,
  TruckRackFields,
} from "../formConfigs";

const fieldOrder = [
  "SHIPMENT_TYPE",
  "SHIPMENT_ID",
  "SHIPMENT_DATE",
  "SHIPMENT_START_DATE",
  "SHIPMENT_END_DATE",
  "ESTIMATED_VOLUME",
  "SOURCE_TANK",
  "NOTES",
];

const inventoryTransferFieldOrder = [
  "SOURCE_TANK",
  "SOURCE_BATCH_ID",
  "DESTINATION_TANK",
  "DESTINATION_BATCH_ID",
  "SHIPMENT_START_DATE",
  "SHIPMENT_END_DATE",
  "ADJUSTED_VOLUME",
  "REMAINING_VOLUME",
  "SHIPMENT_ID",
  "NOTES",
  "SOURCE_RESOURCE_BATCH_ID",
  "DESTINATION__RESOURCE_BATCH_ID",
];

const Review = () => {
  const {getValues} = useFormContext();
  const formValues = getValues();

  const fieldMapping = {
    [SHIPMENT_TYPE.TRUCK_RACK]: {
      fields: TruckRackFields,
      order: fieldOrder,
    },
    [SHIPMENT_TYPE.BACK_TO_UNIT]: {
      fields: BackToUnitFields,
      order: fieldOrder,
    },
    [SHIPMENT_TYPE.INVENTORY_TRANSFER]: {
      fields: InventoryTransferFields,
      order: inventoryTransferFieldOrder,
    },
  };

  const {fields: fieldsToDisplay, order: selectedFieldOrder} =
    fieldMapping[formValues.shipment_type] || {};

  const formatValue = (value, format, identifier) => {
    if (format) {
      return format(value);
    }

    if (
      identifier === "SHIPMENT_ID" &&
      formValues.shipment_type === SHIPMENT_TYPE.BACK_TO_UNIT
    ) {
      return BTU_ID_PREFIX + value;
    }

    return (value ?? "-").toLocaleString();
  };

  const reviewItems = (selectedFieldOrder || [])
    .map((identifier) => {
      const fieldConfig = fieldsToDisplay?.[identifier];

      if (!fieldConfig) return null;

      const {key, label, format} = fieldConfig;
      const value = formValues[key];
      const displayValue = formatValue(value, format, identifier);

      return (
        <div className="detail-item flex justify-between" key={key}>
          <label className="text-gray-700">{label}</label>
          <span
            className={
              identifier === "NOTES"
                ? "max-w-sm text-ellipsis overflow-hidden whitespace-nowrap truncate"
                : ""
            }
          >
            {displayValue}
          </span>
        </div>
      );
    })
    .filter(Boolean);

  return (
    <div className="review-details w-full space-y-4">
      <h5 className="display-6">Review the shipment details</h5>
      <div className="createShipment__summary" data-test="shipment-summary">
        <div className="createShipment__summary-title">Summary</div>
        <div className="createShipment__summary-list">{reviewItems}</div>
      </div>
    </div>
  );
};

export default Review;
