import {gql} from "@apollo/client";

export const LOCATION_YIELD_GMB = gql`
  query bioLcGetLocationYieldDetailsDataApi {
    bioLcGetLocationYieldDetailsDataApi {
      data {
        countryId
        divisionId
        locationCode
        locationId
        locationYieldId
        materialCode
        materialId
        materialShortName
        processingUnitCode
        processingUnitId
        siteReferenceId
        validFrom
        validStatus
        validTo
        yieldRatio
        yieldType
        mbBalanceGroupId
        mbLocationGroupId
      }
      error
      statusCode
    }
  }
`;

export default {LOCATION_YIELD_GMB};
