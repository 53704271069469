import {Close} from "@bphxd/ds-core-react";
import {
  Clockwise24,
  Down24,
  DownloadDocumentGeneric24,
} from "@bphxd/ds-core-react/lib/icons";
import {
  Button,
  Modal,
  ModalBody,
  ModalHeader,
  Popover,
  PopoverBody,
  Spinner,
} from "reactstrap";

import {useQuery} from "@apollo/client";
import {client} from "providers/Apollo";
import {toast} from "react-toastify";

import {
  COMPLIANCE_DETAILED_VIEW_EXPORT,
  GET_COMPLIANCE_DETAILS,
} from "graphql/compliance/massBalance";

import PropTypes from "prop-types";
import {formatNumber, useUserSettings} from "providers/userSettings";
import React, {useState} from "react";
import {roundDecimal} from "utils/helpers/uihelper";
import ColumnUtils from "./ColumnUtils";
import CustomiseTable from "./CustomiseTable";
import DetailTable from "./Table";
import "./index.scss";

const {
  columnMapRTFO,
  existingColumnsRTFO,
  existingColumnsRFS,
  columnMapRFS,
  defaultColumnsOnLoadRFS,
  defaultColumnsOnLoadRTFO,
  manualAdjustmentColumnsRFS,
} = ColumnUtils;
const defaultPageSize = 10;

const dateColumns = [
  "Deal Done Date",
  "Posting Date",
  "Transfer Date",
  "Last Changed Date",
  "Created Date",
];

const DetailedViewModal = ({
  title,
  visible,
  activeTab,
  data: scenarioDetails,
  filters,
  onCancel,
  country,
  ticketsUom,
  quantityUom,
  options,
}) => {
  const {
    userSettings: {decimalFormat},
  } = useUserSettings();
  const [open, setOpen] = useState(false);
  const [isDownloading, setDownloading] = useState(false);

  const [scenarioNumber, volumeType] = (
    scenarioDetails?.scenarioNumber ?? " - "
  ).split(" - ");

  const isRTFO = options?.complianceName?.[0].startsWith("RTFO");
  const isCredit =
    scenarioDetails.scenarioDescription === "Credits" ||
    parseInt(scenarioNumber.split(".")[0], 10) < 51;
  const defaultColumnsOnLoad = isRTFO
    ? defaultColumnsOnLoadRTFO(
        quantityUom,
        ticketsUom,
        scenarioNumber,
        filters,
        decimalFormat,
      )
    : defaultColumnsOnLoadRFS(
        quantityUom,
        ticketsUom,
        scenarioNumber,
        filters,
        decimalFormat,
      );

  const excludedColumns = isRTFO ? existingColumnsRTFO : existingColumnsRFS;
  const columnMap = isRTFO ? columnMapRTFO : columnMapRFS;

  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: defaultPageSize,
  });

  const getFilterValues = (filters) => {
    const filterOptions = [];
    if (scenarioNumber) {
      filterOptions.push({
        columnName: "bioScenarioNumber",
        filterValues: scenarioNumber?.length > 0 ? [scenarioNumber] : [],
      });
    }
    if (filters?.bioType) {
      const filterValues =
        filters?.bioType?.length > 0 ? [filters?.bioType] : [];
      if (filters?.bioType === "Diesel") {
        filterValues.push("RD");
      }
      filterOptions.push({
        columnName: "bioType",
        filterValues,
      });
    }
    if (filters?.subType) {
      filterOptions.push({
        columnName: "subType",
        filterValues: filters?.subType?.length > 0 ? [filters?.subType] : [],
      });
    }
    if (filters?.legalEntity) {
      filterOptions.push({
        columnName: "legalEntity",
        filterValues:
          filters?.legalEntity?.length > 0 ? [filters?.legalEntity] : [],
      });
    }
    return filterOptions;
  };

  const getGroupByForFilters = (filters) => {
    const groupByColumns = ["bioScenarioNumber"];
    if (filters?.bioType) {
      groupByColumns.push("bioType");
    }
    if (filters?.subType) {
      groupByColumns.push("subType");
    }
    if (filters?.legalEntity) {
      groupByColumns.push("legalEntity");
    }
    return groupByColumns;
  };

  const vars = {
    ...filters,
    complianceMethod: activeTab,
    page: pagination.pageIndex,
    perPage: pagination.pageSize,
    groupByColumns: getGroupByForFilters(filters),
    orderByColumns: [],
    filterByColumns: getFilterValues(filters),
  };

  const [topfilterValues, setTopFilterValues] = useState(vars);

  const {
    data: result,
    loading,
    refetch,
  } = useQuery(GET_COMPLIANCE_DETAILS, {
    notifyOnNetworkStatusChange: true,
    variables: topfilterValues,
  });

  const clearSortOrder = (columns) =>
    columns.map(({sortOrder, ...attrs}) => attrs);

  const filteredColumns = (reset = false) => {
    let currentCols = clearSortOrder(defaultColumnsOnLoad);
    if (reset) {
      currentCols = currentCols.map((col) => {
        return {...col, sortOrder: "none"};
      });
    }

    const keys = Object.keys(columnMap).filter(
      (k) => !excludedColumns.includes(k),
    );
    return [
      ...currentCols,
      ...keys.map((k) => ({
        visible: false,
        filterable: true,
        header: columnMap[k],
        dataIndex: k,
        key: k,
        accessorKey: k,
        align: "left",
        size: 150,
        filterValues: [],
        sorter: () => {},
        searchType:
          dateColumns?.find((item) => item === columnMap[k])?.length > 0
            ? "date"
            : "text",
      })),
    ];
  };

  const [columns, setColumns] = useState(filteredColumns());
  const [showManualAdjustment, setShowManualAdjustment] = useState(false);

  const viewManualAdjustment = () => {
    setShowManualAdjustment(true);
    setColumns(
      manualAdjustmentColumnsRFS(decimalFormat, quantityUom, scenarioNumber),
    );
    const newFilterByColumns = vars.filterByColumns.concat([
      {
        columnName: "dataSource",
        filterValues: ["Manual Adjustment"],
      },
    ]);
    const newGroupByColumns = [
      "facilityName",
      "createdDate",
      "comments",
      "bioScenarioNumber",
    ];
    const newVars = {
      ...vars,
      page: 0,
      pageSize: pagination.pageSize,
      filterByColumns: newFilterByColumns,
      groupByColumns: newGroupByColumns,
    };
    setPagination({...pagination, pageIndex: 0});
    setTopFilterValues(newVars);
    refetch(newVars);
  };

  const onSortingChange = (sortArray) => {
    setColumns(clearSortOrder(columns));
    if (!sortArray || sortArray.length === 0) {
      const sortFilterVariables = {
        ...topfilterValues,
        orderByColumns: [],
      };
      setTopFilterValues(sortFilterVariables);

      refetch(sortFilterVariables);
      return;
    }

    const sortItem = {
      columnKey: sortArray[0]?.id,
      direction: sortArray[0]?.desc ? "desc" : "asc",
    };
    if (sortItem?.columnKey) {
      const sortFilterVariables = {
        ...topfilterValues,
        orderByColumns: [sortItem],
      };
      setTopFilterValues(sortFilterVariables);

      refetch(sortFilterVariables);
    }
  };

  const reload = (page, perPage) => {
    const visibleCols = columns.filter((col) => col.visible && col.filterable);
    let groupByColumns = visibleCols.map((column) => column.dataIndex);
    const orderByColumns = topfilterValues?.orderByColumns.filter(
      ({columnKey}) =>
        groupByColumns.includes(columnKey) ||
        columnKey === "quantity" ||
        columnKey === "tickets",
    );

    const filterCols = columns.filter((col) => col.filterable);
    const filterByColumns = filterCols
      .filter(({filterValues}) => filterValues?.length > 0)
      .map((col) => ({
        columnName: col.dataIndex,
        filterValues: col.filterValues,
      }));
    const additionalGroupByColumns = filterByColumns
      .filter(
        (column) =>
          ["bioType", "legalEntity", "subType"].includes(column.columnName) &&
          !groupByColumns.includes(column.columnName),
      )
      .map((column) => column.columnName);
    groupByColumns = groupByColumns.concat(additionalGroupByColumns);
    const variables = {
      ...vars,
      page,
      perPage,
      groupByColumns,
      orderByColumns,
      filterByColumns,
    };
    setTopFilterValues(variables);
    refetch(variables);
  };

  const handleClose = () => {
    setPagination({...pagination, pageIndex: 0});
    setOpen(false);
    reload(0, pagination.pageSize);
  };

  const handlePaginationChange = ({pageIndex, pageSize}) => {
    setPagination({pageIndex, pageSize});
    reload(pageIndex, pageSize);
  };

  const handleRefresh = (isManualAdjustment) => {
    if (isManualAdjustment) {
      viewManualAdjustment();
      return;
    }
    const variables = {
      ...vars,
      page: 0,
      pageSize: pagination.pageSize,
    };
    setPagination({...pagination, pageIndex: 0});
    setColumns(filteredColumns(true));
    setTopFilterValues(variables);
    refetch(variables);
  };

  const downloadFile = (fileUrl) => {
    const fileName = fileUrl.substring(fileUrl.lastIndexOf("/") + 1);
    const a = document.createElement("a");
    a.href = fileUrl;
    a.download = fileName;
    document.body.appendChild(a);
    a.click();
    a.remove();
  };

  const onDownload = async () => {
    const downloadPayload = {
      ...topfilterValues,
      isManualAdjustment: showManualAdjustment,
      uom: quantityUom,
    };
    const {
      data: {downloadComplianceDetailed: response},
    } = await client.query({
      query: COMPLIANCE_DETAILED_VIEW_EXPORT,
      fetchPolicy: "network-only",
      variables: {
        ...downloadPayload,
      },
    });
    return response;
  };

  const handleDownload = async () => {
    setDownloading(true);
    try {
      const response = await onDownload();
      switch (response?.statusCode) {
        case 400:
        case 500:
          // Show friendly server error message
          setDownloading(false);

          toast.error(response?.errorMessage);
          break;
        default:
          // Theorically succesfully
          setDownloading(false);
          if (response) {
            if (response?.url) {
              downloadFile(response?.url);
            } else {
              toast.error("No data available to export");
            }
          }
      }
    } catch (err) {
      console.error(err);
      // Unexpected graphql error
      setDownloading(false);
    }
  };

  const changeTitle = (c, title, header) => {
    const col = {...c};
    col.header = (
      <div>
        <p>{header}</p>
        <p className={loading ? "hidden" : ""}>
          <span className="small-text">
            {title.concat(
              formatNumber(
                roundDecimal(result?.queryComplianceDetailed?.ticketSum),
                decimalFormat,
                0,
              ),
            )}
          </span>
        </p>
      </div>
    );
    return col;
  };
  return (
    <Modal size="xl" isOpen={visible} className="modal-dialog-centered">
      <ModalHeader
        className="border-b-2 border-gray-200 mb-2 text-xl"
        close={<Close onClick={onCancel} />}
      >
        {scenarioDetails.scenarioDescription + " - " + title}
      </ModalHeader>
      <ModalBody className="text-center pt-0 customise-table-modal">
        <div className="py-5 w-full min-h-[75vh]">
          <div className="flex flex-row w-full gap-3 py-2 mb-3 justify-between items-center">
            <div className="flex w-full gap-3 py-2 items-center">
              <Button
                color="primary"
                type="secondary"
                className="rounded-0"
                id="popover-customise"
                onClick={() => setOpen(!open)}
              >
                Customise table
                <Down24 className="btn-icon-suffix" />
              </Button>

              <Popover
                placement="bottom"
                popperClassName="compliance-detailed-popover w-[30rem]"
                innerClassName="compliance-detailed-popover-inner"
                target="popover-customise"
                isOpen={open}
                toggle={() => setOpen(!open)}
                trigger="legacy"
              >
                <PopoverBody>
                  <CustomiseTable
                    columns={columns}
                    scenarioNumber={scenarioNumber}
                    onColumnChange={setColumns}
                    onClose={handleClose}
                    filters={topfilterValues}
                    options={options}
                    columnMap={columnMap}
                  />
                </PopoverBody>
              </Popover>
              {country === "USA" && !isCredit && !showManualAdjustment && (
                <Button
                  color="standard-tertiary rounded-0"
                  onClick={viewManualAdjustment}
                >
                  View manual adjustment
                </Button>
              )}
              {country === "USA" && !isCredit && showManualAdjustment && (
                <Button
                  color="standard-tertiary rounded-0"
                  onClick={() => {
                    setShowManualAdjustment(false);
                    handleRefresh(false);
                  }}
                >
                  Reset to default columns
                </Button>
              )}
            </div>
            <div className="flex w-full gap-3 py-2 justify-end items-center">
              <Button
                data-test="customise-download-button"
                color="standard-tertiary rounded-0"
                onClick={handleDownload}
              >
                Export
                {isDownloading ? (
                  <Spinner size="sm" className="btn-icon-suffix" />
                ) : (
                  <DownloadDocumentGeneric24 className="btn-icon-suffix" />
                )}
              </Button>
              <Button
                data-test="customise-download-button"
                color="standard-tertiary rounded-0"
                onClick={() => handleRefresh(showManualAdjustment)}
              >
                Refresh
                <Clockwise24 className="btn-icon-suffix" />
              </Button>
            </div>
          </div>

          <div>
            <DetailTable
              columns={columns
                .filter((c) => c.visible)
                .map((c) => {
                  // Unfortunately we have to do it here so that rerender triggers the logic again
                  if (c.dataIndex === "tickets") {
                    return changeTitle(
                      c,
                      "Sum of tickets: ",
                      columnMapRTFO.tickets + " - " + ticketsUom,
                    );
                  }
                  if (c.dataIndex === "rinRvoQuantity") {
                    return changeTitle(c, "Sum of RINs/RVO: ", ticketsUom);
                  }
                  return c;
                })}
              data={result?.queryComplianceDetailed?.data ?? []}
              className="detailed-view-table"
              loading={loading}
              data-test="detailed-view-table"
              totalCount={result?.queryComplianceDetailed?.totalCount}
              onPaginationChange={handlePaginationChange}
              onSortingChange={onSortingChange}
              pagination={pagination}
              orderByColumns={topfilterValues.orderByColumns.map(
                ({columnKey, direction}) => ({
                  id: columnKey,
                  desc: direction === "desc",
                }),
              )}
            />
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};

DetailedViewModal.propTypes = {
  title: PropTypes.string,
  visible: PropTypes.bool,
  onCancel: PropTypes.func,
  activeTab: PropTypes.number,
  data: PropTypes.object,
  filters: PropTypes.object.isRequired,
  country: PropTypes.string,
  ticketsUom: PropTypes.string,
  quantityUom: PropTypes.string,
  options: PropTypes.object,
};

export default DetailedViewModal;
