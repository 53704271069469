import {
  format,
  subDays,
  isValid,
  isAfter,
  isSameHour,
  startOfHour,
  startOfToday,
  endOfToday,
  formatDistanceToNow,
  parseISO,
  startOfDay,
} from "date-fns";
import {toZonedTime, format as tzFormat, fromZonedTime} from "date-fns-tz";
import {DATE, ERRORS} from "modules/co-processing/constants/coProcessing";

export const toPST = (date) => {
  const parsedDate = typeof date === "string" ? parseISO(date) : date;
  return toZonedTime(parsedDate, DATE.TIMEZONE);
};

export const toUTC = (date) => {
  const parsedDate = typeof date === "string" ? parseISO(date) : date;
  return fromZonedTime(parsedDate, DATE.TIMEZONE);
};

const formatTimeStamp = (dateString, isShortHand = false) => {
  const string = dateString.replace(/\b(about|almost|over)\s+/, "");

  const parts = string.split(" ");
  let number = parts[0];
  let unit = parts[1];
  const suffix = parts[2] || "";

  number =
    Number.isNaN(parseInt(number, 10)) || number === "a"
      ? 1
      : parseInt(number, 10);

  const unitShortMap = {
    minute: "m",
    minutes: "m",
    hour: "h",
    hours: "h",
    day: "d",
    days: "d",
    month: "m",
    months: "m",
    year: "y",
    years: "y",
  };

  unit = isShortHand ? unitShortMap[unit] || unit.charAt(0) : unit;

  if (isShortHand) {
    return `${number}${unit} ${suffix}`.trim();
  }
  return `${number} ${unit} ${suffix}`.trim();
};

const calculateDaysAgo = (date, isShortHand) => {
  if (!date) {
    return "Invalid date format";
  }
  const parsedDate =
    typeof date !== "string" ? date : parseISO(date?.replace(/\.\d+/, ""));
  const relativeTime = formatDistanceToNow(parsedDate, {addSuffix: true});
  return formatTimeStamp(relativeTime, isShortHand);
};

export const formatDatePickerValue = (date) => {
  const pstDate = toPST(date);
  return format(pstDate, DATE.ISO_FORMAT, {
    timeZone: DATE.TIMEZONE,
  });
};

export const formatDatePickerValueForReceiptsForm = (date) => {
  return format(date, DATE.ISO_FORMAT, {
    timeZone: DATE.TIMEZONE,
  });
};

export const displayDate = (value, shortHand = false) => {
  const dateFormat = shortHand ? DATE.DATE_FORMAT_SHORT : DATE.DATE_FORMAT;
  const pstDate = toPST(new Date(value));
  return tzFormat(pstDate, dateFormat);
};

export const displayTime = (value) => {
  const pstDate = toPST(value);
  return tzFormat(pstDate, DATE.TIME_FORMAT);
};

export const parseDate = (dateStr) => {
  const modifiedDateStr = dateStr.replace(/\.\d+/, ""); // Removes microseconds
  return new Date(modifiedDateStr);
};

export const getDateRange = (numberOfDaysFromToday) => {
  const startDate = subDays(startOfToday(), numberOfDaysFromToday);
  const endDate = endOfToday();

  const startPST = toPST(startDate);
  const endPST = toPST(endDate);

  return [format(startPST, DATE.ISO_FORMAT), format(endPST, DATE.ISO_FORMAT)];
};

export const invalidStartDate = (startTime, shipmentTime) => {
  const startDate = startOfDay(new Date(startTime));
  const shipmentDate = startOfDay(new Date(shipmentTime));
  return startDate < shipmentDate;
};

export const invalidEndDate = (startTime, endTime) => {
  return new Date(endTime) <= new Date(startTime);
};

export default calculateDaysAgo;

export const isBeforeTankCertifiedDate = (shipmentDate, tankCertifiedDate) => {
  if (!shipmentDate || !tankCertifiedDate) return null;

  const shipmentDateTime = startOfHour(parseISO(shipmentDate));
  const tankCertificationTime = startOfHour(parseISO(tankCertifiedDate));

  if (!isValid(shipmentDateTime) || !isValid(tankCertificationTime))
    return null;

  return isSameHour(shipmentDateTime, tankCertificationTime) ||
    isAfter(shipmentDateTime, tankCertificationTime)
    ? ""
    : ERRORS.SHIPMENT_DATE_PRIOR_TO_BATCH;
};
