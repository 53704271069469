import {Add24} from "@bphxd/ds-core-react/lib/icons";
import {useState} from "react";
import {Button} from "reactstrap";

import UserFeedbackPopup from "./UserFeedbackPopup";
import "./index.css";

const UserFeedbackButton = () => {
  const [showSendFeedback, setShowSendFeedback] = useState(false);

  return (
    <>
      <div className="flex m-0 p-0">
        {!showSendFeedback && (
          <Button
            type="secondary"
            className=" transform -translate-y-1/2 -rotate-90 button-send-feedback"
            id="sendFeedbackButton"
            size="sm"
            onClick={() => {
              setShowSendFeedback(!showSendFeedback);
            }}
          >
            <Add24 className="btn-icon-preffix" /> Send feedback
          </Button>
        )}
      </div>

      <UserFeedbackPopup
        setShowSendFeedback={setShowSendFeedback}
        showSendFeedback={showSendFeedback}
      />
    </>
  );
};

UserFeedbackButton.propTypes = {};

export default UserFeedbackButton;
