// import outboundTypes from "constants/allocation";
import PropTypes from "prop-types";
import {Input} from "reactstrap";

const TableCheckbox = ({row, table, type}) => {
  const isSelected = table.getIsAllRowsSelected();
  const handleChange = table.getToggleAllRowsSelectedHandler();

  return <Input type="checkbox" checked={isSelected} onChange={handleChange} />;
};

TableCheckbox.propTypes = {
  row: PropTypes.object,
  table: PropTypes.object,
  type: PropTypes.string,
};

export default TableCheckbox;
