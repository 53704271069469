import PropTypes from "prop-types";
import React from "react";
import {Popover, PopoverBody, PopoverHeader} from "reactstrap";
import {massBalanceType, mbStatus} from "../constants";

const ClosingPopover = ({open, type, periodStatus}) => {
  return (
    <Popover fade placement="top" target="mass-balance-close-btn" isOpen={open}>
      <PopoverHeader>
        {type === massBalanceType.TR &&
          periodStatus === mbStatus.OPEN &&
          "Close a trader only mass balance"}
        {type === massBalanceType.TR &&
          periodStatus === mbStatus.CLOSED &&
          "Re-open a trader only mass balance"}
        {type === massBalanceType.TRS &&
          periodStatus === mbStatus.OPEN &&
          "Close a trader with storage mass balance"}
        {type === massBalanceType.TRS &&
          periodStatus === mbStatus.CLOSED &&
          "Re-open a trader with storage mass balance"}
        {type === massBalanceType.CPP &&
          periodStatus === mbStatus.OPEN &&
          "To close a co-processing mass balance"}
        {type === massBalanceType.CPP &&
          periodStatus === mbStatus.CLOSED &&
          "To re-open a mass balance"}
      </PopoverHeader>
      <PopoverBody>
        {type === massBalanceType.TR && periodStatus === mbStatus.OPEN && (
          <>
            <div>1. The mass balance period needs to have ended</div>
            <div>2. All purchases must be actualized and allocated</div>
            <div>
              3. Outgoing transactions must be allocated to all purchases and
              all outgoing documents must be generated
            </div>
            <div>4. The closing balance must be zero</div>
          </>
        )}
        {type === massBalanceType.TR && periodStatus === mbStatus.CLOSED && (
          <div>Mass balance can be re-opened prior to the audit</div>
        )}
        {type === massBalanceType.TRS && periodStatus === mbStatus.OPEN && (
          <>
            <div>1. The mass balance period needs to have ended</div>
            <div>
              2. The previous mass balance period for the same location needs to
              have been closed
            </div>
            <div>3. All purchases must be actualized</div>
            <div>
              4. Outgoing transactions must be allocated to purchases and all
              outgoing documents must be generated
            </div>
          </>
        )}
        {type === massBalanceType.TRS && periodStatus === mbStatus.CLOSED && (
          <>
            <div>1. There must be no carried over purchases</div>
            <div>2. Any subsequent mass balances must be open</div>
            <div>3. Mass balance can be re-opened prior to the audit</div>
          </>
        )}
        {type === massBalanceType.CPP && periodStatus === mbStatus.OPEN && (
          <>
            <div>1. The mass balance period should have ended</div>
            <div>2. Preceding mass balances have been closed</div>
            <div>3. All purchases are actualized</div>
            <div>
              4. Physical stock is within 0.5% tolerance of the closing position
            </div>
          </>
        )}
        {type === massBalanceType.CPP && periodStatus === mbStatus.CLOSED && (
          <>
            <div>1. Subsequent mass balances are open</div>
            <div>2. No audits have taken place</div>
          </>
        )}
      </PopoverBody>
    </Popover>
  );
};

ClosingPopover.propTypes = {
  open: PropTypes.bool,
  type: PropTypes.string,
  periodStatus: PropTypes.string,
};

export default ClosingPopover;
