import {gql} from "@apollo/client";

// Fetch query for Business Partner link lm table on Config center(/configuration-center/business-partner-link-lm)
export const BUSINESS_PARTNER_LINK_LM_GET_QUERY = gql`
  query bioLcGetBusinessPartnerLinkLm {
    bioLcGetBusinessPartnerLinkLm(event: {}) {
      businessPartnerLinkLmData {
        businessPartnerCode
        businessPartnerId
        businessPartnerName
        businessPartnerLinkLmId
        countryCode
        countryId
        countryName
        divisionCode
        divisionId
        divisionName
        locationCode
        locationId
        locationName
        siteName
        siteReferenceId
        validFrom
        validTo
      }
    }
  }
`;

export default {BUSINESS_PARTNER_LINK_LM_GET_QUERY};
