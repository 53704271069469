import PropTypes from "prop-types";
import React, {useEffect, useState} from "react";

import {useQuery} from "@apollo/client";
import {GET_COMPLIANCE_DETAILS_FILTER} from "graphql/compliance/massBalance";
import {debounce} from "lodash";
import {Input, Spinner} from "reactstrap";

const FilterColumn = ({column, scenarioNumber, onChange, filters, columns}) => {
  const [filterByColumns, setfilterByColumns] = useState([]);
  const [selected, setSelected] = useState(new Set(column.filterValues));
  const [filterFields, setFilterFields] = useState([]);
  const [allSelected, setAllSelected] = useState(false);
  const [fields, setFields] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  useEffect(() => {
    const visibleCols = columns.filter(
      (col) => col.filterable && col.key !== column.key,
    );
    const filterByColumns = visibleCols
      .filter(({filterValues}) => filterValues?.length > 0)
      .map((col) => ({
        columnName: col.dataIndex,
        filterValues: col.filterValues,
      }));
    setfilterByColumns(filterByColumns);
  }, [columns, column.key]);

  const {
    data: result,
    loading,
    refetch,
  } = useQuery(GET_COMPLIANCE_DETAILS_FILTER, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "network-only",
    variables: {
      ...filters,
      filterColumn: column.queryKey || column.key,
      scenarioNumber: "",
      searchTerm: "",
      filterByColumns,
    },
  });
  const handleSelect = (field, e) => {
    if (e.target.checked) {
      selected.add(field);
    } else {
      selected.delete(field);
    }
    setSelected(new Set([...selected]));
    setAllSelected(selected.size === column.filterValues);
    onChange({...column, filterValues: [...selected]});
  };
  const handleSelectClear = () => {
    const currentSelection = allSelected
      ? new Set()
      : new Set(fields.map((field) => field?.value));
    setSelected(currentSelection);
    setAllSelected(!allSelected);
    onChange({...column, filterValues: [...currentSelection]});
  };

  const handleSearch = (e) => {
    const value = (e.target.value ?? "").trim().toLowerCase();
    setSearchTerm(value);

    const searchVariables = {
      ...filters,
      filterColumn: column.queryKey || column.key,
      scenarioNumber: "",
      searchTerm: value,
      filterByColumns,
    };
    refetch(searchVariables);
  };

  useEffect(() => {
    let columnFields =
      result?.queryComplianceDetailedFilter?.columnFields ?? [];
    // Handle RFS subType differently
    if (column.customizeName === "Sub Type") {
      columnFields = columnFields.map((field) => {
        return {
          label:
            field.label === filters.complianceYear
              ? "Current Year Vintage"
              : "Previous Year Vintage",
          value: field.value,
        };
      });
    }
    setFields(columnFields);
    setFilterFields(columnFields);
    setAllSelected(columnFields.length === (column.filterValues ?? []).length);
  }, [result, column, filters]);

  return (
    <div className="flex flex-col py-2 pl-4 pr-2 gap-2">
      <Input
        placeholder="Search..."
        name="search"
        autoComplete="on"
        onChange={debounce(handleSearch, 300)}
      />
      <div className="flex justify-content-center">
        {searchTerm.length === 0 && (
          <button
            type="button"
            className="rounded-xl text-xs py-1 px-3 bg-gray-200"
            onClick={handleSelectClear}
          >
            {allSelected ? "Clear" : "Select"} all attributes
          </button>
        )}
      </div>
      {loading && <Spinner size="sm" className="btn-icon-suffix" />}
      {!loading &&
        filterFields.map((field, i) => (
          <div className="flex w-100">
            <div className="mr-auto pr-4">
              <span>{field?.label}</span>
            </div>
            <div>
              <Input
                type="checkbox"
                checked={selected.has(field?.value)}
                onChange={(e) => handleSelect(field?.value, e)}
              />
            </div>
          </div>
        ))}
    </div>
  );
};

FilterColumn.propTypes = {
  column: PropTypes.object,
  scenarioNumber: PropTypes.string,
  onChange: PropTypes.func,
  filters: PropTypes.object.isRequired,
  columns: PropTypes.array,
};
export default FilterColumn;
