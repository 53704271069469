import {gql} from "@apollo/client";

export const MANDATE_TOP_FILTERS = gql`
  query bioLcGetMandateFiltersApi(
    $siteReferenceId: String
    $divisionId: String
  ) {
    bioLcGetMandateFiltersApi(
      event: {divisionId: $divisionId, siteReferenceId: $siteReferenceId}
    ) {
      error
      statusCode
      results {
        mbLocationGroups {
          id
          name
        }
        periods {
          id
          name
        }
        outboundTypes {
          id
          name
        }
      }
    }
  }
`;

export default MANDATE_TOP_FILTERS;
