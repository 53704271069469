// import outboundTypes from "constants/allocation";
import PropTypes from "prop-types";
import {Input} from "reactstrap";

const Checkbox = ({row, table, type}) => {
  const isSelected = row.getIsSelected();
  const handleChange = row.getToggleSelectedHandler();

  return <Input type="checkbox" checked={isSelected} onChange={handleChange} />;
};

Checkbox.propTypes = {
  row: PropTypes.object,
  table: PropTypes.object,
  type: PropTypes.string,
};

export default Checkbox;
