import {gql} from "@apollo/client";

export const GET_COMPLIANCE_SCHEME_GRAPH_V2 = gql`
  query getComplianceSchemeGraph(
    $complianceScheme: String!
    $bioType: String!
    $complianceFromDate: String!
    $complianceType: String!
    $complianceToDate: String!
    $country: String!
    $legalEntity: String!
    $subType: String!
    $complianceYear: String!
    $complianceFromMonth: String
    $complianceToMonth: String
  ) {
    getComplianceSchemeGraphV2(
      event: {
        complianceScheme: $complianceScheme
        complianceType: $complianceType
        bioType: $bioType
        complianceFromDate: $complianceFromDate
        complianceToDate: $complianceToDate
        complianceYear: $complianceYear
        country: $country
        legalEntity: $legalEntity
        subType: $subType
        complianceFromMonth: $complianceFromMonth
        complianceToMonth: $complianceToMonth
      }
    ) {
      creditActualizedVolume
      creditCommittedVolume
      creditForecastVolume
      deficitActualizedVolume
      deficitCommittedVolume
      deficitForecastVolume
      timePeriod
      volume
      netCommittedVolume
      netActualizedVolume
      netForecastVolume
    }
  }
`;

export const GET_COMPLIANCE_FILTER_V2 = gql`
  query GetComplianceFilter($record: GetComplianceFilterV2Request!) {
    getComplianceFilterV2(event: $record) {
      complianceScheme
      complianceType
      country
      bioType
      subType
      compliancePeriod
      complianceName
      complianceUnitOfMeasure
      legalEntity
    }
  }
`;

export const GET_COMPLIANCE_OPENING_V2 = gql`
  query GetComplianceSchemeOpen(
    $complianceScheme: String!
    $bioType: String!
    $complianceFromDate: String!
    $complianceType: String!
    $complianceToDate: String!
    $country: String!
    $legalEntity: String!
    $subType: String!
    $complianceYear: String!
    $uom: String
    $complianceFromMonth: String
    $complianceToMonth: String
  ) {
    getComplianceSchemeOpenV2(
      event: {
        bioType: $bioType
        complianceScheme: $complianceScheme
        complianceType: $complianceType
        complianceFromDate: $complianceFromDate
        complianceToDate: $complianceToDate
        country: $country
        legalEntity: $legalEntity
        subType: $subType
        complianceYear: $complianceYear
        uom: $uom
        complianceFromMonth: $complianceFromMonth
        complianceToMonth: $complianceToMonth
      }
    ) {
      scenarioGroups {
        scenarioTotals {
          scenarioDescription
          scenarioNumber
          rins
          quantity
          rtfotype
          emission
          totalvolume
          totalrenewablevolume

          scenarioDetails {
            scenarioDescription
            scenarioNumber
            quantity
            rtfotype
            rins
            emission
            totalvolume
            totalrenewablevolume
          }
        }
        groupTotal
        groupName
        scenarioType
      }
      complianceScheme
      complianceSchemeName
      total
    }
  }
`;

export const GET_COMPLIANCE_CLOSING_V2 = gql`
  query GetComplianceSchemeClose(
    $complianceScheme: String!
    $bioType: String!
    $complianceFromDate: String!
    $complianceType: String!
    $complianceToDate: String!
    $country: String!
    $legalEntity: String!
    $subType: String!
    $complianceYear: String!
    $uom: String
    $complianceFromMonth: String
    $complianceToMonth: String
  ) {
    getComplianceSchemeCloseV2(
      event: {
        bioType: $bioType
        complianceScheme: $complianceScheme
        complianceType: $complianceType
        complianceFromDate: $complianceFromDate
        complianceToDate: $complianceToDate
        country: $country
        legalEntity: $legalEntity
        subType: $subType
        complianceYear: $complianceYear
        uom: $uom
        complianceFromMonth: $complianceFromMonth
        complianceToMonth: $complianceToMonth
      }
    ) {
      scenarioGroups {
        scenarioTotals {
          scenarioDescription
          scenarioNumber
          rins
          quantity
          rtfotype
          emission
          totalvolume
          totalrenewablevolume

          scenarioDetails {
            scenarioDescription
            scenarioNumber
            quantity
            rtfotype
            rins
            emission
            totalvolume
            totalrenewablevolume
          }
        }
        groupTotal
        groupName
        scenarioType
      }
      complianceScheme
      complianceSchemeName
      total
    }
  }
`;

export const GET_COMPLIANCE_CREDITS_V2 = gql`
  query GetComplianceSchemeCredit(
    $complianceScheme: String!
    $bioType: String!
    $complianceFromDate: String
    $complianceType: String!
    $complianceToDate: String
    $country: String!
    $legalEntity: String!
    $subType: String!
    $complianceYear: String!
    $uom: String
    $complianceFromMonth: String
    $complianceToMonth: String
  ) {
    getComplianceSchemeCreditV2(
      event: {
        bioType: $bioType
        complianceScheme: $complianceScheme
        complianceType: $complianceType
        complianceFromDate: $complianceFromDate
        complianceToDate: $complianceToDate
        country: $country
        legalEntity: $legalEntity
        subType: $subType
        complianceYear: $complianceYear
        uom: $uom
        complianceFromMonth: $complianceFromMonth
        complianceToMonth: $complianceToMonth
      }
    ) {
      scenarioGroups {
        scenarioTotals {
          scenarioDescription
          scenarioNumber
          rins
          quantity
          rtfotype
          emission
          totalvolume
          totalrenewablevolume

          scenarioDetails {
            scenarioDescription
            scenarioNumber
            quantity
            rtfotype
            rins
            emission
            totalvolume
            totalrenewablevolume
          }
        }
        groupTotal
        groupName
        scenarioType
      }
      complianceScheme
      complianceSchemeName
      total
    }
  }
`;

export const GET_COMPLIANCE_DEFICITS_V2 = gql`
  query GetComplianceSchemeDeficit(
    $complianceScheme: String!
    $bioType: String!
    $complianceFromDate: String!
    $complianceType: String!
    $complianceToDate: String!
    $country: String!
    $legalEntity: String!
    $subType: String!
    $complianceYear: String!
    $uom: String
    $complianceFromMonth: String
    $complianceToMonth: String
  ) {
    getComplianceSchemeDeficitV2(
      event: {
        bioType: $bioType
        complianceScheme: $complianceScheme
        complianceType: $complianceType
        complianceFromDate: $complianceFromDate
        complianceToDate: $complianceToDate
        country: $country
        legalEntity: $legalEntity
        subType: $subType
        complianceYear: $complianceYear
        uom: $uom
        complianceFromMonth: $complianceFromMonth
        complianceToMonth: $complianceToMonth
      }
    ) {
      scenarioGroups {
        scenarioTotals {
          scenarioDescription
          scenarioNumber
          rins
          quantity
          rtfotype
          emission
          totalvolume
          totalrenewablevolume

          scenarioDetails {
            scenarioDescription
            scenarioNumber
            quantity
            rtfotype
            rins
            emission
            totalvolume
            totalrenewablevolume
          }
        }
        groupTotal
        groupName
        scenarioType
      }
      complianceScheme
      complianceSchemeName
      total
    }
  }
`;

export const GET_COMPLIANCE_UOM_V2 = gql`
  query GetComplianceSchemeUom($complianceScheme: String!) {
    getComplianceSchemeUomV2(event: {complianceScheme: $complianceScheme}) {
      unitOfMeasures {
        unitOfMeasureValue
        unitOfMeasureKey
        unitOfMeasure
        order
        complianceScheme
      }
      obligationUnit
    }
  }
`;

export default {GET_COMPLIANCE_FILTER_V2, GET_COMPLIANCE_OPENING_V2};

export const GET_COMPLIANCE_DETAILS_FILTER_V2 = gql`
  query queryComplianceDetailedFilter(
    $filterColumn: String!
    $scenarioNumber: String!
    $complianceYear: String!
    $complianceFromDate: String!
    $complianceToDate: String!
    $complianceMethod: String!
    $complianceScheme: String!
    $complianceType: String!
    $country: String!
    $legalEntity: String!
    $searchTerm: String!
    $filterByColumns: [GetComplianceDetailedFilterCriteria]!
    $subType: String!
    $bioType: String!
  ) {
    queryComplianceDetailedFilter(
      event: {
        filterColumn: $filterColumn
        searchTerm: $searchTerm
        complianceYear: $complianceYear
        complianceFromDate: $complianceFromDate
        complianceMethod: $complianceMethod
        complianceScheme: $complianceScheme
        complianceToDate: $complianceToDate
        country: $country
        complianceType: $complianceType
        legalEntity: $legalEntity
        scenarioNumber: $scenarioNumber
        filterByColumns: $filterByColumns
        bioType: $bioType
        subType: $subType
      }
    ) {
      columnFields {
        label
        value
      }
      filterColumn
    }
  }
`;

export const GET_COMPLIANCE_DETAILS = gql`
  query queryComplianceDetailed(
    $complianceScheme: String!
    $bioType: String!
    $complianceFromDate: String!
    $complianceType: String!
    $complianceToDate: String!
    $country: String!
    $legalEntity: String!
    $subType: String!
    $complianceYear: String!
    $complianceMethod: String!
    $page: Int!
    $perPage: Int!
    $groupByColumns: [String]!
    $filterByColumns: [GetComplianceDetailedFilters]!
    $orderByColumns: [GetComplianceDetailedOrderRequest]!
    $uom: String
  ) {
    queryComplianceDetailed(
      event: {
        bioType: $bioType
        complianceScheme: $complianceScheme
        complianceType: $complianceType
        complianceFromDate: $complianceFromDate
        complianceToDate: $complianceToDate
        country: $country
        legalEntity: $legalEntity
        subType: $subType
        complianceYear: $complianceYear
        complianceMethod: $complianceMethod
        page: $page
        perPage: $perPage
        groupByColumns: $groupByColumns
        filterByColumns: $filterByColumns
        orderByColumns: $orderByColumns
        uom: $uom
      }
    ) {
      data {
        bioType
        complianceStatus
        contractItem
        contractNo
        customerCode
        customerName
        dealDoneDate
        documentItem
        documentNo
        goodMovementDocItemNumber
        goodMovementDocNumber
        legalEntity
        materialDescription
        materialNumber
        plantCode
        plantName
        postingDate
        quantityL15
        scenarioNumber
        subType
        tickets
        transferDate
        vendorCode
        vendorName
        scenarioDescription
        volume
        rinRvoQuantity
        fuelCode
        dataSourceReport
        dataSource
        createdDate
        complianceStatus
        transactionType
        tradingPartnerName
        tradingPartnerCode
        sourceSystemId
        sapVendorNumber
        sapVendorName
        sapCustomerNumber
        sapCustomerName
        rinRvoYear
        nextGenDealNumber
        modifiedBy
        materialDescription
        materialCode
        lastChangedDate
        facilityName
        comments
        businessUnit
        complianceRfsUsId
        fuelCodeSubType
        chainPrefix
      }
      totalCount
      ticketSum
    }
  }
`;

export const COMPLIANCE_DETAILED_VIEW_EXPORT = gql`
  query DownloadComplianceDetailed(
    $complianceScheme: String!
    $bioType: String!
    $complianceFromDate: String!
    $complianceType: String!
    $complianceToDate: String!
    $country: String!
    $legalEntity: String!
    $subType: String!
    $complianceYear: String!
    $complianceMethod: String!
    $groupByColumns: [String]!
    $filterByColumns: [GetComplianceDetailedFilters]!
    $orderByColumns: [GetComplianceDetailedOrderRequest]!
    $uom: String
    $isManualAdjustment: Boolean
  ) {
    downloadComplianceDetailed(
      event: {
        bioType: $bioType
        complianceScheme: $complianceScheme
        complianceType: $complianceType
        complianceFromDate: $complianceFromDate
        complianceToDate: $complianceToDate
        country: $country
        legalEntity: $legalEntity
        subType: $subType
        complianceYear: $complianceYear
        complianceMethod: $complianceMethod
        groupByColumns: $groupByColumns
        filterByColumns: $filterByColumns
        orderByColumns: $orderByColumns
        uom: $uom
        isManualAdjustment: $isManualAdjustment
      }
    ) {
      errorMessage
      statusCode
      url
    }
  }
`;
