import {useSubscription} from "@apollo/client";
import GENERATE_PDF_SUBSCRIPTION_NEW from "graphql/docManager/onBiolccertgenpdfcomplete";
import {client} from "providers/Apollo";
import {useAppSetting} from "providers/appSetting";
import {useEffect} from "react";
import {Link} from "react-router-dom";
import {toast} from "react-toastify";
import appendParamsToUrl from "utils/helpers/appendParamsToUrl";

const useGenerateCertificateSubscriptionNew = () => {
  const {appSetting, setAppSetting} = useAppSetting();
  const {transactionId, country, division, redirectTo} =
    appSetting.subscriptionDataGenerateCertificate;

  const {data, loading, error} = useSubscription(
    GENERATE_PDF_SUBSCRIPTION_NEW,
    {
      variables: {transactionId},
      skip: !transactionId,
    },
  );

  useEffect(() => {
    if (data && transactionId) {
      data?.onBiolccertgenpdfcomplete?.certificateOutboundData.forEach(
        (item) => {
          const redirectUrl = appendParamsToUrl(
            "/mass-balance/outgoing-view",
            [country, division, item?.certificateOutboundEuId],
            {
              redirectTo,
            },
          );
          const toastMessage = (
            <span>
              Your document is now ready{" "}
              <Link
                className="text-[#757575] hover:text-[#757575] italic !underline"
                to={redirectUrl}
              >
                ${item.sdNumber}
              </Link>
            </span>
          );
          const failiureToastMessage = (
            <span>{`There’s an issue generating ${item.sdNumber}. Please try again.`}</span>
          );

          if (item.status === "success") {
            toast.success(toastMessage);
          } else {
            toast.error(failiureToastMessage);
          }
        },
      );
      if (data.onBiolccertgenpdfcomplete.statusCode !== 202) {
        client.refetchQueries({
          include: [
            "bioLcGetMassBalanceTransactionData",
            "bioLcGetMassBalanceCounts",
          ],
        });
        setAppSetting({
          ...appSetting,
          subscriptionDataGenerateCertificate: {
            transactionId: null,
            country: null,
            division: null,
            redirectTo: null,
          },
        });
      }
    }
  }, [
    appSetting,
    country,
    data,
    division,
    redirectTo,
    setAppSetting,
    transactionId,
  ]);

  return {data, loading, error};
};

export default useGenerateCertificateSubscriptionNew;
