import {gql} from "@apollo/client";
import {client} from "providers/Apollo";

const MASS_BALANCE_EXPORT = gql`
  query bioLcGetMassBalanceExportReport(
    $siteReferenceId: String
    $divisionId: String
    $mbBalanceTypeId: String
    $mbLocationGroupId: String
    $mbPeriodStatusId: String
    $mbBalanceGroupId: String
  ) {
    bioLcGetMassBalanceExportReport(
      event: {
        divisionId: $divisionId
        siteReferenceId: $siteReferenceId
        mbBalanceTypeId: $mbBalanceTypeId
        mbLocationGroupId: $mbLocationGroupId
        mbPeriodStatusId: $mbPeriodStatusId
        mbBalanceGroupId: $mbBalanceGroupId
      }
    ) {
      url
      error
      statusCode
    }
  }
`;

const MASS_BALANCE_EXPORT_COPRO = gql`
  query bioLcGetMassBalanceExportReportNew(
    $siteReferenceId: String
    $divisionId: String
    $mbBalanceTypeId: String
    $mbLocationGroupId: String
    $mbPeriodStatusId: String
    $mbBalanceGroupId: String
  ) {
    bioLcGetMassBalanceExportReportNew(
      event: {
        divisionId: $divisionId
        siteReferenceId: $siteReferenceId
        mbBalanceTypeId: $mbBalanceTypeId
        mbLocationGroupId: $mbLocationGroupId
        mbPeriodStatusId: $mbPeriodStatusId
        mbBalanceGroupId: $mbBalanceGroupId
      }
    ) {
      url
      error
      statusCode
    }
  }
`;

export const MassBalanceExportApi = (data) =>
  client.query({
    query: MASS_BALANCE_EXPORT,
    variables: {
      ...data,
    },
    fetchPolicy: "no-cache",
  });

export const MassBalanceExportCoproApi = (data) =>
  client.query({
    query: MASS_BALANCE_EXPORT_COPRO,
    variables: {
      ...data,
    },
    fetchPolicy: "no-cache",
  });

export default {MassBalanceExportApi};
