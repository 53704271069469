import CheckboxFlagNullable from "modules/common/BinaryFlagNullable";
import PropTypes from "prop-types";
import {useUserSettings} from "providers/userSettings";
import {useEffect} from "react";
import {useFormContext} from "react-hook-form";
import {FormFeedback, FormGroup, Input, Label} from "reactstrap";
import parseAndRoundToTwoDecimal from "utils/numberUtil";
import CheckboxFlag from "../../BinaryFlag";

const GHGEmissionInfoSD = ({data}) => {
  const {
    register,
    formState: {errors},
    control,
    setValue,
  } = useFormContext();

  const {
    userSettings: {decimalFormat},
  } = useUserSettings();

  const computeProps = (name, options) => {
    const {ref, ...props} = register(name, options);
    return {innerRef: ref, ...props};
  };

  useEffect(() => {
    setValue(
      "ghgEec",
      parseAndRoundToTwoDecimal(
        data?.rowData?.original?.outboundRecords?.ghgEec,
      ),
    );
    setValue(
      "ghgEl",
      parseAndRoundToTwoDecimal(
        data?.rowData?.original?.outboundRecords?.ghgEl,
      ),
    );
    setValue(
      "ghgEp",
      parseAndRoundToTwoDecimal(
        data?.rowData?.original?.outboundRecords?.ghgEp,
      ),
    );
    setValue(
      "ghgEtd",
      data?.rowData?.original?.outboundRecords?.ghgEtd === "DDV"
        ? data?.rowData?.original?.outboundRecords?.ghgEtd
        : parseAndRoundToTwoDecimal(
            data?.rowData?.original?.outboundRecords?.ghgEtd,
          ),
    );
    setValue(
      "ghgEu",
      parseAndRoundToTwoDecimal(
        data?.rowData?.original?.outboundRecords?.ghgEu,
      ),
    );
    setValue(
      "ghgEsca",
      parseAndRoundToTwoDecimal(
        data?.rowData?.original?.outboundRecords?.ghgEsca,
      ),
    );
    setValue(
      "ghgEccs",
      parseAndRoundToTwoDecimal(
        data?.rowData?.original?.outboundRecords?.ghgEccs,
      ),
    );
    setValue(
      "ghgEccr",
      parseAndRoundToTwoDecimal(
        data?.rowData?.original?.outboundRecords?.ghgEccr,
      ),
    );
    setValue(
      "totalDefaultValueRedApplied",
      data?.rowData?.original?.outboundRecords?.ghgTotal === "TDV",
    );
    setValue(
      "ghgTotal",
      data?.rowData?.original?.outboundRecords?.ghgTotal === "DDV" ||
        data?.rowData?.original?.outboundRecords?.ghgTotal === "TDV"
        ? data?.rowData?.original?.outboundRecords?.ghgTotal
        : parseAndRoundToTwoDecimal(
            data?.rowData?.original?.outboundRecords?.ghgTotal,
          ),
    );
  }, [setValue, data, decimalFormat]);

  return (
    <div className="flex flex-col gap-4">
      <FormGroup>
        <Label for="totalDefaultValueRedApplied" className="fw-light">
          Total default value according to RED II applied
        </Label>
        <CheckboxFlag
          id="totalDefaultValueRedApplied"
          name="totalDefaultValueRedApplied"
          control={control}
          error={errors.totalDefaultValueRedApplied}
        />
      </FormGroup>
      <div className="flex flex-row justify-between items-start gap-3">
        <FormGroup>
          <Label for="ghgEec" className="fw-light">
            Eec
          </Label>
          <Input
            type="text"
            id="ghgEec"
            name="ghgEec"
            data-test="ghgEec"
            {...computeProps("ghgEec")}
            invalid={!!errors.ghgEec}
            disabled
          />

          {errors.ghgEec && (
            <FormFeedback>{errors.ghgEec?.message}</FormFeedback>
          )}
        </FormGroup>
        <FormGroup>
          <Label for="ghgEl" className="fw-light">
            El
          </Label>
          <Input
            type="text"
            id="ghgEl"
            name="ghgEl"
            data-test="ghgEl"
            {...computeProps("ghgEl")}
            invalid={!!errors.ghgEl}
            disabled
          />
        </FormGroup>
        <FormGroup>
          <Label for="ghgEp" className="fw-light">
            Ep
          </Label>
          <Input
            type="text"
            id="ghgEp"
            name="ghgEp"
            data-test="ghgEp"
            {...computeProps("ghgEp")}
            invalid={!!errors.ghgEp}
            disabled
          />
        </FormGroup>
        <FormGroup>
          <Label for="ghgEtd" className="fw-light">
            Etd
          </Label>
          <Input
            type="text"
            id="ghgEtd"
            name="ghgEtd"
            data-test="ghgEtd"
            {...computeProps("ghgEtd")}
            invalid={!!errors.ghgEtd}
            disabled
          />
        </FormGroup>
        <FormGroup>
          <Label for="ghgEu" className="fw-light">
            Eu
          </Label>
          <Input
            type="text"
            id="ghgEu"
            name="ghgEu"
            data-test="ghgEu"
            {...computeProps("ghgEu")}
            invalid={!!errors.ghgEu}
            disabled
          />
        </FormGroup>
        <FormGroup>
          <Label for="ghgEsca" className="fw-light">
            Esca
          </Label>
          <Input
            type="text"
            id="ghgEsca"
            name="ghgEsca"
            data-test="ghgEsca"
            {...computeProps("ghgEsca")}
            invalid={!!errors.ghgEsca}
            disabled
          />
        </FormGroup>
        <FormGroup>
          <Label for="ghgEccs" className="fw-light">
            Eccs
          </Label>
          <Input
            type="text"
            id="ghgEccs"
            name="ghgEccs"
            data-test="ghgEccs"
            {...computeProps("ghgEccs")}
            invalid={!!errors.ghgEccs}
            disabled
          />
        </FormGroup>
        <FormGroup>
          <Label for="ghgEccr" className="fw-light">
            Eccr
          </Label>
          <Input
            type="text"
            id="ghgEccr"
            name="ghgEccr"
            data-test="ghgEccr"
            {...computeProps("ghgEccr")}
            invalid={!!errors.ghgEccr}
            disabled
          />
        </FormGroup>
      </div>
      <Label className="text-sm text-gray-600">values reflect: gCO2eq/MJ</Label>
      <FormGroup>
        <Label for="ghgTotal" className="fw-light">
          Total GHG emissions from supply and use of the fuel (gCO2eq/MJ)
        </Label>
        <Input
          type="text"
          id="ghgTotal"
          name="ghgTotal"
          data-test="ghgTotal"
          {...computeProps("ghgTotal")}
          invalid={!!errors.ghgTotal}
          disabled
        />
        {errors.ghgTotal && (
          <FormFeedback>{errors.ghgTotal?.message}</FormFeedback>
        )}
      </FormGroup>

      <Label className="fw-light">
        Means of transport. Transport distance in km
      </Label>
      <FormGroup>
        <Label for="shipKm" className="fw-light">
          Ship km
        </Label>

        <Input
          {...computeProps("shipKm")}
          type="number"
          id="shipKm"
          data-test="shipKm"
          invalid={!!errors.shipKm}
          maxLength={50}
        />

        {errors.shipKm && <FormFeedback>{errors.shipKm.message}</FormFeedback>}
      </FormGroup>
      <FormGroup>
        <Label for="trainKm" className="fw-light">
          Train km
        </Label>

        <Input
          {...computeProps("trainKm")}
          type="number"
          id="trainKm"
          data-test="trainKm"
          invalid={!!errors.trainKm}
          maxLength={50}
        />

        {errors.trainKm && (
          <FormFeedback>{errors.trainKm.message}</FormFeedback>
        )}
      </FormGroup>
      <FormGroup>
        <Label for="roadKm" className="fw-light">
          Road km
        </Label>

        <Input
          {...computeProps("roadKm")}
          type="number"
          id="roadKm"
          data-test="roadKm"
          invalid={!!errors.roadKm}
          maxLength={50}
        />

        {errors.roadKm && <FormFeedback>{errors.roadKm.message}</FormFeedback>}
      </FormGroup>
      <Label className="fw-light">
        Disaggregated default value for oil extraction only is applied
      </Label>
      <FormGroup check inline className="form-check-alt form-check-lg !mb-0">
        <Input
          {...computeProps("IsDisaggregatedDefaultValueForOilApplied")}
          type="checkbox"
          id="IsDisaggregatedDefaultValueForOilApplied"
          name="IsDisaggregatedDefaultValueForOilApplied"
          data-test="IsDisaggregatedDefaultValueForOilApplied"
        />
        <Label check for="IsDisaggregatedDefaultValueForOilApplied">
          Yes
        </Label>
      </FormGroup>
      <Label className="fw-light">
        Disaggregated default value for soil N2O emissions is applied
      </Label>
      <FormGroup check inline className="form-check-alt form-check-lg !mb-0">
        <Input
          {...computeProps("IsDisaggregatedDefaultValueForSoilApplied")}
          type="checkbox"
          id="IsDisaggregatedDefaultValueForSoilApplied"
          name="IsDisaggregatedDefaultValueForSoilApplied"
          data-test="IsDisaggregatedDefaultValueForSoilApplied"
        />

        <Label check for="IsDisaggregatedDefaultValueForSoilApplied">
          Yes
        </Label>
      </FormGroup>

      <FormGroup>
        <Label for="animalManureUsedFlag" className="fw-light">
          Esca: Bonus of 45g CO2eq/MJ manure in the case animal manure is used
          as substrate for the production of biogas and biomethane
        </Label>
        <CheckboxFlagNullable
          id="animalManureUsedFlag"
          name="animalManureUsedFlag"
          control={control}
          error={errors.animalManureUsedFlag}
        />
      </FormGroup>

      <FormGroup>
        <Label
          for="biomassObtainedFromRestoredDegradedLandFlag"
          className="fw-light"
        >
          El: Bonus of 29 g CO2eq/MJ biofuel/bioliquid/biomass fuel if biomass
          is obtained from restored degraded land
        </Label>
        <CheckboxFlagNullable
          id="biomassObtainedFromRestoredDegradedLandFlag"
          name="biomassObtainedFromRestoredDegradedLandFlag"
          control={control}
          error={errors.biomassObtainedFromRestoredDegradedLandFlag}
        />
      </FormGroup>

      <FormGroup>
        <Label
          for="incentivesReceivedforBiogasProductionFlag"
          className="fw-light"
        >
          For biogas supply chains: Were incentives/subsidies received for the
          production of the biogas?
        </Label>
        <CheckboxFlagNullable
          id="incentivesReceivedforBiogasProductionFlag"
          name="incentivesReceivedforBiogasProductionFlag"
          control={control}
          error={errors.incentivesReceivedforBiogasProductionFlag}
        />
      </FormGroup>
      <FormGroup>
        <Label for="biogasIncentivesDetails" className="fw-light">
          If yes, please specify
        </Label>

        <Input
          {...computeProps("biogasIncentivesDetails")}
          type="textarea"
          rows="2"
          name="biogasIncentivesDetails"
          id="biogasIncentivesDetails"
          data-test="biogasIncentivesDetails"
          invalid={!!errors.biogasIncentivesDetails}
          maxLength={250}
        />
      </FormGroup>
      <FormGroup>
        <Label for="additionalInfoNUTS2region" className="fw-light">
          If applicable, please specify the NUTS2 Region
        </Label>

        <Input
          {...computeProps("additionalInfoNUTS2region")}
          type="textarea"
          rows="3"
          name="additionalInfoNUTS2region"
          id="additionalInfoNUTS2region"
          data-test="additionalInfoNUTS2region"
          invalid={!!errors.additionalInfoNUTS2region}
          maxLength={250}
        />
      </FormGroup>
    </div>
  );
};
GHGEmissionInfoSD.propTypes = {
  data: PropTypes.object,
};
export default GHGEmissionInfoSD;
