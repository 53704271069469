import PropTypes from "prop-types";
import {useFormContext} from "react-hook-form";
import {FormGroup, Label} from "reactstrap";
import CheckboxFlag from "../CheckboxNullable";

const SplitInputAs = ({setSplitInputAsQty, isDisabled}) => {
  const {
    formState: {errors},
    control,
  } = useFormContext();

  return (
    <FormGroup className="py-3 flex justify-evenly">
      <Label for="splitInputByQty" className="fw-normal !text-base">
        You have chosen to create a split, how would you like to define these?
      </Label>
      <CheckboxFlag
        id="splitInputByQty"
        name="splitInputByQty"
        control={control}
        error={errors.inputAs}
        type="radio"
        disabled={isDisabled}
        labels={{yes: "By quantity", no: " By percentage"}}
        handleChange={(splitInputByQty) => setSplitInputAsQty(splitInputByQty)}
        flex
      />
    </FormGroup>
  );
};

SplitInputAs.propTypes = {
  setSplitInputAsQty: PropTypes.func.isRequired,
  isDisabled: PropTypes.bool.isRequired,
};
export default SplitInputAs;
