import {gql} from "@apollo/client";

export const ADD_MB_STOCK_INVENTORY = gql`
  mutation bioLcUpdateMBPhysicalStockApi(
    $mbPeriodId: String
    $physicalStockM3: Float
    $physicalStockMT: Float
    $reason: String
  ) {
    bioLcUpdateMBPhysicalStockApi(
      event: {
        mbPeriodId: $mbPeriodId
        physicalStockM3: $physicalStockM3
        physicalStockMT: $physicalStockMT
        reason: $reason
      }
    ) {
      error
      statusCode
    }
  }
`;
export default ADD_MB_STOCK_INVENTORY;
