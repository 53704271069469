import {gql} from "@apollo/client";
import {client} from "providers/Apollo";

export const MB_CERT_GEN_MUTATION = gql`
  mutation bioLcMBGenerateOutgoingCertificate(
    $event: bioLcMBGenerateOutgoingCertificateRequest!
  ) {
    bioLcMBGenerateOutgoingCertificate(event: $event) {
      error
      statusCode
      transactionId
    }
  }
`;

export const MbCertGenMutationAPI = (data) =>
  client.mutate({
    mutation: MB_CERT_GEN_MUTATION,
    variables: {
      ...data,
    },
  });

export default {
  MB_CERT_GEN_MUTATION,
  MbCertGenMutationAPI,
};
