import PropTypes from "prop-types";
import {useState} from "react";
import {Button, Input} from "reactstrap";
import {Datepicker} from "@bphxd/ds-core-react";
import SpinnerLoading from "modules/common/SpinnerLoading";
import {
  SHIPMENT_TYPES_DROPDOWN,
  TITLES,
} from "modules/co-processing/constants/shipments";
import CollapsibleCard from "./CollapsibleCard";
import BulkAdjustmentModal from "./BulkAdjustment/BulkAdjustmentModal";
import CreateShipment from "../CreateShipment";
import "./index.scss";

const ShipmentColumn = ({
  title,
  data,
  refreshData,
  buttonText,
  loadMore,
  showButton,
  refetchShipments,
  filterDateRangeShipments,
  filterTypeShipments,
  handleTankCertifiedDates,
  isLoading,
  onCardSelect,
  lastSelectedCardRef,
  getBatchTankCertifiedDate,
}) => {
  return (
    <div className="pb-52" data-test="copro-shipments-column">
      <h2 className="pt-7 fs-4 grid justify-items-start mb-4">
        <span data-test="copro-shipment-column-title">{title}</span>
      </h2>
      {title === TITLES.ACTIVE_BATCHES && (
        <div className="flex mb-4 w-full" data-test="shipments-filters">
          <div className="w-[320px]">
            <Datepicker
              data-test="tank-certified-date-filter"
              placeholder="Tank certified date range"
              options={{
                mode: "range",
                onChange: handleTankCertifiedDates,
              }}
            />
          </div>
        </div>
      )}
      {title === TITLES.SHIPMENTS && (
        <div className="flex mb-4 w-full" data-test="shipments-filters">
          <div className="w-25 mr-2">
            <Input
              type="select"
              onChange={(e) => filterTypeShipments(e.target.value)}
              data-test="shipments-shipment-type-filter"
            >
              <option value="">All shipment types</option>
              {SHIPMENT_TYPES_DROPDOWN.map(({value, label}) => (
                <option key={label} value={value}>
                  {label}
                </option>
              ))}
            </Input>
          </div>
          <div className="w-[320px]">
            <Datepicker
              data-test="shipments-date-range-filter"
              placeholder="Date range"
              options={{
                mode: "range",
                onChange: (selectedDates) => {
                  if (selectedDates.length === 2) {
                    // Make end date inclusive when selecting the ranges.
                    const endDate = new Date(selectedDates[1]);
                    endDate.setDate(selectedDates[1].getDate() + 1);

                    const dates = [selectedDates[0], endDate];
                    filterDateRangeShipments(
                      dates.map((date) => date.toISOString()),
                    );
                  }
                },
              }}
            />
          </div>
          <BulkAdjustmentModal />
          <CreateShipment />
        </div>
      )}
      <div className="shipment-cards">
        {isLoading && (
          <div className="h-[60vh] grid place-content-center">
            <SpinnerLoading />
          </div>
        )}
        {data?.length === 0 && !isLoading && (
          <div className="h-[60vh] grid place-content-center">
            No {title} data
          </div>
        )}
        {!isLoading &&
          data?.length > 0 &&
          data.map((card, index) => (
            <CollapsibleCard
              key={`${title}-${index}`}
              cardType={title}
              cardData={card}
              refreshData={refreshData}
              refetchShipments={refetchShipments}
              lastSelectedCardRef={lastSelectedCardRef}
              onCardSelect={onCardSelect}
              getBatchTankCertifiedDate={getBatchTankCertifiedDate}
            />
          ))}
      </div>
      {showButton && (
        <div className="copro-us-shipments-load-more-container">
          <Button
            className="py-2 mx-auto rounded-0 enabled:text-gray-800 bg-secondary copro-us-shipments-load-more-button"
            disabled={isLoading}
            onClick={() => loadMore()}
          >
            {buttonText}
          </Button>
        </div>
      )}
    </div>
  );
};

ShipmentColumn.propTypes = {
  title: PropTypes.string,
  data: PropTypes.array,
  refreshData: PropTypes.func,
  buttonText: PropTypes.string,
  loadMore: PropTypes.func,
  showButton: PropTypes.bool,
  refetchShipments: PropTypes.func,
  filterDateRangeShipments: PropTypes.func,
  filterTypeShipments: PropTypes.func,
  handleTankCertifiedDates: PropTypes.func,
  isLoading: PropTypes.bool,
  lastSelectedCardRef: PropTypes.object,
  onCardSelect: PropTypes.func,
  getBatchTankCertifiedDate: PropTypes.func,
};

ShipmentColumn.defaultProps = {
  refreshData: () => {},
  loadMore: () => {},
  showButton: false,
  refetchShipments: () => {},
  filterDateRangeShipments: () => {},
  filterTypeShipments: () => {},
  handleTankCertifiedDates: () => {},
  getBatchTankCertifiedDate: () => {},
  isLoading: false,
};

export default ShipmentColumn;
