import {Left24} from "@bphxd/ds-core-react/lib/icons";
import {has, sortBy, startCase} from "lodash";
import PropTypes from "prop-types";
import {useAppSetting} from "providers/appSetting";
import {useMemo} from "react";
import {useNavigate} from "react-router-dom";

import Feature from "modules/common/FeatureFlag/Feature";
import FEATURE_FLAGS from "modules/common/FeatureFlag/featureFlagConstants";
import {useFeatureFlag} from "modules/common/FeatureFlag/FeatureFlagContext";
import activityModuleList from "../../constants/navigations";
import "./index.scss";
import NavSection from "./NavSection";

const NavigationMenu = ({menuConfig, isGlobalConfigCenter}) => {
  const {appSetting, setAppSetting} = useAppSetting();
  const navigate = useNavigate();

  const [
    bioverseMenuItems,
    sortItems,
    showReturnToBioverse,
    showModuleTitle,
    isHeaderClickable,
    isActivityPage,
  ] = useMemo(() => {
    // filter features according to the user's country
    // Extracting features available for the user's current country
    const userFeatures =
      appSetting?.currentCountryMappingData &&
      Object.entries(appSetting?.currentCountryMappingData)
        ?.filter(([, value]) => {
          // Filtering out features that are enabled (value === true)
          return value === true;
        })
        ?.map((item) => item && item[0]);

    // Filtering menuConfig based on visibility, user's features, and current module
    const availableFeatures = menuConfig?.filter(
      (item) =>
        item.isVisible &&
        userFeatures?.includes(item?.module) &&
        item.module ===
          (appSetting?.currentCountry?.toLowerCase() === "global" ||
          appSetting?.currentModule === ""
            ? item.module
            : appSetting?.currentModule) &&
        // If it is global config center page, display the subItems of new config center instead of old
        ((isGlobalConfigCenter && item.id === "global-config-center") ||
          !isGlobalConfigCenter),
    );

    const isHomePage =
      appSetting?.currentModule === "" &&
      appSetting?.currentCountry?.toLowerCase() === "global";

    const isSorted =
      !isHomePage && has(availableFeatures[0], "sortItems")
        ? availableFeatures[0]?.sortItems
        : true;

    const showReturn =
      !isHomePage && has(availableFeatures[0], "showReturnToBioverse")
        ? availableFeatures[0]?.showReturnToBioverse
        : appSetting?.currentModule !== "" &&
          appSetting?.currentCountry?.toLowerCase() !== "global";

    const showTitle =
      !isHomePage && has(availableFeatures[0], "showModuleTitle")
        ? availableFeatures[0]?.showModuleTitle
        : !(
            appSetting?.currentModule === "" ||
            appSetting?.currentCountry === "GLOBAL"
          );

    const isHeaderClickable = has(availableFeatures[0], "isHeaderClickable")
      ? availableFeatures[0]?.isHeaderClickable
      : true;

    const isActivityPage =
      activityModuleList.includes(appSetting.currentModule) &&
      appSetting.currentCountry.toLowerCase() !== "global";

    // Checking for subItems in the first available feature and assigning them to subItems
    // If no subItems are found, defaults to availableFeatures
    const subItems = has(availableFeatures[0], "subItems")
      ? availableFeatures[0]?.subItems
      : availableFeatures;

    // If the current module is global or empty, all features are displayed
    // If the current module is not global or empty, only the features of the current module are displayed
    const getSubItems =
      appSetting?.currentModule?.toLowerCase() === "global" ||
      appSetting?.currentModule === ""
        ? availableFeatures
        : subItems;

    return [
      getSubItems,
      isSorted,
      showReturn,
      showTitle,
      isHeaderClickable,
      isActivityPage,
    ];
  }, [
    appSetting?.currentCountryMappingData,
    appSetting?.currentCountry,
    isGlobalConfigCenter,
    menuConfig,
    appSetting?.currentModule,
  ]);

  const handleSectionNavigation = (link, module, moduleTitle) => {
    setAppSetting({
      ...appSetting,
      currentModule: module,
      currentModuleTitle: moduleTitle,
    });
    navigate(link);
  };

  const commonNavigationRedesign = useFeatureFlag(
    FEATURE_FLAGS.COMMON_NAVIGATION_REDESIGN,
  );
  const sortedMenuItems = sortItems
    ? sortBy(bioverseMenuItems, "title", "asc")
    : bioverseMenuItems;

  const featureMenuItems =
    commonNavigationRedesign && !isActivityPage
      ? sortedMenuItems.filter(
          (item) => !activityModuleList.includes(item?.module),
        )
      : sortedMenuItems;
  const activitiesMenuItems = isActivityPage
    ? []
    : sortedMenuItems.filter((item) =>
        activityModuleList.includes(item?.module),
      );

  const handleNavTitleClick = (currentModule) => {
    if (currentModule === "") {
      const countryFeatureData = appSetting?.featureMappingData?.find(
        (item) => item.countryName === "GLOBAL",
      );

      setAppSetting({
        ...appSetting,
        currentCountry: "GLOBAL",
        currentCountryMappingData: countryFeatureData,
        currentModule: "",
        currentModuleTitle: "",
      });
      navigate("/");
    } else {
      setAppSetting({
        ...appSetting,

        currentModule: "",
        currentModuleTitle: "",
      });
      navigate("/");
    }
  };

  const countryTitle =
    appSetting?.currentCountry?.toLowerCase() === "global" ||
    !commonNavigationRedesign
      ? ""
      : startCase(appSetting?.currentCountry?.toLowerCase());

  return (
    <div className="d-flex h-100">
      <div className="nav-sidebar d-flex flex-column flex-shrink-0">
        <ul className="list-unstyled mb-auto">
          <li>
            <h5
              className={`nav-sidebar-header uppercase no-underline pointer-events-none fw-normal `}
            >
              <span>
                {showModuleTitle
                  ? appSetting?.currentModuleTitle
                  : "bioverse " + countryTitle}
              </span>
            </h5>
            <NavSection
              items={featureMenuItems}
              handleSectionNavigation={handleSectionNavigation}
            />
            <Feature flag={FEATURE_FLAGS.COMMON_NAVIGATION_REDESIGN}>
              {activitiesMenuItems?.length > 0 && (
                <>
                  <hr className="border-1" />
                  <h5
                    className={`nav-sidebar-header uppercase no-underline pointer-events-none fw-normal `}
                  >
                    <span>Activities</span>
                  </h5>
                  <NavSection
                    items={activitiesMenuItems}
                    handleSectionNavigation={handleSectionNavigation}
                    hideSubItems
                  />
                </>
              )}
            </Feature>
            {showReturnToBioverse && (
              <>
                <hr className="border-1 mt-6  mb-5 border-[#ccc]" />
                <h5 className="side-menu-underline no-underline  cursor-pointer w-full">
                  <span
                    className="flex flex-row items-center w-full justify-start "
                    onClick={() =>
                      handleNavTitleClick(appSetting?.currentModuleTitle)
                    }
                    onKeyDown={() =>
                      handleNavTitleClick(appSetting?.currentModuleTitle)
                    }
                  >
                    <Left24 />
                    <span className="pl-3.5">{`BioVerse ${
                      appSetting?.currentCountry?.toLowerCase() === "global"
                        ? ""
                        : startCase(appSetting?.currentCountry?.toLowerCase())
                    }`}</span>
                  </span>
                </h5>
              </>
            )}
          </li>
        </ul>
      </div>
    </div>
  );
};

NavigationMenu.propTypes = {
  menuConfig: PropTypes.any,
  isGlobalConfigCenter: PropTypes.bool,
};

NavigationMenu.defaultProps = {
  isGlobalConfigCenter: false,
};
export default NavigationMenu;
