import {gql} from "@apollo/client";

export const RULE_VERSION_TEST = gql`
  query ExecuteVersionTestQuery($event: GetRuleVersionConfigRequest!) {
    bioLcComplianceRulesEngineTestExecute(event: $event) {
      schema {
        column
        type
      }
      statusCode
      error
      executionResultDownloadLink
    }
  }
`;

export default {RULE_VERSION_TEST};
