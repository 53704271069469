export const MODULE_NAMES = {
  ceemas: "CEEMAS",
  coProcessing: "Co-processing",
  compliance: "Compliance monitor",
  configCenter: "Configuration centre",

  documentManager: "Documents",
  drawdown: "Drawdown",
  forecasting: "Forecasting",
  reporting: "Reporting",
  saf: "SAF",
};

export default MODULE_NAMES;
