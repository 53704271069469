import {useQuery} from "@apollo/client";
import {GET_Materials} from "graphql/MassBalance/Actions/AllocateSale";
import PropTypes from "prop-types";

import {useEffect} from "react";
import {useFormContext} from "react-hook-form";
import {FormFeedback, FormGroup, Input, Label} from "reactstrap";
import CheckboxFlag from "../../BinaryFlag";

const GeneralInformation = ({data}) => {
  const {
    register,
    formState: {errors},
    control,
    setValue,
  } = useFormContext();

  const computeProps = (name, options) => {
    const {ref, ...props} = register(name, options);
    return {innerRef: ref, ...props};
  };

  const {data: materialMasterData} = useQuery(GET_Materials, {
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
    variables: {
      divisionId: data?.divisionId,
    },
  });

  const chainOfCustody = ["Mass Balance", "Physical Segregation"];

  useEffect(() => {
    console.log("data", data);
    setValue("productType", data?.productType);
    if (
      materialMasterData?.bioLcGetMaterialDataApi?.rawMaterialNames?.length > 0
    ) {
      setValue(
        "rawMaterialType",
        data?.rowData?.original?.inboundRecord?.rawMaterial,
      );
    }
    setValue(
      "rawMaterialOriginCountryName",
      data?.rowData?.original?.inboundRecord?.countryOfOrigin,
    );
    setValue("feedstockQty", data?.quantity);

    if (data?.uom === "MT") {
      setValue("uomMetricTons", true);
    }
    if (data?.uom === "M3") {
      setValue("uomm3", true);
    }
    setValue("isccCompliantFlag", data?.isccCompliantFlag);
    setValue("euRedCompliantFlag", data?.euRedCompliantFlag);
  }, [setValue, data, materialMasterData]);

  return (
    <div className="flex flex-col gap-4 pb-6">
      <FormGroup>
        <Label for="productType" className="fw-light">
          Type of product
        </Label>
        <Input
          {...computeProps("productType")}
          type="text"
          id="productType"
          data-test="productType"
          invalid={!!errors.productType}
          maxLength={100}
          disabled
        />
        {errors.productType && (
          <FormFeedback>{errors.productType.message}</FormFeedback>
        )}
      </FormGroup>
      <FormGroup>
        <Label for="rawMaterialType" className="fw-light">
          Type of raw material
        </Label>
        <Input
          {...computeProps("rawMaterialType")}
          type="select"
          id="rawMaterialType"
          data-test="rawMaterialType"
          invalid={!!errors.rawMaterialType}
          disabled
        >
          {materialMasterData?.bioLcGetMaterialDataApi?.rawMaterialNames?.map(
            (material) => (
              <option
                key={material.rawMaterialName}
                value={material.rawMaterialName}
              >
                {material.rawMaterialName}
              </option>
            ),
          )}
        </Input>

        {errors.rawMaterialType && (
          <FormFeedback>{errors.rawMaterialType.message}</FormFeedback>
        )}
      </FormGroup>
      <FormGroup>
        <Label for="additionalInfo" className="fw-light">
          Additional information
        </Label>
        <Input
          {...computeProps("additionalInfo")}
          type="textarea"
          rows="3"
          id="additionalInfo"
          data-test="additionalInfo"
          invalid={!!errors.additionalInfo}
          maxLength={250}
        />
      </FormGroup>
      <FormGroup>
        <Label for="rawMaterialOriginCountryName" className="fw-light">
          Country of origin (of the origin raw material)
        </Label>
        <Input
          {...computeProps("rawMaterialOriginCountryName")}
          type="text"
          id="rawMaterialOriginCountryName"
          data-test="rawMaterialOriginCountryName"
          invalid={!!errors.rawMaterialOriginCountryName}
          disabled
        />
        {errors.rawMaterialOriginCountryName && (
          <FormFeedback>
            {errors.rawMaterialOriginCountryName.message}
          </FormFeedback>
        )}
      </FormGroup>
      <FormGroup>
        <Label for="feedstockQty" className="fw-light">
          Quantity (t)
        </Label>

        <Input
          {...computeProps("feedstockQty")}
          type="number"
          id="feedstockQty"
          data-test="feedstockQty"
          invalid={!!errors.feedstockQty}
          disabled
        />

        {errors.feedstockQty && (
          <FormFeedback>{errors.feedstockQty.message}</FormFeedback>
        )}
      </FormGroup>

      <FormGroup>
        <Label for="feedstockQtyUom" className="fw-light">
          Unit of Measure
        </Label>
        <div>
          <FormGroup
            check
            inline
            className="form-check-alt form-check-lg !mb-0"
          >
            <Input
              {...computeProps("uomMetricTons")}
              type="checkbox"
              id="uomMetricTons"
              name="uomMetricTons"
              data-test="uomMetricTons"
              disabled
            />

            <Label check for="uomMetricTons">
              Metric tons
            </Label>
          </FormGroup>

          <FormGroup
            check
            inline
            className="form-check-alt form-check-lg !mb-0"
          >
            <Input
              {...computeProps("uomm3")}
              type="checkbox"
              id="uomm3"
              name="uomm3"
              data-test="uomm3"
              disabled
            />

            <Label check for="uomm3">
              m<sup>3</sup>
            </Label>
          </FormGroup>
        </div>
      </FormGroup>
      <FormGroup>
        <Label for="isccCompliantFlag" className="fw-light">
          ISCC compliant material
        </Label>
        <CheckboxFlag
          id="isccCompliantFlag"
          name="isccCompliantFlag"
          control={control}
          error={errors.isccCompliantFlag}
        />
      </FormGroup>
      <FormGroup>
        <Label for="euRedCompliantFlag" className="fw-light">
          EU RED compliant material
        </Label>
        <CheckboxFlag
          id="euRedCompliantFlag"
          name="euRedCompliantFlag"
          control={control}
          error={errors.euRedCompliantFlag}
        />
      </FormGroup>
      <FormGroup>
        <Label for="chainOfCustody" className="fw-light">
          Chain of custody
        </Label>
        <Input
          {...computeProps("chainOfCustody")}
          type="select"
          id="chainOfCustody"
          data-test="chainOfCustody"
          invalid={!!errors.chainOfCustody}
        >
          {chainOfCustody.map((option) => (
            <option key={option} value={option}>
              {option}
            </option>
          ))}
        </Input>
      </FormGroup>
    </div>
  );
};
GeneralInformation.propTypes = {
  data: PropTypes.object,
};
export default GeneralInformation;
