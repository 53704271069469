export const HEADER = "Receipts";
export const TASKS = "Tasks";
export const CONTRACT_SUMMARY_GRAPH = "Received at CHP";
export const DATE_RANGE_TEXT = "Receipts date range";
export const POUNDS_TO_METRIC_TONS = 0.00045359237;

/**
 * @enum {string}
 * Possible document types
 * Api returns lowercase values: pdf | xlsx
 */
export const DOC_TYPE = {
  PDF: "pdf",
  XLSX: "xlsx",
};
/**
 * @enum {string}
 * File processed status for pdf or spreadsheet doc
 * Api returns uppercase values:  FAILED | SUCCESSFUL | UPDATED
 */
export const DOC_PROCESSED_STATUS = {
  FAILED: "FAILED",
  SUCCESSFUL: "SUCCESSFUL",
  UPDATED: "UPDATED",
};

export const TABLE_TITLE = {
  SUMMARY: "Summary",
  CONTRACTS: "Contracts",
  FEEDSTOCK_TRACKING: "Feedstock tracking",
};

export const CONTRACT_STATUS = {
  DRAFT: "Draft",
  SUMMARY: "Summary",
  STATUS: "Status",
};

export const OPERATIONS = {
  GET: "get",
  UPDATE: "update",
};

export const SCHEDULE_POPOVER = {
  HEADER: "Schedule?",
  MESSAGE: "This action can't be undone",
  YES: "Yes",
  NO: "No",
};

export const PARCEL_STATUS = {
  DRAFT: "Draft",
  PENDING: "Pending",
  ACTUALIZED: "Actualized",
  SCHEDULED: "Scheduled",
};

export const TASKS_CARDS = {
  VIEW_UPDATED_RECORD: "Updated records",
  VIEW_FAILED_SPREADSHEET: "View failed spreadsheet",
  VOLUME_UPDATED: "Volume Updated",
  CLEAR_NOTIFICATIONS: "Clear these Notifications",
};

export const TASKS_LABELS = {
  RECORDS_UPDATED: "Records updated",
  SPREADSHEETS_FAILED: "Spreadsheets failed import",
  ALL_DONE: "All Done!",
  NO_TASKS_RECEIPTS: "There are no tasks for 'Receipts'",
  NO_TASKS_BATCHES: "There are no tasks for 'Batches'",
  NO_TASKS_CREDIT_GEN: "There are no tasks for 'Credit Generation'",
};

export const feedstockHeaders = {
  delivered_from: "Delivered From",
  trade_reference: "Contract #",
  leg_number: "Commitment #",
  parcel_number: "Purchase Parcel #",
  shipment_id: "OWB Shipment ID",
  bol_number: "BOL",
  status: "Status",
  shipment_date: "Ship Date",
  tank_number: "Tank #",
  feedstock_type: "Feedstock Type",
  feedstock_percentage: "Feedstock %",
  feedstock_qty: "Amount Received",
  feedstock_qty_uom: "Amount Received UOM",
  density: "Density",
  density_uom: "Density UOM",
  certificate_number: "COA",
};

export const feedstockContractHeaders = {
  vendor_sales_contract: "Vendor Contract ID",
  vendor_bpwca: "OWB ID - Vendor BPPNA (P)",
  trade_reference: "OWB ID - BPPNA_BPWCP (S)",
  owb_batch_tank: "Vendor Tank #",
  density: "Lab Report Density (@60F)",
  lab_report_metals: "Lab Report Metals (ppm)",
  feedstock_qty: "Composite Contract Mass (MT)",
  product_tallow: "Portion of Contract - Animal Fats (MT)",
  product_uco: "Portion of Contract - Used Cooking Oil (MT)",
  product_soy: "Portion of Contract - Soybean Oil (MT)",
  product_canola: "Portion of Contract - Canola Oil (MT)",
  product_dco: "Portion of Contract - Distillers Corn Oil (MT)",
  product_carinata: "Portion of Contract - Carinata Oil (MT)",
  product_cwg: "Portion of Contract - CWG (MT)",
  portion_tallow: "Portion of Contract Density - Animal Fats",
  portion_uco: "Portion of Contract Density - Used Cooking Oil",
  portion_soy: "Portion of Contract Density - Soybean Oil",
  portion_canola: "Portion of Contract Density - Canola Oil",
  portion_dco: "Portion of Contract Density - Distillers Corn Oil",
  portion_carinata: "Portion of Contract Density - Carinata Oil",
  portion_cwg: "Portion of Contract Density - CWG",
};

export const feedstockOWBHeaders = {
  owb_batch_tank: "OWB Batch ID",
  vendor_sales_id: "Vendor Invoice #",
  shipment_date: "Month",
  vendor_sales_contract: "Vendor Sales Contract ID",
  feedstock_qty: "Invoice Total Mass Billed (MT)",
};

export const feedstockReceiptsHeaders = {
  batch_id: "Batch ID",
  owb_batch_tank: "OWB Batch ID",
  vendor_sales_contract: "Vendor Sales Contract ID",
  shipment_date: "Ship Date",
  feedstock_qty: "Quantity Received (MT)",
};

export const feedstockSheets = {
  sheet_2: "Bio Feedstock Contracts",
  sheet_3: "Bio Feedstock OWB Info",
  sheet_4: "Bio Feedstock Receipts",
  sheet_1: "Feedstock Tracking Table",
};

export const contractSummaryHeaders = {
  delivered_from: "Delivered From",
  trade_reference: "Contract #",
  leg_number: "Commitment #",
  open_balance: "Open Balance",
  status: "Status",
  feedstock_type: "Product",
  date_range: "Date Range",
  feedstock_qty_col: "Volume",
};

export const RECEIPT_LABELS = {
  CERT_NUMBER: "Certificate number",
  TANK_NUMBER: "Tank number",
  SAMPLE_LOCATION: "Sample location",
  TEST_DATE: "Test date",
  TALLOW_CONCENTRATE: "Tallow concentration",
  SOY_CONCENTRATE: "Soy concentration",
  CORN_OIL_CONCENTRATE: "Distillers corn oil concentration",
  CANOLA_OIL_CONCENTRATE: "Canola oil concentration",
  USED_COOKING_OIL_CONCENTRATE: "Used cooking oil concentration",
  CHOICE_WHITE_GREASE_CONCENTRATE: "Choice white grease concentration",
  CARINATA_OIL_CONCENTRATE: "Carinata oil concentration",
  DENSITY: "Density at 60F",
  DATE_RECEIVED: "Date received",
  DATE_REPORTED: "Date reported",
  BATCH_ID: "Batch ID",
  PERCENT_MODERN_CARBON: "Percent modern carbon (pMC)",
};

export const keyToLabel = {
  cert_number: RECEIPT_LABELS.CERT_NUMBER,
  tank_number: RECEIPT_LABELS.TANK_NUMBER,
  sample_location: RECEIPT_LABELS.SAMPLE_LOCATION,
  test_date: RECEIPT_LABELS.TEST_DATE,
  tallow_concentration: RECEIPT_LABELS.TALLOW_CONCENTRATE,
  soy_concentration: RECEIPT_LABELS.SOY_CONCENTRATE,
  corn_oil_concentration: RECEIPT_LABELS.CORN_OIL_CONCENTRATE,
  canola_oil_concentration: RECEIPT_LABELS.CANOLA_OIL_CONCENTRATE,
  used_cooking_oil_concentration: RECEIPT_LABELS.USED_COOKING_OIL_CONCENTRATE,
  choice_white_grease_concentration:
    RECEIPT_LABELS.CHOICE_WHITE_GREASE_CONCENTRATE,
  carinata_oil_concentration: RECEIPT_LABELS.CARINATA_OIL_CONCENTRATE,
  density: RECEIPT_LABELS.DENSITY,
};

export const receiptFormMap = new Map(Object.entries(keyToLabel));

export const RECEIPT_INPUTS = [
  {
    label: RECEIPT_LABELS.CERT_NUMBER,
    key: "certificate_number",
    data_type: "text",
    placeholder: "Enter certification number",
  },
  {
    label: RECEIPT_LABELS.TANK_NUMBER,
    key: "sample_source",
    data_type: "text",
    placeholder: "Enter tank number",
  },
  {
    label: RECEIPT_LABELS.SAMPLE_LOCATION,
    key: "sample_location",
    data_type: "text",
    placeholder: "Enter sample location",
  },
  {
    label: RECEIPT_LABELS.TEST_DATE,
    key: "tested_datetime",
    data_type: "date",
  },
  {
    label: RECEIPT_LABELS.TALLOW_CONCENTRATE,
    key: "tallow_concentration",
    data_type: "number",
    placeholder: "Enter tallow concentration",
    className: "px-8 w-80",
  },
  {
    label: RECEIPT_LABELS.SOY_CONCENTRATE,
    key: "soy_concentration",
    data_type: "number",
    placeholder: "Enter soy concentration",
    className: "px-8 w-80",
  },
  {
    label: RECEIPT_LABELS.CANOLA_OIL_CONCENTRATE,
    key: "canola_oil_concentration",
    data_type: "number",
    placeholder: "Enter tallow concentration",
    className: "px-8 w-80",
  },
  {
    label: RECEIPT_LABELS.USED_COOKING_OIL_CONCENTRATE,
    key: "used_cooking_oil_concentration",
    data_type: "number",
    placeholder: "Enter tallow concentration",
    className: "px-8 w-80",
  },
  {
    label: RECEIPT_LABELS.CORN_OIL_CONCENTRATE,
    key: "corn_oil_concentration",
    data_type: "number",
    placeholder: "Enter distillers corn oil concentration",
    className: "px-8 w-80",
  },
  {
    label: RECEIPT_LABELS.CHOICE_WHITE_GREASE_CONCENTRATE,
    key: "choice_white_grease_concentration",
    data_type: "number",
    placeholder: "Enter choice white grease concentration",
    className: "px-8 w-80",
  },
  {
    label: RECEIPT_LABELS.CARINATA_OIL_CONCENTRATE,
    key: "carinata_oil_concentration",
    data_type: "number",
    placeholder: "Enter carinata oil concentration",
    className: "px-8 w-80",
  },
  {
    label: RECEIPT_LABELS.DENSITY,
    key: "density",
    data_type: "",
    placeholder: "Enter density",
  },
];

export const BAR_CHART = [
  {type: "Total", label: "Total", fill: "#199011"},
  {type: "Tallow", label: "Tallow", fill: "#199011"},
  {type: "Soy", label: "Soy", fill: "#199011"},
  {type: "DCO", label: "Corn oil", fill: "#199011"},
  {type: "Canola", label: "Canola", fill: "#199011"},
  {type: "UCO", label: "UCO", fill: "#199011"},
  {type: "CWG", label: "CWG", fill: "#199011"},
  {type: "Carinata", label: "Carinata", fill: "#199011"},
];

export const LOCATION_FILTER = {
  ALL_SUPPLIERS: "All Suppliers",
};
