import {Clockwise16} from "@bphxd/ds-core-react/lib/icons";
import _ from "lodash";
import PropTypes from "prop-types";
import {useCallback, useEffect, useMemo, useState} from "react";
import {Controller, useFormContext, useWatch} from "react-hook-form";
import {Button, FormFeedback, Label} from "reactstrap";

import {countries} from "content/country";
import FormField from "modules/common/FormField";
import moment from "moment";
import DatePicker from "../DatePicker/DatePicker";
import FormDropdown from "../FormDropdown/FormDropdown";

const FilterContent = ({
  options,
  onFilterChange,
  dataSource,
  endDatePlaceholder,
  startDatePlaceholder,
  setEndDatePlaceholder,
  setStartDatePlaceholder,
  startDate,
  endDate,
  setStartDate,
  setEndDate,
  isStartDateValid,
  setStartDateValid,
  isEndDateValid,
  setEndDateValid,
  onClear,
  onFilter,
  isDisabled,
}) => {
  const {
    control,
    setValue,
    getValues,
    reset,
    formState: {errors, defaultValues},
  } = useFormContext();

  const countryValue = useWatch({name: "country"});
  const schemeValue = useWatch({name: "complianceScheme"});
  const typeValue = useWatch({name: "complianceType"});
  const bioTypeValue = useWatch({name: "bioType"});
  const subTypeValue = useWatch({name: "subType"});
  const entityValue = useWatch({name: "legalEntity"});

  useEffect(
    () => onFilterChange("country", countryValue),
    [onFilterChange, countryValue],
  );
  useEffect(
    () => onFilterChange("complianceType", typeValue),
    [onFilterChange, typeValue],
  );
  useEffect(
    () => onFilterChange("complianceScheme", schemeValue),
    [onFilterChange, schemeValue],
  );
  useEffect(
    () =>
      onFilterChange("bioType", bioTypeValue === "" ? undefined : bioTypeValue),
    [bioTypeValue, onFilterChange],
  );
  useEffect(
    () =>
      onFilterChange("subType", subTypeValue === "" ? undefined : subTypeValue),
    [subTypeValue, onFilterChange],
  );
  useEffect(
    () =>
      onFilterChange(
        "legalEntity",
        entityValue === "" ? undefined : entityValue,
      ),
    [entityValue, onFilterChange],
  );

  useEffect(() => {
    // If only one option available on select, set it as value
    _.forOwn(options, (value, key) => {
      // Compliance period works on a weird way compared to the others, I think this need a backend change on payload to be consistent.
      // Check compliancePeriod select options props and reducer extract fn for part of this possible refactor.
      if (key !== "compliancePeriod" && value.length === 1) {
        setValue(key, value[0]);
      }
    });
  }, [getValues, options, setValue]);

  const isValid = useCallback((startDate, endDate) => {
    if (!endDate) return true;
    if (!startDate) return true;
    return new Date(startDate) <= new Date(endDate);
  }, []);

  const handleClear = useCallback(() => {
    reset(undefined, {keepDefaultValues: true});
    onClear?.(getValues());
    onFilter?.(getValues());
  }, [getValues, onClear, onFilter, reset]);

  const buttons = useMemo(
    () => (
      <div className="bp-filter-buttons bp-filter-buttons-compliance">
        <Button
          className="padded btn btn-light rounded-0 border apply-button"
          data-test="filter-submit-button"
          disabled={isDisabled}
        >
          Apply
        </Button>
        <Button
          className="padded btn btn-light rounded-0 border"
          data-test="filter-submit-button"
          onClick={handleClear}
        >
          Refresh <Clockwise16 />
        </Button>
      </div>
    ),
    [handleClear, isDisabled],
  );

  const legalEntityDropdown = useCallback(
    (addMargin) => (
      <FormField className={addMargin ? "mr-auto" : ""}>
        <Controller
          control={control}
          name="legalEntity"
          render={({field}) => (
            <FormDropdown
              label="Legal Entity/Business Unit"
              options={options?.legalEntity}
              values={options?.legalEntity}
              hasAllOption
              hookToBody
              menuClassName="compliance-dropdown-filter"
              {...field}
            />
          )}
        />
      </FormField>
    ),
    [options, control],
  );
  const setValid = useCallback(
    (startDateVal, endDateVal) => {
      const isValid_ = isValid(startDateVal, endDateVal);
      setStartDateValid(isValid_);
      setEndDateValid(isValid_);
    },
    [isValid, setStartDateValid, setEndDateValid],
  );

  const setStartDateAndValid = useCallback(
    (value) => {
      setStartDate(value);
      setValid(value, endDate);
    },
    [setStartDate, setValid, endDate],
  );

  const setEndDateAndValid = useCallback(
    (value) => {
      setEndDate(value);
      setValid(startDate, value);
    },
    [setEndDate, setValid, startDate],
  );

  const [showStartInvalid, setShowStartInvalid] = useState(true);
  const [isStartShown, setIsStartShown] = useState(false);
  const [showEndInvalid, setShowEndInvalid] = useState(true);
  const [isEndShown, setIsEndShown] = useState(false);

  const monthsList = useMemo(
    () => [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ],
    [],
  );

  const complianceFromMonth = useWatch({name: "complianceFromMonth"});
  const [availableToMonths, setAvailableToMonths] = useState(monthsList);

  useEffect(() => {
    if (complianceFromMonth) {
      const fromMonth = moment(complianceFromMonth, "MMMM").month();

      const filteredMonths = monthsList.filter(
        (month, index) => index >= fromMonth,
      );
      setAvailableToMonths(filteredMonths);
    } else {
      setAvailableToMonths(monthsList);
    }
  }, [complianceFromMonth, monthsList]);

  return (
    <div className="compliance-filter-content flex flex-grow max-w-full gap-x-4">
      <div className="flex flex-row flex-wrap gap-4">
        <FormField>
          <Controller
            control={control}
            name="country"
            render={({field}) => {
              return (
                <FormDropdown
                  label="Country"
                  options={options?.country.map(
                    (str) =>
                      countries?.find((o) => o?.countryCode === str)
                        ?.countryName,
                  )}
                  values={options?.country}
                  hookToBody
                  menuClassName="compliance-dropdown-filter"
                  {...field}
                />
              );
            }}
          />
        </FormField>
        <FormField className="compliance-scheme-filter">
          <Controller
            control={control}
            name="complianceScheme"
            render={({field}) => (
              <FormDropdown
                label="Compliance scheme"
                options={options?.complianceScheme.map(
                  (str) =>
                    dataSource?.find((item) => item.complianceScheme === str)
                      .complianceName,
                )}
                values={options?.complianceScheme}
                hookToBody
                menuClassName="compliance-scheme-dropdown-filter"
                {...field}
              />
            )}
          />
        </FormField>
        <FormField>
          <Controller
            control={control}
            name="compliancePeriod"
            render={({field}) => {
              const periods = _.chain(options?.compliancePeriod)
                .flattenDeep()
                .map((str) => ({
                  value: str,
                  label: moment(str, "DD/MM/YYYY").year(),
                }))
                .uniqBy("label")
                .value()
                .map((obj) => obj.label.toString());
              return (
                <FormDropdown
                  label="Compliance period"
                  options={periods}
                  values={periods}
                  hookToBody
                  menuClassName="compliance-dropdown-filter"
                  {...field}
                />
              );
            }}
          />
        </FormField>
        {countryValue === "ES" ? (
          <>
            <FormField className="">
              <Controller
                control={control}
                name="complianceFromMonth"
                render={({field}) => (
                  <FormDropdown
                    label="Starting month"
                    options={monthsList}
                    values={monthsList}
                    hookToBody
                    hasAllOption
                    menuClassName="compliance-dropdown-filter"
                    {...field}
                  />
                )}
              />
            </FormField>
            <FormField className="">
              <Controller
                control={control}
                name="complianceToMonth"
                render={({field}) => (
                  <FormDropdown
                    label="End month"
                    options={availableToMonths}
                    values={availableToMonths}
                    hasAllOption
                    hookToBody
                    menuClassName="compliance-dropdown-filter"
                    {...field}
                  />
                )}
              />
            </FormField>
          </>
        ) : (
          <>
            <FormField>
              <Controller
                control={control}
                name="startDate"
                render={({field: {value, onChange}}) => (
                  <>
                    <Label for="startDate" className="mb-2">
                      Start date
                    </Label>
                    <DatePicker
                      isEmpty={startDate === ""}
                      invalid={isStartDateValid === false}
                      data-test="startDate"
                      placeholder={startDatePlaceholder}
                      id="startDate"
                      name="startDate"
                      allowEmpty={[true]}
                      resetOptions="true"
                      defaultValue=""
                      setDate={(value) => {
                        onChange(value);
                        setStartDateAndValid(value);
                        if (endDate !== "") {
                          setStartDatePlaceholder("All previous days.");
                        } else if (value === "") {
                          setStartDatePlaceholder("Start date");
                          setEndDatePlaceholder("End date");
                        }
                      }}
                      setIsShown={setIsStartShown}
                      setShowInvalid={setShowStartInvalid}
                      isShown={isStartShown}
                      showInvalid={showStartInvalid}
                      options={{
                        allowInput: false,
                        appendTo: "body",
                        altInput: true,
                        showMonths: 1,
                        allowInvalidPreload: false,
                        altFormat: "M j, Y",
                        dateFormat: "d-m-Y",
                        enableTime: false,
                        defaultDate: value,
                        onChange: (value) => {
                          setStartDateAndValid(value);
                          setIsStartShown(false);
                          setShowStartInvalid(true);
                        },
                        onClose: (selectedDates, date, instance) => {
                          onChange(date);
                          if (endDate === "") {
                            if (date === "") {
                              setStartDatePlaceholder("Start date");
                              setEndDatePlaceholder("End date");
                            } else {
                              setEndDatePlaceholder("Open ended.");
                            }
                          }
                        },
                      }}
                    />
                    {isStartDateValid !== undefined && !isStartDateValid && (
                      <FormFeedback style={{display: "block"}} invalid="true">
                        Date range is invalid
                      </FormFeedback>
                    )}
                  </>
                )}
              />
            </FormField>

            <FormField>
              <Controller
                control={control}
                name="endDate"
                render={({field: {value, onChange}}) => (
                  <>
                    <Label for="endDate" className="mb-2">
                      End date
                    </Label>
                    <DatePicker
                      isEmpty={endDate === ""}
                      invalid={isEndDateValid === false}
                      data-test="endDate"
                      placeholder={endDatePlaceholder}
                      id="endDate"
                      name="endDate"
                      allowEmpty={[true]}
                      resetOptions="true"
                      defaultValue=""
                      setIsShown={setIsEndShown}
                      setShowInvalid={setShowEndInvalid}
                      isShown={isEndShown}
                      showInvalid={showEndInvalid}
                      setDate={(value) => {
                        onChange(value);
                        setEndDateAndValid(value);
                        if (startDate !== "") {
                          setEndDatePlaceholder("Open ended.");
                        } else if (value === "") {
                          setStartDatePlaceholder("Start date");
                          setEndDatePlaceholder("End date");
                        }
                      }}
                      options={{
                        allowInput: false,
                        appendTo: "body",
                        altInput: true,
                        showMonths: 1,
                        allowInvalidPreload: false,
                        altFormat: "M j, Y",
                        defaultDate: value,
                        dateFormat: "d-m-Y",
                        enableTime: false,
                        onChange: (value) => {
                          setEndDateAndValid(value);
                          setIsEndShown(false);
                          setShowEndInvalid(true);
                        },
                        onClose: (selectedDates, date, instance) => {
                          onChange(date);
                          if (startDate === "") {
                            if (date === "") {
                              setStartDatePlaceholder("Start date");
                              setEndDatePlaceholder("End date");
                            } else {
                              setStartDatePlaceholder("All previous days.");
                            }
                          }
                        },
                      }}
                    />
                    {isEndDateValid !== undefined && !isEndDateValid && (
                      <FormFeedback style={{display: "block"}} invalid="true">
                        Date range is invalid
                      </FormFeedback>
                    )}
                  </>
                )}
              />
            </FormField>
          </>
        )}

        <FormField>
          <Controller
            control={control}
            name="complianceType"
            render={({field}) => (
              <FormDropdown
                label="Compliance Type"
                options={options?.complianceType}
                values={options?.complianceType}
                hookToBody
                menuClassName="compliance-dropdown-filter"
                {...field}
              />
            )}
          />
        </FormField>
        <FormField>
          <Controller
            disabled={countryValue === "ES"}
            control={control}
            name="bioType"
            render={({field}) => (
              <FormDropdown
                label="Bio Type"
                options={options?.bioType}
                values={options?.bioType}
                hasAllOption
                hookToBody
                menuClassName="compliance-dropdown-filter"
                {...field}
              />
            )}
          />
        </FormField>
        <FormField>
          <Controller
            disabled={countryValue === "ES"}
            control={control}
            name="subType"
            render={({field}) => (
              <FormDropdown
                label="Sub Type"
                options={options?.subType}
                values={options?.subType}
                hasAllOption
                hookToBody
                menuClassName="compliance-dropdown-filter"
                {...field}
              />
            )}
          />
        </FormField>
        {countryValue !== "US" ? (
          <div className="flex flex-grow flex-nowrap flex-row gap-4">
            {legalEntityDropdown(true)}
            {buttons}
          </div>
        ) : (
          <>
            {legalEntityDropdown(false)}
            <div className="flex flex-grow flex-nowrap flex-row gap-4">
              <FormField className="mr-auto">
                <Controller
                  control={control}
                  name="uom"
                  render={({field}) => (
                    <FormDropdown
                      label="Unit of measure"
                      options={["Gallons", "BBLS"]}
                      values={["Gallons", "BBLS"]}
                      hookToBody
                      menuClassName="compliance-dropdown-filter"
                      {...field}
                    />
                  )}
                />
              </FormField>
              {buttons}
            </div>
          </>
        )}
      </div>
    </div>
  );
};

FilterContent.propTypes = {
  dataSource: PropTypes.array,
  options: PropTypes.object,
  onFilterChange: PropTypes.func,
  endDate: PropTypes.array,
  startDate: PropTypes.array,
  setEndDate: PropTypes.func,
  setStartDate: PropTypes.func,
  endDatePlaceholder: PropTypes.string,
  startDatePlaceholder: PropTypes.string,
  setEndDatePlaceholder: PropTypes.func,
  setStartDatePlaceholder: PropTypes.func,
  isStartDateValid: PropTypes.bool,
  setStartDateValid: PropTypes.func,
  isEndDateValid: PropTypes.bool,
  setEndDateValid: PropTypes.func,
  onClear: PropTypes.func,
  onFilter: PropTypes.func,
  isDisabled: PropTypes.bool,
};

export default FilterContent;
