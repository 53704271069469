import {Close} from "@bphxd/ds-core-react";
import outboundTypes, {ALL_MANDATES} from "constants/allocation";
import PropTypes from "prop-types";
import {useUserSettings} from "providers/userSettings";
import React, {useMemo} from "react";
import {FormProvider, useForm} from "react-hook-form";
import {
  Button,
  Form,
  FormFeedback,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Spinner,
} from "reactstrap";
import {formatNumberIfExists, preventZeroOrNegative} from "utils/numberUtil";

const AllocateModal = ({
  showModal,
  setShowModal,
  outboundType,
  sustainableQty,
  fuelQty,
  unitOfMeasure,
  onSubmit,
  loading,
}) => {
  const {
    userSettings: {decimalFormat},
  } = useUserSettings();

  const methods = useForm({
    mode: "onChange",
    shouldFocusError: true,
  });

  const {
    register,
    formState: {errors},
    getValues,
    watch,
    trigger,
  } = methods;

  const computeProps = (name, options) => {
    const {ref, ...props} = register(name, options);
    return {innerRef: ref, ...props};
  };

  const watchQuantity = watch("sustainableQtyM3");

  const checkQuantity = useMemo(() => {
    const sustainQty = parseFloat(sustainableQty);
    const fQty = parseFloat(fuelQty);
    const formQuantity = parseFloat(watchQuantity);
    return sustainQty < fQty ? formQuantity > sustainQty : formQuantity > fQty;
  }, [sustainableQty, fuelQty, watchQuantity]);

  let headerText = "";
  switch (outboundType) {
    case outboundTypes.LOCATION_TRANSFER:
      headerText = "Location transfer";
      break;
    case outboundTypes.EXPORT:
      headerText = "Export";
      break;
    default:
      headerText = `Retire for ${outboundType}`;
  }

  const isMandate = ALL_MANDATES.includes(outboundType);

  const showSustainableQty = formatNumberIfExists(
    sustainableQty,
    decimalFormat,
    3,
  );

  const showFuelQty = formatNumberIfExists(fuelQty, decimalFormat, 3);

  return (
    <Modal
      size="sm"
      isOpen={showModal}
      className="modal-dialog-centered [&>div]:w-[330px]"
    >
      {outboundType !== outboundTypes.VOLUNTARY && (
        <ModalHeader
          className="!border-b-[1px] !border-gray-200 mb-2 text-xl"
          close={<Close onClick={() => setShowModal(false)} />}
        >
          {headerText}
        </ModalHeader>
      )}
      {outboundType === outboundTypes.VOLUNTARY && (
        <ModalBody className="text-center">
          <p className="mb-[2px]">Confirm allocation</p>
          <p className="text-[13px] text-gray-800 mb-0">
            Are you sure you want to allocate this purchase to the outgoing
            transaction?
          </p>
        </ModalBody>
      )}
      {outboundType !== outboundTypes.VOLUNTARY && (
        <ModalBody
          className={`flex flex-col items-center w-[330px] !pt-[17px] ${
            isMandate ? "!pb-[25px]" : "!pb-[52px]"
          }`}
        >
          <div className="w-full">
            <FormProvider {...methods}>
              <Form>
                <Label for="sustainableQtyM3">Sustainable quantity m³</Label>
                <Input
                  id="sustainableQtyM3"
                  type="number"
                  placeholder="Enter quantity"
                  {...computeProps("sustainableQtyM3", {
                    required: "Sustainable quantity is required",
                    pattern: preventZeroOrNegative,
                  })}
                  invalid={!!errors.sustainableQtyM3 || checkQuantity}
                />
                {errors.sustainableQtyM3 && (
                  <FormFeedback>{errors.sustainableQtyM3.message}</FormFeedback>
                )}
                {!errors.sustainableQtyM3 && checkQuantity && (
                  <FormFeedback className="!block">
                    It cannot exceed either of the quantities below
                  </FormFeedback>
                )}
                <FormFeedback className="!block !text-gray-700 !tracking-[0.2px]">
                  You have max {showSustainableQty}
                  {unitOfMeasure} sustainable available. ({showFuelQty}
                  {unitOfMeasure} fuel quantity can be allocated)
                </FormFeedback>

                <Label className="mt-[30px]" for="notes">
                  Notes
                </Label>
                <Input
                  id="notes"
                  type="textarea"
                  rows="3"
                  {...computeProps("notes")}
                />
                {isMandate && (
                  <FormFeedback className="!block !text-gray-700 !tracking-[0.2px]">
                    Please ensure that an equivalent quantity of fuel volume has
                    been sold to customers which can be retired for mandate in
                    this mass balance period.
                  </FormFeedback>
                )}
              </Form>
            </FormProvider>
          </div>
        </ModalBody>
      )}

      <ModalFooter className="p-0 d-block">
        <div className="row g-0 m-0 modal-footer-row">
          <div className="col-6 d-grid">
            <Button
              color="darker-tertiary"
              className="border-0 rounded-0 py-4 opacity-80 opacity-100-hover bg-transparent"
              onClick={() => setShowModal(false)}
            >
              Cancel
            </Button>
          </div>
          <div className="col-6 d-grid">
            <Button
              color="darker-tertiary"
              className="border-0 rounded-0 py-4 bg-transparent text-default"
              onClick={() => {
                trigger().then((isValid) => {
                  if (isValid) {
                    onSubmit(getValues());
                  }
                });
              }}
              disabled={errors.sustainableQtyM3 || checkQuantity || loading}
            >
              {loading && <Spinner size="sm" className="btn-icon-prefix" />}
              {isMandate ? "Retire for mandate" : "Allocate"}
            </Button>
          </div>
        </div>
      </ModalFooter>
    </Modal>
  );
};

AllocateModal.propTypes = {
  showModal: PropTypes.bool,
  setShowModal: PropTypes.func,
  outboundType: PropTypes.string,
  sustainableQty: PropTypes.number,
  fuelQty: PropTypes.number,
  unitOfMeasure: PropTypes.string,
  onSubmit: PropTypes.func,
  loading: PropTypes.bool,
};

export default AllocateModal;
