import {gql} from "@apollo/client";
import {client} from "providers/Apollo";

const SEND_CERTIFICATE_EMAIL = gql`
  mutation bioLcCertGenEmailFile(
    $event: bioLcOutgoingCertGenEmailFileRequest!
  ) {
    bioLcCertGenEmailFile(event: $event) {
      error
      statusCode
    }
  }
`;

export const SendCertificateEmailAPI = (data) =>
  client.mutate({
    mutation: SEND_CERTIFICATE_EMAIL,
    variables: {
      ...data,
    },
    refetchQueries: ["bioLcGetCertificateOutboundData"],
  });

export default {SEND_CERTIFICATE_EMAIL, SendCertificateEmailAPI};
