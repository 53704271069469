import Checkbox from "./Checkbox";
import TableCheckbox from "./TableCheckbox";
import Warning from "./Warning";

const createCheckboxColumn = (type, otherRows, otherData) => ({
  header: ({table}) => (
    <TableCheckbox
      table={table}
      type={type}
      otherRows={otherRows}
      otherData={otherData}
    />
  ),
  accessorKey: "checkbox",
  enableSorting: false,
  size: 25,
  cell: ({row, table}) => (
    <Checkbox
      row={row}
      table={table}
      type={type}
      otherRows={otherRows}
      otherData={otherData}
    />
  ),
});

const checkValue = (val) => {
  if (val === null || val <= 0) return false;
  return true;
};

const createConditionalCheckboxColumn = (type, otherRows, otherData) => ({
  ...createCheckboxColumn(type, otherRows, otherData),
  cell: ({row, table}) =>
    row.original.mb_date &&
    checkValue(row.original.received_qty_mt) &&
    checkValue(row.original.purchase_qty_mt) &&
    row.original.purchase_balance_group_name &&
    row.original.purchase_location_group_name ? (
      <Checkbox
        row={row}
        table={table}
        type={type}
        otherRows={otherRows}
        otherData={otherData}
      />
    ) : (
      <Warning row={row} />
    ),
});

const getColumnsCopro = (
  columns,
  enableRowSelection,
  type,
  otherRows,
  otherData,
) => {
  if (!enableRowSelection) {
    return columns;
  }

  const newColumn =
    type === "Incoming"
      ? createCheckboxColumn(type, otherRows, otherData)
      : createConditionalCheckboxColumn(type, otherRows, otherData);

  return type === "Incoming"
    ? [newColumn, ...columns]
    : [...columns, newColumn];
};

export default getColumnsCopro;
