const {gql} = require("@apollo/client");

const GENERATE_PDF_SUBSCRIPTION = gql`
  subscription onBiolccertgenpdfcomplete($certificateOutboundEuId: String!) {
    onBiolccertgenpdfcomplete(
      certificateOutboundEuId: $certificateOutboundEuId
    ) {
      certificateOutboundEuId
      statusCode
      error
    }
  }
`;

export default GENERATE_PDF_SUBSCRIPTION;
