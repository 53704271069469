import {gql} from "@apollo/client";

export const GET_COMPLIANCE_SCHEMES = gql`
  query GetComplianceSchemes($ruleVersionId: String!) {
    bioLcComplianceRuleEngineGetComplianceSchemes(
      event: {ruleVersionId: $ruleVersionId}
    ) {
      complianceSchemes {
        uuid
        complianceScheme
      }
    }
  }
`;

export const GET_POS_STATUS = gql`
  query PosStatus {
    bioLcComplianceRuleEngineGetPosStatus {
      error
      statusCode
      posStatus {
        statusKey
        statusText
      }
    }
  }
`;

export default GET_COMPLIANCE_SCHEMES;
