import tw from "twin.macro";
import {ReactTable} from "@bphxd/ds-core-react";
import {
  Button,
  Nav,
  NavItem,
  NavLink,
  Popover,
  PopoverHeader,
  PopoverBody,
} from "reactstrap";
import {useDateRange} from "modules/co-processing/context/DateRangeContext";
import {useQuery, useLazyQuery} from "@apollo/client";
import {
  COPRO_US_API_CONTRACT_DATA,
  COPRO_US_API_SAVE_DRAFT_DATA,
} from "graphql/coprocessing/receipts";
import {useMemo, useState, useEffect} from "react";
import {BUTTONS} from "modules/co-processing/constants/coProcessing";
import {
  CONTRACT_SUMMARY_GRAPH,
  TABLE_TITLE,
  contractSummaryHeaders,
  CONTRACT_STATUS,
  SCHEDULE_POPOVER,
} from "../../constants/receipts";
import {useStatus} from "../../context/StatusContext";
import SummaryBarChart from "./SummaryBarChart";
import buildColumns, {filterDraftColumns} from "../../helpers/buildColumns";

const GraphContainer = tw.div`mt-12 xl:w-[445px]`;
const GraphBody = tw.div`flex bg-white py-4 w-full justify-center`;
const ContainerTitle = tw.div`h-[52px] flex bg-white items-center pl-[15px] border-b`;
const ContainerHeader = tw.div`h-[52px] flex items-center pl-[15px] border-b justify-between`;

const ContractSummary = () => {
  const {dateRange, isScheduled, setIsScheduled} = useDateRange();
  const {
    isDraft,
    draftChangeHandler,
    rowChangeHandler,
    schedulableRecordHandler,
    isRowSelected,
    isSchedulableRecords,
  } = useStatus();
  const [selectedRows, setSelectedRows] = useState({});
  const [showPopover, setShowPopover] = useState(false);
  const [activeTab, setActiveTab] = useState(CONTRACT_STATUS.DRAFT);

  const handleSchedule = () => {
    setShowPopover(false);
    setIsScheduled(true);
  };

  const initialVariables = {
    tracking_type: isDraft
      ? CONTRACT_STATUS.STATUS.toLowerCase()
      : CONTRACT_STATUS.SUMMARY.toLowerCase(),
    date_range: {
      start_date: dateRange.from,
      end_date: dateRange.to,
    },
  };

  const {data} = useQuery(COPRO_US_API_CONTRACT_DATA, {
    variables: initialVariables,
    fetchPolicy: "cache-and-network",
    notifyOnNetworkStatusChange: true,
  });

  useEffect(() => {
    rowChangeHandler(Object.keys(selectedRows).length > 0);
  }, [rowChangeHandler, selectedRows]);

  const [scheduleQuery] = useLazyQuery(COPRO_US_API_SAVE_DRAFT_DATA, {
    fetchPolicy: "cache-and-network",
    onCompleted: () => {
      setIsScheduled(false);
      setSelectedRows({});
      draftChangeHandler(false);
    },
  });

  useEffect(() => {
    if (isScheduled) {
      const filteredDraftColumns = () => {
        if (Object.keys(selectedRows).length === 0 || !isDraft) return {};
        return filterDraftColumns(data?.bioLcCoproUsApi?.data, selectedRows);
      };
      const draftVariables = {
        tracking_type: "insert_drafts",
        data_to_insert: filteredDraftColumns(),
        date_range: {
          start_date: dateRange.from,
          end_date: dateRange.to,
        },
      };
      scheduleQuery({variables: draftVariables});
    }
  }, [isScheduled, scheduleQuery, data, dateRange, isDraft, selectedRows]);

  useEffect(() => {
    schedulableRecordHandler(data?.bioLcCoproUsApi?.schedulable_records);
  }, [data, schedulableRecordHandler]);

  const contractData = useMemo(() => {
    const dataArray = data?.bioLcCoproUsApi?.data;
    if (!Array.isArray(dataArray)) return [];

    return dataArray.map((item) => ({
      ...item,
      feedstock_qty_col: item.feedstock_qty
        ? `${item.feedstock_qty.toLocaleString()} ${item.feedstock_qty_uom}`
        : 0,
    }));
  }, [data]);

  const dataColumns = buildColumns(contractSummaryHeaders);

  return (
    <div>
      <div
        data-test="copro-status-pills"
        className="copro-status-pills  w-full"
      >
        <Nav tabs>
          <NavItem>
            <NavLink
              data-test="copro-draft-pill"
              active={activeTab === CONTRACT_STATUS.DRAFT}
              onClick={() => {
                setActiveTab(CONTRACT_STATUS.DRAFT);
                draftChangeHandler(true);
              }}
            >
              {CONTRACT_STATUS.DRAFT}
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              data-test="copro-summary-pill"
              active={activeTab === CONTRACT_STATUS.SUMMARY}
              onClick={() => {
                setActiveTab(CONTRACT_STATUS.SUMMARY);
                draftChangeHandler(false);
              }}
            >
              {CONTRACT_STATUS.SUMMARY}
            </NavLink>
          </NavItem>
        </Nav>
      </div>
      <div
        data-test="copro-contract-summary"
        className="copro-contract-summary flex flex-wrap justify-between pb-[100px] w-full pt-4"
      >
        <div data-test="copro-contract-summary-table" className="copro-table">
          <ContainerHeader
            data-test="copro-table-container-title"
            className="copro-container-title pb-4"
          >
            {TABLE_TITLE.CONTRACTS}{" "}
            <Button
              className="rounded-0 enabled:border-black disabled:border-gray-300 text-white bg-black"
              color="standard-secondary"
              id="popover-schedule"
              onClick={() => setShowPopover(!showPopover)}
              disabled={
                !isSchedulableRecords ||
                !dateRange ||
                !isDraft ||
                !isRowSelected
              }
            >
              {BUTTONS.SCHEDULE}
            </Button>
            <Popover
              popperClassName="schedule-popover"
              innerClassName="schedule-popover-inner"
              placement="bottom"
              hideArrow
              isOpen={showPopover && !!dateRange}
              target="popover-schedule"
            >
              <PopoverHeader className="pt-3.5 text-center font-semibold">
                {SCHEDULE_POPOVER.HEADER}
              </PopoverHeader>
              <PopoverBody>
                <div className="text-slate-300 text-center border-b px-3 pb-3">
                  {SCHEDULE_POPOVER.MESSAGE}
                </div>
                <div className="flex justify-between p-3">
                  <Button
                    className="border-black w-[75px]"
                    color="standard-tertiary"
                    onClick={() => setShowPopover(false)}
                  >
                    {SCHEDULE_POPOVER.NO}
                  </Button>
                  <Button
                    className="schedule-btn border-black w-[75px]"
                    color=""
                    onClick={handleSchedule}
                  >
                    {SCHEDULE_POPOVER.YES}
                  </Button>
                </div>
              </PopoverBody>
            </Popover>
          </ContainerHeader>

          <ReactTable
            responsive
            columns={dataColumns}
            data={contractData}
            enableSorting
            rowSelection={isDraft ? selectedRows : false}
            setRowSelection={setSelectedRows}
          />
        </div>

        <GraphContainer
          className="contract-summary-graph"
          data-test="copro-contract-summary-graph"
        >
          <ContainerTitle
            data-test="copro-chart-container-title"
            className="copro-container-title"
          >
            {CONTRACT_SUMMARY_GRAPH}
          </ContainerTitle>
          <GraphBody>
            <SummaryBarChart summaryData={contractData}></SummaryBarChart>
          </GraphBody>
        </GraphContainer>
      </div>
    </div>
  );
};

export default ContractSummary;
