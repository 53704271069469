import {useLocation} from "react-router-dom";
import {useEffect} from "react";
import ContractSummary from "modules/co-processing/components/Receipts/ContractSummary";
import FeedstockTracking from "modules/co-processing/components/Receipts/FeedstockTracking";
import SectionWrapper from "modules/co-processing/components/SectionWrapper";
import {
  TABLE_TITLE,
  HEADER,
  DATE_RANGE_TEXT,
} from "modules/co-processing/constants/receipts";
import StickyDatePicker from "modules/co-processing/components/DatePicker/StickyDatePicker";
import TaskManager from "modules/co-processing/components/Shared/TaskManager";
import {
  ContractDateRangeProvider,
  useDateRange,
} from "modules/co-processing/context/DateRangeContext";
import {StatusProvider} from "modules/co-processing/context/StatusContext";
import {PAGE_TITLE} from "modules/co-processing/constants/coProcessing";
import "../../components/index.scss";

const PrimaryPageContent = () => {
  const {state} = useLocation();
  const {dateChangeHandler} = useDateRange();

  useEffect(() => {
    if (state?.dateRange) {
      dateChangeHandler([state.dateRange?.from, state.dateRange?.to]);
    }
  }, [state, dateChangeHandler]);

  return (
    <div className="co-processing-container overflow-x-auto">
      <TaskManager task_type="receipts" />
      <div className="receipts-header">
        <span className="text-xl">{DATE_RANGE_TEXT}</span>
        <StickyDatePicker />
        <SectionWrapper page={HEADER} tableTitle={TABLE_TITLE.SUMMARY}>
          <ContractSummary />
        </SectionWrapper>
      </div>
      <div className="receipts">
        <SectionWrapper
          page={HEADER}
          tableTitle={TABLE_TITLE.FEEDSTOCK_TRACKING}
        >
          <FeedstockTracking />
        </SectionWrapper>
      </div>
    </div>
  );
};

const PrimaryPage = () => {
  return (
    <div>
      <div className="mt-4 pb-6 mx-[40px] text-[32px] border-b-[1px]">
        {PAGE_TITLE.RECEIPTS}
      </div>
      <ContractDateRangeProvider>
        <StatusProvider>
          <PrimaryPageContent />
        </StatusProvider>
      </ContractDateRangeProvider>
    </div>
  );
};

export default PrimaryPage;
