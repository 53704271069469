import {useState, useCallback} from "react";
import PropTypes from "prop-types";
import {useLazyQuery} from "@apollo/client";
import {Popover, PopoverBody, Button, Input} from "reactstrap";
import {COPRO_US_SHIPMENTS_API_EDIT_SHIPMENT_ID} from "graphql/coprocessing/shipments";
import {
  POPOVER_LABELS,
  BUTTONS,
  METRICS,
} from "modules/co-processing/constants/bulkAdjustment";
import {displayDate} from "modules/co-processing/helpers/dateHelper";
import FieldRow from "./FieldRow";
import BatchesStatusChip from "../../Shared/BatchesStatusChip";

const BulkAdjustmentShipmentPopover = ({shipment, target}) => {
  const [popoverOpen, setPopoverOpen] = useState(false);
  const [notesValue, setNotesValue] = useState(shipment?.notes);
  const isCommitted = shipment?.shipment_status === "Committed";

  const [editShipment] = useLazyQuery(COPRO_US_SHIPMENTS_API_EDIT_SHIPMENT_ID, {
    fetchPolicy: "no-cache",
    notifyOnNetworkStatusChange: true,
  });

  const handleChange = useCallback((event) => {
    const {value} = event.target;
    setNotesValue(value);
  }, []);

  const togglePopover = () => {
    setPopoverOpen(!popoverOpen);
  };

  const handleKeyDown = (event) => {
    if (!isCommitted && (event.key === "Enter" || event.key === " ")) {
      togglePopover();
    }
  };

  const saveNotes = () => {
    const variables = {
      shipmentId: shipment?.parent_shipment_id,
      update_field: {
        allocated_shipment_id: shipment?.shipment_id,
        field: "notes",
        value: notesValue,
      },
    };
    editShipment({variables});
    setPopoverOpen(false);
  };

  return (
    <>
      <div
        data-test={`bulk-adjustment-shipment-element--${shipment.shipment_id}`}
        id={target}
        onClick={togglePopover}
        onKeyDown={handleKeyDown}
        className={isCommitted ? `text-gray-400` : `cursor-pointer underline`}
      >
        {shipment.shipment_id}
      </div>
      <Popover
        data-test={`bulk-adjustment-popover-shipment-${shipment.shipment_id}`}
        placement="top"
        isOpen={popoverOpen}
        target={target}
        toggle={togglePopover}
        className="bulk-adjustment-shipment-popover"
      >
        <PopoverBody>
          <div className="pb-5">
            <BatchesStatusChip value={shipment?.shipment_status} small />
          </div>

          <FieldRow
            label={POPOVER_LABELS.SHIPMENT_DATE}
            value={displayDate(shipment?.shipment_date, true)}
          />
          {shipment?.shipment_type === "TRUCK_RACK" && (
            <FieldRow
              label={POPOVER_LABELS.VOLUME_ALLOCATED}
              value={`${shipment?.total_allocated_volume?.toLocaleString()} ${
                METRICS.BBL
              }`}
            />
          )}
          <div className="my-3.5">
            <div className="pb-2">{POPOVER_LABELS.NOTES}</div>
            <Input
              style={{resize: "none", height: "126px"}}
              data-test="copro-bulk-adjustment-modal-input"
              type="textarea"
              value={notesValue ?? ""}
              onChange={handleChange}
              maxLength={200}
              required
            />
          </div>
          <div className="flex justify-end">
            <Button
              className="btn-clear rounded-0"
              color="standard-outline"
              onClick={() => saveNotes()}
            >
              {BUTTONS.SAVE_NOTES}
            </Button>
          </div>
        </PopoverBody>
      </Popover>
    </>
  );
};

BulkAdjustmentShipmentPopover.propTypes = {
  shipment: PropTypes.object.isRequired,
  target: PropTypes.string.isRequired,
};

export default BulkAdjustmentShipmentPopover;
