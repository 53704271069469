import PropTypes from "prop-types";
import {useFormContext} from "react-hook-form";
import {FormGroup, Label, Input} from "reactstrap";
import CheckboxYesOnly from "./checkboxYes";

const InformationForDeliveriesOfBiofuels = ({
  status,
  selectedCountry,
  isDisabled,
}) => {
  const {
    register,
    formState: {errors},
    control,
    watch,
  } = useFormContext();
  const computeProps = (name, options) => {
    const {ref, ...props} = register(name, options);
    return {innerRef: ref, ...props};
  };

  return (
    <div className="flex flex-col gap-5 pb-6">
      <FormGroup>
        <Label for="japaneseDefaultValueCorn" className="fw-normal">
          Use of Japanese Default Values for U.S. ethanol (corn)
        </Label>
        <CheckboxYesOnly
          id="japaneseDefaultValueCorn"
          name="japaneseDefaultValueCorn"
          control={control}
          error={errors.japaneseDefaultValueCorn}
          disabled={isDisabled}
        />
      </FormGroup>
      <FormGroup>
        <Label for="emissionsFromLandUseCorn" className="fw-normal">
          Emissions from Land Use Change are zero (el = 0)
        </Label>
        <CheckboxYesOnly
          id="emissionsFromLandUseCorn"
          name="emissionsFromLandUseCorn"
          control={control}
          error={errors.emissionsFromLandUseCorn}
          disabled={isDisabled}
        />
      </FormGroup>
      <FormGroup>
        <Label for="japaneseDefaultValueSugarcane" className="fw-normal">
          Use of Japanese Default Values for Brazilian ethanol (sugar cane)
        </Label>
        <CheckboxYesOnly
          id="japaneseDefaultValueSugarcane"
          name="japaneseDefaultValueSugarcane"
          control={control}
          error={errors.japaneseDefaultValueSugarcane}
          disabled={isDisabled}
        />
      </FormGroup>
      <FormGroup>
        <Label for="emissionsFromLandUseSugarcane" className="fw-normal">
          Emissions from Land Use Change are zero (el = 0)
        </Label>
        <CheckboxYesOnly
          id="emissionsFromLandUseSugarcane"
          name="emissionsFromLandUseSugarcane"
          control={control}
          error={errors.emissionsFromLandUseSugarcane}
          disabled={isDisabled}
        />
      </FormGroup>
    </div>
  );
};

InformationForDeliveriesOfBiofuels.propTypes = {
  status: PropTypes.string.isRequired,
  selectedCountry: PropTypes.string,
  isDisabled: PropTypes.bool,
};
export default InformationForDeliveriesOfBiofuels;
