import {Kebab24} from "@bphxd/ds-core-react/lib/icons";
import moment from "moment";
import {formatNumber} from "providers/userSettings";
import {Link} from "react-router-dom";
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from "reactstrap";
import appendParamsToUrl from "utils/helpers/appendParamsToUrl";

const showStatus = (status, saleStatus) => {
  if (saleStatus === "VOIDED") {
    return "Assignment removed";
  }
  switch (status) {
    case "ASSIGNED AND SOLD":
      return "Assigned to sale";
    case "ASSIGNED AND SENT":
      return "Assigned & sent";
    default:
      return "Unknown state";
  }
};

const getColumns = (
  dateFormat,
  decimalFormat,
  handleSendCertificate,
  selectedCountry,
  divisionCode,
  divisionId,
  documentManager1_4FeatureFlag,
) => [
  {
    header: "Status",
    key: "status",
    accessorKey: "status",
    visible: true,
    disabled: true,
  },
  {
    header: "Date of issuance",
    key: "dateOfIssuance",
    accessorKey: "dateOfIssuance",
    cell: (cell) => {
      const dateValue = cell.getValue();
      return dateValue ? moment(dateValue).format(dateFormat) : "";
    },
    visible: true,
    meta: {
      filterVariant: "daterange",
    },
    filterFn: "dateRangeFilterFn",
  },
  {
    header: "Certificate System",
    key: "certificationSystem",
    accessorKey: "certificationSystem",
    visible: true,
    meta: {
      filterVariant: "select",
    },
  },
  {
    header: "Certificate number",
    accessorKey: "certificateNumber",
    key: "certificateNumber",
    visible: true,
    disabled: true,
    cell: ({cell, row, table}) => {
      console.log("row", cell);
      return (
        <Link
          to={appendParamsToUrl(
            "/mass-balance/outgoing-view",
            [
              selectedCountry,
              divisionCode,
              row?.original?.certificateOutboundEuId,
            ],
            documentManager1_4FeatureFlag
              ? {
                  redirectTo: appendParamsToUrl(
                    "/doc-manager",
                    [selectedCountry],
                    {
                      divisionId,
                      page: "outgoing",
                    },
                  ),
                  pageNo: table.getState()?.pagination?.pageIndex,
                  page: "outgoing",
                }
              : {
                  redirectTo: appendParamsToUrl("/doc-manager/outgoing", [
                    selectedCountry,
                  ]),
                  pageNo: table.getState()?.pagination?.pageIndex,
                  page: "outgoing",
                },
          )}
          className="link-dark"
        >
          <u>{cell.getValue()}</u>
        </Link>
      );
    },
  },
  {
    header: "Quantity (t)",
    accessorKey: "quantityAllocatedMt",
    key: "quantityAllocatedMt",
    sortingFn: "alphanumeric",
    cell: (cell) => {
      return formatNumber(cell.getValue(), decimalFormat, 3);
    },
    visible: true,
  },
  {
    header: "Recipient",
    accessorKey: "recipientName",
    key: "recipientName",
    size: 200,
    visible: true,
    meta: {
      filterVariant: "select",
    },
  },
  {
    header: "Product",
    accessorKey: "productType",
    key: "productType",
    visible: true,
    meta: {
      filterVariant: "select",
    },
  },
  {
    header: "Mass Balance",
    accessorKey: "mbLocation",
    key: "mbLocation",
    size: 110,
    visible: true,
    defaultOption: true,
    accessorFn: ({mbPeriodName, mbLocationGroupName}) => {
      return `${mbPeriodName} - ${mbLocationGroupName}`;
    },
    cell: ({getValue, row, cell}) => {
      if (
        row.original.mbLocationGroupName &&
        row.original.mbPeriodName &&
        row.original.mbBalanceTypeCode
      )
        return (
          <Link
            to={appendParamsToUrl("/mass-balance", [
              selectedCountry,
              divisionCode,
              row.original.mbBalanceTypeCode,
              row.original.mbLocationGroupName,
              row.original.mbPeriodName,
              row.original.mbBalanceGroupName,
            ])}
            className="link-dark"
            state={row.original.mbPeriodId}
          >
            <u>{cell.getValue()}</u>
          </Link>
        );

      return "";
    },
  },
  {
    header: "",
    accessorKey: "actions",
    key: "actions",
    size: 25,
    visible: true,
    enableSorting: false,
    cell: ({row, table}) => {
      return (
        <UncontrolledDropdown direction="up">
          <DropdownToggle color="standard-quartenary" className="!px-0">
            <Kebab24></Kebab24>
          </DropdownToggle>
          <DropdownMenu>
            <DropdownItem>
              <Link
                to={appendParamsToUrl(
                  "/mass-balance/outgoing-view",
                  [
                    selectedCountry,
                    divisionCode,
                    row.original?.certificateOutboundEuId,
                  ],
                  documentManager1_4FeatureFlag
                    ? {
                        redirectTo: appendParamsToUrl(
                          "/doc-manager",
                          [selectedCountry],
                          {
                            divisionId,
                            page: "outgoing",
                          },
                        ),
                        pageNo: table.getState()?.pagination?.pageIndex,
                        page: "outgoing",
                      }
                    : {
                        redirectTo: appendParamsToUrl("/doc-manager/outgoing", [
                          selectedCountry,
                        ]),
                        pageNo: table.getState()?.pagination?.pageIndex,
                        page: "outgoing",
                      },
                )}
                className="link-dark"
              >
                <u>View certificate</u>
              </Link>
            </DropdownItem>
            <DropdownItem
              onClick={() =>
                handleSendCertificate(
                  row.original?.certificateOutboundEuId,
                  row?.original?.mbOutboundQtyEuId,
                )
              }
              disabled={row?.original?.status === "REMOVED"}
            >
              Send certificate
            </DropdownItem>
          </DropdownMenu>
        </UncontrolledDropdown>
      );
    },
  },
];

export default getColumns;
