import {NavItemAccordion} from "@bphxd/ds-core-react";
import {useFeatureFlag} from "modules/common/FeatureFlag/FeatureFlagContext";
import FEATURE_FLAGS from "modules/common/FeatureFlag/featureFlagConstants";
import PropTypes from "prop-types";
import {useLocation} from "react-router-dom";
import {Nav, NavItem, NavLink} from "reactstrap";

const NavSection = ({items, handleSectionNavigation, hideSubItems = false}) => {
  const docManager1_4FeatureFlag = useFeatureFlag(
    FEATURE_FLAGS.DOC_MANAGER_1_4,
  );
  const location = useLocation();
  const {pathname} = location;

  return (
    <Nav vertical className={docManager1_4FeatureFlag ? "!pl-2" : ""}>
      {items?.length > 0 &&
        items?.map((item) => {
          if (!item.isVisible) {
            return null;
          }
          if (item?.subItems && !hideSubItems) {
            let level2AccordionOpen = false;
            const level1AccordionOpen = item?.subItems?.find(
              (element) => element.link === pathname,
            );
            return (
              <NavItemAccordion
                key={item.id}
                title={item.title}
                data-test={item.id}
                active={level1AccordionOpen || level2AccordionOpen}
                open={level1AccordionOpen || level2AccordionOpen}
              >
                <Nav vertical>
                  {item?.subItems?.map((child) => {
                    if (!child.isVisible) {
                      return null;
                    }
                    level2AccordionOpen = child?.subItems?.find(
                      (element) => element.link === pathname,
                    );
                    if (child?.subItems) {
                      return (
                        <NavItemAccordion
                          key={child.id}
                          title={child.title}
                          data-test={child.id}
                          active={level2AccordionOpen}
                          open={level2AccordionOpen}
                        >
                          <Nav vertical>
                            {child?.subItems?.map((subChild, index) => {
                              if (!subChild.isVisible) {
                                return null;
                              }

                              return (
                                <NavItem key={subChild.id}>
                                  <NavLink
                                    key={index}
                                    href={subChild.link}
                                    active={subChild.link === pathname}
                                    onClick={(e) => {
                                      e.preventDefault();
                                      handleSectionNavigation(
                                        subChild.link,
                                        subChild?.module,
                                        subChild?.moduleTitle,
                                      );
                                    }}
                                  >
                                    {subChild.title}
                                  </NavLink>
                                </NavItem>
                              );
                            })}
                          </Nav>
                        </NavItemAccordion>
                      );
                    }
                    return (
                      <NavItem key={child.id}>
                        <NavLink
                          href={child.link}
                          active={child.link === pathname}
                          onClick={(e) => {
                            e.preventDefault();
                            handleSectionNavigation(
                              child.link,
                              child?.module,
                              child?.moduleTitle,
                            );
                          }}
                        >
                          {child.title}
                        </NavLink>
                      </NavItem>
                    );
                  })}
                </Nav>
              </NavItemAccordion>
            );
          }

          return (
            <NavItem key={item.id}>
              <NavLink
                href={item.link}
                active={item.link === pathname}
                onClick={(e) => {
                  e.preventDefault();
                  handleSectionNavigation(
                    item.link,
                    item?.module,
                    item?.moduleTitle,
                  );
                }}
              >
                {item.title}
              </NavLink>
            </NavItem>
          );
        })}
    </Nav>
  );
};

NavSection.propTypes = {
  items: PropTypes.array,
  handleSectionNavigation: PropTypes.func,
  hideSubItems: PropTypes.bool,
};

export default NavSection;
