import PropTypes from "prop-types";
import {useUserSettings} from "providers/userSettings";
import {useEffect} from "react";
import {useFormContext} from "react-hook-form";
import {FormFeedback, FormGroup, Input, Label} from "reactstrap";
import parseAndRoundToTwoDecimal from "utils/numberUtil";

const GHGEmissionInfo = ({data}) => {
  const {
    register,
    formState: {errors},
    setValue,
  } = useFormContext();

  const {
    userSettings: {decimalFormat},
  } = useUserSettings();

  const computeProps = (name, options) => {
    const {ref, ...props} = register(name, options);
    return {innerRef: ref, ...props};
  };

  useEffect(() => {
    setValue(
      "ghgEec",
      parseAndRoundToTwoDecimal(
        data?.rowData?.original?.outboundRecords?.ghgEec,
      ),
    );
    setValue(
      "ghgEl",
      parseAndRoundToTwoDecimal(
        data?.rowData?.original?.outboundRecords?.ghgEl,
      ),
    );
    setValue(
      "ghgEp",
      parseAndRoundToTwoDecimal(
        data?.rowData?.original?.outboundRecords?.ghgEp,
      ),
    );
    setValue(
      "ghgEtd",
      data?.rowData?.original?.outboundRecords?.ghgEtd === "DDV"
        ? data?.rowData?.original?.outboundRecords?.ghgEtd
        : parseAndRoundToTwoDecimal(
            data?.rowData?.original?.outboundRecords?.ghgEtd,
          ),
    );
    setValue(
      "ghgEu",
      parseAndRoundToTwoDecimal(
        data?.rowData?.original?.outboundRecords?.ghgEu,
      ),
    );
    setValue(
      "ghgEsca",
      parseAndRoundToTwoDecimal(
        data?.rowData?.original?.outboundRecords?.ghgEsca,
      ),
    );
    setValue(
      "ghgEccs",
      parseAndRoundToTwoDecimal(
        data?.rowData?.original?.outboundRecords?.ghgEccs,
      ),
    );
    setValue(
      "ghgEccr",
      parseAndRoundToTwoDecimal(
        data?.rowData?.original?.outboundRecords?.ghgEccr,
      ),
    );

    setValue(
      "ghgTotal",
      data?.rowData?.original?.outboundRecords?.ghgTotal === "DDV" ||
        data?.rowData?.original?.outboundRecords?.ghgTotal === "TDV"
        ? data?.rowData?.original?.outboundRecords?.ghgTotal
        : parseAndRoundToTwoDecimal(
            data?.rowData?.original?.outboundRecords?.ghgTotal,
          ),
    );
  }, [setValue, data, decimalFormat]);

  return (
    <div className="flex flex-col gap-4">
      <div className="flex flex-row justify-between items-start gap-3">
        <FormGroup>
          <Label for="ghgEec" className="fw-light">
            Eec
          </Label>
          <Input
            type="text"
            id="ghgEec"
            name="ghgEec"
            data-test="ghgEec"
            {...computeProps("ghgEec")}
            invalid={!!errors.ghgEec}
            disabled
          />
          {errors.ghgEec && (
            <FormFeedback>{errors.ghgEec?.message}</FormFeedback>
          )}
        </FormGroup>
        <FormGroup>
          <Label for="ghgEl" className="fw-light">
            El
          </Label>
          <Input
            type="text"
            id="ghgEl"
            name="ghgEl"
            data-test="ghgEl"
            {...computeProps("ghgEl")}
            invalid={!!errors.ghgEl}
            disabled
          />
        </FormGroup>
        <FormGroup>
          <Label for="ghgEp" className="fw-light">
            Ep
          </Label>
          <Input
            type="text"
            id="ghgEp"
            name="ghgEp"
            data-test="ghgEp"
            {...computeProps("ghgEp")}
            invalid={!!errors.ghgEp}
            disabled
          />
        </FormGroup>
        <FormGroup>
          <Label for="ghgEtd" className="fw-light">
            Etd
          </Label>
          <Input
            type="text"
            id="ghgEtd"
            name="ghgEtd"
            data-test="ghgEtd"
            {...computeProps("ghgEtd")}
            invalid={!!errors.ghgEtd}
            disabled
          />
        </FormGroup>
        <FormGroup>
          <Label for="ghgEu" className="fw-light">
            Eu
          </Label>
          <Input
            type="text"
            id="ghgEu"
            name="ghgEu"
            data-test="ghgEu"
            {...computeProps("ghgEu")}
            invalid={!!errors.ghgEu}
            disabled
          />
        </FormGroup>
        <FormGroup>
          <Label for="ghgEsca" className="fw-light">
            Esca
          </Label>
          <Input
            type="text"
            id="ghgEsca"
            name="ghgEsca"
            data-test="ghgEsca"
            {...computeProps("ghgEsca")}
            invalid={!!errors.ghgEsca}
            disabled
          />
        </FormGroup>
        <FormGroup>
          <Label for="ghgEccs" className="fw-light">
            Eccs
          </Label>
          <Input
            type="text"
            id="ghgEccs"
            name="ghgEccs"
            data-test="ghgEccs"
            {...computeProps("ghgEccs")}
            invalid={!!errors.ghgEccs}
            disabled
          />
        </FormGroup>
        <FormGroup>
          <Label for="ghgEccr" className="fw-light">
            Eccr
          </Label>
          <Input
            type="text"
            id="ghgEccr"
            name="ghgEccr"
            data-test="ghgEccr"
            {...computeProps("ghgEccr")}
            invalid={!!errors.ghgEccr}
            disabled
          />
        </FormGroup>
      </div>
      <FormGroup>
        <Label for="ghgTotal" className="fw-light">
          Total value gCO2eq/MJ
        </Label>
        <Input
          type="text"
          id="ghgTotal"
          name="ghgTotal"
          data-test="ghgTotal"
          {...computeProps("ghgTotal")}
          invalid={!!errors.ghgTotal}
          disabled
        />
        {errors.ghgTotal && (
          <FormFeedback>{errors.ghgTotal?.message}</FormFeedback>
        )}
      </FormGroup>
    </div>
  );
};
GHGEmissionInfo.propTypes = {
  data: PropTypes.object,
};
export default GHGEmissionInfo;
