import React, {useState} from "react";
import {
  Table,
  Spinner,
  Pagination,
  PaginationItem,
  PaginationLink,
} from "reactstrap";
import {TriangleDown24, TriangleUp24} from "@bphxd/ds-core-react/lib/icons";
import PropTypes from "prop-types";
import {ITEMS_PER_PAGE} from "../constants/batches";

const StickyColumnTable = ({columns, data, loading, enableSorting}) => {
  const [currentPage, setCurrentPage] = useState(0);
  const [sortConfig, setSortConfig] = useState(null);

  const pageCount = Math.ceil(data.length / ITEMS_PER_PAGE);

  const handlePageClick = (page) => {
    setCurrentPage(page);
  };

  const sortedData = React.useMemo(() => {
    if (sortConfig) {
      const sorted = [...data].sort((a, b) => {
        const aValue = a[sortConfig.key];
        const bValue = b[sortConfig.key];
        if (aValue < bValue)
          return sortConfig.direction === "ascending" ? -1 : 1;
        if (aValue > bValue)
          return sortConfig.direction === "ascending" ? 1 : -1;
        return 0;
      });
      return sorted;
    }
    return data;
  }, [data, sortConfig]);

  const currentItems = sortedData.slice(
    currentPage * ITEMS_PER_PAGE,
    (currentPage + 1) * ITEMS_PER_PAGE,
  );

  const handleSort = (key) => {
    let direction = "ascending";
    if (
      sortConfig &&
      sortConfig.key === key &&
      sortConfig.direction === "ascending"
    ) {
      direction = "descending";
    }
    setSortConfig({key, direction});
  };

  return (
    <div className="sticky-table-container">
      {loading ? (
        <div className="flex mx-auto">
          <Spinner className="flex mx-auto" />
        </div>
      ) : (
        <Table bordered>
          <thead>
            <tr>
              {columns.map((col) => (
                <th
                  key={col.id}
                  className={col.id === "dropdown_icon" ? "sticky-column" : ""}
                  style={{width: col.width || "auto"}}
                  onClick={
                    enableSorting
                      ? () => handleSort(col.accessorKey)
                      : undefined
                  }
                >
                  <div className="header-content">
                    <span>{col.header}</span>
                    {enableSorting && col.id !== "dropdown_icon" && (
                      <span className="sort-icons">
                        <span
                          className={`sort-icon ${
                            sortConfig?.key === col.accessorKey &&
                            sortConfig?.direction === "ascending"
                              ? "active"
                              : ""
                          }`}
                        >
                          <TriangleUp24 />
                        </span>
                        <span
                          className={`sort-icon ${
                            sortConfig?.key === col.accessorKey &&
                            sortConfig?.direction === "descending"
                              ? "active"
                              : ""
                          }`}
                        >
                          <TriangleDown24 />
                        </span>
                      </span>
                    )}
                  </div>
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {currentItems.map((row, rowIndex) => (
              <tr key={rowIndex}>
                {columns.map((col) => (
                  <td
                    key={col.id}
                    className={
                      col.id === "dropdown_icon" ? "sticky-column" : ""
                    }
                    style={{width: col.width || "auto"}}
                  >
                    {col.cell
                      ? col.cell({
                          row,
                          column: col,
                          getValue: () => row[col.accessorKey],
                        })
                      : row[col.accessorKey]}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </Table>
      )}
      <Pagination aria-label="Pagination">
        {Array.from({length: pageCount}, (_, i) => (
          <PaginationItem key={i} active={i === currentPage}>
            <PaginationLink onClick={() => handlePageClick(i)}>
              {i + 1}
            </PaginationLink>
          </PaginationItem>
        ))}
      </Pagination>
    </div>
  );
};

StickyColumnTable.propTypes = {
  columns: PropTypes.array,
  data: PropTypes.array,
  loading: PropTypes.bool,
  enableSorting: PropTypes.bool,
};

export default StickyColumnTable;
