import moment from "moment";
import {displayDate} from "modules/co-processing/helpers/dateHelper";

const BooleanMap = {true: "Yes", false: "No"};
const DATE_FORMAT = "DD/MM/YYYY hh:mm A";

const handleNullOrUndefined = (value, formatterFn = (v) => v) =>
  value == null || value === undefined ? "-" : formatterFn(value);

const booleanToYesOrNo = (value) => {
  if (typeof value === "string") {
    return BooleanMap[value.toLowerCase()] || value;
  }
  return "-";
};

const formatNumber = (value) =>
  typeof value === "number"
    ? new Intl.NumberFormat("en-US").format(Math.round(value))
    : value;

const format = {
  bool: (value) => handleNullOrUndefined(value, booleanToYesOrNo),
  date: (value) => handleNullOrUndefined(value, displayDate),
  number: (value) => handleNullOrUndefined(value, formatNumber),
  volume: (value) =>
    handleNullOrUndefined(
      value,
      (v) => `(${v?.toLocaleString("en-US", {minimumFractionDigits: 2})})`,
    ),
  default: handleNullOrUndefined,
};

const dateTimeKeysList = [
  "batch_start_time",
  "batch_end_time",
  "tank_certified_datez",
  "c14_test_date",
  "shipment_date",
  "load_start_time",
  "load_end_time",
];

const changeDateFormat = (value, outputFormat) => {
  const dateTimeObj = moment(value, DATE_FORMAT, true);
  return dateTimeObj.isValid() ? dateTimeObj.format(outputFormat) : value;
};

export const RECALL_POPOVER = {
  HEADER: "Recall Shipment",
  MESSAGE:
    "Are you sure you want to recall this shipment and linked batch for editing?",
  YES: "Confirm",
  CANCEL: "Cancel",
};

export const ERRORS = {
  FAILED_RECALL:
    "Failed to move the batch and shipment link back to Ready to Commit status",
};

export {changeDateFormat, dateTimeKeysList, format, handleNullOrUndefined};
