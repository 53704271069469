import {COUNTRY_GERMANY} from "./countryDetails";
import {DIV_CODE_COPRO} from "./divisionDetails";

export const COMMON = {};

export const NUMBER_OF_DECIMALS = 5;

export const copro_urls = {
  germanyLandingPage: `/dashboard/${COUNTRY_GERMANY}/${DIV_CODE_COPRO}`,
  germanyDocumentPage: `doc-manager/${COUNTRY_GERMANY}`,
  germanyLinkToPurchase: `/${DIV_CODE_COPRO}/${COUNTRY_GERMANY}/link-to-purchase`,
  germanyMassBalance: `/mass-balance/list/${COUNTRY_GERMANY}/${DIV_CODE_COPRO}`,
  germanyMandateCertificate: `/mandate-certificate/${COUNTRY_GERMANY}/${DIV_CODE_COPRO}`,
  germanyReports: `${DIV_CODE_COPRO}/${COUNTRY_GERMANY}/reports`,
};
