import {useMutation, useQuery} from "@apollo/client";
import {useAccount, useMsal} from "@azure/msal-react";
import {Chip, Close} from "@bphxd/ds-core-react";
import {Attach16, Upload32} from "@bphxd/ds-core-react/lib/icons";
import {
  GET_USERFEEDBACK_MASTER_DETAILS,
  SAVE_USER_FEEDBACK,
  uploadFile,
} from "graphql/user-feedback/userfeedback";
import JSZip from "jszip";
import Form from "modules/common/FormNew";
import PropTypes from "prop-types";
import {useEffect, useState} from "react";
import {useDropzone} from "react-dropzone";
import {useForm} from "react-hook-form";
import {
  Button,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Offcanvas,
  OffcanvasBody,
  OffcanvasHeader,
  Spinner,
} from "reactstrap";

import {checkFileTooLarge} from "utils/helpers/checkData";
import {isNullOrEmptyOrUndefined} from "utils/numberUtil";
import "./index.css";

const UserFeedbackPopup = ({showSendFeedback, setShowSendFeedback}) => {
  const [acceptedFiles, setAcceptedFiles] = useState([]);
  const [uploadFileData, setUploadFileData] = useState(null);
  const [isProcessing, setProcessing] = useState(false);
  const [showFeedbackForm, setShowFeedbackForm] = useState(true);
  const [isSubmitted, setIsSubmitted] = useState(false);

  const {accounts} = useMsal();
  const account = useAccount(accounts[0]);

  const maxSize = 52428800;

  const methods = useForm({
    mode: "onChange",
    shouldFocusError: true,
  });

  const {
    register,
    formState: {errors},
    getValues,
    trigger,
    watch,
    setValue,
    reset,
  } = methods;

  const computeProps = (name, options) => {
    const {ref, ...props} = register(name, options);
    return {innerRef: ref, ...props};
  };

  const {data: masterDetails} = useQuery(GET_USERFEEDBACK_MASTER_DETAILS);

  const divisionData = masterDetails?.bioLcGetDivisionDetails?.divisionData;
  const countryData = masterDetails?.bioLcGlobNaviGetModuleCatalogue;
  const typeOfFeedback = ["Feature suggestion", "Bug report", "General/other"];
  // const fetaures = countryData && Object.keys(countryData[0]);
  const featureOptions = [
    "Allocation",
    "Batches",
    "Compliance monitor",
    "Configuration centre",
    "Dashboards",
    "Documents",
    "Document generation",
    "Forecasting",
    "Link to purchase",
    "Mass balance",
    "Navigation",
    "Receipts",
    "Reporting",
    "Rules engine",
    "Shipments",
    "Others",
  ];

  const watchTypeofFeedback = watch("typeOfFeedback");
  const watchFeature = watch("feature");

  useEffect(() => {
    const typeOfFeedback = !isNullOrEmptyOrUndefined(watchTypeofFeedback)
      ? watchTypeofFeedback
      : "";

    const feature = !isNullOrEmptyOrUndefined(watchFeature) ? watchFeature : "";

    const subjectLabel = `${typeOfFeedback?.trim()} ${
      !isNullOrEmptyOrUndefined(watchFeature?.trim()) &&
      !isNullOrEmptyOrUndefined(watchTypeofFeedback.trim())
        ? " - "
        : ""
    } ${feature?.trim()} `;
    setValue("subject", subjectLabel?.trim());
    if (!isNullOrEmptyOrUndefined(feature?.trim())) {
      trigger("subject");
    }
  }, [watchTypeofFeedback, watchFeature, setValue, trigger]);

  useEffect(() => {
    setValue("username", account?.name);
  }, [account?.name, setValue]);

  const onDrop = (acceptedFilesUpload) => {
    setAcceptedFiles([...acceptedFiles, ...acceptedFilesUpload]);
  };

  const {fileRejections, getRootProps, getInputProps} = useDropzone({
    accept: ".jpeg,.jpg,.png,.pdf",
    maxFiles: 5,
    maxSize,
    multiple: true,
    onDrop,
  });

  const file = acceptedFiles[0];

  const handleRemove = (id) => {
    const newFiles = [...acceptedFiles];
    newFiles.splice(id, 1);
    setAcceptedFiles(newFiles);
  };

  const files = acceptedFiles.map((item, index) => (
    <Chip
      style={{width: "100%", height: "27px"}}
      data-test="file"
      icon={Attach16}
      color="neutral"
      id={index}
      label={item.path}
      key={`${index} ${item}`}
      onRemove={handleRemove}
    />
  ));

  useEffect(() => {
    if (acceptedFiles && acceptedFiles.length > 0) {
      setUploadFileData(acceptedFiles[0]);
    }
  }, [acceptedFiles, setUploadFileData]);

  const isFileTooLarge = checkFileTooLarge(fileRejections, maxSize);

  const [saveUserFeedback, {loading: isSaving}] = useMutation(
    SAVE_USER_FEEDBACK,
    {
      onCompleted: ({userFeedBack}) => {
        setProcessing(false);
        setShowFeedbackForm(false);
        if (userFeedBack?.statusCode === 200) {
          setIsSubmitted(true);
        } else {
          setIsSubmitted(false);
        }
      },
    },
  );

  const handleSubmit = async () => {
    const valid = await trigger();
    const values = getValues();
    console.log(values, uploadFileData, valid);
    if (valid) {
      const zip = new JSZip();
      setProcessing(true);
      if (
        acceptedFiles !== null &&
        acceptedFiles !== undefined &&
        acceptedFiles.length > 0
      ) {
        // Add files to the ZIP
        acceptedFiles.forEach((file) => {
          zip.file(file.name, file);
        });
        // Generate ZIP file
        const content = await zip.generateAsync({type: "blob"});

        const zipFileName = `attachment_${Date.now()}_${Math.floor(
          Math.random() * 1000,
        )}.zip`;
        try {
          const uploadStatusCode = await uploadFile(content, zipFileName);
          if (uploadStatusCode === 200) {
            // Success

            await saveUserFeedback({
              variables: {
                event: {
                  username: account?.username,
                  division: values?.division,
                  country: values?.country,
                  feedback_type: values?.typeOfFeedback,
                  feature: values?.feature,
                  subject: values?.subject,
                  feedback: values?.feedback,
                  attached_file: zipFileName,
                },
              },
            });
          }
        } catch (err) {
          console.error(err);
          // Unexpected error
        }
      } else {
        await saveUserFeedback({
          variables: {
            event: {
              username: account?.username,
              division: values?.division,
              country: values?.country,
              feedback_type: values?.typeOfFeedback,
              feature: values?.feature,
              subject: values?.subject,
              feedback: values?.feedback,
            },
          },
        });
      }
    }
    return false;
  };

  return (
    <Offcanvas
      direction="end"
      isOpen={showSendFeedback}
      toggle={() => setShowSendFeedback(!showSendFeedback)}
      scrollable
      backdrop={false}
    >
      <OffcanvasHeader
        close={
          <Close
            onClick={() => {
              setShowSendFeedback(!showSendFeedback);
              setShowFeedbackForm(true);
            }}
          />
        }
      >
        <div className="success-message py-3"> Send feedback</div>
      </OffcanvasHeader>
      <OffcanvasBody>
        {!showFeedbackForm && (
          <>
            {isSubmitted && (
              <div className="success-message h-[650px] items-center justify-center w-full flex">
                Your feedback has been successfully submitted. We will respond
                to your feedback via email within the next 2 days. Thank you for
                your input!
              </div>
            )}
            {!isSubmitted && (
              <div className="success-message h-[650px] items-center justify-center w-full flex">
                Failed to submit the feedback. Please try again later.
              </div>
            )}
            <div className="flex flex-row items-start justify-start my-6">
              <Button
                color="tertiary"
                outline
                type="secondary"
                size="md"
                className="show link-btn rounded-0 w-[87px]"
                onClick={() => {
                  reset();
                  setAcceptedFiles([]);
                  setShowSendFeedback(!showSendFeedback);
                  setShowFeedbackForm(true);
                }}
              >
                Close
              </Button>
              <Button
                color="primary"
                type="secondary"
                className="float-right rounded-0 text-gray-400 w-[150px]"
                id="addDocumentButton"
                size="md"
                onClick={() => {
                  reset();
                  setAcceptedFiles([]);
                  setAcceptedFiles([]);
                  setShowFeedbackForm(!showFeedbackForm);
                }}
              >
                Send another
              </Button>
            </div>
          </>
        )}
        {showFeedbackForm && (
          <>
            {" "}
            <Form id="upload-form" onSubmit={() => handleSubmit()}>
              <div className="mt-3 w-full flex flex-col gap-5">
                <FormGroup>
                  <Label for="name" className="fw-normal text-sm">
                    Your name
                  </Label>
                  <Input
                    className="!text-[#111111a3] !text-[13px]"
                    type="text"
                    id="username"
                    name="username"
                    invalid={!!errors.username}
                    value={account?.name}
                    disabled
                    {...computeProps("username")}
                  ></Input>
                  {errors.username && (
                    <FormFeedback className="mt-0">
                      {errors.username.message}
                    </FormFeedback>
                  )}
                </FormGroup>
                <FormGroup>
                  <Label for="division" className="fw-normal text-sm">
                    Business area
                  </Label>
                  <Input
                    className="!text-[#111111a3] !text-[13px]"
                    type="select"
                    id="division"
                    name="division"
                    {...computeProps("division", {
                      required: "Please select division",
                    })}
                    invalid={!!errors.division}
                  >
                    <option value="">Please select a division</option>
                    {divisionData
                      ?.filter((x) => x.divisionCode !== "GLOBAL")
                      ?.map((division) => (
                        <option
                          key={division.divisionName}
                          value={division.divisionName}
                        >
                          {division.divisionName}
                        </option>
                      ))}
                    <option value="Others">Others</option>
                  </Input>
                  {errors.division && (
                    <FormFeedback className="mt-0">
                      {errors.division.message}
                    </FormFeedback>
                  )}
                </FormGroup>
                <FormGroup>
                  <Label for="country" className="fw-normal text-sm">
                    Country
                  </Label>
                  <Input
                    className="!text-[#111111a3] !text-[13px]"
                    type="select"
                    id="country"
                    name="country"
                    {...computeProps("country", {
                      required: "Please select country",
                    })}
                    invalid={!!errors.country}
                  >
                    <option value="">Please select a country</option>
                    {countryData
                      ?.filter((x) => x.countryName !== "GLOBAL")
                      ?.map((country) => (
                        <option
                          key={country.countryName}
                          value={country.countryName}
                        >
                          {country?.countryName}
                        </option>
                      ))}
                  </Input>
                  {errors.country && (
                    <FormFeedback className="mt-0">
                      {errors.country.message}
                    </FormFeedback>
                  )}
                </FormGroup>
                <FormGroup>
                  <Label for="typeOfFeedback" className="fw-normal text-sm">
                    Type of feedback
                  </Label>
                  <Input
                    className="!text-[#111111a3] !text-[13px]"
                    type="select"
                    id="typeOfFeedback"
                    name="typeOfFeedback"
                    {...computeProps("typeOfFeedback", {
                      required: "Please select type of feedback",
                    })}
                    invalid={!!errors.typeOfFeedback}
                  >
                    <option value="">Please select a type of feedback</option>
                    {typeOfFeedback?.map((feedbackItem) => (
                      <option key={feedbackItem} value={feedbackItem}>
                        {feedbackItem}
                      </option>
                    ))}
                  </Input>
                  {errors.typeOfFeedback && (
                    <FormFeedback className="mt-0">
                      {errors.typeOfFeedback.message}
                    </FormFeedback>
                  )}
                </FormGroup>
                <FormGroup>
                  <Label for="feature" className="fw-normal text-sm">
                    Feature
                  </Label>
                  <Input
                    className="!text-[#111111a3] !text-[13px]"
                    type="select"
                    id="feature"
                    name="feature"
                    {...computeProps("feature", {
                      required: "Please select feature",
                    })}
                    invalid={!!errors.feature}
                  >
                    <option value="">Please select a feature</option>
                    {featureOptions?.map((featureItem) => (
                      <option key={featureItem} value={featureItem}>
                        {featureItem}
                      </option>
                    ))}
                  </Input>
                  {errors.feature && (
                    <FormFeedback className="mt-0">
                      {errors.feature.message}
                    </FormFeedback>
                  )}
                </FormGroup>
                <FormGroup>
                  <Label for="subject" className="fw-normal text-sm">
                    Subject
                  </Label>
                  <Input
                    className="!text-[#111111a3] !text-[13px]"
                    type="text"
                    id="subject"
                    name="subject"
                    placeholder="Enter subject"
                    {...computeProps("subject", {
                      required: "Please enter subject",
                    })}
                    invalid={!!errors.subject}
                    maxLength={100}
                  ></Input>
                  {errors.subject && (
                    <FormFeedback className="mt-0">
                      {errors.subject.message}
                    </FormFeedback>
                  )}
                </FormGroup>
                <FormGroup>
                  <Label for="feedback" className="fw-normal text-sm">
                    Feedback
                  </Label>
                  <Input
                    type="textarea"
                    rows="5"
                    id="feedback"
                    data-test="feedback"
                    {...computeProps("feedback", {
                      required: "Please enter feedback",
                    })}
                    invalid={!!errors.feedback}
                    maxLength={500}
                    placeholder="Please describe the issue, how to reproduce it, what you expected to happen vs. what actually happened, the environment (device, OS, browser), and include any relevant files like screenshots."
                  />
                  {errors.feedback && (
                    <FormFeedback className="mt-0">
                      {errors.feedback.message}
                    </FormFeedback>
                  )}
                </FormGroup>
              </div>
              <Label for="uploadItems" className="fw-normal text-sm mt-4">
                Screenshots or any supporting files (optional)
              </Label>

              <div className="mt-3">
                <div {...getRootProps({className: "dropzone w-full mt-1"})}>
                  <input
                    data-test="document-uploader"
                    id="documents"
                    {...getInputProps()}
                  />
                  <div className="flex flex-col items-center justify-center px-2 py-1 h-[125px] mb-[12px] dashed-border">
                    <div className="flex flex-row w-full items-center justify-center my-[12px]">
                      <Upload32 color="#111111a3" />
                    </div>
                    <div className="flex flex-row w-full items-center justify-center">
                      <p className="text-[13px] text-[#111111a3] mb-[12px]">
                        Drag and drop or <u>choose file to upload</u>
                      </p>
                    </div>
                    <div className="flex flex-row w-full items-center justify-center mb-[12px]">
                      <label
                        data-test="file-type-label"
                        className="text-[12px] text-[#111111a3] font-light"
                        htmlFor="documents"
                      >
                        File supported: PNG, JPEG, PDF
                      </label>
                    </div>
                  </div>
                </div>
                {isFileTooLarge && (
                  <p className="block text-red-500">File is larger than 50MB</p>
                )}
              </div>
            </Form>
            {files.length > 0 && (
              <div className="flex flex-col flex-start mt-[30px] mb-[10px]">
                {files}
              </div>
            )}
            <div className="flex flex-row items-start justify-start my-6">
              <Button
                color="tertiary"
                outline
                type="secondary"
                size="md"
                className="show link-btn rounded-0 w-[87px]"
                onClick={() => {
                  reset();
                  setAcceptedFiles([]);
                  setShowFeedbackForm(true);
                  setShowSendFeedback(!showSendFeedback);
                }}
                disabled={isProcessing}
              >
                Cancel
              </Button>
              <Button
                color="primary"
                type="secondary"
                className="float-right rounded-0 text-gray-400 w-[150px]"
                id="addDocumentButton"
                size="md"
                onClick={() => {
                  handleSubmit();
                }}
                disabled={isProcessing}
              >
                {isProcessing && (
                  <Spinner size="sm" className="btn-icon-prefix" />
                )}
                Send feedback
              </Button>
            </div>
          </>
        )}
      </OffcanvasBody>
    </Offcanvas>
  );
};

UserFeedbackPopup.propTypes = {
  setShowSendFeedback: PropTypes.func,
  showSendFeedback: PropTypes.bool,
};

export default UserFeedbackPopup;
