import React from "react";
import PropTypes from "prop-types";
import {Controller} from "react-hook-form";
import {FormFeedback, FormGroup, Input, Label} from "reactstrap";

const CheckboxYesOnly = ({
  control,
  name,
  error,
  rules,
  type = "checkbox",
  disabled = false,
  label = "Yes",
  handleChange,
  flex = false,
}) => {
  return (
    <Controller
      rules={rules}
      render={({field: {onChange, value, ref}}) => {
        const yes = {checked: value === true};

        return (
          <>
            <div className={flex ? "flex" : ""}>
              <FormGroup
                check
                inline
                className="form-check-alt form-check-lg !mb-0"
              >
                <Input
                  innerRef={ref}
                  id={`${name}Yes`}
                  name={`${name}Yes`}
                  type={type}
                  data-test={`${name}Yes`}
                  invalid={!!error}
                  disabled={disabled}
                  {...yes}
                  onChange={() => {
                    if (handleChange) {
                      handleChange(!value);
                    }
                    onChange(value ? null : true);
                  }}
                />
                <Label check for={`${name}Yes`}>
                  {label}
                </Label>
              </FormGroup>
            </div>
            {error && (
              <FormFeedback style={{display: "block"}}>
                {error.message}
              </FormFeedback>
            )}
          </>
        );
      }}
      control={control}
      name={name}
      defaultValue={null}
    />
  );
};

CheckboxYesOnly.propTypes = {
  type: PropTypes.string,
  control: PropTypes.object,
  name: PropTypes.string.isRequired,
  error: PropTypes.object,
  rules: PropTypes.object,
  disabled: PropTypes.bool,
  label: PropTypes.string,
  handleChange: PropTypes.func,
  flex: PropTypes.bool,
};

export default CheckboxYesOnly;
