import {useMutation, useQuery} from "@apollo/client";
import {useAccount, useMsal} from "@azure/msal-react";
import {Remove48} from "@bphxd/ds-core-react/lib/icons";
import {DndContext} from "@dnd-kit/core";
import {restrictToHorizontalAxis} from "@dnd-kit/modifiers";
import {GET_RULE_BLOCKS} from "graphql/rules-engine/rule-editor/getBlocks";
import GET_COMPLIANCE_SCHEMES, {
  GET_POS_STATUS,
} from "graphql/rules-engine/rule-editor/getComplianceSchemes";
import GET_RULE_BLOCK_CONVERSION_LIST from "graphql/rules-engine/rule-editor/getConvertUnits";
import {GET_VERSION_DETAILS} from "graphql/rules-engine/rule-editor/getRuleVersionDetails";
import GET_RULE_BLOCK_SELECT_DATASETS from "graphql/rules-engine/rule-editor/getSelectDatasets";
import GET_RULE_BLOCK_WRITE_DATASETS from "graphql/rules-engine/rule-editor/getWriteDatasets";
import {PUBLISH_RULE} from "graphql/rules-engine/rule-editor/publishRuleBlocks";
import {SAVE_RULE} from "graphql/rules-engine/rule-editor/saveRuleBlocks";
import {isEqual, sortBy} from "lodash";
import {useCallback, useEffect, useMemo, useState} from "react";
import {FormProvider, useForm} from "react-hook-form";
import {useNavigate, useParams} from "react-router-dom";
import {toast} from "react-toastify";
import {Form} from "reactstrap";
import ExecutionResultsPage from "../components/ExcecutionResults/ExecutionResultsPage";
import ExecutionResultsPopup from "../components/ExcecutionResults/ExecutionResultsPopup";
import ButtonInDroppable from "../components/rules-engine-editor/ButtonInDroppable";
import DraggableButton from "../components/rules-engine-editor/DND/DraggableButton";
import DroppableSection from "../components/rules-engine-editor/DND/DroppableSection";
import HeaderSection from "../components/rules-engine-editor/HeaderSection";
import PublishOverrideModal from "../components/rules-engine-editor/PublishOverrideModal";
import Calculate from "../components/rules-engine-editor/rules-block-forms/Calculate";
import CarbonIntensity from "../components/rules-engine-editor/rules-block-forms/CarbonIntensity";
import ConvertUnits from "../components/rules-engine-editor/rules-block-forms/ConvertUnits";
import EnergyDensity from "../components/rules-engine-editor/rules-block-forms/EnergyDensity";
import SelectData from "../components/rules-engine-editor/rules-block-forms/SelectData";
import WriteData from "../components/rules-engine-editor/rules-block-forms/WriteData";
import blockList, {blockIconList, blockTypeList} from "../content/Blocks";
import "./index.css";

const RulesEditorLandingpage = () => {
  const {accounts} = useMsal();
  const account = useAccount(accounts[0]);

  const navigate = useNavigate();

  const methods = useForm({
    mode: "onChange",
    shouldFocusError: true,
  });

  const {
    register,
    formState: {errors},
    getValues,
    setError,
    trigger,
    setFocus,
  } = methods;

  // get ruleVersionId and country from the URL
  const {ruleVersionId, country} = useParams();

  // set select data and write data as initial state
  const initialStateBlocks = [
    {
      blockType: "data",
      id: "select_data",
      blockName: "Select data",
      ruleBlockMasterId: "selectDataBlockRuleId",
      templateCount: 1,
      disabled: true,
      configuration: {
        table_name: undefined,
        predicate: undefined,
        description: undefined,
      },
    },
    {
      blockType: "data",
      id: "write_data",
      ruleBlockMasterId: "writeDataBlockRuleId",
      blockName: "Write data",
      templateCount: 2,
      disabled: true,
      configuration: {
        table_name: undefined,
        result_column: undefined,
        description: undefined,
      },
    },
  ];

  // set states here
  const [selectedTemplateItems, setSelectedTemplateItems] =
    useState(initialStateBlocks);
  const [initialTemplateItems, setInitialTemplateItems] =
    useState(initialStateBlocks);
  const [addDetailsVisible, setAddDetailsVisible] = useState(false);
  const [selectedBlock, setSelectedBlock] = useState(null);
  const [saveErrorData, setSaveErrorData] = useState(null);
  const [selectedDataset, setSelectDataSet] = useState(null);
  const [isTesting, setIsTesting] = useState(false);
  const [publishOverrideModal, setPublishOverrideModal] = useState(false);
  const [publishOverrideDetails, setPublishOverrideDetails] = useState([]);
  const [ExecutionResultsPopupVisible, setExecutionResultsPopupVisible] =
    useState(false);

  const [indexForExecute, setIndexForExecute] = useState(0);

  // get blocks for left side
  const {data: blocksList, loading} = useQuery(GET_RULE_BLOCKS);

  // get select_data datasets
  const {data: selectDatasetListFromQuery, loading: selectDatasetLoading} =
    useQuery(GET_RULE_BLOCK_SELECT_DATASETS);

  // get write_data datasets
  const {data: writeDatasetListFromQuery, loading: datasetLoading} = useQuery(
    GET_RULE_BLOCK_WRITE_DATASETS,
  );

  // get conversion factor list
  const {data: conversionFactorList, loading: conversionFactorListLoading} =
    useQuery(GET_RULE_BLOCK_CONVERSION_LIST);
  const blockListsFromQuery =
    blocksList?.bioLcComplianceRuleEngineGetRuleBlocks?.blocks;

  // get version details
  const {
    data: versionData,
    loading: versionDetailsLoading,
    error,
    refetch,
  } = useQuery(GET_VERSION_DETAILS, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "network-only",
    variables: {
      ruleVersionId,
    },
  });

  const {data: compliaceSchemeDetails} = useQuery(GET_COMPLIANCE_SCHEMES, {
    variables: {
      ruleVersionId,
    },
  });

  const {data: posDetails} = useQuery(GET_POS_STATUS);

  const versionDetailsData =
    versionData?.bioLcComplianceRuleEngineGetRuleVersionBlocks;

  const compliaceSchemeDetailsData =
    compliaceSchemeDetails &&
    compliaceSchemeDetails.bioLcComplianceRuleEngineGetComplianceSchemes
      ?.complianceSchemes;

  const posDetailsData =
    posDetails && posDetails.bioLcComplianceRuleEngineGetPosStatus?.posStatus;

  const blockCodesFromQuery = blockListsFromQuery?.map((col) => col.blockCode);

  const selectDatasetList =
    selectDatasetListFromQuery?.bioLcComplianceRuleEngineGetSelectDataSets
      ?.selectDataSets;

  const writeDatasetList =
    writeDatasetListFromQuery?.bioLcComplianceRuleEngineGetWriteDataSets
      ?.writeDataSets;

  const conversionFactorListfromQuery =
    conversionFactorList?.bioLcComplianceRuleEngineGetConvertUnitsBlock
      ?.convertUnitsBlock;

  const reOrderItems = (items) => {
    // Sort items by templateCount in ascending order and adjust templateCount based on new position
    const orderedArray = items?.map((item, index) => ({
      ...item,
      templateCount: index + 1,
    }));

    return orderedArray;
  };

  useEffect(() => {
    const versionBlocks = sortBy(
      versionDetailsData?.versionBlocks,
      "index",
      "asc",
    );
    const blockDetails = versionBlocks?.map((block) => {
      const blockItem = blockList?.find(
        (item) => item.ruleBlockMasterId === block?.ruleBlockMasterId,
      );

      return {
        id: blockItem?.id,
        ruleBlockMasterId: block?.ruleBlockMasterId,
        blockName: blockItem?.blockName,
        blockType: blockItem?.blockType,
        disabled: blockItem?.disabled,
        templateCount: block?.index,
        configuration: JSON.parse(block?.configuration),
      };
    });
    if (versionBlocks?.length > 0) {
      setSelectedTemplateItems(blockDetails);
      setInitialTemplateItems(blockDetails);
      const selectedblockDetails = blockDetails.find(
        (item) => item?.id === "select_data",
      );
      setSelectDataSet(selectedblockDetails?.configuration?.table_name);
    }
  }, [versionDetailsData?.versionBlocks]);

  const handleDragEnd = (event) => {
    const {active, over} = event;
    const draggedItem = active?.data?.current;

    setSelectedTemplateItems((currentItems) => {
      // If there are no items or only one item, just add the new item normally.
      if (!currentItems || currentItems.length <= 1) {
        return [
          ...currentItems,
          {
            ...draggedItem,
            templateCount: currentItems.length + 1,
          },
        ];
      }
      // For more than one item, add the new item at the second position.
      const newItem = {
        ...draggedItem,
        templateCount: currentItems.length + 1,
      };
      const lastItem = currentItems[currentItems.length - 1];
      // Create a new array with the new item inserted at index 1.
      let orderedArray = [
        currentItems[0],
        newItem,
        ...currentItems.slice(1, currentItems.length - 1), // Exclude the last item from this slice
        {...lastItem, templateCount: currentItems.length + 2}, // Add the last item at the end
      ];

      // Adjust templateCount for each item based on its new position
      orderedArray = reOrderItems(sortBy(orderedArray, "templateCount", "asc"));

      return orderedArray;
    });
  };

  const handleTemplate = useCallback(
    (visible, item) => {
      setAddDetailsVisible(visible);
      if (visible) {
        setSelectedBlock(item);
      }
    },
    [setAddDetailsVisible, setSelectedBlock],
  );

  //  Group by blockType
  const groupedByBlockType = blockList.reduce((acc, block) => {
    if (!acc[block.blockType]) {
      acc[block.blockType] = [];
    }
    acc[block.blockType].push(block);
    return acc;
  }, {});

  const deleteItem = (itemIndex) => {
    // Create a copy of the selectedTemplateItems array
    const newItems = [...selectedTemplateItems];
    // Remove the item at the specified index
    newItems.splice(itemIndex, 1);
    // Update the state with the new array
    const reOrderedItems = reOrderItems(newItems);
    setSelectedTemplateItems(reOrderedItems); // Update the items array with the new order
    setAddDetailsVisible(false);
  };

  const moveItemDown = (itemIndex) => {
    if (itemIndex < selectedTemplateItems.length - 1) {
      // Use itemIndex to determine if the item can move down
      const newItems = [...selectedTemplateItems];
      // Swap the item with the next item
      [newItems[itemIndex], newItems[itemIndex + 1]] = [
        newItems[itemIndex + 1],
        newItems[itemIndex],
      ];
      const reOrderedItems = reOrderItems(newItems);
      setAddDetailsVisible(false);
      setSelectedTemplateItems(reOrderedItems); // Update the items array with the new order
    }
  };

  const moveItemUp = (itemIndex) => {
    if (itemIndex > 0) {
      // Check if the item is not the first item
      const newItems = [...selectedTemplateItems];
      // Swap the item with the previous item
      [newItems[itemIndex], newItems[itemIndex - 1]] = [
        newItems[itemIndex - 1],
        newItems[itemIndex],
      ];
      const reOrderedItems = reOrderItems(newItems);
      setAddDetailsVisible(false);
      setSelectedTemplateItems(reOrderedItems); // Update the items array with the new order
    }
  };

  const updateConfigurationInBlocks = useCallback(
    (selectedItem, updatedConfiguration) => {
      if (updatedConfiguration) {
        const index = selectedTemplateItems?.findIndex(
          (item) =>
            item?.id === selectedItem?.id &&
            item?.templateCount === selectedItem?.templateCount,
        );

        // Create the updated item by merging existing configuration with updated configuration
        const updatedSelectedItem = {
          ...selectedItem,
          configuration: {
            ...selectedItem.configuration, // Existing configuration
            ...updatedConfiguration, // Updated configuration (including description)
          },
        };

        // Update the array with the new item
        if (index !== -1) {
          const updatedItems = [
            ...selectedTemplateItems.slice(0, index),
            updatedSelectedItem,
            ...selectedTemplateItems.slice(index + 1),
          ];
          if (!isEqual(selectedTemplateItems, updatedItems)) {
            setSelectedTemplateItems(updatedItems);
          }
        }
      }
    },
    [selectedTemplateItems],
  );

  const versionDetails =
    versionDetailsData &&
    versionDetailsData.versionDetails?.length > 0 &&
    versionDetailsData.versionDetails[0];

  const isDisabled =
    versionDetails?.status === "PUBLISHED" ||
    versionDetails?.status === "DELETED";

  const renderSelectedBlockWithData = useMemo(() => {
    switch (selectedBlock?.id) {
      case "select_data":
        return (
          <SelectData
            selectedItem={selectedBlock}
            selectDatasetList={selectDatasetList}
            setSelectDataSet={setSelectDataSet}
            selectedTemplateItems={selectedTemplateItems}
            setSelectedTemplateItems={setSelectedTemplateItems}
            updateConfigurationInBlocks={updateConfigurationInBlocks}
            saveErrorData={saveErrorData}
            isDisabled={isDisabled}
          />
        );
      case "write_data":
        return (
          <WriteData
            selectedItem={selectedBlock}
            selectDatasetList={selectDatasetList}
            writeDatasetList={writeDatasetList}
            selectedDataset={selectedDataset}
            selectedTemplateItems={selectedTemplateItems}
            setSelectedTemplateItems={setSelectedTemplateItems}
            updateConfigurationInBlocks={updateConfigurationInBlocks}
            saveErrorData={saveErrorData}
            isDisabled={isDisabled}
          />
        );
      case "convert_units":
        return (
          <ConvertUnits
            selectedItem={selectedBlock}
            selectDatasetList={selectDatasetList}
            selectedDataset={selectedDataset}
            selectedTemplateItems={selectedTemplateItems}
            setSelectedTemplateItems={setSelectedTemplateItems}
            conversionFactorList={conversionFactorListfromQuery}
            updateConfigurationInBlocks={updateConfigurationInBlocks}
            saveErrorData={saveErrorData}
            isDisabled={isDisabled}
          />
        );
      case "energy_density":
        return (
          <EnergyDensity
            selectedItem={selectedBlock}
            selectDatasetList={selectDatasetList}
            selectedDataset={selectedDataset}
            selectedTemplateItems={selectedTemplateItems}
            setSelectedTemplateItems={setSelectedTemplateItems}
            updateConfigurationInBlocks={updateConfigurationInBlocks}
            saveErrorData={saveErrorData}
            isDisabled={isDisabled}
            ruleVersionId={ruleVersionId}
            compliaceSchemeDetailsData={compliaceSchemeDetailsData}
          />
        );

      case "carbon_intensity":
        return (
          <CarbonIntensity
            selectedItem={selectedBlock}
            selectDatasetList={selectDatasetList}
            selectedDataset={selectedDataset}
            selectedTemplateItems={selectedTemplateItems}
            setSelectedTemplateItems={setSelectedTemplateItems}
            updateConfigurationInBlocks={updateConfigurationInBlocks}
            saveErrorData={saveErrorData}
            isDisabled={isDisabled}
            ruleVersionId={ruleVersionId}
            compliaceSchemeDetailsData={compliaceSchemeDetailsData}
            posDetailsData={posDetailsData}
          />
        );

      case "calculate":
        return (
          <Calculate
            selectedItem={selectedBlock}
            selectDatasetList={selectDatasetList}
            selectedDataset={selectedDataset}
            selectedTemplateItems={selectedTemplateItems}
            setSelectedTemplateItems={setSelectedTemplateItems}
            updateConfigurationInBlocks={updateConfigurationInBlocks}
            saveErrorData={saveErrorData}
            isDisabled={isDisabled}
          />
        );
      default:
        return null;
    }
  }, [
    selectedDataset,
    selectedTemplateItems,
    selectDatasetList,
    conversionFactorListfromQuery,
    writeDatasetList,
    selectedBlock,
    updateConfigurationInBlocks,
    saveErrorData,
    isDisabled,
    ruleVersionId,
    compliaceSchemeDetailsData,
    posDetailsData,
  ]);

  const handleErrorMessage = useCallback(
    (errorData) => {
      if (errorData?.block) {
        const errorDataItem = selectedTemplateItems.find(
          (item) =>
            item?.blockName.toLowerCase() === errorData?.block?.toLowerCase() &&
            item?.templateCount === errorData?.index,
        );
        if (errorDataItem) {
          handleTemplate(true, {...errorDataItem});

          setError(errorData?.field, {
            type: "manual",
            message: errorData?.error,
          });
          setFocus(errorData?.field);
        }
      }
    },

    [selectedTemplateItems, setError, setFocus, handleTemplate],
  );

  const [saveRule, {loading: isSaving}] = useMutation(SAVE_RULE, {
    onCompleted: ({bioLcComplianceRulesEngineSaveBlockLinks}) => {
      if (bioLcComplianceRulesEngineSaveBlockLinks?.statusCode === 200) {
        toast.success(
          bioLcComplianceRulesEngineSaveBlockLinks?.error ??
            "Rule blocks saved successfully",
          {
            autoClose: 750,
          },
        );
        setInitialTemplateItems(selectedTemplateItems);
        refetch({
          ruleVersionId,
        });
        setAddDetailsVisible(false);
      } else {
        setSaveErrorData(bioLcComplianceRulesEngineSaveBlockLinks);
        handleErrorMessage(bioLcComplianceRulesEngineSaveBlockLinks);
        toast.error(
          bioLcComplianceRulesEngineSaveBlockLinks?.error ??
            "Failed to save rule blocks",
          {
            autoClose: 750,
          },
        );
      }
    },
  });

  const [publishRule, {loading: isPublishing}] = useMutation(PUBLISH_RULE, {
    onCompleted: ({bioLcComplianceRulesEngineCreateUpdateVersion}) => {
      if (bioLcComplianceRulesEngineCreateUpdateVersion?.statusCode === 204) {
        toast.success(
          bioLcComplianceRulesEngineCreateUpdateVersion?.error ??
            "Rule version has been successfully published",
          {
            autoClose: 750,
          },
        );

        navigate(
          `/compliance-rules-engine/versions/${
            versionDetailsData?.ruleDetails?.length > 0 &&
            versionDetailsData?.ruleDetails[0]?.ruleMasterId
          }`,
        );
      } else if (
        bioLcComplianceRulesEngineCreateUpdateVersion?.statusCode === 409 &&
        bioLcComplianceRulesEngineCreateUpdateVersion?.ruleVersions?.length > 0
      ) {
        setPublishOverrideModal(true);
        setPublishOverrideDetails(
          bioLcComplianceRulesEngineCreateUpdateVersion?.ruleVersions,
        );
      } else {
        toast.error(
          bioLcComplianceRulesEngineCreateUpdateVersion?.error ??
            "Failed to publish rule blocks",
          {
            autoClose: 750,
          },
        );
      }
    },
  });

  const onSave = async () => {
    const valid = await trigger();

    const blockConfigs = selectedTemplateItems?.map((item) => {
      const configuration = item?.configuration;
      return {
        ruleBlockMasterId: item?.ruleBlockMasterId,
        index: item?.templateCount,
        configuration: configuration ? JSON.stringify(configuration) : {},
      };
    });
    if (valid) {
      await saveRule({
        variables: {
          event: {
            userId: account.username,
            ruleVersionId,
            blockConfigs,
          },
        },
      });
    }
  };

  const onPublish = async (isPublishActionConfirmed) => {
    await publishRule({
      variables: {
        event: {
          userId: account.username,
          isPublishActionConfirmed,
          operation: "publish",
          ruleMasterId:
            versionDetailsData?.ruleDetails?.length > 0 &&
            versionDetailsData?.ruleDetails[0]?.ruleMasterId,
          ruleVersionId,
        },
      },
    });
  };

  return (
    <div className="flex flex-col justify-stretch w-full items-start rule-engine-editor">
      {isTesting && (
        <>
          <HeaderSection
            onSave={onSave}
            onPublish={onPublish}
            isSaving={isSaving}
            isPublishing={isPublishing}
            versionDetailsData={versionDetailsData}
            selectedCountry={country}
            selectedTemplateItems={selectedTemplateItems}
            initialTemplateItems={initialTemplateItems}
            isTesting={isTesting}
            setIsTesting={setIsTesting}
            isDisabled={isDisabled}
            setIndexForExecute={setIndexForExecute}
          />
          <ExecutionResultsPage
            ruleName={versionDetailsData?.ruleDetails[0]?.ruleName}
            ruleVersionId={ruleVersionId}
          />
        </>
      )}
      {!isTesting && (
        <FormProvider {...methods}>
          <Form
            id="add-rule-form"
            className={`w-full ${!versionDetails ? "hidden" : ""}`}
          >
            <HeaderSection
              onSave={onSave}
              onPublish={onPublish}
              isSaving={isSaving}
              isPublishing={isPublishing}
              versionDetailsData={versionDetailsData}
              selectedCountry={country}
              selectedTemplateItems={selectedTemplateItems}
              initialTemplateItems={initialTemplateItems}
              isTesting={isTesting}
              setIsTesting={setIsTesting}
              setIndexForExecute={setIndexForExecute}
            />
            <div className="w-full flex">
              <DndContext
                onDragEnd={handleDragEnd}
                modifiers={[restrictToHorizontalAxis]}
                disabled={isDisabled}
              >
                {/* Conditionally render the left panel */}
                {versionDetails?.status !== "DELETED" && (
                  <div className="w-1/4 bg-[#fff] min-h-screen items-start px-4 py-6">
                    <h5 className="w-full m-0"> Blocks</h5>
                    <div
                      className="flex w-full flex-col gap-4"
                      key="draggable-item"
                    >
                      {Object.keys(groupedByBlockType).map((blockType) => (
                        <>
                          <span className="block-header mt-4">
                            {blockTypeList[blockType]}
                          </span>
                          {groupedByBlockType[blockType].map((block) => {
                            if (blockCodesFromQuery?.includes(block.id)) {
                              if (block?.disabled) {
                                return (
                                  <button
                                    className="draggable-button-disabled"
                                    type="button"
                                    disabled={block?.disabled || isDisabled}
                                    key={block.id}
                                  >
                                    {blockIconList[block.id]}
                                    {block?.blockName}
                                  </button>
                                );
                              }
                              return (
                                <DraggableButton
                                  id={`draggable-item-${blockType}-${block.id}`}
                                  key={`draggable-item-${blockType}-${block.id}`}
                                  className="draggable-button"
                                  data={block}
                                  disabled={block.disabled || isDisabled}
                                >
                                  {blockIconList[block.id]}
                                  {block.blockName}
                                </DraggableButton>
                              );
                            }
                            return null;
                          })}
                        </>
                      ))}
                    </div>
                  </div>
                )}

                {/* Center part expanding into left panel's space when deleted */}
                <div
                  className={`${
                    versionDetails?.status === "DELETED"
                      ? "w-3/4 flex-grow flex items-center justify-center"
                      : addDetailsVisible
                      ? "w-2/4"
                      : "w-3/4"
                  } bg-[#f2f2f2] min-h-screen overflow-y-auto`}
                >
                  <DroppableSection
                    key="droppable"
                    id="droppable"
                    className="flex flex-col items-center justify-center w-full"
                    versionDetails={versionDetails}
                  >
                    {versionDetails?.status === "DELETED" && (
                      <div className="text-left text-black-500 pb-5 w-full">
                        This rule version cannot be edited as it’s been deleted.
                      </div>
                    )}
                    {selectedTemplateItems &&
                      selectedTemplateItems?.length > 0 &&
                      selectedTemplateItems?.map((item, index) => (
                        <ButtonInDroppable
                          index={index}
                          item={item}
                          key={`${item.templateCount}-button`}
                          handleTemplate={handleTemplate}
                          selectedTemplateItems={selectedTemplateItems}
                          moveItemDown={moveItemDown}
                          moveItemUp={moveItemUp}
                          deleteItem={deleteItem}
                          selectedBlock={selectedBlock}
                          isDisabled={isDisabled}
                          setExecutionResultsPopupVisible={
                            setExecutionResultsPopupVisible
                          }
                          ExecutionResultsPopupVisible={
                            ExecutionResultsPopupVisible
                          }
                          setIndexForExecute={setIndexForExecute}
                        />
                      ))}
                  </DroppableSection>
                </div>
              </DndContext>

              {/* Right panel logic remains unchanged, based on addDetailsVisible */}
              {addDetailsVisible && (
                <div
                  className=" flex flex-col w-1/4 bg-[#fff] min-h-screen fadeInAnimation "
                  hidden={!addDetailsVisible}
                >
                  <div className="flex flex-row justify-between items-center p-4">
                    <h5 className="m-0">{selectedBlock?.blockName}</h5>
                    <Remove48
                      className="cursor-pointer"
                      onClick={() => handleTemplate(false, selectedBlock)}
                    />
                  </div>
                  <hr className="border-1 my-3" />
                  <div className="p-4">{renderSelectedBlockWithData}</div>
                </div>
              )}
            </div>
          </Form>
        </FormProvider>
      )}
      <PublishOverrideModal
        publishOverrideModal={publishOverrideModal}
        setPublishOverrideModal={setPublishOverrideModal}
        publishOverrideDetails={publishOverrideDetails}
        onPublish={onPublish}
      />
      <ExecutionResultsPopup
        ruleName={versionDetailsData?.ruleDetails[0]?.ruleName}
        ruleVersionId={ruleVersionId}
        ExecutionResultsPopupVisible={ExecutionResultsPopupVisible}
        setExecutionResultsPopupVisible={setExecutionResultsPopupVisible}
        indexForExecute={indexForExecute}
        selectedTemplateItems={selectedTemplateItems}
      />
    </div>
  );
};

export default RulesEditorLandingpage;
