import {useQuery} from "@apollo/client";
import {Download24} from "@bphxd/ds-core-react/lib/icons";
import {COPRO_US_API_DATA} from "graphql/coprocessing/receipts";
import {useDateRange} from "modules/co-processing/context/DateRangeContext";
import moment from "moment";
import {useMemo, useState} from "react";
import {Button, Input} from "reactstrap";
import Table from "../../common/Table";
import {BUTTONS} from "../../constants/coProcessing";
import {LOCATION_FILTER, feedstockHeaders} from "../../constants/receipts";
import buildColumns from "../../helpers/buildColumns";
import buildExportFn from "../../helpers/exportDataExcel";
import FilterBtn from "../Historical/FilterBtn";
import TableSearch from "../TableSearch";

const FeedstockTracking = () => {
  const {dateRange} = useDateRange();

  const initialVariables = {
    tracking_type: "feedstock_table",
    date_range: {
      start_date: dateRange.from,
      end_date: dateRange.to,
    },
    search_text: "",
  };

  const {loading, data, refetch} = useQuery(COPRO_US_API_DATA, {
    variables: initialVariables,
    fetchPolicy: "cache-and-network",
    notifyOnNetworkStatusChange: true,
  });

  const dataColumns = buildColumns(feedstockHeaders);

  // For filtering headers
  const [filteredColumnData, setFilteredColumnData] = useState(dataColumns);
  const filteredColumns = useMemo(
    () => filteredColumnData.filter(({visible}) => !visible),
    [filteredColumnData],
  );

  let feedstockData = null;
  let exportDataExcel = null;
  let exportSheets = null;

  const today = moment().format("YYYY-MM-DD");

  if (!loading) {
    feedstockData = data?.bioLcCoproUsApi?.data ?? [];
    exportSheets = data?.bioLcCoproUsApi?.sheets_data ?? [];
    exportDataExcel = buildExportFn(
      feedstockData,
      exportSheets,
      `FeedstockTracking-${today}.xlsx`,
    );
  }

  // For location filtering
  const allLocationsData = feedstockData?.map((data) => {
    const location = data.delivered_from;
    return location;
  });

  const filteredLocationsData = [
    "All Suppliers",
    ...new Set(allLocationsData),
  ].sort();

  const [selectedLocation, setSelectedLocation] = useState("All Suppliers");
  const handleLocationChange = (x) => {
    setSelectedLocation(x);
  };

  // For fetching data based on location selected
  const selectedLocationData = feedstockData?.filter((location) => {
    if (selectedLocation === LOCATION_FILTER.ALL_SUPPLIERS)
      return feedstockData;
    return location?.delivered_from === selectedLocation;
  });

  return (
    <div data-test="copro-feedstock-tracking" className="bp-core">
      <div className="copro-table">
        <div className="copro-table-header flex justify-between">
          <div className="pl-2 flex justify-start">
            <Input
              type="select"
              id="suppliersLocation"
              data-test="copro-supplies-btn"
              onChange={(e) => handleLocationChange(e.target.value)}
              disabled={feedstockData?.length === 0}
            >
              {filteredLocationsData?.map((location) => (
                <option key={location} value={location}>
                  {location}
                </option>
              ))}
            </Input>
          </div>

          <div className="flex justify-end pr-2">
            <div className="mr-2">
              <FilterBtn
                data-test="copro-filter-btn"
                columns={filteredColumnData}
                onColumnChange={(cols) => setFilteredColumnData(cols)}
              />
            </div>
            <div className="mr-2">
              <Button
                data-test="copro-export-btn"
                color="standard-tertiary rounded-0"
                size="sm"
                onClick={exportDataExcel}
                disabled={feedstockData?.length === 0}
              >
                {BUTTONS.EXPORT}
                <Download24 className="btn-icon-suffix" />
              </Button>
            </div>
            <TableSearch refetch={refetch} variables={initialVariables} />
          </div>
        </div>

        <Table
          columns={filteredColumns}
          data={selectedLocationData}
          loading={loading}
        />
      </div>
    </div>
  );
};

export default FeedstockTracking;
