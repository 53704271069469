import PropTypes from "prop-types";
import React from "react";
import {Button, Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";

// modal displaying the delete message on UI based on version status. If the status is published then no deletion operation is possible.
// If delete is feasible then the former block hits.
// If delete is not possible then later block hits.

const DeleteRuleModal = ({
  showDeleteModal,
  setDeleteModal,
  onDelete,
  errorMessage,
}) => {
  return (
    <Modal
      size="sm"
      isOpen={showDeleteModal}
      toggle={() => setDeleteModal(false)}
      className="modal-dialog-centered"
    >
      <ModalHeader className="flex !justify-center !items-center mt-4">
        {errorMessage ? (
          " "
        ) : (
          <span className="text-lg text-black opacity-100 bg-transparent font-[400]">
            Delete rule
          </span>
        )}
      </ModalHeader>
      <ModalBody className="text-center pt-0">
        {errorMessage ? (
          <>
            <div className="text-lg text-black opacity-100 bg-transparent pb-2">
              Rule cannot be deleted
            </div>
            <p className="small opacity-100 lh-1-375 mx-4 mb-0 flex flex-wrap">
              The rule has a &apos;published&apos; rule version and cannot be
              deleted.
            </p>
          </>
        ) : (
          <p className="mb-0">Are you sure you want to delete this rule?</p>
        )}
      </ModalBody>
      <ModalFooter className="p-0">
        <div className="w-100">
          <div className="flex justify-evenly">
            <Button
              color="darker-tertiary"
              size="lg"
              className="border-0 rounded-0 py-3 opacity-60 opacity-100-hover bg-transparent"
              onClick={() => setDeleteModal(false)}
            >
              {errorMessage ? "Return to Rules" : "Cancel"}
            </Button>

            {!errorMessage && (
              <Button
                color="darker-tertiary"
                size="lg"
                className="border-0 rounded-0 py-3 opacity-80 opacity-100-hover bg-transparent"
                onClick={onDelete}
              >
                Delete
              </Button>
            )}
          </div>
        </div>
      </ModalFooter>
    </Modal>
  );
};

DeleteRuleModal.propTypes = {
  showDeleteModal: PropTypes.bool.isRequired,
  setDeleteModal: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  errorMessage: PropTypes.string,
};

export default DeleteRuleModal;
