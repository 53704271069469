import {FilePdf24} from "@bphxd/ds-core-react/lib/icons";
import {NUMBER_OF_DECIMALS} from "constants/common";
import ColumnHighlight from "modules/GlobalMassBalance/components/ColumnHighlight";
import {getFilterValue} from "modules/GlobalMassBalance/utils";
import moment from "moment";
import {Link} from "react-router-dom";
import appendParamsToUrl from "utils/helpers/appendParamsToUrl";
import {getFormattedNumberWithCheck} from "utils/numberUtil";

export const INCOMING_COLUMNS_SAF = (
  dateFormat,
  decimalFormat,
  country,
  siteReferenceId,
  divisionId,
  backToAllocationPage,
) => [
  {
    header: "Incoming",
    columns: [
      {
        header: "Physical receipt date",
        accessorKey: "physicalReceiptDate",
        key: "physicalReceiptDate",
        visible: true,
        meta: {
          filterVariant: "daterange",
        },
        filterFn: "dateRangeFilterFn",
        cell: ({cell}) => {
          return (
            cell.getValue() &&
            moment(cell.getValue(), "DD/MM/YYYY").format(dateFormat)
          );
        },
      },
      {
        header: "Document",
        accessorKey: "documentName",
        key: "documentName",
        visible: true,
        cell: ({cell, row}) => {
          const {certificateOutboundEuId} = row.original;
          const hideSendBtn = true;
          const pathName = certificateOutboundEuId
            ? appendParamsToUrl(
                "/mass-balance/outgoing-view",
                [country, divisionId, certificateOutboundEuId],
                {
                  page: "mandate-allocation",
                  redirectTo: backToAllocationPage,
                  hideSendBtn: "true",
                },
              )
            : appendParamsToUrl(
                "/document-manager/incoming",
                [
                  country,
                  "detailedView",
                  row?.original?.certificateInboundEuId,
                ],
                {siteReferenceId, divisionId},
              );
          return (
            <Link to={pathName} className="link-dark">
              <u className="flex">
                {cell.getValue() && <FilePdf24 />}
                <ColumnHighlight
                  value={cell.getValue()}
                  columnFilter={getFilterValue(cell, "documentName")}
                  hasEllipsis
                  isCertificate
                />
              </u>
            </Link>
          );
        },
      },
      {
        header: "Raw material",
        accessorKey: "rawMaterial",
        key: "rawMaterial",
        visible: true,
        meta: {
          filterVariant: "select",
        },
      },
      {
        header: "Country of origin",
        accessorKey: "originCountryName",
        key: "originCountryName",
        visible: false,
        meta: {
          filterVariant: "select",
        },
      },
      {
        header: "GHG total gCO2eq/MJ",
        accessorKey: "ghgTotal",
        key: "ghgTotal",
        visible: true,
        meta: {
          filterVariant: "range",
        },
        dataType: "number",
        accessorFn: (row) => {
          return row?.ghgTotal;
        },
        cell: ({cell}) => {
          return getFormattedNumberWithCheck(
            cell.getValue(),
            NUMBER_OF_DECIMALS,
          );
        },
      },
      {
        header: "Retired quantity (in) m³",
        accessorKey: "retiredQty",
        key: "retiredQty",
        visible: true,
        disabled: true,
        dataType: "number",
        cell: ({cell}) => {
          return getFormattedNumberWithCheck(
            cell.getValue(),
            NUMBER_OF_DECIMALS,
          );
        },
        filterFn: "numberFilterFn",
      },
    ],
  },
];

export const OUTGOING_COLUMNS_SAF = (dateFormat, decimalFormat, country) => [
  {
    header: "Outgoing",
    columns: [
      {
        header: "Up to date",
        accessorKey: "uptoDate",
        key: "uptoDate",
        visible: true,
        meta: {
          filterVariant: "daterange",
        },
        filterFn: "dateRangeFilterFn",
        cell: ({cell}) => {
          return (
            cell.getValue() &&
            moment(cell.getValue(), "DD/MM/YYYY").format(dateFormat)
          );
        },
      },
      {
        header: "Outbound type",
        accessorKey: "outMovementType",
        key: "outMovementType",
        visible: true,
        meta: {
          filterVariant: "select",
        },
      },
      {
        header: "Recipient",
        accessorKey: "recipientName",
        key: "recipientName",
        visible: true,
        meta: {
          filterVariant: "select",
        },
      },
      {
        header: "Accrued quantity m³",
        accessorKey: "accruedQuantity",
        key: "accruedQuantity",
        visible: true,
        disabled: true,
        filterFn: "numberFilterFn",
        dataType: "number",
        cell: ({cell}) => {
          return getFormattedNumberWithCheck(
            cell.getValue(),
            NUMBER_OF_DECIMALS,
          );
        },
      },
      {
        header: "Total quantity m³",
        accessorKey: "totalFuelQuantity",
        key: "totalFuelQuantity",
        visible: true,
        disabled: true,
        filterFn: "numberFilterFn",
        dataType: "number",
        cell: ({cell}) => {
          return getFormattedNumberWithCheck(
            cell.getValue(),
            NUMBER_OF_DECIMALS,
          );
        },
      },
    ],
  },
];

export const getColumnsSAF = (
  dateFormat,
  decimalFormat,
  country,
  siteReferenceId,
  divisionId,
  backToAllocationPage,
) => {
  return {
    INCOMING_COLUMNS: INCOMING_COLUMNS_SAF(
      dateFormat,
      decimalFormat,
      country,
      siteReferenceId,
      divisionId,
      backToAllocationPage,
    ),
    OUTGOING_COLUMNS: OUTGOING_COLUMNS_SAF(
      dateFormat,
      decimalFormat,
      country,
      siteReferenceId,
      divisionId,
    ),
  };
};
