import {useLazyQuery, useQuery} from "@apollo/client";
import {GET_BATCH_BY_ID, GET_TANKS} from "graphql/coprocessing/batches";
import {
  GENERATE_SHIPMENT_ID,
  GET_BATCH_ID_BY_TANK,
} from "graphql/coprocessing/shipments";
import {orderBy} from "lodash";
import {
  BTU_ID_PREFIX,
  dtnShipmentKeys,
} from "modules/co-processing/constants/shipments";
import PropTypes from "prop-types";
import React, {useEffect, useMemo, useState} from "react";
import {
  Controller,
  FormProvider,
  useForm,
  useFormContext,
} from "react-hook-form";
import {
  FormFeedback,
  FormGroup,
  FormText,
  Input,
  Label,
  UncontrolledTooltip,
} from "reactstrap";
import {
  BackToUnitFields,
  InventoryTransferFields,
  TruckRackFields,
} from "./formConfigs";
import {calculateMaxVolume, renderInputs} from "./formUtils";

const Field = ({fieldName}) => {
  const {
    control,
    formState: {errors},
    watch,
  } = useFormContext();
  const fieldConfig = TruckRackFields?.[fieldName] ?? {};
  const {key} = fieldConfig;

  if (!fieldConfig || !key) return null;

  const error = errors[key];
  const startTime = watch(dtnShipmentKeys.SHIPMENT_START_DATE);

  const renderInput = renderInputs[fieldConfig?.type] || renderInputs.input;

  return (
    <FormGroup
      className={`createShipment__field ${fieldConfig?.width || "col-md-6"}`}
    >
      <Label
        className={`text-nowrap ${
          fieldConfig?.isOptional ? "label-optional" : ""
        }`}
        htmlFor={key}
      >
        {fieldConfig.label}
      </Label>
      <Controller
        name={key}
        control={control}
        rules={{
          ...(fieldConfig.type !== "switch" && {
            required: !fieldConfig.isOptional,
          }),
        }}
        render={({field}) =>
          renderInput({field, error, startTime, ...fieldConfig})
        }
      />

      {error && (
        <FormFeedback style={{display: "block"}} invalid="true">
          {error.message || "Expected information missing"}
        </FormFeedback>
      )}

      {fieldConfig?.info && <FormText>{fieldConfig?.info}</FormText>}
    </FormGroup>
  );
};

const ShipmentIdField = ({validate, fieldError, prevId, isBtu = false}) => {
  const {control, watch} = useFormContext();
  const shipmentId = watch(dtnShipmentKeys.SHIPMENT_ID);
  const fieldConfig = TruckRackFields?.SHIPMENT_ID ?? {};
  const {key} = fieldConfig;

  if (!fieldConfig || !key) return null;

  const renderInput = renderInputs[fieldConfig?.type] || renderInputs.input;

  return (
    <FormGroup
      className={`createShipment__field ${fieldConfig?.width || "col-md-6"}`}
      onBlur={() => {
        if (validate) {
          const idToCheck = isBtu
            ? `${BTU_ID_PREFIX}${shipmentId}`
            : shipmentId;
          validate({variables: {shipmentId: idToCheck}});
        }
      }}
    >
      <Label className="text-nowrap" htmlFor={key}>
        {fieldConfig.label}
      </Label>
      <Controller
        name={key}
        control={control}
        rules={{
          required: "Shipment ID is required",
          validate: () => {
            return !fieldError.message;
          },
        }}
        render={({field}) =>
          renderInput({
            field,
            error: fieldError.message,
            prevId,
            isBtu,
            ...fieldConfig,
          })
        }
      />

      {fieldError && (
        <FormFeedback style={{display: "block"}} invalid="true">
          {fieldError.type === "custom"
            ? fieldError.message
            : fieldError.type === "required"
            ? "Expected information missing"
            : ""}
        </FormFeedback>
      )}

      {fieldConfig?.info && <FormText>{fieldConfig?.info}</FormText>}
    </FormGroup>
  );
};

const BackUnitField = ({fieldName}) => {
  const {
    control,
    formState: {errors},
  } = useFormContext();
  const fieldConfig = BackToUnitFields?.[fieldName] ?? {};

  if (!fieldConfig?.key) return null;

  const {key, label, width, type, isOptional} = fieldConfig;
  const error = errors?.[key]?.[type] || errors?.[key];

  const renderInput = renderInputs[fieldConfig?.type] || renderInputs.input;

  return (
    <FormGroup className={`createShipment__field ${width || "col-md-6"}`}>
      <Label
        className={`text-nowrap ${isOptional ? "label-optional" : ""}`}
        htmlFor={key}
      >
        {label}
      </Label>
      <Controller
        name={key}
        control={control}
        rules={fieldConfig?.validation}
        render={({field}) => renderInput({field, error, ...fieldConfig})}
      />

      {error && (
        <FormFeedback style={{display: "block"}} invalid="true">
          {error.message || `${label} is missing`}
        </FormFeedback>
      )}
    </FormGroup>
  );
};

const SourceBatchIdField = () => {
  const {
    control,
    formState: {errors},
    setValue,
    watch,
  } = useFormContext();
  const sourceTank = watch(dtnShipmentKeys.SOURCE_TANK);

  const [sourceBatchCodes, setSourceBatchCodes] = useState([
    {value: "", label: "Select source batch id"},
  ]);
  const [batchCodeResourceMap, setBatchCodeResourceMap] = useState({});
  const [selectedBatchStatus, setSelectedBatchStatus] = useState(null);

  const [fetchSourceBatchIds] = useLazyQuery(GET_BATCH_ID_BY_TANK, {
    fetchPolicy: "no-cache",
    onCompleted: (data) => {
      const codes = data?.bioLcCoproUsShipmentsApi?.body?.tank_batch_info?.map(
        (batch) => ({
          value: batch.batch_code,
          label: batch.batch_code,
          resourceId: batch.batch_resource_id,
          batchStatus: batch.batch_status,
        }),
      );
      if (codes?.length) {
        setSourceBatchCodes([
          {value: "", label: "Select source batch id"},
          ...codes,
        ]);
        setBatchCodeResourceMap(
          Object.fromEntries(
            codes.map(({value, resourceId, batchStatus}) => [
              value,
              {resourceId, batchStatus},
            ]),
          ),
        );
      } else {
        setSourceBatchCodes([{value: null, label: "No options available"}]);
      }
    },
  });

  useEffect(() => {
    if (sourceTank != null) {
      fetchSourceBatchIds({variables: {tankNumber: sourceTank}});
    }
  }, [sourceTank, fetchSourceBatchIds]);

  const handleBatchSelection = (selectedBatchCode) => {
    const selectedBatch = batchCodeResourceMap[selectedBatchCode] || {};
    const resourceId = selectedBatch.resourceId || null;
    const batchStatus = selectedBatch.batchStatus || "";

    setSelectedBatchStatus(batchStatus);
    setValue(dtnShipmentKeys.SOURCE_RESOURCE_BATCH_ID, resourceId);
  };

  return (
    <FormGroup className="createShipment__field col-md-6">
      <Label htmlFor={dtnShipmentKeys.SOURCE_BATCH_ID}>Source Batch ID</Label>
      <Controller
        name={dtnShipmentKeys.SOURCE_BATCH_ID}
        control={control}
        rules={{
          required: "Source batch ID is required.",
          validate: (value) => {
            if (!value) return "Source batch ID is required.";
            if (selectedBatchStatus?.toLowerCase() === "committed") {
              return "Batch already Committed. Recall to make changes.";
            }
            return true;
          },
        }}
        render={({field}) => (
          <Input
            type="select"
            {...field}
            onChange={(e) => {
              field.onChange(e);
              handleBatchSelection(e.target.value);
            }}
            invalid={!!errors[dtnShipmentKeys.SOURCE_BATCH_ID]}
          >
            {sourceBatchCodes.map((option) => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </Input>
        )}
      />
      {errors[dtnShipmentKeys.SOURCE_BATCH_ID] && (
        <FormFeedback>
          {errors[dtnShipmentKeys.SOURCE_BATCH_ID].message || "Required"}
        </FormFeedback>
      )}
    </FormGroup>
  );
};

const DestinationBatchIdField = () => {
  const {
    control,
    formState: {errors},
    setValue,
    watch,
  } = useFormContext();
  const destinationTank = watch(dtnShipmentKeys.DESTINATION_TANK);

  const [destinationBatchCodes, setDestinationBatchCodes] = useState([
    {value: "", label: "Select destination batch id"},
  ]);
  const [batchCodeResourceMap, setBatchCodeResourceMap] = useState({});
  const [selectedBatchStatus, setSelectedBatchStatus] = useState(null);

  const [fetchDestinationBatchIds] = useLazyQuery(GET_BATCH_ID_BY_TANK, {
    fetchPolicy: "no-cache",
    onCompleted: (data) => {
      const codes = data?.bioLcCoproUsShipmentsApi?.body?.tank_batch_info?.map(
        (batch) => ({
          value: batch.batch_code,
          label: batch.batch_code,
          resourceId: batch.batch_resource_id,
          batchStatus: batch.batch_status,
        }),
      );
      if (codes?.length) {
        setDestinationBatchCodes([
          {value: "", label: "Select destination batch id"},
          ...codes,
        ]);
        setBatchCodeResourceMap(
          Object.fromEntries(
            codes.map(({value, resourceId, batchStatus}) => [
              value,
              {resourceId, batchStatus},
            ]),
          ),
        );
      } else {
        setDestinationBatchCodes([
          {value: null, label: "No options available"},
        ]);
      }
    },
  });

  useEffect(() => {
    if (destinationTank != null) {
      fetchDestinationBatchIds({variables: {tankNumber: destinationTank}});
    }
  }, [destinationTank, fetchDestinationBatchIds]);

  const handleBatchSelection = (selectedBatchCode) => {
    const selectedBatch = batchCodeResourceMap[selectedBatchCode] || {};
    const resourceId = selectedBatch.resourceId || null;
    const batchStatus = selectedBatch.batchStatus || "";

    setSelectedBatchStatus(batchStatus);
    setValue(dtnShipmentKeys.DESTINATION_RESOURCE_BATCH_ID, resourceId);
  };

  return (
    <FormGroup className="createShipment__field col-md-6">
      <Label htmlFor={dtnShipmentKeys.DESTINATION_BATCH_ID}>
        Destination Batch ID
      </Label>
      <Controller
        name={dtnShipmentKeys.DESTINATION_BATCH_ID}
        control={control}
        rules={{
          required: "Destination batch ID is required.",
          validate: (value) => {
            if (!value) return "Destination batch ID is required.";
            if (selectedBatchStatus?.toLowerCase() === "committed") {
              return "Batch already Committed. Recall to make changes.";
            }
            return true;
          },
        }}
        render={({field}) => (
          <Input
            type="select"
            {...field}
            onChange={(e) => {
              field.onChange(e);
              handleBatchSelection(e.target.value);
            }}
            invalid={!!errors[dtnShipmentKeys.DESTINATION_BATCH_ID]}
          >
            <option key="none" disabled hidden value="">
              Select destination batch id
            </option>
            {destinationBatchCodes.map((option) => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </Input>
        )}
      />
      {errors[dtnShipmentKeys.DESTINATION_BATCH_ID] && (
        <FormFeedback>
          {errors[dtnShipmentKeys.DESTINATION_BATCH_ID].message || "Required"}
        </FormFeedback>
      )}
    </FormGroup>
  );
};

const TankNumberField = ({label, rules, dtnKey}) => {
  const {
    control,
    formState: {errors},
    setValue,
  } = useFormContext();

  const [tankNumbers, setTankNumbers] = useState([
    {value: "", label: `Select ${label}`},
  ]);

  const {data} = useQuery(GET_TANKS, {
    fetchPolicy: "network-only",
    onCompleted: (data) => {
      const numbers = orderBy(
        data?.bioLcCoproUsBatchesApi?.body?.tanks ?? [],
        [(tank) => parseInt(tank?.tank_number?.replace("TK", ""), 10)],
        ["asc"], // Sort in ascending
      ).map((tank) => ({
        value: tank?.tank_number,
        label: tank?.tank_number,
      }));
      if (numbers?.length) {
        setTankNumbers([{value: "", label: `Select ${label}`}, ...numbers]);
      } else {
        setTankNumbers([{value: null, label: "No options available"}]);
      }
    },
  });

  const handleTankSelection = (selectedTank) => {
    setValue(dtnKey, selectedTank);
  };

  return (
    <FormGroup className="createShipment__field col-md-6">
      <Label htmlFor={dtnKey}>{label}</Label>
      <Controller
        name={dtnKey}
        control={control}
        rules={rules}
        render={({field}) => (
          <Input
            type="select"
            {...field}
            onChange={(e) => {
              field.onChange(e);
              handleTankSelection(e.target.value);
            }}
            invalid={!!errors[dtnKey]}
          >
            <option key="none" disabled hidden value="">
              Select {label}
            </option>
            {tankNumbers.map((option) => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </Input>
        )}
      />
      {errors[dtnKey] && (
        <FormFeedback>{errors[dtnKey].message || "Required"}</FormFeedback>
      )}
    </FormGroup>
  );
};

const InventoryTransferField = ({fieldName}) => {
  const {
    control,
    formState: {errors},
    setValue,
    watch,
  } = useFormContext();

  const sourceBatchId = watch(dtnShipmentKeys.SOURCE_BATCH_ID);
  const sourceResourceBatchId = watch(dtnShipmentKeys.SOURCE_RESOURCE_BATCH_ID);
  const destinationResourceBatchId = watch(
    dtnShipmentKeys.DESTINATION_RESOURCE_BATCH_ID,
  );
  const remainingVolume = watch(dtnShipmentKeys.REMAINING_VOLUME);

  const [generateShipmentId] = useLazyQuery(GENERATE_SHIPMENT_ID, {
    fetchPolicy: "no-cache",
    onCompleted: (data) => {
      const shipmentId =
        data?.bioLcCoproUsShipmentsApi?.body?.transfer_shipment_name || "";
      setValue(dtnShipmentKeys.SHIPMENT_ID, shipmentId);
    },
  });

  const [fetchBatchDetails] = useLazyQuery(GET_BATCH_BY_ID, {
    fetchPolicy: "no-cache",
    onCompleted: (data) => {
      const remainingTankVolume =
        data?.bioLcCoproUsBatchesApi?.body?.batch_details?.tank_volume || 0;
      setValue(dtnShipmentKeys.REMAINING_VOLUME, remainingTankVolume);
    },
  });

  useEffect(() => {
    if (sourceResourceBatchId && destinationResourceBatchId) {
      generateShipmentId({
        variables: {
          sourceResourceBatchId,
          destinationResourceBatchId,
        },
      });
    }
    if (sourceBatchId) {
      fetchBatchDetails({variables: {batch_id: sourceBatchId}});
    }
  }, [
    sourceBatchId,
    sourceResourceBatchId,
    destinationResourceBatchId,
    generateShipmentId,
    fetchBatchDetails,
  ]);

  const maxValueMessage = useMemo(() => {
    if (remainingVolume == null) {
      return "Please set remaining volume";
    }
    return `Volume cannot exceed remaining volume of ${remainingVolume} bbl`;
  }, [remainingVolume]);

  const volumeMaxValue = useMemo(() => {
    if (fieldName === "ADJUSTED_VOLUME" && remainingVolume) {
      return remainingVolume;
    }
    return 0;
  }, [fieldName, remainingVolume]);

  const fieldConfig = InventoryTransferFields?.[fieldName] ?? {};
  const {key} = fieldConfig;

  if (!fieldConfig || !key) return null;

  const error = errors[key];
  const renderInput = renderInputs[fieldConfig?.type] || renderInputs.input;

  return (
    <FormGroup
      className={`createShipment__field ${fieldConfig?.width || "col-md-6"}`}
    >
      <Label
        className={`text-nowrap ${
          fieldConfig?.isOptional ? "label-optional" : ""
        }`}
        htmlFor={key}
      >
        {fieldConfig.label}
      </Label>
      <Controller
        name={key}
        control={control}
        rules={{
          required: !fieldConfig.isOptional,
          ...(fieldName === "ADJUSTED_VOLUME"
            ? {
                max: {
                  value: volumeMaxValue,
                  message: maxValueMessage,
                },
              }
            : {}),
        }}
        render={({field}) =>
          renderInput({
            field,
            error,
            placeholder: fieldConfig.placeholder,
            addOn: fieldConfig.addOn,
          })
        }
      />
      {error && (
        <FormFeedback style={{display: "block"}} invalid="true">
          {error.message || "Expected information missing"}
        </FormFeedback>
      )}
    </FormGroup>
  );
};

const InventoryTransferTextDisplay = ({fieldName}) => {
  const {register, watch} = useFormContext();

  const fieldKey =
    fieldName === "REMAINING_VOLUME"
      ? dtnShipmentKeys.REMAINING_VOLUME
      : dtnShipmentKeys.SHIPMENT_ID;
  const label =
    fieldName === "REMAINING_VOLUME"
      ? "Remaining volume source batch"
      : "Shipment ID";

  const value = watch(fieldKey) || (fieldName === "REMAINING_VOLUME" ? 0 : "");

  const displayValue =
    fieldName === "REMAINING_VOLUME" ? `${value?.toLocaleString()} bbl` : value;

  return (
    <FormGroup className="createShipment__field col-md-6">
      <Label htmlFor={fieldKey}>{label}</Label>
      <div className="input-group bg-transparent">
        <Input {...register(fieldKey)} type="hidden" />
        <span className="display-5 fs-5 form-text">{displayValue}</span>
      </div>
    </FormGroup>
  );
};

const EstVolumeDisplay = ({startTime, endTime}) => {
  const {register, setValue} = useFormContext();
  const estVolumeKey = dtnShipmentKeys.ESTIMATED_VOLUME;
  const volume = calculateMaxVolume(startTime, endTime) ?? 0;

  setValue(estVolumeKey, volume, {shouldDirty: true});

  return (
    <FormGroup className="createShipment__field col-md-6">
      <Label htmlFor={estVolumeKey}>Estimated shipment max volume</Label>
      <span
        className="label-info pl-1 text-[#002bbc]"
        href="#"
        id={estVolumeKey}
      >
        What is this?
      </span>
      <UncontrolledTooltip
        popperClassName="tooltip-light"
        placement="top"
        target={estVolumeKey}
      >
        This number is calculated by the amount of time X 1,500 bbl/hour
      </UncontrolledTooltip>

      <div className="input-group bg-transparent">
        <Input {...register(estVolumeKey)} type="hidden" />
        <span className="display-5 fs-5 form-text">{`${volume?.toLocaleString()} bbl`}</span>
      </div>
    </FormGroup>
  );
};

const ShipmentForm = ({onSubmit, children, defaultValues}) => {
  const {handleSubmit, ...methods} = useForm({
    defaultValues,
    mode: "onSubmit",
  });

  return (
    <FormProvider {...methods}>
      <form
        className="createShipment__form"
        id="create_shipment_form"
        onSubmit={handleSubmit(onSubmit)}
        noValidate
      >
        {children}
      </form>
    </FormProvider>
  );
};

const fieldPropTypes = {fieldName: PropTypes.string};

Field.propTypes = fieldPropTypes;
BackUnitField.propTypes = fieldPropTypes.fieldName;
InventoryTransferField.propTypes = fieldPropTypes.fieldName;
InventoryTransferTextDisplay.propTypes = fieldPropTypes.fieldName;
ShipmentIdField.propTypes = {
  validate: PropTypes.func,
  fieldError: PropTypes.object,
  prevId: PropTypes.string,
  isBtu: PropTypes.bool,
};
ShipmentForm.propTypes = {
  children: PropTypes.node.isRequired,
  defaultValues: PropTypes.object,
  onSubmit: PropTypes.func.isRequired,
};
EstVolumeDisplay.propTypes = {
  startTime: PropTypes.string,
  endTime: PropTypes.string,
};
TankNumberField.propTypes = {
  identifier: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  rules: PropTypes.object.isRequired,
  dtnKey: PropTypes.string.isRequired,
};

ShipmentForm.Field = Field;
ShipmentForm.BackUnitField = BackUnitField;
ShipmentForm.ShipmentIdField = ShipmentIdField;
ShipmentForm.InventoryTransferField = InventoryTransferField;
ShipmentForm.SourceBatchIdField = SourceBatchIdField;
ShipmentForm.DestinationBatchIdField = DestinationBatchIdField;
ShipmentForm.TankNumberField = TankNumberField;
ShipmentForm.InventoryTransferTextDisplay = InventoryTransferTextDisplay;
ShipmentForm.EstVolumeDisplayField = EstVolumeDisplay;

export default ShipmentForm;
