import PropTypes from "prop-types";
import {useEffect, useState} from "react";
import {Input} from "reactstrap";

// Helper function to get the contract number or deal number
const getItem = (type, element) => {
  return type === "Incoming"
    ? element?.original?.contract_number
    : element?.original?.deal_number;
};

// Helper function to get the balance group
const getBalanceGroup = (type, element) => {
  return type === "Incoming"
    ? element?.original?.incoming_balance_group_name
    : element?.original?.purchase_balance_group_name;
};

// Helper function to get the Location group
const getLocationGroup = (type, element) => {
  return type === "Incoming"
    ? element?.original?.incoming_location_group_name
    : element?.original?.purchase_location_group_name;
};

// Helper function to get the Purchased Quantity
const getPurchasedQuantity = (type, element) => {
  return type === "Incoming" ? 10 : element?.original?.purchase_qty_mt;
};

// Helper function to get the Received Quantity
const getReceivedQuantity = (type, element) => {
  return type === "Incoming" ? 10 : element?.original?.received_qty_mt;
};

// Helper function to get the MB Date
const getMbDate = (type, element) => {
  return type === "Incoming" ? "inc" : element?.original?.mb_date;
};

// Helper function to get the UOM
const getUOM = (type, element) => {
  return type === "Incoming" ? element?.original?.product_qty_uom : "";
};

// Utility function to check if a value is available (not undefined, null, or empty)
const isValueAvailable = (value) => {
  return value !== undefined && value !== null && value !== "";
};

const compareAttributes = (currentItemAttribute, selectedItemAttribute) => {
  if (
    !isValueAvailable(selectedItemAttribute) ||
    !isValueAvailable(currentItemAttribute)
  )
    return true;
  return currentItemAttribute?.trim() === selectedItemAttribute?.trim();
};

const compareUOM = (type, checkedUOM, selectedUOM) => {
  if (type !== "Incoming") return true;
  return checkedUOM === selectedUOM;
};

const checkAlert = (
  balanceGroup,
  locationGroup,
  purchasedQuantity,
  receivedQuantity,
  mbDate,
) => {
  const val =
    isValueAvailable(balanceGroup) &&
    isValueAvailable(locationGroup) &&
    isValueAvailable(purchasedQuantity) &&
    purchasedQuantity > 0 &&
    isValueAvailable(receivedQuantity) &&
    receivedQuantity > 0 &&
    isValueAvailable(mbDate);
  return val;
};

// Helper function to get selected row data
const getSelectedRowData = (table, type) => {
  return table
    .getSelectedRowModel()
    .rows?.filter((item) => getItem(type, item) !== undefined);
};

const TableCheckbox = ({table, type, otherRows, otherData}) => {
  const rowList = table.getRowModel().rows;

  // State to manage whether the main checkbox is enabled or disabled
  const [isEnabled, setIsEnabled] = useState(false);
  const [isChecked, setIsChecked] = useState(false);

  // Helper to get selected row data
  const selectedRowData = getSelectedRowData(table, type);

  // Get values for selected row to check against
  const selectedValue = getItem(type, selectedRowData?.[0]);
  const selectedBalanceGroup = getBalanceGroup(type, selectedRowData?.[0]);
  const selectedLocationGroup = getLocationGroup(type, selectedRowData?.[0]);
  const selectedUOM = getUOM(type, selectedRowData?.[0]);

  // Effect to update checkbox enabled/disabled and checked status based on row selection
  useEffect(() => {
    // Check if any eligible rows are selected
    const isAnySelected = rowList.some((element) => element.getIsSelected());

    // Check if all eligible rows are selected
    const allEligibleSelected = rowList.every((element) => {
      const checkElement = getItem(type, element);
      const checkBalanceGroup = getBalanceGroup(type, element);
      const checkLocationGroup = getLocationGroup(type, element);
      const checkPurchaseData = getPurchasedQuantity(type, element);
      const checkReceivedData = getReceivedQuantity(type, element);
      const checkMbDate = getMbDate(type, element);
      const checkUOM = getUOM(type, element);

      return (
        compareAttributes(checkElement, selectedValue) &&
        compareAttributes(checkBalanceGroup, selectedBalanceGroup) &&
        compareAttributes(checkLocationGroup, selectedLocationGroup) &&
        compareUOM(type, checkUOM, selectedUOM) &&
        checkAlert(
          checkLocationGroup,
          checkBalanceGroup,
          checkPurchaseData,
          checkReceivedData,
          checkMbDate,
        ) &&
        element.getIsSelected()
      );
    });

    // Enable checkbox if any rows are selected
    setIsEnabled(isAnySelected);

    // Check the main checkbox only if all eligible rows are selected
    setIsChecked(allEligibleSelected);
  }, [
    rowList,
    selectedValue,
    selectedBalanceGroup,
    selectedLocationGroup,
    selectedUOM,
    type,
  ]);

  const handleChange = () => {
    if (isChecked) {
      // Uncheck all eligible rows
      rowList.forEach((element) => {
        element.toggleSelected(false);
      });
      setIsChecked(false);
    } else {
      // Check all eligible rows
      rowList.forEach((element) => {
        const checkElement = getItem(type, element);
        const checkBalanceGroup = getBalanceGroup(type, element);
        const checkLocationGroup = getLocationGroup(type, element);
        const checkPurchaseData = getPurchasedQuantity(type, element);
        const checkReceivedData = getReceivedQuantity(type, element);
        const checkMbDate = getMbDate(type, element);
        const checkUOM = getUOM(type, element);

        if (
          compareAttributes(checkElement, selectedValue) &&
          compareAttributes(checkBalanceGroup, selectedBalanceGroup) &&
          compareAttributes(checkLocationGroup, selectedLocationGroup) &&
          compareUOM(type, checkUOM, selectedUOM) &&
          checkAlert(
            checkLocationGroup,
            checkBalanceGroup,
            checkPurchaseData,
            checkReceivedData,
            checkMbDate,
          )
        ) {
          element.toggleSelected(true);
        }
      });
      setIsChecked(true);
    }
  };

  return (
    <div className="d-flex align-items-center">
      <Input
        type="checkbox"
        checked={isChecked}
        onChange={handleChange}
        disabled={!isEnabled}
      />
    </div>
  );
};

TableCheckbox.propTypes = {
  table: PropTypes.object,
  type: PropTypes.string,
  otherRows: PropTypes.object,
  otherData: PropTypes.array,
};

export default TableCheckbox;
