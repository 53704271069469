import {useAccount, useMsal} from "@azure/msal-react";
import {sortBy, uniq} from "lodash";
import PropTypes from "prop-types";
import {useUserSettings} from "providers/userSettings";
import {useEffect} from "react";
import {useFormContext} from "react-hook-form";
import {FormFeedback, FormGroup, Input, Label} from "reactstrap";
import useRuleFormValidationEffects, {
  useRuleValidateOutputColumns,
} from "./validationError";

const EnergyDensity = ({
  selectedItem,
  selectDatasetList,
  selectedDataset,
  selectedTemplateItems,
  conversionFactorList,
  updateConfigurationInBlocks,
  saveErrorData,
  isDisabled,
  ruleVersionId,
  compliaceSchemeDetailsData,
}) => {
  const {
    userSettings: {decimalFormat},
  } = useUserSettings();

  const {accounts} = useMsal();
  const account = useAccount(accounts[0]);

  const {
    register,
    formState: {errors},
    control,
    setError,
    setFocus,
    setValue,
    clearErrors,
    watch,
  } = useFormContext();
  const computeProps = (name, options) => {
    const {ref, ...props} = register(name, options);
    return {innerRef: ref, ...props};
  };

  const watchInput_column = watch("compliance_scheme_id");
  const watchOutput_column = watch("output_column");
  const watchDescription = watch("description");

  const inputColumnOptions =
    selectDatasetList?.find((x) => x.dataset === selectedDataset)?.schema || [];

  // get numeric columns
  const numericColumnOptions = inputColumnOptions
    ?.filter((obj) => obj.type === "numeric")
    ?.map((obj) => obj.column);

  // get configuration data from previous blocks
  const configurationData = selectedTemplateItems
    ?.filter((x) => x.templateCount < selectedItem?.templateCount)
    ?.map((item) => item.configuration);

  // get output columns from previous blocks
  const outputColumns = configurationData
    ?.map((config) => config?.output_column)
    ?.filter(
      (output) => output !== undefined && output !== null && output !== "",
    );

  const numericAndOutputColumns = sortBy(
    uniq([...numericColumnOptions, ...outputColumns]),
  );

  // configuration values are set here

  useEffect(() => {
    updateConfigurationInBlocks(selectedItem, {
      compliance_scheme_id: watchInput_column,
      output_column: watchOutput_column,
      description: watchDescription,
    });
  }, [
    selectedItem,
    watchInput_column,
    watchOutput_column,
    watchDescription,
    updateConfigurationInBlocks,
  ]);

  useRuleValidateOutputColumns({
    field: "output_column",
    value: watchOutput_column,
    selectedTemplateItems,
    clearErrors,
    setError,
    setFocus,
  });

  useEffect(() => {
    // configuration values are set here
    if (selectedItem && selectedItem?.configuration) {
      setValue(
        "compliance_scheme_id",
        selectedItem?.configuration?.compliance_scheme_id,
      );
      setValue("output_column", selectedItem?.configuration?.output_column);
      setValue("description", selectedItem?.configuration?.description);
    } else {
      setValue("compliance_scheme_id", "");
      setValue("output_column", "");
      setValue("description", "");
    }
  }, [selectedItem?.configuration, selectedItem, setValue]);

  useRuleFormValidationEffects({
    selectedItem,
    saveErrorData,
    clearErrors,
    setError,
    setFocus,
  });

  return (
    <div className="flex flex-col gap-5 text-left">
      <FormGroup>
        <Label for="description" className="fw-normal">
          Description
        </Label>
        <Input
          type="input"
          id="description"
          data-test="description"
          {...computeProps("description")}
          maxLength={30}
          placeholder="Enter description"
          disabled={isDisabled}
        />
        <span className="absolute fw-normal right-1 p-2.5 text-xs">
          {watchDescription?.length || 0}/30
        </span>
      </FormGroup>
      <FormGroup>
        <Label for="compliance_scheme_id" className="fw-normal mb-4">
          Compliance scheme
        </Label>
        <Input
          type="select"
          id="compliance_scheme_id"
          data-test="compliance_scheme_id"
          {...computeProps("compliance_scheme_id", {
            required: "Please select input column",
          })}
          invalid={!!errors.compliance_scheme_id}
          disabled={isDisabled}
        >
          <option value="">Select option</option>
          {compliaceSchemeDetailsData?.map((scheme) => (
            <option key={scheme.uuid} value={scheme.uuid}>
              {scheme.complianceScheme}
            </option>
          ))}
        </Input>
        {errors.compliance_scheme_id && (
          <FormFeedback className="mt-2">
            {errors.compliance_scheme_id?.message}
          </FormFeedback>
        )}
      </FormGroup>

      <FormGroup>
        <Label for="output_column" className="fw-normal">
          Output column name
        </Label>
        <Input
          type="text"
          id="output_column"
          {...computeProps("output_column", {
            required: "Please enter name",
            pattern: {
              value: /^[a-zA-Z_][a-zA-Z0-9_]*$/,
              message:
                "Column name must start with a letter or underscore, and can only contain letters, digits, and underscores",
            },
          })}
          invalid={!!errors.output_column}
          maxLength={31}
          placeholder="Enter name"
          disabled={isDisabled}
        />
        {errors.output_column && (
          <FormFeedback>{errors.output_column.message}</FormFeedback>
        )}
      </FormGroup>
    </div>
  );
};

EnergyDensity.propTypes = {
  selectedItem: PropTypes.object,
  selectDatasetList: PropTypes.array,
  selectedDataset: PropTypes.string,
  selectedTemplateItems: PropTypes.array,
  conversionFactorList: PropTypes.array,
  updateConfigurationInBlocks: PropTypes.func,
  saveErrorData: PropTypes.object,
  isDisabled: PropTypes.bool,
  ruleVersionId: PropTypes.string,
  compliaceSchemeDetailsData: PropTypes.array,
};

export default EnergyDensity;
