import {FilePdf24} from "@bphxd/ds-core-react/lib/icons";
import {formatNumber, getFilterValue} from "modules/SAF-dashboard/utils";
import moment from "moment";
import {Link, useParams} from "react-router-dom";
import ColumnHighlight from "./ColumnHighlight";

export const INCOMING_COLUMNS = (dateFormat, siteReferenceId, divisionId) => [
  {
    header: "Incoming document",
    columns: [
      {
        header: "Contract number",
        accessorKey: "contract_number",
        key: "contract_number",
        visible: true,
        disabled: true,
        size: 50,
        meta: {
          filterVariant: "select",
        },
      },
      {
        header: "Document",
        accessorKey: "certificate_number",
        key: "certificate_number",
        visible: true,
        size: 200,
        cell: ({cell, row}) => {
          const {country} = useParams();
          return (
            <Link
              to={{
                pathname: `/document-manager/incoming/${country}/detailedView/${row.original.certificate_inbound_eu_id}`,
                search: `?pageNo=${0}&siteReferenceId=${siteReferenceId}&divisionId=${divisionId}`,
              }}
              className="link-dark"
            >
              <u className="flex">
                {cell.getValue() && <FilePdf24 />}
                <ColumnHighlight
                  value={cell.getValue()}
                  columnFilter={getFilterValue(cell, "certificate_number")}
                  hasEllipsis
                  isCertificate
                />
              </u>
            </Link>
          );
        },
      },
      {
        header: "Dispatch date",
        key: "material_dispatch_datez",
        accessorKey: "material_dispatch_datez",
        filterFn: "dateRangeFilterFn",
        visible: true,
        meta: {
          filterVariant: "daterange",
        },
        cell: ({cell}) => {
          return moment(cell.getValue()).format(dateFormat);
        },
      },
      {
        header: "Location",
        accessorKey: "location_group_name",
        key: "location_group_name",
        size: 200,
        visible: true,
        meta: {
          filterVariant: "select",
        },
      },
      {
        header: "Quantity m³",
        accessorKey: "product_qty_m3",
        key: "product_qty_m3",
        size: 200,
        visible: true,
        dataType: "number",
        meta: {
          filterVariant: "range",
        },
        cell: ({cell}) => {
          return cell.getValue() ? formatNumber(cell.getValue(), "", 3) : "";
        },
      },
      {
        header: "Quantity MT",
        accessorKey: "product_qty_mt",
        key: "product_qty_mt",
        size: 200,
        visible: false,
        dataType: "number",
        meta: {
          filterVariant: "range",
        },
        cell: ({cell}) => {
          return cell.getValue() ? formatNumber(cell.getValue(), "", 3) : "";
        },
      },
      {
        header: "Dispatch address",
        accessorKey: "supplier_dispatch_address",
        key: "supplier_dispatch_address",
        footer: (props) => props.column.id,
        visible: false,
        cell: ({cell}) => {
          return (
            <ColumnHighlight
              value={cell.getValue()}
              columnFilter={getFilterValue(cell, "supplier_dispatch_address")}
            />
          );
        },
      },
      {
        header: "Product",
        accessorKey: "product_type",
        key: "product_type",
        visible: false,
        meta: {
          filterVariant: "select",
        },
      },
      {
        header: "Raw material",
        accessorKey: "raw_material",
        key: "raw_material",
        visible: false,
        meta: {
          filterVariant: "select",
        },
      },
      {
        header: "Country of origin",
        accessorKey: "country_of_origin",
        key: "country_of_origin",
        visible: false,
        meta: {
          filterVariant: "select",
        },
      },
      {
        header: "Supplier",
        accessorKey: "supplier_name",
        key: "supplier_name",
        visible: false,
        meta: {
          filterVariant: "select",
        },
      },
      {
        header: "Receiving point",
        accessorKey: "recipient_address",
        key: "recipient_address",
        visible: false,
        cell: ({cell}) => {
          return (
            <ColumnHighlight
              value={cell.getValue()}
              columnFilter={getFilterValue(cell, "recipient_address")}
            />
          );
        },
      },
      {
        header: "Energy MJ",
        accessorKey: "energy_mj_incoming",
        key: "energy_mj_incoming",
        visible: false,
        dataType: "number",
        meta: {
          filterVariant: "range",
        },
        cell: ({cell}) => {
          return cell.getValue() ? formatNumber(cell.getValue(), "", 3) : "";
        },
      },
      {
        header: "GHG Total gCO2eq/MJ",
        accessorKey: "ghg_gco2eq_incoming",
        accessorFn: (row) => {
          return (row.ghg_gco2eq_incoming / row.energy_mj_incoming) * 1000;
        },
        key: "ghg_gco2eq_incoming",
        visible: false,
        dataType: "number",
        meta: {
          filterVariant: "range",
        },
        cell: ({cell}) => {
          return cell.getValue() ? formatNumber(cell.getValue(), "", 3) : "";
        },
      },
      {
        header: "Balance group",
        accessorKey: "balance_group_name",
        key: "balance_group_name",
        visible: false,
        meta: {
          filterVariant: "select",
        },
      },
    ],
  },
];

export const PURCHASE_COLUMNS = (dateFormat) => [
  {
    header: "Purchase",
    columns: [
      {
        header: "Deal number",
        accessorKey: "deal_number",
        key: "deal_number",
        visible: true,
        disabled: true,
        meta: {
          filterVariant: "select",
        },
      },
      {
        header: "Deal date",
        key: "deal_done_date",
        accessorKey: "deal_done_date",
        filterFn: "dateRangeFilterFn",
        visible: true,
        meta: {
          filterVariant: "daterange",
        },
        cell: ({cell}) => {
          return moment(cell.getValue()).format(dateFormat);
        },
      },
      {
        header: "Location",
        accessorKey: "location_group_name",
        key: "location_group_name",
        size: 200,
        visible: true,
        meta: {
          filterVariant: "select",
        },
      },
      {
        header: "Actualizable quantity m³",
        accessorKey: "quantity_actual",
        key: "quantity_actual",
        size: 100,
        visible: true,
        dataType: "number",
        meta: {
          filterVariant: "range",
        },
        cell: ({cell}) => {
          return cell.getValue() ? formatNumber(cell.getValue(), "", 3) : "";
        },
      },
      {
        header: "Actualizable quantity MT",
        accessorKey: "quantity_mt_actualized",
        key: "quantity_mt_actualized",
        size: 100,
        visible: false,
        dataType: "number",
        meta: {
          filterVariant: "range",
        },
        cell: ({cell}) => {
          return cell.getValue() ? formatNumber(cell.getValue(), "", 3) : "";
        },
      },
      {
        header: "Energy MJ",
        accessorKey: "energy_mj_purchase",
        key: "energy_mj_purchase",
        visible: false,
        dataType: "number",
        meta: {
          filterVariant: "range",
        },
        cell: ({cell}) => {
          return cell.getValue() ? formatNumber(cell.getValue(), "", 3) : "";
        },
      },
      {
        header: "Purchased sustainable quantity m³",
        accessorKey: "quantity_m3_sbc",
        key: "quantity_m3_sbc",
        size: 100,
        visible: false,
        dataType: "number",
        meta: {
          filterVariant: "range",
        },
        cell: ({cell}) => {
          return cell.getValue() ? formatNumber(cell.getValue(), "", 3) : "";
        },
      },
      {
        header: "Purchased sustainable quantity MT",
        accessorKey: "quantity_mt_sbc",
        key: "quantity_mt_sbc",
        size: 100,
        visible: false,
        dataType: "number",
        meta: {
          filterVariant: "range",
        },
        cell: ({cell}) => {
          return cell.getValue() ? formatNumber(cell.getValue(), "", 3) : "";
        },
      },
      {
        header: "Purchased quantity m³",
        accessorKey: "quantity_m3_hdr",
        key: "quantity_m3_hdr",
        size: 100,
        visible: false,
        dataType: "number",
        meta: {
          filterVariant: "range",
        },
        cell: ({cell}) => {
          return cell.getValue() ? formatNumber(cell.getValue(), "", 3) : "";
        },
      },
      {
        header: "Purchased quantity MT",
        accessorKey: "quantity_mt_hdr",
        key: "quantity_mt_hdr",
        size: 100,
        visible: false,
        dataType: "number",
        meta: {
          filterVariant: "range",
        },
        cell: ({cell}) => {
          return cell.getValue() ? formatNumber(cell.getValue(), "", 3) : "";
        },
      },
      {
        header: "Received quantity m³",
        accessorKey: "quantity_m3_in",
        key: "quantity_m3_in",
        size: 100,
        visible: false,
        dataType: "number",
        meta: {
          filterVariant: "range",
        },
        cell: ({cell}) => {
          return cell.getValue() ? formatNumber(cell.getValue(), "", 3) : "";
        },
      },
      {
        header: "Received quantity MT",
        accessorKey: "quantity_mt_in",
        key: "quantity_mt_in",
        size: 100,
        visible: false,
        dataType: "number",
        meta: {
          filterVariant: "range",
        },
        cell: ({cell}) => {
          return cell.getValue() ? formatNumber(cell.getValue(), "", 3) : "";
        },
      },
      {
        header: "Purchase order",
        accessorKey: "po_number",
        key: "po_number",
        visible: false,
        cell: ({cell}) => {
          return (
            <ColumnHighlight
              value={cell.getValue()}
              columnFilter={getFilterValue(cell, "po_number")}
            />
          );
        },
      },
      {
        header: "Purchase date",
        key: "purchase_date",
        accessorKey: "purchase_date",
        filterFn: "dateRangeFilterFn",
        visible: false,
        meta: {
          filterVariant: "daterange",
        },
        cell: ({cell}) => {
          return moment(cell.getValue()).format(dateFormat);
        },
      },
      {
        header: "Receipt date",
        key: "discharge_date",
        accessorKey: "discharge_date",
        filterFn: "dateRangeFilterFn",
        visible: false,
        meta: {
          filterVariant: "daterange",
        },
        cell: ({cell}) => {
          return moment(cell.getValue()).format(dateFormat);
        },
      },
      {
        header: "Invoice number",
        accessorKey: "invoice_number",
        key: "invoice_number",
        visible: false,
        cell: ({cell}) => {
          return (
            <ColumnHighlight
              value={cell.getValue()}
              columnFilter={getFilterValue(cell, "invoice_number")}
            />
          );
        },
      },
      {
        header: "Material code",
        accessorKey: "material_code",
        key: "material_code",
        visible: false,
        cell: ({cell}) => {
          return (
            <ColumnHighlight
              value={cell.getValue()}
              columnFilter={getFilterValue(cell, "material_code")}
            />
          );
        },
      },
      {
        header: "Raw material",
        accessorKey: "material_description",
        key: "material_description",
        visible: false,
        meta: {
          filterVariant: "select",
        },
      },
      {
        header: "Supplier",
        accessorKey: "vendor_name",
        key: "vendor_name",
        visible: false,
        meta: {
          filterVariant: "select",
        },
      },
      {
        header: "Shipping point",
        accessorKey: "source_location_name",
        key: "source_location_name",
        visible: false,
        cell: ({cell}) => {
          return (
            <ColumnHighlight
              value={cell.getValue()}
              columnFilter={getFilterValue(cell, "source_location_name")}
            />
          );
        },
      },
      {
        header: "Receiving point",
        accessorKey: "destination_location_name",
        key: "destination_location_name",
        visible: false,
        cell: ({cell}) => {
          return (
            <ColumnHighlight
              value={cell.getValue()}
              columnFilter={getFilterValue(cell, "destination_location_name")}
            />
          );
        },
      },
      {
        header: "Discharge plant code",
        accessorKey: "discharge_plant_code",
        key: "discharge_plant_code",
        visible: false,
        cell: ({cell}) => {
          return (
            <ColumnHighlight
              value={cell.getValue()}
              columnFilter={getFilterValue(cell, "discharge_plant_code")}
            />
          );
        },
      },
      {
        header: "Purchase plant code",
        accessorKey: "purchase_plant_code",
        key: "purchase_plant_code",
        visible: false,
        cell: ({cell}) => {
          return (
            <ColumnHighlight
              value={cell.getValue()}
              columnFilter={getFilterValue(cell, "purchase_plant_code")}
            />
          );
        },
      },
      {
        header: "GHG Total kgCO2eq",
        accessorKey: "ghg_gco2eq_purchase",
        key: "ghg_gco2eq_purchase",
        visible: false,
        dataType: "number",
        meta: {
          filterVariant: "range",
        },
        cell: ({cell}) => {
          return cell.getValue() ? formatNumber(cell.getValue(), "", 3) : "";
        },
      },
      {
        header: "GHG Total gCO2eq/MJ",
        accessorKey: "ghg_gco2eq_mj",
        accessorFn: (row) => {
          return (row.ghg_gco2eq_purchase / row.energy_mj_purchase) * 1000;
        },
        key: "ghg_gco2eq_mj",
        visible: false,
        dataType: "number",
        meta: {
          filterVariant: "range",
        },
        cell: ({cell}) => {
          return cell.getValue() ? formatNumber(cell.getValue(), "", 3) : "";
        },
      },
      {
        header: "Balance group",
        accessorKey: "balance_group_name",
        key: "balance_group_name",
        visible: false,
        meta: {
          filterVariant: "select",
        },
      },
    ],
  },
];

export default INCOMING_COLUMNS;
