import {useQuery} from "@apollo/client";
import {useAccount, useMsal} from "@azure/msal-react";
import {Close} from "@bphxd/ds-core-react";
import {uniqBy} from "lodash";

import {
  GET_TRANSFER_DETAILS,
  TransferAPI,
} from "graphql/MassBalance/Actions/TransferAPI";
import Datepicker from "modules/GlobalMassBalance/components/Datepicker";
import {getQtyandUom} from "modules/GlobalMassBalance/utils";
import PropTypes from "prop-types";
import {useAppSetting} from "providers/appSetting/context.js";
import {useEffect, useState} from "react";
import {FormProvider, useForm} from "react-hook-form";
import {toast} from "react-toastify";
import {
  Button,
  Form,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Spinner,
} from "reactstrap";
import {getDivisionData, getSiteDetails} from "utils/helpers/getAppSetting.js";
import {validateDecimalWithFivePoints} from "utils/numberUtil";
import "../../index.css";

const TransferDetails = ({
  isVisibleModal,
  setModal,
  rowData,
  divisionCode,
  currentFilterValues,
}) => {
  const {appSetting} = useAppSetting();
  const countryId = appSetting?.currentCountryMappingData?.countryId;

  const siteReferenceData = getSiteDetails(countryId);
  const divisionData = getDivisionData(divisionCode);

  const {accounts} = useMsal();
  const account = useAccount(accounts[0]);
  const [isModalVisible, setModalVisible] = useState(isVisibleModal);
  const [motDetails, setMOTDetails] = useState([]);
  const [locationDetails, setLocationDetails] = useState([]);
  const [isProcessing, setProcessing] = useState(false);
  useEffect(() => {
    setModalVisible(isVisibleModal);
  }, [isVisibleModal]);

  const methods = useForm({
    mode: "onChange",
    shouldFocusError: true,
  });

  const {
    register,
    formState: {errors},
    getValues,
    trigger,
    watch,
    control,
  } = methods;

  const {data: masterDetails, loading} = useQuery(GET_TRANSFER_DETAILS, {
    fetchPolicy: "network-only",
    variables: {
      siteReferenceId: siteReferenceData?.siteReferenceId,
      divisionId: divisionData?.divisionId,
      sendingLocationGroupId: rowData.original.inboundRecord?.mbLocationGroupId,
      // divisionId: "8b4f54b5207d198b87d83d660b22e6c6",
      // siteReferenceId: "907eba32d950bfab68227fd7ea22999b",
      // sendingLocationGroupId: "bca0532ce2dc078a6a6ede2eea8aa398",
    },
    skip:
      siteReferenceData?.siteReferenceId === undefined ||
      divisionData?.divisionId === undefined ||
      isVisibleModal === false,
  });

  const modeofTransportationDetails =
    masterDetails?.bioLcGetLocationMotDetails?.data;

  useEffect(() => {
    const motDetailsList = [
      ...new Set(modeofTransportationDetails?.map((obj) => obj.motDetail)),
    ];

    const locationDetails = [
      ...new Set(
        modeofTransportationDetails?.map((obj) => {
          return {
            locationGroupId: obj.locationGroupId,
            locationName: obj.locationName,
          };
        }),
      ),
    ];
    setMOTDetails(uniqBy(motDetailsList ?? []));
    setLocationDetails(uniqBy(locationDetails ?? [], "locationGroupId"));
  }, [modeofTransportationDetails, setMOTDetails, setLocationDetails]);

  const {quantity, quantity_uom} = getQtyandUom(rowData, divisionCode);
  const watchQuantity = parseFloat(watch("quantity"));

  const onSubmit = async () => {
    const valid = await trigger();
    const {...docData} = getValues();
    if (valid) {
      setProcessing(true);
      const {data: response} = await TransferAPI({
        mbInboundQtyEuId: rowData.original.inboundRecord.mbInboundQtyEuId,

        createdBy: account.username,
        siteReferenceId: siteReferenceData?.siteReferenceId,
        divisionId: divisionData?.divisionId,
        transferQuantity: docData.quantity,

        fromLocationGroupId: rowData.original.inboundRecord.mbLocationGroupId,
        toLocationGroupId: docData?.transferLocation,
        mbBalanceGroupId: rowData.original.inboundRecord.mbBalanceGroupId,
        mbBalanceTypeId: currentFilterValues?.mbBalanceTypeId,
        mbPeriodStatusId: rowData.original.inboundRecord.mbPeriodStatusId,
        modesOfTransport: docData?.modeOfTransport,

        transferDate: docData?.transferDate,

        vesselName: docData?.vesselName,
      });

      if (response?.bioLcGlobalMassBalanceTransfer?.statusCode === 200) {
        setProcessing(false);
        setModalVisible(false);
        setModal("");
        toast.success(
          response?.bioLcGlobalMassBalanceTransfer?.message ??
            "Successfully transferred",
        );
      } else {
        setProcessing(false);
        toast.error(
          response?.bioLcGlobalMassBalanceTransfer?.message ??
            "Something went wrong, failed to transfer",
        );
      }
    }
  };

  const computeProps = (name, options) => {
    const {ref, ...props} = register(name, options);
    return {innerRef: ref, ...props};
  };

  return (
    <div>
      <Modal
        size="lg"
        isOpen={isModalVisible}
        className="modal-dialog-centered mb-action-modal-666"
      >
        <ModalHeader
          className="!border-b-[1px] !border-gray-200 mb-5 text-xl"
          close={
            <Close
              onClick={() => {
                setModalVisible(false);
                setModal("");
              }}
            />
          }
        >
          Transfer
        </ModalHeader>
        <ModalBody className="text-center pt-0">
          <FormProvider {...methods}>
            <Form className="grid sm:grid-cols-1 text-left md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-2 gap-x-4 gap-y-4 ">
              <FormGroup>
                <Label for="quantity" className="fw-light">
                  Quantity {quantity_uom}
                </Label>
                <Input
                  type="number"
                  id="quantity"
                  {...computeProps("quantity", {
                    required: "Please enter quantity",
                    pattern: validateDecimalWithFivePoints,
                  })}
                  invalid={!!errors.quantity}
                  maxLength={100}
                />
                {errors.quantity && (
                  <FormFeedback>{errors.quantity.message}</FormFeedback>
                )}
                {!errors.quantity && watchQuantity > quantity && (
                  <FormFeedback className="!block">
                    It cannot exceed the maximum quantity
                  </FormFeedback>
                )}
                <span className="text-xs text-gray-700">{`You have ${quantity} ${quantity_uom} available`}</span>
              </FormGroup>
              <FormGroup>
                <Label for="transferLocation" className="fw-light">
                  Transfer Location
                </Label>
                <Input
                  type="select"
                  id="transferLocation"
                  data-test="transferLocation"
                  invalid={!!errors.transferLocation}
                  {...computeProps("transferLocation", {
                    required: "Please select transfer location",
                  })}
                >
                  <option key="none" value="">
                    --Select an option--
                  </option>
                  {locationDetails?.map((location) => (
                    <option
                      key={location.locationGroupId}
                      value={location.locationGroupId}
                    >
                      {location.locationName}
                    </option>
                  ))}
                </Input>
                {errors.transferLocation && (
                  <FormFeedback>{errors.transferLocation.message}</FormFeedback>
                )}
              </FormGroup>
              <FormGroup>
                <Label for="modeOfTransport" className="fw-light">
                  Mode of transport
                </Label>
                <Input
                  type="select"
                  id="modeOfTransport"
                  data-test="modeOfTransport"
                  invalid={!!errors.modeOfTransport}
                  {...computeProps("modeOfTransport", {
                    required: "Please select mode of transport",
                  })}
                >
                  <option key="none" value="">
                    --Select an option--
                  </option>
                  {motDetails?.map((motName) => (
                    <option key={motName} value={motName}>
                      {motName}
                    </option>
                  ))}
                </Input>
                {errors.modeOfTransport && (
                  <FormFeedback>{errors.modeOfTransport.message}</FormFeedback>
                )}
              </FormGroup>
              <FormGroup>
                <Label for="transferDate" className="fw-light">
                  Date of transfer
                </Label>
                <Datepicker
                  id="transferDate"
                  name="transferDate"
                  {...computeProps("transferDate", {
                    required: "Please enter date of transfer",
                  })}
                  control={control}
                  error={errors.transferDate}
                  rules={{required: "Please enter date of transfer"}}
                  format="d/m/Y"
                />
                {errors.transferDate && (
                  <FormFeedback>{errors.transferDate.message}</FormFeedback>
                )}
              </FormGroup>
              <FormGroup>
                <Label for="vesselName" className="fw-light">
                  Vessel name
                </Label>

                <Input
                  type="text"
                  id="vesselName"
                  data-test="vesselName"
                  invalid={!!errors.vesselName}
                  maxLength={20}
                  {...computeProps("vesselName", {})}
                />
                {errors.vesselName && (
                  <FormFeedback>{errors.vesselName.message}</FormFeedback>
                )}
              </FormGroup>
            </Form>
          </FormProvider>
        </ModalBody>
        <ModalFooter className="p-0 d-block">
          <div className="row g-0 m-0 modal-footer-row">
            <div className="col-6 d-grid">
              <Button
                color="darker-tertiary"
                size="lg"
                className="border-0 rounded-0 py-4 opacity-80 opacity-100-hover bg-transparent"
                onClick={() => {
                  setModalVisible(false);
                  setModal("");
                }}
              >
                Cancel
              </Button>
            </div>
            <div className="col-6 d-grid">
              <Button
                color="darker-tertiary"
                size="lg"
                className="border-0 rounded-0 !p-0 bg-transparent text-default whitespace-nowrap"
                type="button"
                disabled={
                  isProcessing ||
                  errors.quantity ||
                  !watchQuantity ||
                  watchQuantity > quantity
                }
                onClick={onSubmit}
              >
                {isProcessing && (
                  <Spinner size="sm" className="btn-icon-prefix" />
                )}
                Confirm transfer
              </Button>
            </div>
          </div>
        </ModalFooter>
      </Modal>
    </div>
  );
};

TransferDetails.propTypes = {
  isVisibleModal: PropTypes.bool,
  setModal: PropTypes.func,
  rowData: PropTypes.object,
  divisionCode: PropTypes.string,
  currentFilterValues: PropTypes.object,
};

export default TransferDetails;
