import {Close} from "@bphxd/ds-core-react";
import {massBalanceType} from "modules/GlobalMassBalance/constants";
import PropTypes from "prop-types";
import React, {useEffect, useMemo, useState} from "react";
import {FormProvider, useForm} from "react-hook-form";
import {
  Button,
  Form,
  FormFeedback,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Spinner,
} from "reactstrap";
import {validateDecimalWithFivePoints} from "utils/numberUtil";

const ClosingStockAndApiModal = ({
  showResponse,
  setShowResponse,
  onSubmit,
  closingStock,
  physicalStock,
  loading,
  mbType,
  unitOfMeasure,
}) => {
  const methods = useForm({
    mode: "onChange",
    shouldFocusError: true,
  });

  const {
    register,
    formState: {errors},
    setValue,
    watch,
    getValues,
  } = methods;

  const computeProps = (name, options) => {
    const {ref, ...props} = register(name, options);
    return {innerRef: ref, ...props};
  };

  const watchPhysicalStock = watch("physicalStock");

  const [difference, setDifference] = useState();
  const [showReason, setShowReason] = useState(false);
  const [reason, setReason] = useState();
  const [reasonFocused, setReasonFocused] = useState(false);

  useEffect(() => {
    if (physicalStock !== null && physicalStock !== undefined) {
      setValue("physicalStock", physicalStock);
    }
  }, [physicalStock, setValue]);

  useEffect(() => {
    const physicalStockFormData = getValues("physicalStock");
    if (physicalStockFormData) {
      const greaterQuantity = Math.max(physicalStockFormData, closingStock);
      const lesserQuantity = Math.min(physicalStockFormData, closingStock);
      const difference =
        ((greaterQuantity - lesserQuantity) / greaterQuantity) * 100;
      setDifference(difference.toFixed(2));
    }
  }, [closingStock, getValues, watchPhysicalStock]);

  const isWithinTolerance = useMemo(() => {
    return difference <= 0.5;
  }, [difference]);

  function isValidNumber(number) {
    return number != null && !Number.isNaN(Number(number));
  }

  return (
    <Modal
      size="sm"
      isOpen={showResponse}
      className="modal-dialog-centered [&>div]:w-[330px]"
    >
      <ModalHeader
        className="w-[330px] !border-b-[1px] !border-gray-200 mb-2 text-xl"
        close={<Close onClick={() => setShowResponse("")} />}
      >
        Close mass balance
      </ModalHeader>

      <ModalBody className={`flex flex-col items-center !pt-[17px] `}>
        {mbType === massBalanceType.CPP && showReason && (
          <>
            <p className="text-[13px] font-light m-0">
              As the tolerance is outside of the agreed 0.5%, you must specify a
              reason to close this balance.
            </p>
            <div className="w-full">
              <Label className="mt-[30px]" for="notes">
                Reason
              </Label>
              <Input
                id="reason"
                type="textarea"
                rows="3"
                value={reason}
                onChange={(e) => setReason(e.target.value)}
                invalid={reasonFocused && !reason}
              />
              {reasonFocused && !reason && (
                <FormFeedback className="!block">
                  Reason is required
                </FormFeedback>
              )}
            </div>
          </>
        )}
        {!showReason && (
          <>
            <p className="text-[13px] font-light">
              In order to close the current mass balance, we require the stock
              inventory quantity. Please enter it below.
            </p>
            <div className="w-full">
              <Label for="closing-stock">Closing balance {unitOfMeasure}</Label>
              <Input
                id="closing-stock"
                type="number"
                value={closingStock}
                disabled
              />
            </div>
            <div className="w-full mt-[20px]">
              <FormProvider {...methods}>
                <Form>
                  <Label for="physicalStock">
                    Physical closing stock total {unitOfMeasure}
                  </Label>
                  <Input
                    id="physicalStock"
                    type="number"
                    placeholder="Enter quantity"
                    {...computeProps("physicalStock", {
                      required:
                        "Please enter closing stock total " + unitOfMeasure,
                      pattern: validateDecimalWithFivePoints,
                    })}
                    invalid={
                      !!errors.physicalStock ||
                      (mbType === massBalanceType.TRS &&
                        watchPhysicalStock < closingStock)
                    }
                  />
                  {errors.physicalStock && (
                    <FormFeedback>{errors.physicalStock.message}</FormFeedback>
                  )}
                  {!errors.physicalStock &&
                    mbType === massBalanceType.TRS &&
                    watchPhysicalStock < closingStock && (
                      <FormFeedback className="!block">
                        It cannot be less than the closing balance
                      </FormFeedback>
                    )}
                </Form>
              </FormProvider>
            </div>
            {mbType === massBalanceType.CPP && (
              <>
                <div className="w-full mt-[20px]">
                  <Label for="difference">Difference %</Label>
                  <Input
                    id="difference"
                    type="number"
                    value={difference}
                    disabled
                  />
                </div>
                {!isWithinTolerance && (
                  <FormFeedback className="!block">
                    Difference exceeds tolerance of 0.5%
                  </FormFeedback>
                )}
              </>
            )}
          </>
        )}
      </ModalBody>
      <ModalFooter className="p-0 d-block">
        <div className="row g-0 m-0 modal-footer-row">
          <div className="col-6 d-grid">
            <Button
              color="darker-tertiary"
              className="border-0 rounded-0 py-4 opacity-80 opacity-100-hover bg-transparent"
              onClick={() => setShowResponse("")}
            >
              Cancel
            </Button>
          </div>
          <div className="col-6 d-grid">
            <Button
              color="darker-tertiary"
              className="border-0 rounded-0 py-4 bg-transparent text-default"
              onClick={() => {
                if (
                  mbType === massBalanceType.CPP &&
                  !isWithinTolerance &&
                  !showReason
                ) {
                  setShowReason(true);
                } else if (!isWithinTolerance && !reasonFocused && !reason) {
                  setReasonFocused(true);
                } else {
                  onSubmit(parseFloat(watchPhysicalStock), reason);
                }
              }}
              disabled={
                errors.physicalStock ||
                !isValidNumber(watchPhysicalStock) ||
                (mbType === massBalanceType.TRS &&
                  watchPhysicalStock < closingStock)
              }
            >
              {loading && <Spinner size="sm" className="btn-icon-prefix" />}
              Close balance
            </Button>
          </div>
        </div>
      </ModalFooter>
    </Modal>
  );
};

ClosingStockAndApiModal.propTypes = {
  showResponse: PropTypes.bool,
  setShowResponse: PropTypes.func,
  onSubmit: PropTypes.func,
  closingStock: PropTypes.number,
  physicalStock: PropTypes.number,
  loading: PropTypes.bool,
  mbType: PropTypes.string,
  unitOfMeasure: PropTypes.string,
};

export default ClosingStockAndApiModal;
