import PropTypes from "prop-types";
import {useUserSettings} from "providers/userSettings";
import {useCallback, useEffect, useState} from "react";
import {useFormContext} from "react-hook-form";

import {useAccount, useMsal} from "@azure/msal-react";

import {sortBy, uniq} from "lodash";
import {FormFeedback, FormGroup, Input, Label} from "reactstrap";
import useRuleFormValidationEffects, {
  useRuleValidateOutputColumns,
} from "./validationError";

const ConvertUnits = ({
  selectedItem,
  selectDatasetList,
  selectedDataset,
  selectedTemplateItems,
  conversionFactorList,
  updateConfigurationInBlocks,
  saveErrorData,
  isDisabled,
}) => {
  const {
    userSettings: {decimalFormat},
  } = useUserSettings();

  const {accounts} = useMsal();
  const account = useAccount(accounts[0]);

  const {
    register,
    formState: {errors},
    control,
    setError,
    setFocus,
    setValue,
    clearErrors,
    watch,
  } = useFormContext();
  const computeProps = (name, options) => {
    const {ref, ...props} = register(name, options);
    return {innerRef: ref, ...props};
  };

  const [disableConversionFactor, setDisableConversionFactor] = useState(false);

  const watchConversion_factor_name = watch("conversion_factor_name");
  const watchInput_column = watch("input_column");

  const watchConversion_factor = watch("conversion_factor");
  const watchOutput_column = watch("output_column");
  const watchDescription = watch("description");

  const inputColumnOptions =
    selectDatasetList?.find((x) => x.dataset === selectedDataset)?.schema || [];

  // get numeric columns
  const numericColumnOptions = inputColumnOptions
    ?.filter((obj) => obj.type === "numeric")
    ?.map((obj) => obj.column);

  // get configuration data from previous blocks
  const configurationData = selectedTemplateItems
    ?.filter((x) => x.templateCount < selectedItem?.templateCount)
    ?.map((item) => item.configuration);

  // get output columns from previous blocks
  const outputColumns = configurationData
    ?.map((config) => config?.output_column)
    ?.filter(
      (output) => output !== undefined && output !== null && output !== "",
    );

  const numericAndOutputColumns = sortBy(
    uniq([...numericColumnOptions, ...outputColumns]),
  );

  // configuration values are set here

  useEffect(() => {
    updateConfigurationInBlocks(selectedItem, {
      input_column: watchInput_column,
      conversion_factor_name: watchConversion_factor_name,
      conversion_factor: watchConversion_factor,
      output_column: watchOutput_column,
      description: watchDescription,
    });
  }, [
    selectedItem,
    watchInput_column,
    watchConversion_factor_name,
    watchConversion_factor,
    watchOutput_column,
    watchDescription,
    updateConfigurationInBlocks,
  ]);
  const disableConversionFactorDetails = (itemName) => {
    if (itemName === "manual") {
      setDisableConversionFactor(false);
    } else {
      setDisableConversionFactor(true);
    }
  };

  useRuleValidateOutputColumns({
    field: "output_column",
    value: watchOutput_column,
    selectedTemplateItems,
    clearErrors,
    setError,
    setFocus,
  });

  useEffect(() => {
    // configuration values are set here
    if (selectedItem && selectedItem?.configuration) {
      setValue("input_column", selectedItem?.configuration?.input_column);
      setValue(
        "conversion_factor_name",
        selectedItem?.configuration?.conversion_factor_name,
      );
      disableConversionFactorDetails(
        selectedItem?.configuration?.conversion_factor_name,
      );
      setValue(
        "conversion_factor",
        selectedItem?.configuration?.conversion_factor,
      );
      setValue("output_column", selectedItem?.configuration?.output_column);
      setValue("description", selectedItem?.configuration?.description);
    } else {
      setValue("input_column", "");
      setValue("conversion_factor_name", "");
      setValue("conversion_factor", "");
      setValue("output_column", "");
      setValue("description", "");
    }
  }, [selectedItem?.configuration, selectedItem, setValue]);

  // handler function for conversion factor change
  const handleConversionFactorChange = useCallback(
    (itemName) => {
      const coversionItem = conversionFactorList?.find(
        (conversionItem) => conversionItem?.conversion_factor_name === itemName,
      );
      setValue("conversion_factor", coversionItem?.conversion_factor);
      disableConversionFactorDetails(itemName);
    },
    [conversionFactorList, setValue],
  );

  useRuleFormValidationEffects({
    selectedItem,
    saveErrorData,
    clearErrors,
    setError,
    setFocus,
  });

  return (
    <div className="flex flex-col gap-5 text-left">
      <FormGroup>
        <Label for="description" className="fw-normal">
          Description
        </Label>
        <Input
          type="input"
          id="description"
          data-test="description"
          {...computeProps("description")}
          maxLength={30}
          placeholder="Enter description"
          disabled={isDisabled}
        />
        <span className="absolute fw-normal right-1 p-2.5 text-xs">
          {watchDescription?.length || 0}/30
        </span>
      </FormGroup>
      <FormGroup>
        <Label for="input_column" className="fw-normal mb-4">
          Input Column
        </Label>
        <Input
          type="select"
          id="input_column"
          data-test="input_column"
          {...computeProps("input_column", {
            required: "Please select input column",
          })}
          invalid={!!errors.input_column}
          disabled={isDisabled}
        >
          <option value="">Select input</option>
          {numericAndOutputColumns?.map((inputColumnOption) => (
            <option key={inputColumnOption} value={inputColumnOption}>
              {inputColumnOption}
            </option>
          ))}
        </Input>
        {errors.input_column && (
          <FormFeedback className="mt-2">
            {errors.input_column?.message}
          </FormFeedback>
        )}
      </FormGroup>
      <FormGroup>
        <Label for="conversion_factor_name" className="fw-normal mb-4">
          Conversion factor
        </Label>
        <Input
          type="select"
          id="conversion_factor_name"
          data-test="conversion_factor_name"
          {...computeProps("conversion_factor_name", {
            required: "Please select conversion factor",
          })}
          invalid={!!errors.conversion_factor_name}
          onChange={(e) => handleConversionFactorChange(e.target.value)}
          disabled={isDisabled}
        >
          <option value="">Select conversion</option>
          <option value="manual">Manually enter conversion factor</option>
          {conversionFactorList?.map((conversionItem) => (
            <option
              key={conversionItem?.conversion_factor_name}
              value={conversionItem?.conversion_factor_name}
            >
              {conversionItem?.conversion_factor_name}
            </option>
          ))}
        </Input>
        {errors.conversion_factor_name && (
          <FormFeedback className="mt-2">
            {errors.conversion_factor_name?.message}
          </FormFeedback>
        )}
      </FormGroup>

      <FormGroup>
        <Label for="conversion_factor" className="fw-normal">
          Manual conversion factor
        </Label>
        <Input
          type="number"
          id="conversion_factor"
          {...computeProps("conversion_factor", {
            required: "Please enter manual conversion factor",
            pattern: {
              value: /^(\d{1,10}(\.\d{1,10})?)$/,
              message:
                "Value must be number with (max 10 digits before and 10 digits after decimal point)",
            },
          })}
          invalid={!!errors.conversion_factor}
          maxLength={100}
          placeholder="Enter conversion factor"
          disabled={disableConversionFactor || isDisabled}
        />
        {errors.conversion_factor && (
          <FormFeedback>{errors.conversion_factor.message}</FormFeedback>
        )}
      </FormGroup>

      <FormGroup>
        <Label for="output_column" className="fw-normal">
          Output column name
        </Label>
        <Input
          type="text"
          id="output_column"
          {...computeProps("output_column", {
            required: "Please enter name",
            pattern: {
              value: /^[a-zA-Z_][a-zA-Z0-9_]*$/,
              message:
                "Column name must start with a letter or underscore, and can only contain letters, digits, and underscores",
            },
          })}
          invalid={!!errors.output_column}
          maxLength={31}
          placeholder="Enter name"
          disabled={isDisabled}
        />
        {errors.output_column && (
          <FormFeedback>{errors.output_column.message}</FormFeedback>
        )}
      </FormGroup>
    </div>
  );
};

ConvertUnits.propTypes = {
  selectedItem: PropTypes.object,
  selectDatasetList: PropTypes.array,
  selectedDataset: PropTypes.string,
  selectedTemplateItems: PropTypes.array,
  conversionFactorList: PropTypes.array,
  updateConfigurationInBlocks: PropTypes.func,
  saveErrorData: PropTypes.object,
  isDisabled: PropTypes.bool,
};

export default ConvertUnits;
