import {gql} from "@apollo/client";

const GENERATE_CERTIFICATE_MODAL_DATA = gql`
  query bioLcGetCertGenModalData(
    $siteReferenceId: String
    $divisionId: String
    $mbOutboundQtyEuId: String
  ) {
    bioLcGetCertGenModalData(
      event: {
        divisionId: $divisionId
        siteReferenceId: $siteReferenceId
        mbOutboundQtyEuId: $mbOutboundQtyEuId
      }
    ) {
      statusCode
      error
      dateOfIssuance
      nameOfRecipient
      addressOfRecipient
      contractNumber
      receivingPoint
      motValues {
        motName
      }
    }
  }
`;

export default GENERATE_CERTIFICATE_MODAL_DATA;
