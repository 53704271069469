import {gql} from "@apollo/client";

export const GET_NABISY_ACCOUNTS = gql`
  query bioLcGetNabisyAccounts($siteReferenceId: String, $divisionId: String) {
    bioLcGetNabisyAccounts(
      event: {divisionId: $divisionId, siteReferenceId: $siteReferenceId}
    ) {
      accounts
      error
      statusCode
    }
  }
`;

export default GET_NABISY_ACCOUNTS;
