import {Close} from "@bphxd/ds-core-react";
import {Check24, Remove24} from "@bphxd/ds-core-react/lib/icons";
import {Button, Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";

import {useQuery} from "@apollo/client";
import {useAccount, useMsal} from "@azure/msal-react";
import GET_MANUAL_ADJUSTMENT_OPTIONS, {
  InsertManualAdjustmentAPI,
} from "graphql/compliance/manualAdjustment";
import Form from "modules/common/FormNew";
import PropTypes from "prop-types";
import {useCallback, useEffect, useMemo, useReducer, useState} from "react";
import {toast} from "react-toastify";
import ManualAdjustmentForm from "./ManualAdjustmentForm";
import "./index.scss";
import manualAdjustmentReducer, {
  ACTIONS,
  INITIAL_STATE,
} from "./manualAdjustmentReducer";

const ManualAdjustmentModal = ({visible, onCancel, data}) => {
  const {data: options} = useQuery(GET_MANUAL_ADJUSTMENT_OPTIONS);
  const [state, dispatch] = useReducer(manualAdjustmentReducer, INITIAL_STATE);

  const {accounts} = useMsal();
  const account = useAccount(accounts[0]);

  const optionsObject = useMemo(
    () => options?.getManualAdjustmentOptions?.body,
    [options?.getManualAdjustmentOptions?.body],
  );

  useEffect(() => {
    // When data comes, set options.
    if (optionsObject) {
      dispatch({
        type: ACTIONS.SET_OPTIONS,
        payload: {data: optionsObject},
      });
    }
  }, [optionsObject, dispatch]);

  const handleOptionsChange = useCallback((filterName, filterValue) => {
    dispatch({
      type: ACTIONS.CHANGE_OPTION,
      payload: {name: filterName, value: filterValue},
    });
  }, []);

  const [submitting, setSubmitting] = useState(false);

  return (
    <Modal
      size="xl"
      isOpen={visible}
      className="modal-dialog-centered"
      contentClassName="rounded-0"
    >
      <ModalHeader
        className="border-b-2 border-gray-200 mb-2 text-xl"
        close={<Close onClick={onCancel} />}
      >
        Deficits manual adjustment
      </ModalHeader>
      <ModalBody className="manual-adjustment-modal py-7 px-6">
        <div className="scenario-manual mb-5">
          {data.scenarioNumber} - {data.scenarioDescription}
        </div>
        <div className="status-manual">Status </div>
        <div className="actualized-manual">Actualized </div>
        <Form
          onSubmit={async (values) => {
            if (submitting) return;
            setSubmitting(true);
            const {data: response} = await InsertManualAdjustmentAPI({
              volumeGal: parseFloat(values.volume),
              grade: values.grade,
              gradeSubType: values.gradeSubType,
              comment: values.comment,
              businessUnit: values.businessUnit,
              location: values.location,
              complianceStatus: "Actualized",
              scenarioNumber: data.scenarioNumber,
              transactionDate: values.transactionDate,
              modifiedBy: account.username,
            });
            const {statusCode, body} = response.insertManualAdjustment;
            if (statusCode === 200) {
              toast.success(body);
              onCancel();
            } else {
              toast.error(body);
            }
            setSubmitting(false);
          }}
          id="form-manual"
          className="form-manual"
        >
          <ManualAdjustmentForm
            options={state?.options}
            handleOptionsChange={handleOptionsChange}
          />
        </Form>
      </ModalBody>
      <ModalFooter>
        <Button
          onClick={onCancel}
          className="rounded-0 btn-light cancel-manual-button"
        >
          <Remove24 className="mr-1" />
          Cancel
        </Button>
        <Button
          type="submit"
          form="form-manual"
          className="rounded-0 btn-secondary save-manual-button"
        >
          <Check24 className="mr-1" />
          Save
        </Button>
      </ModalFooter>
    </Modal>
  );
};

ManualAdjustmentModal.propTypes = {
  visible: PropTypes.bool,
  onCancel: PropTypes.func,
  data: PropTypes.object,
};

export default ManualAdjustmentModal;
