import {Chip, Close} from "@bphxd/ds-core-react";
import {Attach16, Upload32} from "@bphxd/ds-core-react/lib/icons";
import Form from "modules/common/FormNew";
import PropTypes from "prop-types";
import {useEffect, useState} from "react";
import {useDropzone} from "react-dropzone";
import {useParams} from "react-router-dom";
import {
  Button,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import {checkFileTooLarge, checkFileType} from "utils/helpers/checkData";
import "./index.css";

const UploadDocuments = ({
  showUploadModal,
  setUploadModal,
  setUploadFile,
  setShowPreview,
  handleUpload,
  divisionData,
  setSelectedDivision,
  selectedDivision,
}) => {
  const {country: navCountry} = useParams();
  const [acceptedFiles, setAcceptedFiles] = useState([]);
  const filesType = "pdf";

  const maxSize = 52428800;

  const onDrop = (acceptedFilesUpload) => {
    setAcceptedFiles([...acceptedFilesUpload]);
  };

  const {fileRejections, getRootProps, getInputProps} = useDropzone({
    accept: checkFileType(filesType),
    maxFiles: 0,
    maxSize,
    multiple: false,
    onDrop,
  });

  const file = acceptedFiles[0];

  const handleRemove = (id) => {
    const newFiles = [...acceptedFiles];
    newFiles.splice(id, 1);
    setAcceptedFiles(newFiles);
  };

  const files = acceptedFiles.map((item, index) => (
    <Chip
      style={{width: "100%", height: "27px"}}
      data-test="file"
      icon={Attach16}
      color="neutral"
      id={index}
      label={item.path}
      key={`${index} ${item}`}
      onRemove={handleRemove}
    />
  ));

  useEffect(() => {
    if (acceptedFiles && acceptedFiles.length > 0) {
      setUploadFile(acceptedFiles[0]);
    }
  }, [acceptedFiles, setUploadFile]);

  const isFileTooLarge = checkFileTooLarge(fileRejections, maxSize);

  return (
    <div>
      <Modal
        isOpen={showUploadModal}
        className="modal-dialog-centered"
        style={{width: "344px"}}
      >
        <ModalHeader
          className="!border-b-[1px] !border-gray-200 text-lg"
          close={
            <Close
              onClick={() => {
                setUploadModal(false);
              }}
            />
          }
        >
          Add document
        </ModalHeader>
        <ModalBody className="pt-0">
          <Form id="upload-form" onSubmit={() => handleUpload()}>
            <div className="mt-3 w-full flex flex-col gap-5">
              <span className="mt-3 text-[13px] text-[#111111d7] leading-[18px] tracking-[0.25px] font-light">
                To ensure that documents are tagged correctly please specify the
                division.
              </span>

              <FormGroup>
                <Label for="division" className="fw-normal text-sm">
                  Division
                </Label>
                <Input
                  className="!text-[#111111a3] !text-[13px]"
                  type="select"
                  id="division"
                  value={selectedDivision}
                  onChange={(e) => setSelectedDivision(e.target.value)}
                >
                  <option value="">Please select a division</option>
                  {divisionData
                    ?.filter(
                      (div) => div?.divisionCode?.toLowerCase() !== "global",
                    )
                    ?.map((division) => (
                      <option
                        key={division.divisionId}
                        value={division.divisionId}
                      >
                        {division.divisionCode}
                      </option>
                    ))}
                </Input>
              </FormGroup>
            </div>
            {files.length === 0 && (
              <div className="mt-6">
                <div {...getRootProps({className: "dropzone w-full mt-1"})}>
                  <input
                    data-test="document-uploader"
                    id="documents"
                    {...getInputProps()}
                  />
                  <div className="flex flex-col items-center justify-center px-2 py-1 h-[125px] mb-[12px] dashed-border">
                    <div className="flex flex-row w-full items-center justify-center my-[12px]">
                      <Upload32 color="#111111a3" />
                    </div>
                    <div className="flex flex-row w-full items-center justify-center">
                      <p className="text-[13px] text-[#111111a3] mb-[12px]">
                        Drag and drop or <u>choose file to upload</u>
                      </p>
                    </div>
                    <div className="flex flex-row w-full items-center justify-center mb-[12px]">
                      <label
                        data-test="file-type-label"
                        className="text-[12px] text-[#111111a3] font-light"
                        htmlFor="documents"
                      >
                        File supported: PDF
                      </label>
                    </div>
                  </div>
                </div>
                {isFileTooLarge && (
                  <p className="block text-red-500">File is larger than 50MB</p>
                )}
              </div>
            )}
          </Form>
          {files.length > 0 && (
            <div className="flex flex-col flex-start mt-[30px] mb-[10px]">
              {files}
            </div>
          )}
        </ModalBody>
        {files.length > 0 && selectedDivision && (
          <ModalFooter className="p-0 d-block">
            <div className="row g-0 m-0 modal-footer-row">
              <div className="col-6 d-grid">
                <Button
                  color="darker-tertiary"
                  size="lg"
                  className="border-0 rounded-0 py-4 opacity-80 opacity-100-hover bg-transparent"
                  onClick={() => {
                    setUploadModal(false);
                  }}
                >
                  Cancel
                </Button>
              </div>
              <div className="col-6 d-grid">
                <Button
                  color="darker-tertiary"
                  size="lg"
                  className="border-0 rounded-0 py-4 bg-transparent text-default"
                  onClick={() => {
                    setShowPreview(true);
                    setUploadModal(false);
                  }}
                  disabled={!file && file.type !== "application/pdf"}
                >
                  Upload document
                </Button>
              </div>
            </div>
          </ModalFooter>
        )}
      </Modal>
    </div>
  );
};

UploadDocuments.propTypes = {
  showUploadModal: PropTypes.bool,
  setUploadModal: PropTypes.func,
  setUploadFile: PropTypes.func,
  setShowPreview: PropTypes.func,
  handleUpload: PropTypes.func,
  divisionData: PropTypes.array,
  setSelectedDivision: PropTypes.func,
  selectedDivision: PropTypes.string,
};

export default UploadDocuments;
