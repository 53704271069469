import {gql} from "@apollo/client";

export const MANDATE_ALLOCATE_OUTGOING = gql`
  query bioLcCustomerMandateOutgoingApi(
    $siteReferenceId: String
    $divisionId: String
    $mbLocationGroupId: String
    $outMovementType: String
    $period: String
  ) {
    bioLcCustomerMandateOutgoingApi(
      event: {
        divisionId: $divisionId
        siteReferenceId: $siteReferenceId
        mbLocationGroupId: $mbLocationGroupId
        outMovementType: $outMovementType
        period: $period
      }
    ) {
      error
      statusCode
      documents {
        uptoDate
        outMovementType
        recipientName
        accruedQuantity
        totalFuelQuantity
      }
    }
  }
`;

export default MANDATE_ALLOCATE_OUTGOING;
