import {useQuery} from "@apollo/client";
import {useIsAuthenticated, useMsal} from "@azure/msal-react";
import {GET_MODULE_MAPPING} from "graphql/global/getModuleMapping";
import {useSetFeatureFlag} from "modules/common/FeatureFlag/FeatureFlagContext";
import PropTypes from "prop-types";
import React, {useEffect, useMemo, useState} from "react";
import {AppSettingDetailContext} from "./context";

export const AppSetting = ({children}) => {
  const {data: moduleMappingData} = useQuery(GET_MODULE_MAPPING);

  const {accounts} = useMsal();
  let isAuthenticated = false;
  isAuthenticated = useIsAuthenticated();

  const [featureFlags, setFeatureFlags] = useState();
  const setFeatureFlag = useSetFeatureFlag();

  useEffect(() => {
    if (featureFlags) {
      featureFlags.forEach((flag) => {
        setFeatureFlag(flag.key, flag.value);
      });
    }
  }, [featureFlags, setFeatureFlag]);

  const [appSetting, setAppSetting] = useState({
    featureMappingData: null, // contains all the data from module catalogue table
    currentSite: "GLOBAL", // keeps the current selected site NOTE: not is use now
    currentCountry: "GLOBAL", // keeps the current selected country
    currentModule: "", // keeps the current selected module
    currentModuleTitle: "", // keeps the current selected module title
    currentCountryMappingData: null, // keeps the current selected country feature mapping data
    siteData: [], // keeps master site details
    divisionData: [], // keeps master division details
    currentCountryCode: "GO", // keeps the current selected country
    subscriptionDataGenerateCertificate: {
      certificateOutboundEuId: null,
      message: null,
      redirectUrl: null,
    }, // this is used for generate certificate subscription
    featureFlags: {
      showGlobalConfigCenter: false,
      isSuperUser: false,
    }, // keeps the feature flags
  });

  useEffect(() => {
    const moduleMappingDataItems =
      moduleMappingData?.bioLcGlobNaviGetModuleCatalogue;
    const countryFeatureData =
      moduleMappingData?.bioLcGlobNaviGetModuleCatalogue?.find(
        (item) => item.countryName === "GLOBAL",
      );
    if (moduleMappingDataItems) {
      setAppSetting((appSetting) => {
        return {
          ...appSetting,
          currentCountryMappingData: countryFeatureData,
          featureMappingData:
            moduleMappingData?.bioLcGlobNaviGetModuleCatalogue,
          siteData:
            moduleMappingData?.bioLcGetSiteReferenceDetails?.siteReferenceData,
          divisionData:
            moduleMappingData?.bioLcGetDivisionDetails?.divisionData,
        };
      });
    }
  }, [
    moduleMappingData?.bioLcGlobNaviGetModuleCatalogue,
    moduleMappingData?.bioLcGetSiteReferenceDetails?.siteReferenceData,
    moduleMappingData?.bioLcGetDivisionDetails?.divisionData,
  ]);

  useEffect(() => {
    const controller = new AbortController();
    if (isAuthenticated && process.env.REACT_APP_FEATURE_HUB_API_KEY) {
      Promise.any([
        fetch(
          `https://edge.featurehub.bpglobal.com/features?apiKey=${process.env.REACT_APP_FEATURE_HUB_API_KEY}`,
          {signal: controller.signal},
        ),
        fetch(
          `https://edge.featurehub.bpweb.bp.com/features?apiKey=${process.env.REACT_APP_FEATURE_HUB_API_KEY}`,
          {signal: controller.signal},
        ),
      ])
        .then((res) => {
          res.json().then((result) => {
            try {
              const configCenterUserList =
                result &&
                result[0]?.features
                  ?.find(
                    (item) => item.key === "config_centre_show_global_config",
                  )
                  ?.strategies[0]?.attributes?.find(
                    (item) => item?.fieldName === "userkey",
                  )?.values;

              const superUserList =
                result &&
                result[0]?.features
                  ?.find((item) => item.key === "common_is_super_user")
                  ?.strategies[0]?.attributes?.find(
                    (item) => item?.fieldName === "userkey",
                  )?.values;

              if (
                configCenterUserList &&
                configCenterUserList.includes(
                  accounts[0]?.username.toLowerCase(),
                )
              ) {
                setAppSetting((appSetting) => {
                  return {
                    ...appSetting,
                    featureFlags: {
                      ...appSetting.featureFlags,
                      showGlobalConfigCenter: true,
                    },
                  };
                });
              }
              if (
                superUserList &&
                superUserList.includes(accounts[0]?.username.toLowerCase())
              ) {
                setAppSetting((appSetting) => {
                  return {
                    ...appSetting,
                    featureFlags: {
                      ...appSetting.featureFlags,
                      isSuperUser: true,
                    },
                  };
                });
              }
              if (result) {
                setFeatureFlags(result[0]?.features);
              }
            } catch (err) {
              console.error(err, "Error fetching feature flags");
            }
          });
        })
        .catch((err) => {
          console.error(err, "Error fetching feature flags");
        });
    }
    return () => {
      controller.abort("This is an stale request");
    };
  }, [isAuthenticated, accounts]);

  const appSettingContextValue = useMemo(() => {
    return {appSetting, setAppSetting};
  }, [appSetting, setAppSetting]);

  return (
    <AppSettingDetailContext.Provider value={appSettingContextValue}>
      {children}
    </AppSettingDetailContext.Provider>
  );
};

AppSetting.propTypes = {
  children: PropTypes.node.isRequired,
};

export default AppSetting;
