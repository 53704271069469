import {useQuery} from "@apollo/client";
import {Bioverse as bioverseConfig} from "content/navigations";
import {LOCATION_DETAILS} from "graphql/config-center/LocationDetails";
import {uniqBy} from "lodash";
import {SectionType} from "modules/common";
import Feature from "modules/common/FeatureFlag/Feature";
import {useFeatureFlag} from "modules/common/FeatureFlag/FeatureFlagContext";
import FEATURE_FLAGS from "modules/common/FeatureFlag/featureFlagConstants";
import {useAppSetting} from "providers/appSetting";
import {useMemo} from "react";
import {Helmet} from "react-helmet";
import {Link} from "react-router-dom";
import {Breadcrumb, BreadcrumbItem} from "reactstrap";
import capitalizeFirstLetter from "../../utils/helpers/stringHelpers";

const Dashboard = () => {
  const {appSetting, setAppSetting} = useAppSetting();
  const {data: locationData, loading: locationLoading} =
    useQuery(LOCATION_DETAILS);

  const uniqueLocations = uniqBy(
    locationData?.bioLcCoproConfigCenterLocationDetails?.Records ?? [],
    "location_code",
  );
  const commonNavigationRedesign = useFeatureFlag(
    FEATURE_FLAGS.COMMON_NAVIGATION_REDESIGN,
  );
  const bioverseMenu = useMemo(
    () =>
      bioverseConfig(
        appSetting?.currentCountry,
        uniqueLocations,
        locationLoading,
        appSetting?.featureFlags?.showGlobalConfigCenter,
      ),
    [
      appSetting?.currentCountry,
      uniqueLocations,
      locationLoading,
      appSetting?.featureFlags?.showGlobalConfigCenter,
    ],
  );

  const [featureMenuItems, activitiesMenuItems] = useMemo(() => {
    const activityModulesList = ["groundFuels", "coProcessing", "saf"];
    const userFeatures =
      appSetting?.currentCountryMappingData &&
      Object.entries(appSetting?.currentCountryMappingData)
        ?.filter(([, value]) => {
          return value === true;
        })
        ?.map((item) => item && item[0]);

    const bioverseMenuItems = bioverseMenu?.filter(
      (item) => item.isVisible && userFeatures?.includes(item?.module),
    );

    const featureMenuItems = bioverseMenuItems?.filter(
      (item) => !activityModulesList.includes(item?.module),
    );
    const activitiesMenuItems = bioverseMenuItems?.filter((item) =>
      activityModulesList.includes(item?.module),
    );
    if (!commonNavigationRedesign) return [bioverseMenuItems, {}];
    return [featureMenuItems, activitiesMenuItems];
  }, [
    appSetting?.currentCountryMappingData,
    bioverseMenu,
    commonNavigationRedesign,
  ]);

  const capitalizedcountryName = capitalizeFirstLetter(
    appSetting?.currentCountry,
  );

  const breadcrumbItems = [
    {text: "BioVerse", link: "/"}, // Takes us back to 'Global' context
    {text: `${capitalizedcountryName}`, link: "/"},
  ];

  return (
    <>
      <Helmet>
        <title>BioVerse - Biofuels Value Chain</title>
      </Helmet>
      <div className="min-h-screen px-6 lg:px-10">
        {capitalizedcountryName !== "Global" && (
          <Breadcrumb className="px-8 pt-5  ">
            {breadcrumbItems.map((item, index) => (
              <BreadcrumbItem
                key={index}
                active={index === breadcrumbItems.length - 1}
              >
                {item.text === "BioVerse" ? (
                  <Link
                    to="/"
                    data-test="home-button"
                    onClick={() => {
                      const countryFeatureData =
                        appSetting?.featureMappingData?.find(
                          (item) => item.countryName === "GLOBAL",
                        );

                      setAppSetting({
                        ...appSetting,
                        currentCountry: "GLOBAL",
                        currentCountryMappingData: countryFeatureData,
                        currentModule: "",
                        currentModuleTitle: "",
                      });
                    }}
                  >
                    {item.text}
                  </Link>
                ) : index === breadcrumbItems.length - 1 ? (
                  <span>{item.text}</span>
                ) : (
                  <Link to={item.link}>{item.text}</Link>
                )}
              </BreadcrumbItem>
            ))}
          </Breadcrumb>
        )}
        <main className="p-6 lg:px-10">
          <SectionType items={featureMenuItems} isDashboard />
          <Feature flag={FEATURE_FLAGS.COMMON_NAVIGATION_REDESIGN}>
            {activitiesMenuItems.length > 0 && (
              <SectionType
                items={activitiesMenuItems}
                isDashboard
                isFirstSection={false}
                sectionHeader="Activities"
              />
            )}
          </Feature>
        </main>
      </div>
    </>
  );
};

export default Dashboard;
