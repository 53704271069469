import {gql} from "@apollo/client";
import {client} from "providers/Apollo";

export const ALLOCATION_MANDATE_MUTATION = gql`
  mutation bioLcMandateAllocation($event: bioLcMandateAllocationRequest!) {
    bioLcMandateAllocation(event: $event) {
      statusCode
      error
    }
  }
`;

export const AllocationMandateAPI = (data) =>
  client.mutate({
    mutation: ALLOCATION_MANDATE_MUTATION,
    variables: {
      ...data,
    },
    refetchQueries: [
      "bioLcCustomerMandateIncomingApi",
      "bioLcCustomerMandateOutgoingApi",
    ],
  });

export default {ALLOCATION_MANDATE_MUTATION, AllocationMandateAPI};
