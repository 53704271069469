import {Info24} from "@bphxd/ds-core-react/lib/icons";
import {ALL_MANDATES} from "constants/allocation";
import {NUMBER_OF_DECIMALS} from "constants/common";
import moment from "moment";
import {UncontrolledTooltip} from "reactstrap";
import {
  formatNumberIfExists,
  getFormattedNumberWithCheck,
} from "utils/numberUtil";

function parseDeliveryDate(deliveryDate) {
  const dateArr = deliveryDate?.split("-");
  const year = dateArr[0];
  const month = dateArr[1];
  const day = 1;
  const date = new Date(year, month - 1, day);
  return date;
}

const getOutgoingColumnsGfTrsSpain = (
  dateFormat,
  decimalFormat,
  country,
  siteReferenceId,
  divisionId,
) => [
  {
    header: "Outgoing",
    columns: [
      {
        header: "Dispatch date",
        accessorKey: "deliveryDate",
        key: "deliveryDate",
        visible: true,
        meta: {
          filterVariant: "daterange",
        },
        filterFn: "dateRangeFilterFn",
        accessorFn: (row) => {
          return parseDeliveryDate(row?.deliveryDate);
        },
        cell: ({cell}) => {
          const month = moment(cell.getValue()).format("MMMM");
          const year = moment(cell.getValue()).format("YYYY");
          return cell.getValue() && `${month} ${year}`;
        },
      },
      {
        header: "Outbound type",
        accessorKey: "outboundType",
        key: "outboundType",
        visible: true,
        meta: {
          filterVariant: "select",
        },
      },
      {
        header: "Recipient",
        accessorKey: "recipient",
        key: "recipient",
        visible: true,
        meta: {
          filterVariant: "select",
        },
        cell: ({row}) => {
          const tooltipId = `recipient${row?.id}`;
          const sustainableQtyM3 = row?.original?.sustainableQtyM3;
          const recipient = row?.original?.recipient;
          const outboundType = row?.original?.outboundType;
          const isMandate = ALL_MANDATES.includes(outboundType);
          const today = moment(); // Get today's date
          const month = today.format("MMMM");
          const year = today.format("YYYY");
          const displayMonth =
            month === "January"
              ? month
              : today.subtract(1, "month").format("MMMM");
          return (
            <>
              <div id={tooltipId} className="flex">
                {recipient}
                {isMandate && <Info24 className="ml-[5px] text-black" />}
              </div>
              {isMandate && (
                <UncontrolledTooltip
                  placement="bottom"
                  target={tooltipId}
                  style={{borderRadius: "5px"}}
                >
                  <div className="text-[14px] mt-[10px] mb-[12px] text-left">
                    {recipient + " obligation"}
                  </div>
                  <div className="text-[13px] text-[#ffffffa3] text-left">
                    Accrued total remaining to {displayMonth} {year} is{" "}
                    {sustainableQtyM3} m3
                  </div>
                </UncontrolledTooltip>
              )}
            </>
          );
        },
      },
      {
        header: "Ship to location",
        accessorKey: "shipToLocation",
        key: "shipToLocation",
        visible: true,
        meta: {
          filterVariant: "select",
        },
      },
      {
        header: "Dispatch location",
        accessorKey: "shipToLocation",
        key: "shipToLocation",
        visible: true,
        meta: {
          filterVariant: "select",
        },
      },
      {
        header: "Fuel quantity m³",
        accessorKey: "fuelQtyM3",
        key: "fuelQtyM3",
        visible: true,
        disabled: true,
        filterFn: "numberFilterFn",
        dataType: "number",
        cell: ({cell}) => {
          return getFormattedNumberWithCheck(
            cell.getValue(),
            NUMBER_OF_DECIMALS,
          );
        },
      },
      {
        header: "Fuel quantity MT",
        accessorKey: "fuelQuantityMT",
        key: "fuelQuantityMT",
        visible: false,
        filterFn: "numberFilterFn",
        dataType: "number",
        cell: ({cell}) => {
          return getFormattedNumberWithCheck(
            cell.getValue(),
            NUMBER_OF_DECIMALS,
          );
        },
      },
      {
        header: "Product",
        accessorKey: "productOutgoing",
        key: "productOutgoing",
        visible: false,
        meta: {
          filterVariant: "select",
        },
      },
      {
        header: "Transport system",
        accessorKey: "transportSystemOutgoing",
        key: "transportSystemOutgoing",
        visible: false,
        meta: {
          filterVariant: "select",
        },
      },
      {
        header: "Vessel name",
        accessorKey: "vesselNameOutgoing",
        key: "vesselNameOutgoing",
        visible: false,
        meta: {
          filterVariant: "select",
        },
      },
      {
        header: "GHG total gCO2eq/MJ",
        accessorKey: "ghgTotalMjOutgoing",
        key: "ghgTotalMjOutgoing",
        visible: false,
        meta: {
          filterVariant: "range",
        },
        dataType: "number",
        accessorFn: (row) => {
          return row?.ghgTotalMjOutgoing;
        },
        cell: ({cell}) => {
          return getFormattedNumberWithCheck(
            cell.getValue(),
            NUMBER_OF_DECIMALS,
          );
        },
      },
      {
        header: "GHG total kgCO2eq",
        accessorKey: "ghgTotalMjOutgoing",
        key: "ghgTotalMjOutgoing",
        visible: false,
        meta: {
          filterVariant: "range",
        },
        dataType: "number",
        accessorFn: (row) => {
          return row?.ghgTotalMjOutgoing;
        },
        cell: ({cell}) => {
          return getFormattedNumberWithCheck(
            cell.getValue(),
            NUMBER_OF_DECIMALS,
          );
        },
      },
      {
        header: "GHG savings %",
        accessorKey: "ghgSavingsPercentOutgoing",
        key: "ghgSavingsPercentOutgoing",
        visible: false,
        meta: {
          filterVariant: "range",
        },
        dataType: "number",
        accessorFn: (row) => {
          return parseFloat(row?.ghgSavingsPercentOutgoing);
        },
        cell: ({cell}) => {
          return (
            cell.getValue() &&
            formatNumberIfExists(cell.getValue(), decimalFormat, 3)
          );
        },
      },
    ],
  },
];

export default getOutgoingColumnsGfTrsSpain;
