import {DIV_CODE_COPRO} from "constants/divisionDetails";
import getColumnsLtpCopro from "./getColumnsLtpCopro";
import getColumnsLtpSaf from "./getColumnsLtpSaf";

const getColumns = (
  division,
  dateFormat,
  site_reference_id,
  division_id,
  preSelectedContractNumber,
  preSelectedBalanceGroup,
  preSelectedLocationGroup,
  preSelectedDocNumberSplit,
  preSelectedSdNumber,
) => {
  switch (division) {
    case DIV_CODE_COPRO:
      return getColumnsLtpCopro(
        dateFormat,
        site_reference_id,
        division_id,
        preSelectedContractNumber,
        preSelectedBalanceGroup,
        preSelectedLocationGroup,
        preSelectedDocNumberSplit,
        preSelectedSdNumber,
      );
    default:
      return getColumnsLtpSaf(
        dateFormat,
        site_reference_id,
        division_id,
        preSelectedContractNumber,
        preSelectedBalanceGroup,
        preSelectedLocationGroup,
        preSelectedDocNumberSplit,
        preSelectedSdNumber,
      );
  }
};

export default getColumns;
