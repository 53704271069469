import React from "react";
import PropTypes from "prop-types";
import {
  useReactTable,
  createColumnHelper,
  getCoreRowModel,
  flexRender,
} from "@tanstack/react-table";
import {BulkAdjustmentShipmentHeaders} from "modules/co-processing/constants/bulkAdjustment";
import buildAdjustmentColumns from "modules/co-processing/helpers/buildAdjustmentColumns";

const BulkAdjustmentShipmentsTable = ({shipments, onVolumeChange, onError}) => {
  const columnHelper = createColumnHelper();

  const columns = buildAdjustmentColumns(
    columnHelper,
    BulkAdjustmentShipmentHeaders,
    onVolumeChange,
    onError,
  );

  const table = useReactTable({
    data: shipments,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  return (
    <div
      data-test="bulk-adjustment-shipments-table"
      className="bulk-adjust-shipments-table"
    >
      <table style={{width: "100%"}}>
        <thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <th key={header.id}>
                  {header.isPlaceholder
                    ? null
                    : flexRender(
                        header.column.columnDef.header,
                        header.getContext(),
                      )}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody>
          {table.getRowModel().rows.map((row) => {
            const isCommitted = row?.original?.shipment_status === "Committed";
            return (
              <tr
                key={row.id}
                style={{
                  backgroundColor: isCommitted ? "#f2f2f2" : "transparent",
                }}
              >
                {row.getVisibleCells().map((cell) => (
                  <td key={cell.id}>
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </td>
                ))}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

BulkAdjustmentShipmentsTable.propTypes = {
  shipments: PropTypes.array.isRequired,
  onVolumeChange: PropTypes.func,
  onError: PropTypes.func,
};

export default BulkAdjustmentShipmentsTable;
