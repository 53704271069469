import {useQuery} from "@apollo/client";
import {GET_Materials} from "graphql/MassBalance/Actions/AllocateSale";
import PropTypes from "prop-types";
import {useEffect} from "react";
import {useFormContext} from "react-hook-form";
import {FormFeedback, FormGroup, Input, Label} from "reactstrap";

const GeneralInformation = ({countries, data}) => {
  const {
    register,
    formState: {errors},
    setValue,
  } = useFormContext();

  const computeProps = (name, options) => {
    const {ref, ...props} = register(name, options);
    return {innerRef: ref, ...props};
  };

  const bioFeedstockList = [
    "Bio-circular",
    "Circular",
    "Bio",
    "Renewable-energy-derived",
  ];

  const {data: materialMasterData} = useQuery(GET_Materials, {
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
    variables: {
      divisionId: data?.divisionId,
    },
  });

  useEffect(() => {
    setValue("productType", data?.productType);
    if (
      materialMasterData?.bioLcGetMaterialDataApi?.rawMaterialNames?.length > 0
    ) {
      setValue(
        "bioFeedstock",
        data?.rowData?.original?.inboundRecord?.rawMaterial,
      );
    }
    setValue(
      "rawMaterialOriginCountryName",
      data?.rowData?.original?.inboundRecord?.countryOfOrigin,
    );
    setValue("feedstockQty", data?.quantity);
    setValue("countryOfProduction", "SPAIN");

    if (data?.uom === "MT") {
      setValue("uomMetricTons", true);
    }
    if (data?.uom === "M3") {
      setValue("uomm3", true);
    }
  }, [setValue, data, materialMasterData]);

  return (
    <div className="flex flex-col gap-4">
      <FormGroup>
        <Label for="productType" className="fw-light">
          Type of product (*)
        </Label>

        <Input
          {...computeProps("productType")}
          type="text"
          id="productType"
          data-test="productType"
          invalid={!!errors.productType}
          maxLength={100}
          disabled
        />

        {errors.productType && (
          <FormFeedback>{errors.productType.message}</FormFeedback>
        )}
      </FormGroup>
      <FormGroup>
        <Label for="rawMaterialType" className="fw-light">
          Type of category
        </Label>

        <Input
          {...computeProps("rawMaterialType")}
          type="select"
          id="rawMaterialType"
          data-test="rawMaterialType"
          invalid={!!errors.rawMaterialType}
        >
          {bioFeedstockList.map((feedstock) => (
            <option key={feedstock} value={feedstock}>
              {feedstock}
            </option>
          ))}
        </Input>

        {errors.rawMaterialType && (
          <FormFeedback>{errors.rawMaterialType.message}</FormFeedback>
        )}
      </FormGroup>

      <FormGroup>
        <Label for="bioFeedstock" className="fw-light">
          Bio feedstock (**)
        </Label>
        <Input
          type="select"
          id="bioFeedstock"
          data-test="bioFeedstock"
          {...computeProps("bioFeedstock", {
            required: "Please select bio feedstock",
          })}
          invalid={!!errors.bioFeedstock}
          disabled
        >
          {materialMasterData?.bioLcGetMaterialDataApi?.rawMaterialNames?.map(
            (material) => (
              <option
                key={material.rawMaterialName}
                value={material.rawMaterialName}
              >
                {material.rawMaterialName}
              </option>
            ),
          )}
        </Input>

        {errors.bioFeedstock && (
          <FormFeedback>{errors.bioFeedstock.message}</FormFeedback>
        )}
      </FormGroup>
      <FormGroup>
        <Label for="rawMaterialOriginCountryName" className="fw-light">
          Country of origin of the raw material
        </Label>

        <Input
          {...computeProps("rawMaterialOriginCountryName")}
          type="text"
          id="rawMaterialOriginCountryName"
          data-test="rawMaterialOriginCountryName"
          invalid={!!errors.rawMaterialOriginCountryName}
          disabled
        />

        {errors.rawMaterialOriginCountryName && (
          <FormFeedback>
            {errors.rawMaterialOriginCountryName.message}
          </FormFeedback>
        )}
      </FormGroup>
      <FormGroup>
        <Label for="countryOfProduction" className="fw-light">
          Country of production
        </Label>

        <Input
          type="select"
          id="countryOfProduction"
          data-test="countryOfProduction"
          {...computeProps("countryOfProduction", {
            required: "Please select country of production",
          })}
          invalid={!!errors.countryOfProduction}
        >
          {countries.map((country) => (
            <option key={country.code} value={country.name}>
              {country.name}
            </option>
          ))}
        </Input>

        {errors.countryOfProduction && (
          <FormFeedback>{errors.countryOfProduction.message}</FormFeedback>
        )}
      </FormGroup>
      <FormGroup>
        <Label for="feedstockQty" className="fw-light">
          Quantity (t)
        </Label>

        <Input
          {...computeProps("feedstockQty")}
          type="number"
          id="feedstockQty"
          data-test="feedstockQty"
          invalid={!!errors.feedstockQty}
          disabled
        />

        {errors.feedstockQty && (
          <FormFeedback>{errors.feedstockQty.message}</FormFeedback>
        )}
      </FormGroup>

      <FormGroup>
        <Label for="feedstockQtyUom" className="fw-light">
          Unit of Measure
        </Label>
        <div>
          <FormGroup
            check
            inline
            className="form-check-alt form-check-lg !mb-0"
          >
            <Input
              {...computeProps("uomMetricTons")}
              type="checkbox"
              id="uomMetricTons"
              data-test="uomMetricTons"
              disabled
            />

            <Label check for="uomMetricTons">
              Metric tons
            </Label>
          </FormGroup>

          <FormGroup
            check
            inline
            className="form-check-alt form-check-lg !mb-0"
          >
            <Input
              {...computeProps("uomm3")}
              type="checkbox"
              id="uomm3"
              data-test="uomm3"
              disabled
            />

            <Label check for="uomm3">
              m<sup>3</sup>
            </Label>
          </FormGroup>
        </div>
      </FormGroup>
    </div>
  );
};
GeneralInformation.propTypes = {
  countries: PropTypes.arrayOf(PropTypes.object).isRequired,
  data: PropTypes.object,
};
export default GeneralInformation;
