export const CREATE_SHIPMENT = "Create shipment";
export const INPUT_METRIC = "bbl";
export const ITEMS_PER_PAGE = 10;
export const MAX_BBL_VOLUME = 400000;
export const VOLUME_PER_HOUR = 1500;
export const CLIPBOARD_TOOLTIP_TIMER = 2000;
export const COPIED = "Copied!";
export const FORTY_FIVE_DAY_RANGE = 45;
export const NON_RENEWABLE = "Non-Renewable";
export const DAYS = "DAYS";

export const TITLES = {
  SHIPMENTS: "Shipments",
  ACTIVE_BATCHES: "Active batches",
  BULK_ALLOCATION_RESET: "Bulk allocation reset",
};

export const ACTIVE_BATCH_LABELS = {
  TANK_NO: "Tank no",
  TOTAL_VOLUME: "Total volume",
  REMAINING_VOLUME: "Remaining volume",
  ACTUAL_ENDING_INV: "Ending inventory",
  SHIPMENT: "Shipment",
  SHIPMENT_VOLUME: "Shipment volume",
  SHIPMENT_ID: "Shipment ID",
  TANK_CERTIFIED_DATE: "Tank certified time",
  INVENTORY_TRANSFER: "Inventory transfer",
};

export const ACTIVE_BATCH_PLACEHOLDERS = {
  INVENTORY: "Enter ending inventory",
  ID: "Enter shipment ID",
};

export const DATA_TABS = {
  PI_DATA: "Pi data",
  ORION_DATA: "Orion data",
};

export const ACTIVE_BATCH_KEYS = {
  TANK_NO: "tank_number",
  TOTAL_VOLUME: "total_volume",
  REMAINING_VOLUME: "remaining_volume",
  ACTUAL_ENDING_INV: "ending_inventory",
  USER_INPUT__ENDING_INV: "estimated_ending_inventory",
  MISSING_C14: "missing_c14",
  SHIPMENT_ID: "shipment_id",
  TANK_CERTIFIED_DATE: "tank_certified_date",
};

export const ACTIVE_BATCH_FIELDS = [
  {
    label: ACTIVE_BATCH_LABELS.TANK_NO,
    key: ACTIVE_BATCH_KEYS.TANK_NO,
    type: "text",
  },
  {
    label: ACTIVE_BATCH_LABELS.TOTAL_VOLUME,
    key: ACTIVE_BATCH_KEYS.TOTAL_VOLUME,
    type: "text",
    metric: "bbl",
  },
  {
    label: ACTIVE_BATCH_LABELS.REMAINING_VOLUME,
    key: ACTIVE_BATCH_KEYS.REMAINING_VOLUME,
    type: "text",
    metric: "bbl",
  },
  {
    label: ACTIVE_BATCH_LABELS.TANK_CERTIFIED_DATE,
    key: ACTIVE_BATCH_KEYS.TANK_CERTIFIED_DATE,
    type: "text",
  },
  {
    label: ACTIVE_BATCH_LABELS.ACTUAL_ENDING_INV,
    key: ACTIVE_BATCH_KEYS.ACTUAL_ENDING_INV,
    type: "input",
    placeholder: ACTIVE_BATCH_PLACEHOLDERS.INVENTORY,
    metric: "bbl",
    max: "remaining_volume",
  },
];

export const ACTION_OPTIONS = {
  VIEW_DETAILS: "View details",
  ALLOCATE_BOLS: "Allocate BOLs",
  SPLIT_SHIPMENT: "Split shipment",
  DELETE_SHIPMENT: "Delete shipment",
  BULK_ALLOCATION_RESET: "Bulk allocation reset",
  ARCHIVE_SHIPMENT: "Archive shipment",
  RECALL_SHIPMENT: "Recall shipment",
  DISCARD_SHIPMENT: "Discard shipment",
};

export const DELETE_DISCARDED_POPOVER = {
  HEADER: "Delete discarded shipment",
  MESSAGE: "Are you sure you want to permanently delete this shipment?",
  YES: "Yes, delete",
  CANCEL: "Cancel",
};

export const REMOVE_POPOVER = {
  HEADER: "Remove all parts",
  MESSAGE: "Are you sure you want to remove the part(s)?",
  YES: "Yes, remove",
  CANCEL: "Cancel",
};

export const SHIPMENT_TYPE = {
  PIPELINE: "PIPELINE",
  VESSEL: "VESSEL",
  BACK_TO_UNIT: "BACK_TO_UNIT",
  TRUCK_RACK: "TRUCK_RACK",
  INVENTORY_TRANSFER: "INVENTORY_TRANSFER",
};

export const SPLIT_SUB_TYPE = {
  EMPTY: "",
  LINE_SET: "Line set",
};

export const SPLIT_LABELS = {
  SPLIT_SUB_TYPE: "Sub-type",
  ACTUAL_VOLUME: "Actual shipment volume",
  RINS_QUALIFIED: "RINS qualified",
  RENEWABLE_SPLIT_FLAG: "Renewable",
  ADDITIONAL_INFO: "Notes",
};

export const SHIPMENTS_LABELS = {
  SHIPMENT_TYPE: "Shipment type",
  SHIPMENT_DATE: "Shipment date",
  LOAD_START_DATE: "Load start time",
  LOAD_END_DATE: "Load end time",
  PARCEL_GRADE: "Parcel grade",
  BATCH_ID: "Batch ID",
  TOTAL_ESTIMATED_SHIP_VOL: "Total estimated shipment volume",
  TOTAL_ACTUAL_SHIP_VOL: "Total actual shipment volume",
  TOTAL_ALLOCATED_VOL: "Total volume allocated",
  ALLOCATED_VOL: "Volume allocated",
  SHIPMENT_VOL: "Shipment volume",
  VOLUME: "Volume",
  SOURCE_BATCH_ID: "Source batch ID",
  DESTINATION_BATCH_ID: "Destination batch ID",
};

export const SHIPMENT_DROPDOWN_LABELS = {
  RECALL_STATUS: "Recall status",
};

export const SHIPMENT_DATE_MODAL_LABELS = {
  HEADER: "Shipment Date Times",
  START_TIME: "Shipment Start Time",
  END_TIME: "Shipment End Time",
};

export const METRICS = {
  BARRELS: "barrels",
};

export const RENEWABLE_STATUS = {
  TRUE: "True",
  FALSE: "False",
  PARTIAL: "Partial",
};

export const RENEWABLE_STATUS_LIST = [
  RENEWABLE_STATUS.TRUE,
  RENEWABLE_STATUS.PARTIAL,
  RENEWABLE_STATUS.FALSE,
];

export const RINS_SELECT_AN_OPTION = "Select an option";

export const RENEWABLE_SHIPMENT_LIST = ["vessel", "pipeline"];

export const SHIPMENT_FIELD_KEYS = {
  SHIPMENT_TYPE: "shipment_type",
  SHIPMENT_DATE: "shipment_date",
  PARCEL_GRADE: "parcel_grade",
  BATCH_ID: "batch_id",
  TOTAL_ESTIMATED_SHIP_VOL: "total_estimated_volume",
  TOTAL_ACTUAL_SHIP_VOL: "total_actual_volume",
  ACTUAL_SHIP_VOL: "actual_shipment_volume",
  TOTAL_ALLOCATED_VOL: "total_allocated_volume",
};

export const INVENTORY_TRANSFER_FIELDS = [
  SHIPMENTS_LABELS.SHIPMENT_TYPE,
  SHIPMENTS_LABELS.SHIPMENT_DATE,
  SHIPMENTS_LABELS.VOLUME,
  SHIPMENTS_LABELS.SOURCE_BATCH_ID,
  SHIPMENTS_LABELS.DESTINATION_BATCH_ID,
];

export const SHIPMENTS_FIELDS_LEFT_COLUMN = [
  {
    label: SHIPMENTS_LABELS.SHIPMENT_TYPE,
    key: SHIPMENT_FIELD_KEYS.SHIPMENT_TYPE,
    type: "formattedText",
  },
  {
    label: SHIPMENTS_LABELS.SHIPMENT_DATE,
    key: SHIPMENT_FIELD_KEYS.SHIPMENT_DATE,
    type: "date",
  },
  {
    label: SHIPMENTS_LABELS.PARCEL_GRADE,
    key: SHIPMENT_FIELD_KEYS.PARCEL_GRADE,
  },
  {
    label: SHIPMENTS_LABELS.BATCH_ID,
    key: SHIPMENT_FIELD_KEYS.BATCH_ID,
    type: "input",
    placeholder: "Enter batch ID",
  },
];

export const discardedHeaders = {
  days_to_delete: "Time until deletion",
  shipment_date: "Shipment date",
  shipment_type: "Shipment type",
  shipment_id: "Shipment ID",
  total_estimated_volume: "Total shipment volume",
  load_start_time: "Load start time",
  load_end_time: "Load end time",
  dropdown_icon: "",
};

export const DISCARD_DROPDOWN_OPTIONS = {
  RETURN_TO_ACTIVE: "Return to active status",
  PERMANENTLY_DELETE: "Permanently delete",
};

export const SHIPMENTS_FIELDS_RIGHT_COLUMN = [
  {
    label: SHIPMENTS_LABELS.TOTAL_ESTIMATED_SHIP_VOL,
    key: SHIPMENT_FIELD_KEYS.TOTAL_ESTIMATED_SHIP_VOL,
    type: "text",
    metric: "bbl",
  },
  {
    label: SHIPMENTS_LABELS.TOTAL_ACTUAL_SHIP_VOL,
    key: SHIPMENT_FIELD_KEYS.TOTAL_ACTUAL_SHIP_VOL,
    type: "input",
    placeholder: "Enter actual volume",
    metric: "bbl",
  },
  {
    label: SHIPMENTS_LABELS.ALLOCATED_VOL,
    key: SHIPMENT_FIELD_KEYS.TOTAL_ALLOCATED_VOL,
    type: "text",
    metric: "bbl",
  },
];

export const ALLOCATED_FIELD = {
  label: SHIPMENTS_LABELS.TOTAL_ALLOCATED_VOL,
  key: SHIPMENT_FIELD_KEYS.TOTAL_ALLOCATED_VOL,
  type: "text",
  metric: "bbl",
};

export const BOL_LABEL_TEXT = {
  ALLOCATED_VOL: "Volume allocated",
  ACTUAL_SHIPMENT_VOLUME: "Actual shipment volume",
  ESTIMATED_SHIPMENT_VOLUME: "Estimated shipment volume",
  SHIPMENT_DATE: "Shipment date",
  SHIPMENT_START_TIME: "Start time",
  SHIPMENT_END_TIME: "End time",
};

export const ENDING_INVENTORY_LIMIT = {
  UPPER: 100,
  LOWER: 100,
};

export const BUTTONS = {
  SAVE: "Save",
  ACTIONS: "Actions",
  LOAD_MORE_BATCHES: "Load more batches",
  LOAD_MORE_SHIPMENTS: "Load more shipments",
  CANCEL: "Cancel",
  REMOVE_SPLIT: "Remove all parts",
  DELETE: "Delete",
  SAVE_AND_CLOSE: "Save & close",
  COMMIT: "Commit links and volumes",
  COMMITTED: "Committed",
};

// Fields from the DTNShipment schema
export const dtnShipmentKeys = {
  SHIPMENT_ID: "dtn_shipment_id",
  SHIPMENT_TYPE: "shipment_type",

  SHIPMENT_DATE: "shipment_datez",
  SHIPMENT_START_DATE: "shipment_start_datez",
  SHIPMENT_END_DATE: "shipment_end_datez",

  MAX_VOLUME: "max_volume",
  SOURCE_TANK: "source_tank",
  ESTIMATED_VOLUME: "estimated_volume",
  QUALIFIED_VOLUME: "qualified_volume",
  ADJUSTED_VOLUME: "adjusted_volume",
  REMAINING_VOLUME: "remaining_volume",

  // Additional Info and Flags
  ADDITIONAL_INFO: "additional_info",
  CREDITS_QUALIFIED: "credits_qualified",
  RENEWABLE_SHIPMENT_FLAG: "renewable_shipment_flag", // Boolean

  // Shipment Status and Audit
  SHIPMENT_STATUS: "shipment_status",

  SOURCE_BATCH_ID: "source_batch_id",
  DESTINATION_TANK: "destination_tank",
  DESTINATION_BATCH_ID: "destination_batch_id",

  // resource ids
  SOURCE_RESOURCE_BATCH_ID: "source_resource_batch_id",
  DESTINATION_RESOURCE_BATCH_ID: "destination_resource_batch_id",
};

export const splitShipmentKeys = {
  SPLIT_SUB_TYPE: "shipment_sub_type",
  ACTUAL_VOLUME: "adjusted_volume_bbl",
  RINS_QUALIFIED: "credits_qualified",
  RENEWABLE_SPLIT_FLAG: "renewable_shipment_flag",
  ADDITIONAL_INFO: "additional_info",
  OWB_AGG_SHIPMENT_ID: "owb_agg_shipment_id",
};

export const MULTI_MODAL_FIELDS = [
  dtnShipmentKeys.RENEWABLE_SHIPMENT_FLAG,
  dtnShipmentKeys.CREDITS_QUALIFIED,
];

export const BATCH_BAR_LABELS = {
  REMAINING_VOLUME: "Remaining Volume",
  ENDING_INVENTORY: "Ending Inventory",
  ALLOCATED_VOLUME: "Allocated Volume",
  TRANSFER_VOLUME: "Inventory Transfer",
  INV_TRANSFER_INCLUDED: "(Inv. transfer)",
};

export const STATUS_DROPDOWN = [
  {value: "Archived", label: "Archived"},
  {value: "Committed", label: "Committed"},
];

export const SHIPMENT_TYPES_DROPDOWN = [
  {value: SHIPMENT_TYPE.PIPELINE, label: "Pipeline"},
  {value: SHIPMENT_TYPE.VESSEL, label: "Vessel"},
  {value: SHIPMENT_TYPE.BACK_TO_UNIT, label: "Back to unit misc"},
  {value: SHIPMENT_TYPE.TRUCK_RACK, label: "Truck rack"},
  {value: SHIPMENT_TYPE.INVENTORY_TRANSFER, label: "Inventory Transfer"},
];

export const SHIPMENT_LABELS = {
  STATUS: "Shipment status",
  DETAILS: "Shipping details",
};

export const DETAIL_HEADERS = {
  SHIPMENT_SUMMARY: "Shipment summary",
};

const SELECT_OPTION = {label: "Select an option", value: "", setDisable: true};

export const SHIPMENTS_WITH_SUBTYPES = [
  SHIPMENT_TYPE.PIPELINE,
  SHIPMENT_TYPE.VESSEL,
];

export const SHIPMENTS_WITH_ACTUAL_VOLUME = [
  SHIPMENT_TYPE.PIPELINE,
  SHIPMENT_TYPE.VESSEL,
  SHIPMENT_TYPE.BACK_TO_UNIT,
];

export const SHIPMENTS_WITH_PART_EDITABLE = [
  SHIPMENT_TYPE.PIPELINE,
  SHIPMENT_TYPE.VESSEL,
  SHIPMENT_TYPE.TRUCK_RACK,
];

export const SHIPMENTS_WITH_NOTES = [
  SHIPMENT_TYPE.PIPELINE,
  SHIPMENT_TYPE.VESSEL,
  SHIPMENT_TYPE.TRUCK_RACK,
  SHIPMENT_TYPE.BACK_TO_UNIT,
];

export const SHIPMENT_WITH_SOURCE_TANK = [SHIPMENT_TYPE.TRUCK_RACK];

export const SHIPMENTS_WITH_START_TIME = [
  SHIPMENT_TYPE.PIPELINE,
  SHIPMENT_TYPE.VESSEL,
];

export const SHIPMENTS_WITH_END_TIME = [
  SHIPMENT_TYPE.PIPELINE,
  SHIPMENT_TYPE.VESSEL,
];

export const SUB_TYPES = {
  LINE_SET: "Line Set",
  NA: "N/A",
  NONE: "None",
};

export const PIPELINE_SUB_TYPE_OPTIONS = [
  SELECT_OPTION,
  {label: SUB_TYPES.NA, value: SUB_TYPES.NA},
];

export const VESSEL_SUB_TYPE_OPTIONS = [
  SELECT_OPTION,
  {label: SUB_TYPES.NONE, value: SUB_TYPES.NONE},
  {label: SUB_TYPES.LINE_SET, value: SUB_TYPES.LINE_SET},
];

export const RINS_QUALIFIED_OPTIONS = [
  SELECT_OPTION,
  {label: "True", value: "True"},
  {label: "False", value: "False"},
  {label: "Partial", value: "Partial"},
];

export const RENEWABLE_OPTIONS = [
  SELECT_OPTION,
  {label: "Yes", value: true},
  {label: "No", value: false},
];

export const SHIPMENT_SUMMARY_LABELS = {
  SHIPMENT_TYPE: "Shipment type",
  SUB_TYPE: "Sub-type",
  BATCH_ID: "Batch ID",
  SHIPMENT_DATE: "Shipment date",
  TOTAL_ESTIMATED_SHIP_VOL: "Total estimated shipment volume",
  TOTAL_ACTUAL_SHIP_VOL: "Total actual shipment volume",
  SHIPMENT_START_TIME: "Shipment start time",
  SHIPMENT_END_TIME: "Shipment end time",
  LOAD_START_TIME: "Load start time",
  LOAD_END_TIME: "Load end time",
  DESTINATION: "Destination",
  RINS_QUALIFIED: "RINS qualified",
  RENEWABLE: "Renewable",
  SOURCE_TANK: "Source Tank",
  NOTES: "Notes",
};

export const SHIPMENT_SUMMARY_FIELDS_HEADER = [
  {
    label: SHIPMENT_SUMMARY_LABELS.SHIPMENT_TYPE,
    key: "shipment_type",
    isEditable: false,
    dataType: "formattedText",
  },
  {
    label: SHIPMENT_SUMMARY_LABELS.SUB_TYPE,
    key: "sub_type",
    isEditable: true,
    inputType: "dropdown",
  },
  {
    label: SHIPMENT_SUMMARY_LABELS.BATCH_ID,
    key: "batch_id",
    isEditable: false,
  },
  {
    label: SHIPMENT_SUMMARY_LABELS.SHIPMENT_DATE,
    key: "shipment_date",
    isEditable: false,
    dataType: "date",
  },
  {
    label: SHIPMENT_SUMMARY_LABELS.TOTAL_ESTIMATED_SHIP_VOL,
    key: "total_estimated_volume",
    isEditable: false,
    metric: METRICS.BARRELS,
    dataType: "number",
  },
  {
    label: SHIPMENT_SUMMARY_LABELS.TOTAL_ACTUAL_SHIP_VOL,
    key: "total_actual_volume",
    isEditable: true,
    metric: METRICS.BARRELS,
    dataType: "number",
    inputType: "text",
  },
];

export const SHIPMENT_SUMMARY_FIELDS_BODY = [
  {
    label: SHIPMENT_SUMMARY_LABELS.LOAD_START_TIME,
    key: "load_start_time",
    isEditable: false,
    dataType: "time",
  },
  {
    label: SHIPMENT_SUMMARY_LABELS.LOAD_END_TIME,
    key: "load_end_time",
    isEditable: false,
    dataType: "time",
  },
  {
    label: SHIPMENT_SUMMARY_LABELS.DESTINATION,
    key: "destination",
    isEditable: false,
    dataType: "formattedText",
  },
  {
    label: SHIPMENT_SUMMARY_LABELS.RINS_QUALIFIED,
    key: "credits_qualified",
    isEditable: true,
    inputType: "dropdown",
  },
  {
    label: SHIPMENT_SUMMARY_LABELS.RENEWABLE,
    key: "renewable_shipment_flag",
    isEditable: true,
    dataType: "boolean",
    inputType: "dropdown",
  },
  {
    label: SHIPMENT_SUMMARY_LABELS.SOURCE_TANK,
    key: "source_tank",
    isEditable: true,
    inputType: "textarea",
  },
  {
    label: SHIPMENT_SUMMARY_LABELS.NOTES,
    key: "notes",
    isEditable: true,
    inputType: "textarea",
  },
];

export const UNLINK_POPOVER = {
  HEADER: "Confirm Delete",
  MESSAGE:
    "Are you sure you want to remove the linked shipment from this batch?",
  YES: "Yes, delete",
  CANCEL: "Cancel",
};

export const DELETE_POPOVER = {
  HEADER: "Delete shipment",
  MESSAGE: "Are you sure you want to delete the shipment?",
  YES: "Yes, delete",
  CANCEL: "Cancel",
};

export const RESET_POPOVER = {
  HEADER: "Reset allocation",
  MESSAGE: "Are you sure you want to reset allocation?",
  YES: "Yes",
  CANCEL: "Cancel",
};

export const COMMIT_POPOVER = {
  HEADER: "Commit links and volumes",
  MISSING_ENDING_INVENTORY_MESSAGE:
    "Are you sure you want to mark the volume(s) and linkages as final? This shipment will also be removed from the shipments page. Ending inventory is zero, do you want to move forward?",
  MESSAGE:
    "Are you sure you want to mark the volume(s) and linkages as final? This shipment will also be removed from the shipments page.",
  YES: "Yes, commit",
  CANCEL: "Cancel",
};

export const BANNER_TYPE = {
  WARNING: "warning",
  ERROR: "error",
};

export const ERRORS = {
  ENDING_INVENTORY_VALUE:
    "Ending Inventory is not within range of remaining balance",
  FAILED_DELETE: "Error: Failed to delete shipment",
  FAILED_REMOVE: "Error: Failed to remove shipment parts",
  FAILED_ALLOCATION: "Error: Failed to allocate",
  FAILED_COMMIT: "Error: Failed to commit link(s) and volume(s)",
  FAILED_ARCHIVED: "Error: Failed to archive shipment",
  EXCEEDS_VOLUME:
    "Invalid shipment ID because shipment volume exceeds available volume from batch.",
  UNEXPECTED: "An unexpected error occurred.",
  EXCEEDS_ESTIMATED_VOLUME:
    "Invalid volume amounts. Shipment volumes should not exceed total estimated volume.",
  ACTUAL_VOLUME_REQUIRED: "Actual shipment volume is required.",
  SHIPMENTS_LINK_REQUIRED: "Please associate Shipments with this Batch.",
  SHIPMENT_UNLINKED_VOLUME:
    "Unlinked Volume remains, please associate with Shipment(s).",
  C_14_LINK_REQUIRED: "C14 link is required.",
  FEEDSTOCK_ALLOCATION_REQUIRED: "Feedstock Allocation must be completed.",
  VOLUME_UNLINKED: "Unlinked Volume Remains, please associate with Shipment(s)",
  MISSING_BATCH_ID: "Please associate Batch with this Shipment",
  DISCARD_SHIPMENT: "Failed to discard shipment",
};

export const WARNINGS = {
  MISSING_C14: "C14 Not Received.",
  MISSING_SHIPMENT_START_TIME: "Please select the shipment start time",
  MISSING_SHIPMENT_END_TIME: "Please select the shipment end time",
  START_TIME_BEFORE_SHIPMENT_DATE: "Start time must be after shipment date",
  END_TIME_BEFORE_START_TIME: "End time must be after start time",
};

export const DELETE_SUCCESS = "Shipment deleted.";
export const SHIPMENT_SUCCESS = "Successfully update shipment information!";
export const UNLINK_SUCCESS = "Shipment unlinked.";
export const ALLOCATION_RESET_SUCCESS = "Bulk allocation reset successful.";
export const DISCARD_SHIPMENT_SUCCESS = "Successfully discarded shipment.";

export const BTU_ID_PREFIX = "MISC/BTU-";
