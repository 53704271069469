import {useQuery} from "@apollo/client";
import {useAccount, useMsal} from "@azure/msal-react";
import {DIV_CODE_COPRO} from "constants/divisionDetails";
import {
  COPRO_EU_COST_CALCULATION_GET_PARAMETERS,
  COPRO_EU_UPDATE_COST_PARAMETERS_API,
} from "graphql/es-co-processing/cost-calculation/costCalculation";
import {isEmpty, isNull} from "lodash";
import moment from "moment";
import {useAppSetting} from "providers/appSetting/context.js";
import {formatNumber, useUserSettings} from "providers/userSettings";
import React, {useEffect, useState} from "react";
import {FormProvider, useForm} from "react-hook-form";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import {toast} from "react-toastify";
import {Button} from "reactstrap";
import {
  getDivisionData,
  getSiteDetails,
  setCountryDetails,
} from "utils/helpers/getAppSetting.js";
import parseAndRoundToTwoDecimal from "utils/numberUtil";
import Layout from "../components/Layout";
import CostAllFeedstock from "./CostAllFeedstock";
import CostPerFeedstock from "./CostPerFeedstock";

import BankContent from "../components/CurrencyExchangePlatts/BankContent";
import {
  CURRENCY,
  DEFAULT_LOCATION_ID,
  DEFAULT_LOCATION_NAME,
  DEFAULT_SAVE_AND_CALCULATE_BUTTON_HEADER,
  DEFAULT_SAVE_BUTTON_HEADER,
  INITIAL,
} from "./DefaultHeaders";

const CostFeedstockParameters = () => {
  const {appSetting} = useAppSetting();
  const {country: countryName} = useParams();
  setCountryDetails(countryName);

  const countryId = appSetting?.currentCountryMappingData?.countryId;

  const siteReferenceData = getSiteDetails(countryId);
  const divisionData = getDivisionData(DIV_CODE_COPRO);
  const methods = useForm({
    mode: "onBlur",
    defaultValues: {},
  });

  const {accounts} = useMsal();
  const account = useAccount(accounts[0]);
  const {getValues, setValue} = methods;

  const navigate = useNavigate();

  const location = useLocation();
  const {
    periodMonth,
    periodYear,
    materialShortName,
    materialIdList,
    paramsEditable,
    currencyUnit,
    unitRate,
  } = location.state;

  const onCancel = () => {
    methods.reset();
  };

  const formatKey = (key) => {
    return key.replace(/[^a-zA-Z0-9]/g, "").toLowerCase();
  };

  const breadcrumbItems = [
    {text: "BioVerse", link: "/"},
    {text: "Co-processing", link: "/copro-spain/spain"},
    {text: "Cost calculation", link: "/copro-spain/spain/cost-calculation"},
    {
      text: `Cost parameters for ${moment(periodMonth, "MM").format(
        "MMMM",
      )} ${periodYear}`,
      link: "/copro-spain/spain/cost-calculation/add-cost-parameters",
    },
  ];

  const {data, loading, error} = useQuery(
    COPRO_EU_COST_CALCULATION_GET_PARAMETERS,
    {
      variables: {
        fifoMonth: periodMonth,
        fifoYear: periodYear,
        materialIds: materialIdList,
        siteReferenceId: siteReferenceData?.siteReferenceId,
        divisionId: divisionData?.divisionId,
      },
      skip:
        siteReferenceData?.siteReferenceId === undefined ||
        divisionData?.divisionId === undefined,
      notifyOnNetworkStatusChange: true,
      fetchPolicy: "network-only",
    },
  );

  const [allFeedStockData, setAllFeedStockData] = useState([]);
  const [perFeedStockData, setPerFeedStockData] = useState([]);
  const [monthlyAverageData, setMonthlyAverageData] = useState([]);
  const [saveButtonHeader, setSaveButtonHeader] = useState(
    DEFAULT_SAVE_BUTTON_HEADER,
  );

  const DefaultSettings = () => {
    return useUserSettings();
  };
  const {
    userSettings: {decimalFormat},
  } = DefaultSettings();

  useEffect(() => {
    const formatNumberIfExists = (value) => {
      if (isNull(value)) return "";
      const finalVal = formatNumber(
        parseAndRoundToTwoDecimal(value),
        decimalFormat,
        2,
      );
      return finalVal.replace(/(\.\d*?)0+$/, "$1").replace(/\.$/, "");
    };
    if (!loading && !error && data) {
      const filteredAFData = [];
      const filteredPFData = [];
      let allValuesAdded = true;
      data?.bioLcCoproCostGetParameters?.data?.forEach((item) => {
        if (isNull(item.parameterValue)) allValuesAdded = false;
        if (item.globalParameterFlag) filteredAFData.push(item);
        else filteredPFData.push(item);
      });
      setSaveButtonHeader(
        allValuesAdded
          ? DEFAULT_SAVE_AND_CALCULATE_BUTTON_HEADER
          : DEFAULT_SAVE_BUTTON_HEADER,
      );
      filteredAFData.forEach((item) => {
        setValue(item.parameterName.toLowerCase(), item.parameterValue);
        setValue(
          `${item.parameterName.toLowerCase()}-${INITIAL}`,
          formatNumberIfExists(item.parameterValue),
        );
      });
      filteredPFData.forEach((item) => {
        setValue(
          `${formatKey(
            item.materialShortName,
          )}-${item.parameterLabel.toLowerCase()}`,
          item.parameterValue,
        );
        setValue(
          `${formatKey(
            item.materialShortName,
          )}-${item.parameterLabel.toLowerCase()}-${INITIAL}`,
          formatNumberIfExists(item.parameterValue),
        );
        setValue(
          `${formatKey(
            item.materialShortName,
          )}-${item.parameterLabel.toLowerCase()}-${CURRENCY}`,
          item.uomSymbol,
        );
      });
      setAllFeedStockData(filteredAFData);
      setPerFeedStockData(filteredPFData);
      setMonthlyAverageData(
        data?.bioLcCoproCostGetParameters?.monthlyAverageData,
      );
    }
  }, [data, loading, error, setValue, decimalFormat]);

  const getFinalValue = (value) => {
    return !value || value === "" ? null : value.toString();
  };

  const onSubmit = async () => {
    const parametersData = data?.bioLcCoproCostGetParameters?.data?.map(
      (item) => ({
        costCoproParameterId: item.costCoproParameterId,
        parameterName: item.parameterName,
        parameterValue: item.globalParameterFlag
          ? getFinalValue(getValues(item.parameterName.toLowerCase()))
          : getFinalValue(
              getValues(
                `${formatKey(
                  item.materialShortName,
                )}-${item.parameterLabel.toLowerCase()}`,
              ),
            ),
        globalParameterFlag: item.globalParameterFlag,
        materialId: item.materialId,
        materialShortName: item.materialShortName,
        uom: item.uom,
      }),
    );

    const event_data = {
      locationId: DEFAULT_LOCATION_ID,
      locationName: DEFAULT_LOCATION_NAME,
      fifoMonth: periodMonth,
      fifoYear: periodYear,
      parameters: parametersData,
      bvAuditChangedBy:
        saveButtonHeader === DEFAULT_SAVE_AND_CALCULATE_BUTTON_HEADER
          ? account?.name
          : null,
      siteReferenceId: siteReferenceData?.siteReferenceId,
      divisionId: divisionData?.divisionId,
    };

    const {data: response} = await COPRO_EU_UPDATE_COST_PARAMETERS_API({
      ...event_data,
    });

    if (response?.bioLcCoproUpdateCostParameters?.statusCode === 200) {
      toast.success(
        response?.bioLcCoproUpdateCostParameters?.message ??
          "Updated Cost parameters successfully",
      );
      navigate("/copro-spain/spain/cost-calculation/", {
        state: {
          periodMonthReceived: periodMonth,
          periodYearReceived: periodYear,
          materialShortNameReceived: materialShortName,
          currencyUnitReceived: currencyUnit,
          unitRateReceived: unitRate,
        },
      });
    } else {
      toast.error(
        response?.bioLcCoproUpdateCostParameters?.message ??
          "Something went wrong, failed to update",
      );
    }
    return response;
  };

  const updateSaveButton = () => {
    const filteredAFData = [];
    const filteredPFData = [];
    let allValuesAdded = true;
    data.bioLcCoproCostGetParameters.data.forEach((item) => {
      if (item.globalParameterFlag) filteredAFData.push(item);
      else filteredPFData.push(item);
    });
    filteredAFData.forEach((item) => {
      const val = getValues(item.parameterName.toLowerCase())?.toString();
      if (isEmpty(val)) allValuesAdded = false;
    });
    filteredPFData.forEach((item) => {
      const val = getValues(
        `${formatKey(
          item.materialShortName,
        )}-${item.parameterLabel.toLowerCase()}`,
      )?.toString();
      if (isEmpty(val)) allValuesAdded = false;
    });
    setSaveButtonHeader(
      allValuesAdded
        ? DEFAULT_SAVE_AND_CALCULATE_BUTTON_HEADER
        : DEFAULT_SAVE_BUTTON_HEADER,
    );
  };

  return (
    <Layout
      title={`Cost parameters for ${moment(periodMonth, "MM").format(
        "MMMM",
      )} ${periodYear}`}
      breadcrumbs={breadcrumbItems}
    >
      <div className="flex flex-col justify-stretch w-full items-start px-10 cost-calculation-details">
        <div className="flex flex-row justify-between">
          <div className="flex flex-col custom-width-for-page">
            <FormProvider {...methods}>
              <form>
                <CostAllFeedstock
                  allFeedStockData={allFeedStockData}
                  updateSaveButton={updateSaveButton}
                  paramsEditable={paramsEditable}
                />
                <CostPerFeedstock
                  perFeedStockData={perFeedStockData}
                  updateSaveButton={updateSaveButton}
                  paramsEditable={paramsEditable}
                />
                <div className="flex justify-end gap-4">
                  <Button
                    type="button"
                    onClick={() => {
                      onCancel();
                      navigate("/copro-spain/spain/cost-calculation/", {
                        state: {
                          periodMonthReceived: periodMonth,
                          periodYearReceived: periodYear,
                          materialShortNameReceived: materialShortName,
                          currencyUnitReceived: currencyUnit,
                          unitRateReceived: unitRate,
                        },
                      });
                    }}
                    className="cancel-button-background"
                  >
                    <div className="text-[14px] text-black">Cancel</div>
                  </Button>
                  <Button
                    type="button"
                    onClick={onSubmit}
                    className={`${
                      paramsEditable
                        ? "save-button-background"
                        : "save-button-editable-header"
                    }`}
                  >
                    <div className="">{saveButtonHeader}</div>
                  </Button>
                </div>
              </form>
            </FormProvider>
          </div>
          <div className="flex flex-col" style={{marginLeft: "145px"}}>
            <BankContent
              selectedMonth={periodMonth || ""}
              selectedYear={periodYear || ""}
              monthlyAverageData={monthlyAverageData}
            />
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default CostFeedstockParameters;
