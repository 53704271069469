import PropTypes from "prop-types";
import {Controller} from "react-hook-form";
import {FormFeedback, FormGroup, Input, Label} from "reactstrap";

const RecyclingType = ({control, name, error, rules, disabled = false}) => {
  return (
    <Controller
      rules={rules}
      render={({field: {onChange, value, ref}}) => {
        const chemical = {checked: value?.toUpperCase() === "CHEMICAL"};
        const mechanical = {checked: value?.toUpperCase() === "MECHANICAL"};
        const notApplicable = {
          checked: value?.toUpperCase() === "NOT_APPLICABLE",
        };

        return (
          <>
            <div>
              <FormGroup
                check
                inline
                className="form-check-alt form-check-lg !mb-0"
              >
                <Input
                  id={`${name}Chemical`}
                  data-test={`${name}Chemical`}
                  type="checkbox"
                  invalid={!!error && !disabled}
                  disabled={disabled}
                  onChange={(e) => {
                    // Uncheck other checkboxes when this is checked
                    if (e.target.checked) {
                      onChange("CHEMICAL");
                    } else {
                      onChange("");
                    }
                  }}
                  {...chemical}
                />
                <Label check for={`${name}Chemical`}>
                  Chemical
                </Label>
              </FormGroup>
              <FormGroup
                check
                inline
                className="form-check-alt form-check-lg !mb-0"
              >
                <Input
                  id={`${name}Mechanical`}
                  data-test={`${name}Mechanical`}
                  type="checkbox"
                  invalid={!!error && !disabled}
                  disabled={disabled}
                  onChange={(e) => {
                    // Uncheck other checkboxes when this is checked
                    if (e.target.checked) {
                      onChange("MECHANICAL");
                    } else {
                      onChange("");
                    }
                  }}
                  {...mechanical}
                />
                <Label check for={`${name}Mechanical`}>
                  Mechanical
                </Label>
              </FormGroup>
              <FormGroup
                check
                inline
                className="form-check-alt form-check-lg !mb-0"
              >
                <Input
                  innerRef={ref}
                  id={`${name}NotApplicable`}
                  data-test={`${name}NotApplicable`}
                  type="checkbox"
                  invalid={!!error && !disabled}
                  disabled={disabled}
                  onChange={(e) => {
                    // Uncheck other checkboxes when this is checked
                    if (e.target.checked) {
                      onChange("NOT_APPLICABLE");
                    } else {
                      onChange("");
                    }
                  }}
                  {...notApplicable}
                />
                <Label check for={`${name}NotApplicable`}>
                  Not applicable
                </Label>
              </FormGroup>
            </div>
            {error && !disabled && (
              <FormFeedback style={{display: "block"}}>
                {error.message}
              </FormFeedback>
            )}
          </>
        );
      }}
      control={control}
      name={name}
      defaultValue=""
    />
  );
};

RecyclingType.propTypes = {
  control: PropTypes.object,
  name: PropTypes.string.isRequired,
  error: PropTypes.object,
  rules: PropTypes.object,
  disabled: PropTypes.bool,
};

export default RecyclingType;
