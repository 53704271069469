import {gql} from "@apollo/client";
import {client} from "providers/Apollo";

export const COPRO_EU_COST_CALCULATION_FILTER = gql`
  query filterQuery($siteReferenceId: String!, $divisionId: String!) {
    bioLcCoproCostCalculationDynamicFilter(
      event: {siteReferenceId: $siteReferenceId, divisionId: $divisionId}
    ) {
      data {
        fifoMonth
        fifoYear
        materialShortName
        currencyUnit
        unitRateBasedOnType
        unitRateBasedOnRatio
        materialId
        locationId
      }
      message
      status
      statusCode
    }
  }
`;

export const COPRO_EU_COST_CALCULATION_GET_PARAMETERS = gql`
  query getParametersQuery(
    $fifoMonth: String!
    $fifoYear: String!
    $materialIds: [String!]
    $siteReferenceId: String!
    $divisionId: String!
  ) {
    bioLcCoproCostGetParameters(
      event: {
        fifoMonth: $fifoMonth
        fifoYear: $fifoYear
        materialIds: $materialIds
        siteReferenceId: $siteReferenceId
        divisionId: $divisionId
      }
    ) {
      message
      status
      statusCode
      data {
        divisionId
        globalParameterFlag
        locationId
        locationName
        materialId
        materialShortName
        costCoproParameterId
        parameterName
        parameterValue
        parameterLabel
        siteReferenceId
        uom
        uomSymbol
      }
      monthlyAverageData {
        exchangeRate {
          average
          difference
          label
        }
        ulsd {
          average
          difference
          label
        }
        gocmh {
          average
          difference
          label
        }
      }
    }
  }
`;

export const COPRO_EU_COST_CALCULATION_DETAILS = gql`
  query getTableDetailsQuery(
    $fifoMonth: String!
    $fifoYear: String!
    $materialId: String!
    $currency: String!
    $siteReferenceId: String
    $divisionId: String
    $unitRateBasedOnRatio: String!
  ) {
    bioLcCoproCostCalculationDetail(
      event: {
        fifoMonth: $fifoMonth
        fifoYear: $fifoYear
        materialId: $materialId
        currency: $currency
        siteReferenceId: $siteReferenceId
        divisionId: $divisionId
        unitRateBasedOnRatio: $unitRateBasedOnRatio
      }
    ) {
      message
      status
      statusCode
      data {
        updatedBy
        updatedAt
        currency
        currencySymbol
        coproCostParamsExists
        coproConsumedQuantityExists
        totalCoprocessingCost {
          label
          values {
            abs
            receiptType
            unit
          }
        }
        calculationBreakdown {
          isTotal
          name
          label
          values {
            abs
            receiptType
            unit
          }
        }
        consumedQuantity {
          label
          values {
            receiptType
            value
            moreDetails {
              contractNo
              dealNumber
              supplier
              sapMaterialCode
              sapNCCode
              documentNumber
              documentItemNumber
              locationName
              locationId
              routeDetailsFlag
              routeDetail {
                route {
                  mot
                  motName
                  plantName
                  seqNumber
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const COPRO_EU_UPDATE_COST_PARAMETERS = gql`
  mutation UpdateCostParameters($event: CostCoproParameterRequest!) {
    bioLcCoproUpdateCostParameters(event: $event) {
      statusCode
      message
    }
  }
`;

export const COPRO_EU_UPDATE_COST_PARAMETERS_API = (data) => {
  return client.mutate({
    mutation: COPRO_EU_UPDATE_COST_PARAMETERS,
    variables: {
      event: data,
    },
  });
};

export default {
  COPRO_EU_COST_CALCULATION_FILTER,
  COPRO_EU_COST_CALCULATION_GET_PARAMETERS,
  COPRO_EU_COST_CALCULATION_DETAILS,
  COPRO_EU_UPDATE_COST_PARAMETERS,
  COPRO_EU_UPDATE_COST_PARAMETERS_API,
};
