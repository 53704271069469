import {Edit24} from "@bphxd/ds-core-react/lib/icons";
import {UncontrolledTooltip} from "reactstrap";

export const getUniqueList = (data, key) => {
  const allData = data.map((item) => item[key]).filter(Boolean);
  return [...new Set(allData)];
};

export const customBooleanSelectFilterFn = (row, columnId, value) =>
  (row.getValue(columnId) === true ? "yes" : "no") === value.toLowerCase();

export const customArrayFilterFn = (row, columnId, value) => {
  const valueList = row.getValue(columnId);
  if (!valueList || valueList.length < 1) return false;
  return valueList.some((item) =>
    item.toLowerCase().includes(value.toLowerCase()),
  );
};

export const addEditColumn = (columns, onClickEditRow) => {
  return [
    ...columns,
    {
      id: "edit",
      header: () => (
        <div className="position-relative">
          <div className="position-absolute start-50 translate-middle-x">
            <Edit24 className="opacity-40" />
          </div>
        </div>
      ),
      cell: ({row}) => (
        <div className="position-relative">
          <div
            className="position-absolute start-50 translate-middle-x"
            style={{top: "-2px"}}
          >
            <button
              id={`table-edit-row-${row.id}`}
              className="p-0 bg-transparent border-0 text-default"
              onClick={() => onClickEditRow(row)}
              type="button"
              aria-label="Edit row"
            >
              <Edit24 />
            </button>
            <UncontrolledTooltip
              popperClassName="tooltip-light tooltip-lg"
              target={`table-edit-row-${row.id}`}
            >
              Edit
            </UncontrolledTooltip>
          </div>
        </div>
      ),
      size: 40,
    },
  ];
};
