import PropTypes from "prop-types";
import {useUserSettings} from "providers/userSettings";
import {useCallback, useEffect, useMemo} from "react";
import {useFieldArray, useFormContext} from "react-hook-form";

import {useAccount, useMsal} from "@azure/msal-react";

import {FormFeedback, FormGroup, Input, Label} from "reactstrap";
import WriteDataOutput from "./WriteDataOutput";
import useRuleFormValidationEffects from "./validationError";

const WriteData = ({
  selectedItem,
  writeDatasetList,
  selectedDataset,
  selectedTemplateItems,
  updateConfigurationInBlocks,
  saveErrorData,
  isDisabled,
  selectDatasetList,
}) => {
  const {
    userSettings: {decimalFormat},
  } = useUserSettings();

  const {accounts} = useMsal();
  const account = useAccount(accounts[0]);

  const {
    register,
    formState: {errors},
    control,
    setError,
    setFocus,
    setValue,
    clearErrors,
    watch,
    getValues,
  } = useFormContext();
  const computeProps = (name, options) => {
    const {ref, ...props} = register(name, options);
    return {innerRef: ref, ...props};
  };

  // dynamic fields for the output columns
  const {fields, append, remove, update} = useFieldArray({
    control,
    name: "outputList",
    mode: "onBlur",
  });

  const watchWriteDataDataset = watch("table_name");
  const watchWriteDataResultColumn = watch("result_column");
  const watchDescription = watch("description");

  const handleUpdateConfig = useCallback(() => {
    const outputList = getValues("outputList");
    const outputColumns = outputList.map((output) => {
      if (output.manualInputFlag) {
        return {
          output_column: output.column,
          input_value: output.manualInput,
        };
      }

      return {
        output_column: output.column,
        input_column: output.outputColumn,
      };
    });
    if (watchWriteDataDataset) {
      updateConfigurationInBlocks(selectedItem, {
        table_name: watchWriteDataDataset,
        output_columns: outputColumns || [],
        description: watchDescription,
      });
    }
  }, [
    selectedItem,
    watchWriteDataDataset,
    updateConfigurationInBlocks,
    getValues,
    watchDescription,
  ]);

  useEffect(() => {
    handleUpdateConfig();
  }, [watchWriteDataDataset, handleUpdateConfig]);

  // set the output columns based on the selected dataset
  useEffect(() => {
    const targetDatasetOptions =
      writeDatasetList?.find((x) => x.dataset === watchWriteDataDataset)
        ?.schema || [];
    if (targetDatasetOptions.length > 0 && fields.length === 0) {
      targetDatasetOptions?.forEach(({column, optional, type}) => {
        append({column, optional, type});
      });
    }
  }, [writeDatasetList, fields, append, watchWriteDataDataset]);

  // set the values according to the configuration
  useEffect(() => {
    // configuration values are set here
    if (selectedItem && selectedItem?.configuration) {
      if (selectedItem?.configuration?.table_name) {
        setValue("table_name", selectedItem?.configuration?.table_name);
      }
      setValue("description", selectedItem?.configuration?.description);

      if (fields?.length > 0) {
        selectedItem?.configuration?.output_columns?.forEach(
          (output, index) => {
            if (output?.input_value) {
              setValue(`outputList.${index}.manualInputFlag`, true);
              setValue(`outputList.${index}.manualInput`, output.input_value);
            } else {
              setValue(`outputList.${index}.manualInputFlag`, false);
              setValue(`outputList.${index}.outputColumn`, output.input_column);
            }
          },
        );
      }
    } else {
      setValue("table_name", "");
      setValue("result_column", "");
      setValue("description", "");
    }
  }, [selectedItem?.configuration, selectedItem, setValue, fields]);

  useRuleFormValidationEffects({
    selectedItem,
    saveErrorData,
    clearErrors,
    setError,
    setFocus,
  });

  const writeDataOutputContent = useMemo(
    () =>
      fields?.map((field, index) => (
        <div key={field.id}>
          <WriteDataOutput
            field={field}
            index={index}
            selectedItem={selectedItem}
            writeDatasetList={writeDatasetList}
            selectedDataset={selectedDataset}
            selectedTemplateItems={selectedTemplateItems}
            updateConfigurationInBlocks={updateConfigurationInBlocks}
            saveErrorData={saveErrorData}
            isDisabled={isDisabled}
            selectDatasetList={selectDatasetList}
            handleUpdateConfig={handleUpdateConfig}
          />
        </div>
      )),
    [
      fields,
      selectedItem,
      writeDatasetList,
      selectedDataset,
      selectedTemplateItems,
      updateConfigurationInBlocks,
      saveErrorData,
      isDisabled,
      selectDatasetList,
      handleUpdateConfig,
    ],
  );

  return (
    <div className="flex flex-col gap-4 text-left">
      <FormGroup>
        <Label for="description" className="fw-normal">
          Description
        </Label>
        <Input
          type="input"
          id="description"
          data-test="description"
          {...computeProps("description")}
          maxLength={30}
          placeholder="Enter description"
          disabled={isDisabled}
        />
        <span className="absolute fw-normal right-1 p-2.5 text-xs">
          {watchDescription?.length || 0}/30
        </span>
      </FormGroup>

      <div>
        <h5 className="text-lg font-semibold">Dataset</h5>
        <hr className="border-1 mt-4"></hr>
      </div>
      <FormGroup>
        <Label for="table_name" className="fw-normal mb-4">
          Target dataset
        </Label>
        <Input
          type="select"
          id="table_name"
          data-test="table_name"
          {...computeProps("table_name", {
            required: "Please select a dataset",
          })}
          invalid={!!errors.table_name}
          disabled={isDisabled}
        >
          <option value="">Select dataset</option>
          {writeDatasetList?.map((datasetItem) => (
            <option key={datasetItem?.dataset} value={datasetItem?.dataset}>
              {datasetItem?.dataset}
            </option>
          ))}
        </Input>
        {errors.table_name && (
          <FormFeedback className="mt-2">
            {errors.table_name?.message}
          </FormFeedback>
        )}
      </FormGroup>
      <h5 className="text-lg font-semibold m-0">Output</h5>
      <hr className="border-1 m-0"></hr>
      <div className="pb-10">{writeDataOutputContent}</div>
    </div>
  );
};

WriteData.propTypes = {
  selectedItem: PropTypes.object,
  writeDatasetList: PropTypes.array,
  selectedDataset: PropTypes.string,
  selectedTemplateItems: PropTypes.array,
  updateConfigurationInBlocks: PropTypes.func,
  saveErrorData: PropTypes.object,
  isDisabled: PropTypes.bool,
  selectDatasetList: PropTypes.array,
};

export default WriteData;
