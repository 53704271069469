import {uniqBy} from "lodash";
import PropTypes from "prop-types";
import {useEffect, useMemo, useState} from "react";
import {useFormContext} from "react-hook-form";
import {FormFeedback, FormGroup, Input, Label} from "reactstrap";

import Datepicker from "modules/GlobalMassBalance/components/Datepicker";

const RecipientDetails = ({rowData, masterDetails}) => {
  const {
    register,
    control,
    formState: {errors},
    watch,
    setValue,
  } = useFormContext();

  const computeProps = (name, options) => {
    const {ref, ...props} = register(name, options);
    return {innerRef: ref, ...props};
  };

  const [nonlegalEntitiesList, setnonlegalEntities] = useState([]);

  const [motDetails, setMOTDetails] = useState([]);

  const legalEntitiesItems =
    masterDetails?.bioLcGetBusinessAndLocationDetails
      ?.businessAndLocationDetails;

  const legalEntities = useMemo(() => {
    return uniqBy(legalEntitiesItems ?? [], "businessPartnerCode");
  }, [legalEntitiesItems]);

  const watchRecipientName = watch("recipientName");
  const watchRecipientReceiptName = watch("recipientReceiptName");
  const watchRecipientAddress = watch("recipientAddress");

  const modeofTransportationDetails =
    masterDetails?.bioLcGetTransportationEmission?.TransportationEmissionData;

  useEffect(() => {
    const receivingLocationCode =
      watchRecipientReceiptName === "Same as recipient address"
        ? watchRecipientName
        : watchRecipientReceiptName;
    const motDetailsList = [
      ...new Set(
        modeofTransportationDetails
          ?.filter(
            (objitem) =>
              objitem.receivingLocationCode === receivingLocationCode &&
              objitem.sendingLocationCode ===
                rowData?.original?.inboundRecord?.sendingLocationCode,
          )
          .map((obj) => obj.motName),
      ),
    ];
    setMOTDetails(motDetailsList);
  }, [
    watchRecipientReceiptName,
    modeofTransportationDetails,
    rowData?.original?.inboundRecord?.sendingLocationCode,
    watchRecipientName,
  ]);

  useEffect(() => {
    const recItems = [
      ...new Set(
        legalEntitiesItems?.filter(
          (objitem) => objitem.businessPartnerCode === watchRecipientName,
        ),
      ),
    ];
    setnonlegalEntities(recItems);
  }, [watchRecipientName, legalEntitiesItems, setnonlegalEntities]);

  useEffect(() => {
    const locationAddresses = legalEntitiesItems?.find(
      (objloc) => objloc.businessPartnerCode === watchRecipientName,
    );
    setValue("recipientAddress", locationAddresses?.businessPartnerAddress);
  }, [setValue, watchRecipientName, legalEntitiesItems]);

  useEffect(() => {
    const locationAddresses = legalEntitiesItems?.find(
      (objloc) => objloc.locationCode === watchRecipientReceiptName,
    );

    if (watchRecipientReceiptName === "Same as recipient address") {
      setValue("recipientReceiptAddress", watchRecipientAddress);
    } else {
      setValue("recipientReceiptAddress", locationAddresses?.locationAddress);
    }
  }, [
    setValue,
    watchRecipientReceiptName,
    watchRecipientAddress,
    legalEntitiesItems,
  ]);

  useEffect(() => {
    if (
      rowData?.original?.outboundRecords?.status === "RESERVED" &&
      rowData?.original?.outboundRecords?.recipient
    ) {
      const legalEntityItem = legalEntities?.find(
        (x) =>
          x.businessPartnerLongName?.toLowerCase() ===
          rowData?.original?.outboundRecords?.recipient?.toLowerCase(),
      );
      setValue("recipientName", legalEntityItem?.businessPartnerCode);
    }
  }, [rowData, setValue, legalEntities]);
  return (
    <div className="grid sm:grid-cols-1 text-left md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-2 gap-x-4 gap-y-4 ">
      <FormGroup>
        <Label for="recipientName" className="fw-light">
          Name of recipient
        </Label>
        <Input
          type="select"
          id="recipientName"
          data-test="recipientName"
          invalid={!!errors.recipientName}
          {...computeProps("recipientName", {
            required: "Please select name of recipient",
          })}
        >
          <option key="none" value="">
            --Select an option--
          </option>
          {legalEntities?.map((locdetails) => (
            <option
              key={locdetails.businessPartnerCode}
              value={locdetails.businessPartnerCode}
            >
              {locdetails.businessPartnerLongName}
            </option>
          ))}
        </Input>
        {errors.recipientName && (
          <FormFeedback>{errors.recipientName.message}</FormFeedback>
        )}
      </FormGroup>
      <FormGroup>
        <Label for="recipientReceiptName" className="fw-light">
          Name of receipt/receiving point
        </Label>

        <Input
          type="select"
          id="recipientReceiptName"
          data-test="recipientReceiptName"
          invalid={!!errors.recipientReceiptName}
          {...computeProps("recipientReceiptName", {
            required: "Please select  name of receipt/receiving point",
          })}
        >
          <option key="none" value="">
            --Select an option--
          </option>
          <option
            key="sameAsRecipientAddress"
            value="Same as recipient address"
          >
            Same as recipient address
          </option>
          {nonlegalEntitiesList?.map((locdetails) => (
            <option
              key={locdetails.locationCode}
              value={locdetails.locationCode}
            >
              {`${locdetails.locationLongName} - ${locdetails.locationType}`}
            </option>
          ))}
        </Input>
        {errors.sameAsRecipientAddress && (
          <FormFeedback>{errors.sameAsRecipientAddress.message}</FormFeedback>
        )}
      </FormGroup>
      <FormGroup>
        <Label for="recipientAddress" className="fw-light">
          Address of recipient
        </Label>

        <Input
          type="textarea"
          id="recipientAddress"
          data-test="recipientAddress"
          invalid={!!errors.recipientAddress}
          maxLength={100}
          disabled
          {...computeProps("recipientAddress", {
            required: "Please enter address of recipient",
          })}
        />
        {errors.recipientAddress && (
          <FormFeedback>{errors.recipientAddress.message}</FormFeedback>
        )}
      </FormGroup>
      <FormGroup>
        <Label for="recipientReceiptAddress" className="fw-light">
          Address of receipt/receiving point
        </Label>

        <Input
          type="textarea"
          id="recipientReceiptAddress"
          data-test="recipientReceiptAddress"
          invalid={!!errors.recipientReceiptAddress}
          maxLength={100}
          disabled
          {...computeProps("recipientReceiptAddress", {
            required: "Please enter address of receipt/receiving point",
          })}
        />
        {errors.recipientReceiptAddress && (
          <FormFeedback>{errors.recipientReceiptAddress.message}</FormFeedback>
        )}
      </FormGroup>

      <FormGroup>
        <Label for="dispatchDate" className="fw-light">
          Date of dispatch
        </Label>

        <Datepicker
          id="dispatchDate"
          name="dispatchDate"
          {...computeProps("dispatchDate", {
            required: "Please enter dispatch date",
          })}
          control={control}
          error={errors.dispatchDate}
          rules={{required: "Please enter dispatch date"}}
          format="d/m/Y"
        />
        {errors.dispatchDate && (
          <FormFeedback>{errors.dispatchDate.message}</FormFeedback>
        )}
      </FormGroup>

      <FormGroup>
        <Label for="motRecipient" className="fw-light">
          Mode of transportation
        </Label>

        <Input
          type="select"
          id="motRecipient"
          data-test="motRecipient"
          invalid={!!errors.motRecipient}
          {...computeProps("motRecipient", {
            required: "Please select mode of transportation",
          })}
        >
          <option key="none" value="">
            --Select an option--
          </option>
          {motDetails?.map((motName) => (
            <option key={motName} value={motName}>
              {motName}
            </option>
          ))}
        </Input>
        {errors.motRecipient && (
          <FormFeedback>{errors.motRecipient.message}</FormFeedback>
        )}
      </FormGroup>
      <FormGroup>
        <Label for="contractNumber" className="fw-light">
          Contract number
        </Label>

        <Input
          type="text"
          id="contractNumber"
          data-test="contractNumber"
          invalid={!!errors.contractNumber}
          maxLength={50}
          {...computeProps("contractNumber", {
            required: "Please enter contract number",
          })}
        />
        {errors.contractNumber && (
          <FormFeedback>{errors.contractNumber.message}</FormFeedback>
        )}
      </FormGroup>

      <FormGroup>
        <Label for="vesselName" className="fw-light">
          Vessel name
        </Label>

        <Input
          type="text"
          id="vesselName"
          data-test="vesselName"
          invalid={!!errors.vesselName}
          maxLength={20}
          {...computeProps("vesselName", {
            required: "Please enter vessel name",
          })}
        />
        {errors.vesselName && (
          <FormFeedback>{errors.vesselName.message}</FormFeedback>
        )}
      </FormGroup>
    </div>
  );
};

RecipientDetails.propTypes = {
  masterDetails: PropTypes.object,
  rowData: PropTypes.object,
};

export default RecipientDetails;
