import {FilePdf24} from "@bphxd/ds-core-react/lib/icons";
import {Link} from "react-router-dom";
import {UncontrolledTooltip} from "reactstrap";

import {status} from "modules/GlobalMassBalance/constants";
import {
  getFilterValue,
  getStatusColor,
  getStatusValue,
} from "modules/GlobalMassBalance/utils";

import {NUMBER_OF_DECIMALS} from "constants/common";
import moment from "moment";
import appendParamsToUrl from "utils/helpers/appendParamsToUrl";
import {getFormattedNumberWithCheck} from "utils/numberUtil";
import ActionDropdown from "../../ActionDropdown";
import ColumnHighlight from "../../ColumnHighlight";

const getColumnsCoproTrsSpain = (
  dateFormat,
  decimalFormatNew,
  selectedCountry,
  siteReferenceId,
  divisionId,
  currentFilterValues,
  division,
  periodStatus,
) => [
  {
    header: "Incoming",
    columns: [
      {
        header: "Physical receipt date",
        accessorKey: "physicalReceiptDate",
        key: "physicalReceiptDate",
        visible: true,
        meta: {
          filterVariant: "daterange",
        },
        filterFn: "dateRangeFilterFn",
        accessorFn: (row) => {
          return row?.inboundRecord?.physicalReceiptDate;
        },
        cell: ({cell}) => {
          return cell.getValue() && moment(cell.getValue()).format(dateFormat);
        },
      },
      {
        header: "Document",
        accessorKey: "incomingDocument",
        key: "incomingDocument",
        visible: true,
        size: 500,
        accessorFn: (row) => {
          return row?.inboundRecord?.incomingDocument;
        },
        cell: ({cell, row}) => {
          const tooltipId = `incomingDocument${row?.id}`;
          return (
            <>
              <Link
                to={{
                  pathname: `/document-manager/incoming/${selectedCountry}/detailedView/${encodeURIComponent(
                    row?.original?.inboundRecord?.certificateInboundEuId,
                  )}`,
                  search: `?siteReferenceId=${siteReferenceId}&divisionId=${divisionId}`,
                }}
                className="link-dark"
                id={tooltipId}
              >
                <u className="flex">
                  {cell.getValue() && <FilePdf24 />}
                  <ColumnHighlight
                    value={cell.getValue()}
                    columnFilter={getFilterValue(cell, "incomingDocument")}
                    hasEllipsis
                    isCertificate
                  />
                </u>
              </Link>
              <UncontrolledTooltip placement="bottom" target={tooltipId}>
                {cell.getValue() ?? ""}
              </UncontrolledTooltip>
            </>
          );
        },
      },
      {
        header: "Quantity MT",
        accessorKey: "productQtyMt",
        key: "productQtyMt",
        visible: false,
        dataType: "number",
        accessorFn: (row) => {
          return getFormattedNumberWithCheck(
            row?.inboundRecord?.productQtyMt,
            NUMBER_OF_DECIMALS,
          );
        },
        cell: ({cell}) => {
          return cell.getValue();
        },
      },
      {
        header: "Country of origin",
        accessorKey: "countryOfOrigin",
        key: "countryOfOrigin",
        visible: false,
        meta: {
          filterVariant: "select",
        },
        accessorFn: (row) => {
          return row?.inboundRecord?.countryOfOrigin;
        },
      },
      {
        header: "Supplier",
        accessorKey: "supplier",
        key: "supplier",
        visible: false,
        meta: {
          filterVariant: "select",
        },
        accessorFn: (row) => {
          return row?.inboundRecord?.supplier;
        },
      },
      {
        header: "Transport system",
        accessorKey: "transportSystem",
        key: "transportSystem",
        visible: false,
        meta: {
          filterVariant: "select",
        },
        accessorFn: (row) => {
          return row?.inboundRecord?.transportSystem;
        },
      },
      {
        header: "Vessel name",
        accessorKey: "vesselName",
        key: "vesselName",
        visible: false,
        meta: {
          filterVariant: "select",
        },
        accessorFn: (row) => {
          return row?.inboundRecord?.vesselName;
        },
      },
      {
        header: "Invoice number",
        accessorKey: "invoiceNumber",
        key: "invoiceNumber",
        visible: false,
        accessorFn: (row) => {
          return row?.inboundRecord?.invoiceNumber;
        },
      },
      {
        header: "Transport loss/gain",
        accessorKey: "transportLg",
        key: "transportLg",
        visible: false,
        dataType: "number",
        meta: {
          filterVariant: "select",
        },
        accessorFn: (row) => {
          return getFormattedNumberWithCheck(
            row?.inboundRecord?.transportLg,
            NUMBER_OF_DECIMALS,
          );
        },
      },
      {
        header: "Certified",
        accessorKey: "certified",
        key: "certified",
        visible: false,
        accessorFn: (row) => {
          return row?.inboundRecord?.certified;
        },
      },
      {
        header: "Received quantity MT",
        accessorKey: "receivedQuantityMt",
        key: "receivedQuantityMt",
        visible: true,
        dataType: "number",
        accessorFn: (row) => {
          return getFormattedNumberWithCheck(
            row?.inboundRecord?.receivedQuantityMt,
            NUMBER_OF_DECIMALS,
          );
        },
        cell: ({cell}) => {
          return cell.getValue();
        },
      },
      {
        header: "GHG total gCO2eq/MJ",
        accessorKey: "ghgTotalMj",
        key: "ghgTotalMj",
        visible: true,
        dataType: "number",
        accessorFn: (row) => {
          return getFormattedNumberWithCheck(
            row?.inboundRecord?.ghgTotalMj,
            NUMBER_OF_DECIMALS,
          );
        },
        cell: ({cell}) => {
          return cell.getValue();
        },
      },
    ],
  },
  {
    header: "Outgoing",
    columns: [
      {
        header: "Quantity(out) MT",
        accessorKey: "quantityOutMt",
        key: "quantityOutMt",
        visible: true,
        filterFn: "massBalanceCustomFilterFunction",
        dataType: "number",
        accessorFn: (row) => {
          return getFormattedNumberWithCheck(
            row?.outboundRecords?.quantityOutMt,
            NUMBER_OF_DECIMALS,
          );
        },
        cell: ({row}) => {
          const mbInboundQtyEuId =
            row?.original?.inboundRecord?.mbInboundQtyEuId;
          const rowIndex = row?.original?.rowIndex;
          const tooltipId = `quantityOutMT-${mbInboundQtyEuId}-${rowIndex}`;
          const m3Value = row?.original.outboundRecords?.quantityOutM3;
          const mtValue = row?.original.outboundRecords?.quantityOutMt;
          return (
            <>
              <div id={tooltipId}>
                {getFormattedNumberWithCheck(mtValue, NUMBER_OF_DECIMALS)}
              </div>
              <UncontrolledTooltip placement="right" target={tooltipId}>
                {getFormattedNumberWithCheck(m3Value, NUMBER_OF_DECIMALS)} m
                <sup>3</sup>
              </UncontrolledTooltip>
            </>
          );
        },
      },
      {
        header: "Document",
        accessorKey: "document",
        key: "document",
        visible: true,
        filterFn: "massBalanceCustomFilterFunction",
        accessorFn: (row) => {
          return row?.outboundRecords?.document;
        },
        cell: ({cell, row}) => {
          return (
            <Link
              to={appendParamsToUrl(
                "/mass-balance/outgoing-view",
                [
                  selectedCountry,
                  division,
                  row?.original.outboundRecords?.certificateOutboundEuId,
                ],
                {
                  redirectTo: appendParamsToUrl("/mass-balance", [
                    selectedCountry,
                    division,
                    currentFilterValues?.mbBalanceTypeCode,
                    currentFilterValues?.mbLocationGroupName,
                    currentFilterValues?.mbPeriodName,
                    currentFilterValues?.mbBalanceGroupName,
                  ]),
                },
              )}
              className="link-dark"
            >
              <u className="flex">
                {row?.original.outboundRecords?.document && <FilePdf24 />}
                <ColumnHighlight
                  value={row?.original.outboundRecords?.document}
                  columnFilter={getFilterValue(cell, "document")}
                  hasEllipsis
                  isCertificate
                />
              </u>
            </Link>
          );
        },
      },
      {
        header: "GHG total gCO2eq/MJ",
        accessorKey: "ghgTotalMjOutgoing",
        key: "ghgTotalMjOutgoing",
        visible: true,
        filterFn: "massBalanceCustomFilterFunction",
        dataType: "number",
        accessorFn: (row) => {
          return getFormattedNumberWithCheck(
            row?.outboundRecords?.ghgTotalMjOutgoing,
            NUMBER_OF_DECIMALS,
          );
        },
        cell: ({row}) => {
          const value = row?.original.outboundRecords?.ghgTotalMjOutgoing;
          return getFormattedNumberWithCheck(value, NUMBER_OF_DECIMALS);
        },
      },
      {
        header: "GHG savings %",
        accessorKey: "ghgSavingsOutgoing",
        key: "ghgSavingsOutgoing",
        visible: true,
        filterFn: "massBalanceCustomFilterFunction",
        dataType: "number",
        accessorFn: (row) => {
          return getFormattedNumberWithCheck(
            row?.outboundRecords?.ghgSavingsOutgoing,
            NUMBER_OF_DECIMALS,
          );
        },
        cell: ({row}) => {
          const value = row?.original.outboundRecords?.ghgSavingsOutgoing;
          return getFormattedNumberWithCheck(value, NUMBER_OF_DECIMALS);
        },
      },
      {
        header: "Issuance date",
        accessorKey: "issuanceDateOutgoing",
        key: "issuanceDateOutgoing",
        visible: false,
        meta: {
          filterVariant: "daterange",
        },
        filterFn: "dateRangeFilterFnOutgoing",
        accessorFn: (row) => {
          return row?.outboundRecords?.issuanceDateOutgoing;
        },
        cell: ({row}) => {
          return (
            row?.original.outboundRecords?.issuanceDateOutgoing &&
            moment(row?.original.outboundRecords?.issuanceDateOutgoing).format(
              dateFormat,
            )
          );
        },
      },
      {
        header: "Product",
        accessorKey: "product",
        key: "product",
        visible: false,
        meta: {
          filterVariant: "select",
        },
        filterFn: "massBalanceCustomFilterFunction",
        accessorFn: (row) => {
          return row?.outboundRecords?.product;
        },
        cell: ({row}) => {
          return row?.original.outboundRecords?.product;
        },
      },
      {
        header: "Recipient",
        accessorKey: "recipient",
        key: "recipient",
        visible: false,
        meta: {
          filterVariant: "select",
        },
        filterFn: "massBalanceCustomFilterFunction",
        accessorFn: (row) => {
          return row?.outboundRecords?.recipient;
        },
        cell: ({row}) => {
          return row?.original.outboundRecords?.recipient;
        },
      },
      {
        header: "Transport system",
        accessorKey: "transportSystemOutgoing",
        key: "transportSystemOutgoing",
        visible: false,
        meta: {
          filterVariant: "select",
        },
        filterFn: "massBalanceCustomFilterFunction",
        accessorFn: (row) => {
          return row?.outboundRecords?.transportSystemOutgoing;
        },
        cell: ({row}) => {
          return row?.original.outboundRecords?.transportSystemOutgoing;
        },
      },
      {
        header: "Vessel name",
        accessorKey: "vesselNameOutgoing",
        key: "vesselNameOutgoing",
        visible: false,
        meta: {
          filterVariant: "select",
        },
        filterFn: "massBalanceCustomFilterFunction",
        accessorFn: (row) => {
          return row?.outboundRecords?.vesselNameOutgoing;
        },
        cell: ({row}) => {
          return row?.original.outboundRecords?.vesselNameOutgoing;
        },
      },
      {
        header: "Status",
        accessorKey: "status",
        key: "status",
        visible: true,
        disabled: true,
        meta: {
          filterVariant: "select",
        },
        filterFn: "massBalanceCustomFilterFunction",
        accessorFn: (row) => {
          return row?.outboundRecords?.status;
        },
        cell: ({row}) => {
          let currentStatus = row?.original.outboundRecords?.status;
          const fromMbLocationGroupId =
            row?.original.inboundRecord?.fromMbLocationGroupId;
          const linkError = row?.original.outboundRecords?.linkError;

          if (
            linkError &&
            (linkError === "PURCHASE_SALES" || linkError === "PURCHASE")
          ) {
            currentStatus = status.INVALID_PURCHASE;
          } else if (
            linkError &&
            (linkError === "PURCHASE_SALES" || linkError === "SALES")
          ) {
            currentStatus = status.INVALID_OUTGOING;
          } else if (
            currentStatus === status.AVAILABLE &&
            fromMbLocationGroupId !== null
          ) {
            currentStatus = status.AVAILABLE_FROM_TRANSFER;
          }
          const bgColor = getStatusColor(currentStatus);
          return (
            <span
              className={`whitespace-nowrap uppercase status-badge-mass-balance disabled:text-white ${bgColor}`}
            >
              {getStatusValue(currentStatus)}
            </span>
          );
        },
      },
      {
        header: "",
        accessorKey: "actions",
        key: "actions",
        size: 25,
        visible: true,
        enableSorting: false,
        cell: ({row, table}) => {
          return (
            <ActionDropdown
              row={row}
              table={table}
              currentFilterValues={currentFilterValues}
              periodStatus={periodStatus}
            />
          );
        },
      },
    ],
  },
];

export default getColumnsCoproTrsSpain;
