import {useLazyQuery, useQuery} from "@apollo/client";
import {
  COPRO_US_SHIPMENTS_API_CHECK_SHIPMENT_ID_EXISTS,
  COPRO_US_SHIPMENTS_API_GET_PREVIOUS_DTN_BTU_IDS,
} from "graphql/coprocessing/shipments";
import {
  dtnShipmentKeys as dtnKeys,
  SHIPMENT_TYPE,
} from "modules/co-processing/constants/shipments";
import moment from "moment";
import PropTypes from "prop-types";
import React, {useEffect, useState} from "react";
import {useFormContext} from "react-hook-form";
import PIData from "../../Shared/PIData";
import Form from "../Form";

const LOAD_DATE_FORMAT = "YYYY-MM-DD hh:mm A";

const truckRackFieldOrder = [
  "SHIPMENT_ID",
  "SHIPMENT_DATE",
  "PI_VISION", // field is for display only
  "SHIPMENT_START_DATE",
  "SHIPMENT_END_DATE",
  "ESTIMATED_VOLUME",
  "SOURCE_TANK",
  "NOTES",
];

const TruckRackDetails = () => {
  const {watch, setError, clearErrors} = useFormContext();
  const startTime = watch(dtnKeys.SHIPMENT_START_DATE);
  const endTime = watch(dtnKeys.SHIPMENT_END_DATE);

  const [shipmentIdError, setShipmentIdError] = useState({
    type: "custom",
    message: "",
  });

  const {data: previousDtnBtuIdsData} = useQuery(
    COPRO_US_SHIPMENTS_API_GET_PREVIOUS_DTN_BTU_IDS,
    {
      fetchPolicy: "no-cache",
    },
  );

  const prevId =
    previousDtnBtuIdsData?.bioLcCoproUsShipmentsApi?.body?.previous_dtn_btu_ids?.filter(
      (item) => item.shipment_type === SHIPMENT_TYPE.TRUCK_RACK,
    )?.[0]?.previous_id;

  const [checkShipmentIdExists] = useLazyQuery(
    COPRO_US_SHIPMENTS_API_CHECK_SHIPMENT_ID_EXISTS,
    {
      fetchPolicy: "no-cache",
      onCompleted: (data) => {
        const error = data?.bioLcCoproUsShipmentsApi?.error;
        setShipmentIdError({
          type: "custom",
          message: error,
        });
      },
    },
  );

  useEffect(() => {
    // Validate only if both startTime and endTime have been provided by the user
    if (startTime && endTime) {
      const format = "YYYY-MM-DD hh:mm A";
      const startMoment = moment(startTime, format);
      const endMoment = moment(endTime, format);
      if (startMoment.isSameOrAfter(endMoment)) {
        setError(dtnKeys.SHIPMENT_START_DATE, {
          type: "custom",
          message: "Start time must be earlier than end time.",
        });
        setError(dtnKeys.SHIPMENT_END_DATE, {
          type: "custom",
          message: "End time must be after start time.",
        });
      } else {
        clearErrors([dtnKeys.SHIPMENT_START_DATE, dtnKeys.SHIPMENT_END_DATE]);
      }
    }
  }, [startTime, endTime, setError, clearErrors]);

  return (
    <>
      <h5 className="pb-4">
        Give this truck rack shipment a unique ID and enter the shipping
        details.
      </h5>

      <div className="divide-y-2">
        <div className="row g-5 mb-8">
          {truckRackFieldOrder.map((identifier) => {
            if (identifier === "ESTIMATED_VOLUME") {
              return (
                <Form.EstVolumeDisplayField
                  key={identifier}
                  startTime={startTime}
                  endTime={endTime}
                />
              );
            }
            if (identifier === "PI_VISION") {
              return <PIData key={identifier} />;
            }

            if (identifier === "SHIPMENT_ID") {
              return (
                <Form.ShipmentIdField
                  key={identifier}
                  validate={checkShipmentIdExists}
                  fieldError={shipmentIdError}
                  prevId={prevId}
                />
              );
            }

            return <Form.Field key={identifier} fieldName={identifier} />;
          })}
        </div>
      </div>
    </>
  );
};

const backToUnitFieldOrder = [
  "SHIPMENT_ID",
  "SHIPMENT_DATE",
  "PI_VISION",
  "SHIPMENT_START_DATE",
  "SHIPMENT_END_DATE",
  "ESTIMATED_VOLUME", // user entered volume will be used as actual volume during the allocation process
  "SOURCE_TANK",
  "NOTES",
];

const BackToUnitDetails = () => {
  const {watch, setError, clearErrors} = useFormContext();
  const [loadStart, loadEnd] = watch([
    dtnKeys.SHIPMENT_START_DATE,
    dtnKeys.SHIPMENT_END_DATE,
  ]);

  const [shipmentIdError, setShipmentIdError] = useState({
    type: "custom",
    message: "",
  });

  const [checkShipmentIdExists] = useLazyQuery(
    COPRO_US_SHIPMENTS_API_CHECK_SHIPMENT_ID_EXISTS,
    {
      fetchPolicy: "no-cache",
      onCompleted: (data) => {
        const error = data?.bioLcCoproUsShipmentsApi?.error;
        const updatedErrorMessage = error?.includes("Truck rack")
          ? error.replace("Truck rack", "Back to unit")
          : error;
        setShipmentIdError({
          type: "custom",
          message: updatedErrorMessage,
        });
      },
    },
  );

  const {data: previousDtnBtuIdsData} = useQuery(
    COPRO_US_SHIPMENTS_API_GET_PREVIOUS_DTN_BTU_IDS,
    {
      fetchPolicy: "no-cache",
    },
  );

  const prevId =
    previousDtnBtuIdsData?.bioLcCoproUsShipmentsApi?.body?.previous_dtn_btu_ids?.filter(
      (item) => item.shipment_type === SHIPMENT_TYPE.BACK_TO_UNIT,
    )?.[0]?.previous_id;

  useEffect(() => {
    if (loadStart && loadEnd) {
      const startMoment = moment(loadStart, LOAD_DATE_FORMAT);
      const endMoment = moment(loadEnd, LOAD_DATE_FORMAT);
      if (startMoment.isSameOrAfter(endMoment)) {
        setError(dtnKeys.SHIPMENT_START_DATE, {
          type: "custom",
          message: "Load start must be earlier than load end.",
        });
        setError(dtnKeys.SHIPMENT_END_DATE, {
          type: "custom",
          message: "Load end must be after load start.",
        });
      } else {
        clearErrors([dtnKeys.SHIPMENT_START_DATE, dtnKeys.SHIPMENT_END_DATE]);
      }
    }
  }, [loadStart, loadEnd, setError, clearErrors]);

  return (
    <>
      <div className="col-12">
        <h5 className="display-6">Enter the back to unit details.</h5>
      </div>

      <div className="row g-5 mb-8">
        {backToUnitFieldOrder.map((identifier) => {
          if (identifier === "PI_VISION") {
            return <PIData key={identifier} />;
          }

          if (identifier === "SHIPMENT_ID") {
            return (
              <Form.ShipmentIdField
                key={identifier}
                validate={checkShipmentIdExists}
                fieldError={shipmentIdError}
                isBtu
                prevId={prevId}
              />
            );
          }

          return <Form.BackUnitField key={identifier} fieldName={identifier} />;
        })}
      </div>
    </>
  );
};

const inventoryTransferFieldOrder = [
  "PI_VISION",
  "SOURCE_TANK",
  "SOURCE_BATCH_ID",
  "DESTINATION_TANK",
  "DESTINATION_BATCH_ID",
  "SHIPMENT_START_DATE",
  "SHIPMENT_END_DATE",
  "ADJUSTED_VOLUME", // user entered volume will be used as actual volume during the allocation process
  "REMAINING_VOLUME",
  "SHIPMENT_ID",
  "NOTES",
];

const InventoryTransferDetails = () => {
  return (
    <>
      <div className="col-12">
        <h5 className="display-6">Enter the inventory transfer details.</h5>
      </div>

      <div className="row g-5 mb-8">
        {inventoryTransferFieldOrder.map((identifier) => {
          if (identifier === "PI_VISION") {
            return <PIData key={identifier} />;
          }

          if (
            identifier === "REMAINING_VOLUME" ||
            identifier === "SHIPMENT_ID"
          ) {
            return (
              <Form.InventoryTransferTextDisplay
                key={identifier}
                fieldName={identifier}
              />
            );
          }

          if (identifier === "SOURCE_TANK") {
            return (
              <Form.TankNumberField
                key={identifier}
                identifier={identifier}
                label="Source tank number"
                dtnKey={dtnKeys.SOURCE_TANK}
                rules={{
                  required: "Source tank is required.",
                  validate: (value) => value != null,
                }}
              />
            );
          }

          if (identifier === "DESTINATION_TANK") {
            return (
              <Form.TankNumberField
                key={identifier}
                identifier={identifier}
                label="Destination tank number"
                dtnKey={dtnKeys.DESTINATION_TANK}
                rules={{
                  required: "Destination tank is required.",
                  validate: (value) => value != null,
                }}
              />
            );
          }

          if (identifier === "SOURCE_BATCH_ID") {
            return <Form.SourceBatchIdField key={identifier} />;
          }

          if (identifier === "DESTINATION_BATCH_ID") {
            return <Form.DestinationBatchIdField key={identifier} />;
          }

          return (
            <Form.InventoryTransferField
              key={identifier}
              fieldName={identifier}
            />
          );
        })}
      </div>
    </>
  );
};

const dtnDetailsConfig = {
  [SHIPMENT_TYPE.BACK_TO_UNIT]: BackToUnitDetails,
  [SHIPMENT_TYPE.TRUCK_RACK]: TruckRackDetails,
  [SHIPMENT_TYPE.INVENTORY_TRANSFER]: InventoryTransferDetails,
};

const EnterDetails = ({shipmentType}) => {
  const ShipmentDetailsComponent = dtnDetailsConfig?.[shipmentType];

  return (
    <div className="row pt-4" data-test="enter-shipment-deets">
      {ShipmentDetailsComponent ? (
        <ShipmentDetailsComponent />
      ) : (
        <p>Please select a shipment type.</p>
      )}
    </div>
  );
};

EnterDetails.propTypes = {
  shipmentType: PropTypes.oneOf(Object.keys(SHIPMENT_TYPE)).isRequired,
};
export default EnterDetails;
