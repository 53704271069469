import {TriangleDown16, TriangleUp16} from "@bphxd/ds-core-react/lib/icons";
import {
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import classNames from "classnames";
import {
  addEditColumn,
  customArrayFilterFn,
  customBooleanSelectFilterFn,
} from "modules/GlobalConfigCenter/utils";
import {customDateFilterFn} from "modules/SAF-dashboard/utils";
import PropTypes from "prop-types";
import {Fragment} from "react";
import {Table} from "reactstrap";
import Filter from "../Filter/Filter";

const CustomReactTable = ({
  columns,
  data,
  enableSorting = true,
  columnFilter,
  initialState,
  showFilter,
  onClickEditRow,
  responsive = true,
  setColumnFilter,
  tableClassName,
  ...reactstrapTableProps
}) => {
  let newColumns = columns;

  if (onClickEditRow) {
    newColumns = addEditColumn(columns, onClickEditRow);
  }

  const table = useReactTable({
    data,
    columns: newColumns,
    filterFns: {
      dateRangeFilterFn: customDateFilterFn,
      booleanSelectFilterfn: customBooleanSelectFilterFn,
      arrayFilterFn: customArrayFilterFn,
    },
    state: {
      columnFilters: columnFilter,
    },
    initialState,
    enableSorting,
    onColumnFiltersChange: setColumnFilter,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
  });

  const rowCount = table.getPrePaginationRowModel().rows.length;
  return (
    <Table
      responsive={responsive}
      {...reactstrapTableProps}
      className={classNames("react-table", tableClassName)}
    >
      <thead>
        {table.getHeaderGroups().map((headerGroup) => (
          <tr key={headerGroup.id}>
            {headerGroup.headers.map((header) => (
              <Fragment key={header.id}>
                {header.isPlaceholder ? (
                  <th aria-label="Placeholder header" />
                ) : header.column.getCanSort() ? (
                  <th
                    style={{
                      cursor: "pointer",
                      userSelect: "none",
                      width: header.getSize(),
                    }}
                  >
                    <div
                      onClick={header.column.getToggleSortingHandler()}
                      onKeyDown={header.column.getToggleSortingHandler()}
                      className="d-flex align-items-center w-max"
                    >
                      <div>
                        {flexRender(
                          header.column.columnDef.header,
                          header.getContext(),
                        )}
                      </div>
                      <div className="position-relative ms-2">
                        <div className="react-table-sort position-absolute top-50 translate-middle-y">
                          <TriangleDown16
                            className={`position-absolute bottom-0 ${
                              !header.column.getIsSorted() ||
                              (header.column.getIsSorted() &&
                                header.column.getIsSorted() !== "desc")
                                ? "opacity-20"
                                : ""
                            }`}
                          />
                          <TriangleUp16
                            className={`position-absolute top-0 ${
                              !header.column.getIsSorted() ||
                              (header.column.getIsSorted() &&
                                header.column.getIsSorted() !== "asc")
                                ? "opacity-20"
                                : ""
                            }`}
                          />
                        </div>
                      </div>
                    </div>
                    <div
                      className={
                        "mt-[10px] w-max " +
                        (header.column.getCanFilter() && showFilter
                          ? "opacity-1 h-100"
                          : "opacity-0 h-0")
                      }
                    >
                      <Filter column={header.column} data={data} />
                    </div>
                  </th>
                ) : (
                  <th
                    // align-top for row selection and row options columns so positioning is same as <td>
                    className="align-top"
                    style={{
                      width: header.getSize(),
                    }}
                  >
                    {flexRender(
                      header.column.columnDef.header,
                      header.getContext(),
                    )}
                  </th>
                )}
              </Fragment>
            ))}
          </tr>
        ))}
      </thead>

      <tbody>
        {rowCount === 0 &&
          (showFilter ? (
            <tr className="h-96">
              <td colSpan="100%">
                <div className="pt-7 pl-5 text-center">
                  {`Sorry, no records match your filter. `}
                  <br />
                  <span
                    onClick={() => {
                      setColumnFilter([]);
                    }}
                    onKeyDown={() => {
                      setColumnFilter([]);
                    }}
                    className="underline cursor-pointer leading-10"
                  >
                    Clear the filters
                  </span>{" "}
                  <span>to view all the records.</span>
                </div>
              </td>
            </tr>
          ) : (
            <tr className="h-96">
              <td colSpan="100%">
                <div className="pt-7 pl-5 text-center">
                  <span>Loading...</span>
                </div>
              </td>
            </tr>
          ))}
        {table.getRowModel().rows.map((row) => (
          <tr key={row.id}>
            {row.getVisibleCells().map((cell) => (
              <td key={cell.id} className="whitespace-nowrap">
                {flexRender(cell.column.columnDef.cell, cell.getContext())}
              </td>
            ))}
          </tr>
        ))}
        {rowCount < 7 && <tr style={{height: `${(7 - rowCount) * 52}px`}}></tr>}
      </tbody>
    </Table>
  );
};

CustomReactTable.propTypes = {
  columns: PropTypes.array.isRequired,
  data: PropTypes.array.isRequired,
  enableSorting: PropTypes.bool,
  columnFilter: PropTypes.array.isRequired,
  initialState: PropTypes.object,
  showFilter: PropTypes.bool.isRequired,
  onClickEditRow: PropTypes.func,
  responsive: PropTypes.bool,
  setColumnFilter: PropTypes.func.isRequired,
  tableClassName: PropTypes.string,
};

CustomReactTable.defaultProps = {
  enableSorting: true,
  responsive: true,
};

export default CustomReactTable;
