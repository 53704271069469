import {gql} from "@apollo/client";

export const ALL_INCOMING_DOCUMENTS = gql`
  query getIncomingDocuments($divisionId: String, $siteReferenceId: String) {
    bioLcGetIncomingDocsApi(
      event: {divisionId: $divisionId, siteReferenceId: $siteReferenceId}
    ) {
      documents {
        bvAuditChangedDatez
        bvAuditCreatedDatez
        certificateInboundEuId
        certificateNumber
        contractNumber
        certificationSystem
        countryCode
        dateOfIssuance
        docName
        feedstockIssueDate
        feedstockIssueDatez
        feedstockQty
        feedstockQtyUom
        ghgEmissionPercentageIn
        ghgTotal
        inboundDocStatusEuId
        materialCode
        mbCycle
        mbLocation
        mbLocationCode
        physicalReceiptDate
        productQty
        productQtyUom
        productType
        rawMaterialType
        recipientAddress
        recipientName
        recipientReceiptAddress
        sdNumber
        rawMaterialOriginCountryName
        state
        supplierAddress
        supplierDispatchAddress
        supplierName
        materialDispatchDatez
        bvAuditCreatedDatez
        ghgEmissionPercentageIn
        versionCount
        splitDetails {
          feedstockQty
          ghgEccr
          ghgEccs
          ghgEee
          ghgEl
          ghgEp
          ghgEsca
          ghgEtd
          ghgEu
          ghgTotal
          mbCycle
          originCountryName
          physicalReceiptDate
        }
        versionHistoryData {
          bvAuditChangedDatez
          linkedCert
          sdNumber
        }
        mbBalanceTypeCode
        mbBalanceGroupId
        mbBalanceGroupName
        mbLocationGroupId
        mbLocationGroupName
        mbPeriodName
        mbPeriodId
        mbPeriodFrom
        mbPeriodTo
      }
      error
      statusCode
    }
    bioLcGetDivisionData(event: {}) {
      divisionData {
        divisionCode
        divisionId
        divisionName
      }
      error
      statusCode
    }
  }
`;

export const ALL_INCOMING_DOCUMENTS_GLOBAL = gql`
  query getIncomingDocumentsGlobal(
    $divisionId: String
    $siteReferenceId: String
  ) {
    bioLcGetIncomingDocsApi(
      event: {divisionId: $divisionId, siteReferenceId: $siteReferenceId}
    ) {
      documents {
        bvAuditChangedDatez
        bvAuditCreatedDatez
        certificateInboundEuId
        certificateNumber
        contractNumber
        certificationSystem
        countryCode
        dateOfIssuance
        docName
        feedstockIssueDate
        feedstockIssueDatez
        feedstockQty
        feedstockQtyUom
        ghgEmissionPercentageIn
        ghgTotal
        inboundDocStatusEuId
        materialCode
        mbCycle
        mbLocation
        mbLocationCode
        physicalReceiptDate
        productQty
        productQtyUom
        productType
        rawMaterialType
        recipientAddress
        recipientName
        recipientReceiptAddress
        sdNumber
        rawMaterialOriginCountryName
        state
        supplierAddress
        supplierDispatchAddress
        supplierName
        materialDispatchDatez
        bvAuditCreatedDatez
        ghgEmissionPercentageIn
        versionCount
        docNumberSplit
        splitDetails {
          feedstockQty
          ghgEccr
          ghgEccs
          ghgEee
          ghgEl
          ghgEp
          ghgEsca
          ghgEtd
          ghgEu
          ghgTotal
          mbCycle
          originCountryName
          physicalReceiptDate
          docNumberSplit
          state
          mbBalanceGroupId
          mbBalanceGroupName
          mbBalanceTypeId
          mbBalanceTypeCode
          mbLocationGroupId
          mbLocationGroupName
          mbPeriodName
          mbPeriodId
          mbPeriodFrom
          mbPeriodTo
        }
        versionHistoryData {
          bvAuditChangedDatez
          linkedCert
          sdNumber
        }
        mbBalanceTypeCode
        mbBalanceGroupId
        mbBalanceGroupName
        mbLocationGroupId
        mbLocationGroupName
        mbPeriodName
        mbPeriodId
        mbPeriodFrom
        mbPeriodTo
      }
      error
      statusCode
    }
    bioLcGetDivisionData(event: {}) {
      divisionData {
        divisionCode
        divisionId
        divisionName
      }
      error
      statusCode
    }
  }
`;

export default {ALL_INCOMING_DOCUMENTS, ALL_INCOMING_DOCUMENTS_GLOBAL};
