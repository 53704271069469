import React from "react";

import {useQuery} from "@apollo/client";
import Layout from "modules/GlobalConfigCenter/components/Layout/Layout";
import {BUSINESS_PARTNER_LINK_LM_COULMNS} from "modules/GlobalConfigCenter/constants/Columns";
import {BUSINESS_PARTNER_LINK_LM_CONFIG_PAGE} from "modules/GlobalConfigCenter/constants/ConfigurationPages";
import {BUSINESS_PARTNER_LINK_LM_GET_QUERY} from "../../../../graphql/GlobalConfigCenter/BusinessPartnerLinkLm";

const BusinessPartnerLinkLmConfigPage = () => {
  const {data, loading} = useQuery(BUSINESS_PARTNER_LINK_LM_GET_QUERY);

  return (
    <Layout
      title={BUSINESS_PARTNER_LINK_LM_CONFIG_PAGE.title}
      isGlobal
      description={BUSINESS_PARTNER_LINK_LM_CONFIG_PAGE.description}
      tableColumns={BUSINESS_PARTNER_LINK_LM_COULMNS}
      tableData={
        loading
          ? []
          : data?.bioLcGetBusinessPartnerLinkLm.businessPartnerLinkLmData
      }
    />
  );
};

export default BusinessPartnerLinkLmConfigPage;
