import {Alert24} from "@bphxd/ds-core-react/lib/icons";
import {CONFIG_CENTER_URL} from "modules/SAF-dashboard/constants/common";
import PropTypes from "prop-types";
import React, {useState} from "react";
import {useNavigate} from "react-router-dom";
import {Popover, PopoverBody, PopoverHeader} from "reactstrap";

const Warning = ({row}) => {
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();

  const balanceGroup = row?.original?.purchase_balance_group_name;
  const locationGroup = row?.original?.purchase_location_group_name;
  const mbDate = row?.original?.mb_date;
  const purchasdQuantityMt = row?.original?.purchase_qty_mt;
  const receivedQuantityMt = row?.original?.received_qty_mt;

  const commonHeader = `Location group, Balance Group, MB Date fields are mandatory and Purchased Quantity, Received Quantity values must be strictly positive for actualizing the documents. Please add the required missing fields in the `;
  const missingDataNames = [
    {name: "Balance Group", value: balanceGroup},
    {name: "Location Group", value: locationGroup},
    {name: "MB Date", value: mbDate},
    {name: "Purchased Quantity", value: purchasdQuantityMt},
    {name: "Received Quantity", value: receivedQuantityMt},
  ]
    .filter((item) => item.value === null)
    .map((item) => item.name);

  const warningHeader =
    missingDataNames.length === 0
      ? "Positive Quantity Values Required"
      : missingDataNames.length === 1
      ? `${missingDataNames[0]} Field Missing`
      : `Multiple Fields Missing`;

  return (
    <div>
      <Alert24
        className="cursor-pointer"
        color="#e64949"
        id={`popover-${row?.id}`}
        onClick={() => setOpen(!open)}
      />
      <Popover
        fade
        flip
        placement="left"
        target={`popover-${row?.id}`}
        isOpen={open}
      >
        <PopoverHeader>{warningHeader}</PopoverHeader>
        <PopoverBody>
          {commonHeader}
          <span
            className="underline cursor-pointer"
            onClick={() => {
              navigate({pathname: CONFIG_CENTER_URL});
            }}
            onKeyDown={() => {}}
          >
            Configuration centre.
          </span>
        </PopoverBody>
      </Popover>
    </div>
  );
};

Warning.propTypes = {
  row: PropTypes.object,
};

export default Warning;
