import Form from "modules/common/Form";
import PropTypes from "prop-types";
import {cloneElement, useMemo} from "react";
import {
  AccordionBody,
  AccordionHeader,
  AccordionItem,
  UncontrolledAccordion,
} from "reactstrap";
import "./index.scss";

const Filter = ({className, children, onFilter, defaultValues, schema}) => {
  return (
    <UncontrolledAccordion defaultOpen="1">
      <AccordionItem>
        <AccordionHeader
          targetId="1"
          className="fw-regular compliance-filter-header !text-xl"
        >
          Compliance scheme filtering
        </AccordionHeader>
        <AccordionBody accordionId="1">
          <Form
            className={["bp-filter", className].join(" ")}
            defaultValues={defaultValues}
            onSubmit={onFilter}
            schema={schema}
          >
            <FilterChildrenWrapper>{children}</FilterChildrenWrapper>
          </Form>
        </AccordionBody>
      </AccordionItem>
    </UncontrolledAccordion>
  );
};

const FilterChildrenWrapper = ({children}) => {
  const OneColLayout = useMemo(
    () => <div className="bp-filter-layout">{cloneElement(children)}</div>,
    [children],
  );

  return <div className="bp-filter-content">{OneColLayout}</div>;
};

FilterChildrenWrapper.propTypes = {
  children: PropTypes.node.isRequired,
};

Filter.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  onFilter: PropTypes.func,
  defaultValues: PropTypes.object,
  schema: PropTypes.object,
};

export default Filter;
