import {gql} from "@apollo/client";
import {client} from "providers/Apollo";

const UPDATE_DOCUMENT = gql`
  mutation bioLcMassBalanceTransportationLossApi(
    $event: bioLcMassBalanceTransportationLossApiRequest!
  ) {
    bioLcMassBalanceTransportationLossApi(event: $event) {
      statusCode
      message
    }
  }
`;

export const UpdateDocAPI = (data) => {
  return client.mutate({
    mutation: UPDATE_DOCUMENT,
    variables: {
      ...data,
    },
    refetchQueries: [
      "bioLcGetMassBalanceTransactionData",
      "bioLcGetMassBalanceCounts",
    ],
  });
};

export default {UPDATE_DOCUMENT, UpdateDocAPI};
