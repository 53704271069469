import DatePicker from "modules/common/DatePickerNew";
import FormField from "modules/common/FormFieldNew.js";
import PropTypes from "prop-types";
import React, {useEffect, useMemo} from "react";
import {Controller, useFormContext} from "react-hook-form";
import {Input} from "reactstrap";
import dateFunctions from "utils/helpers/dateFunctions";

const GenerateCertificateForm = ({formData}) => {
  const {
    control,
    setValue,
    formState: {errors},
  } = useFormContext();

  useEffect(() => {
    if (formData?.bioLcGetCertGenModalData) {
      setValue(
        "dateOfIssuance",
        formData?.bioLcGetCertGenModalData?.dateOfIssuance,
      );
      setValue(
        "recipientName",
        formData?.bioLcGetCertGenModalData?.nameOfRecipient,
      );
      setValue(
        "addressOfRecipient",
        formData?.bioLcGetCertGenModalData?.addressOfRecipient,
      );
      setValue(
        "addressOfReceivingPoint",
        formData?.bioLcGetCertGenModalData?.receivingPoint,
      );
      setValue(
        "contractNumber",
        formData?.bioLcGetCertGenModalData?.contractNumber,
      );
    }
  }, [formData?.bioLcGetCertGenModalData, setValue]);

  const dateFormat = dateFunctions.convertDateFormattoUser("d/m/Y");

  const modesOfTransportations = useMemo(() => {
    return formData?.bioLcGetCertGenModalData?.motValues;
  }, [formData?.bioLcGetCertGenModalData]);

  return (
    <div className="grid sm:grid-cols-1 text-left md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-2 gap-x-4 gap-y-4 ">
      <FormField
        label="Date of issuance"
        errorMessage={errors.dateOfIssuance?.message}
        className="p-4"
      >
        <Controller
          name="dateOfIssuance"
          render={({field: {onChange, value}}) => (
            <DatePicker
              data-test="dateOfIssuance"
              placeholder="Date of issuance"
              id="dateOfIssuance"
              name="dateOfIssuance"
              invalid={!!errors.dateOfIssuance}
              options={{
                allowInput: true,
                mode: "single",
                defaultDate: value,
                dateFormat,
                altFormat: dateFormat,
                onChange: (selectedDates) => onChange(selectedDates[0]),
              }}
            />
          )}
        />
      </FormField>
      <FormField
        label="Mode of transportation"
        errorMessage={errors.modeOfTransportation?.message}
        className="p-4"
      >
        <Controller
          name="modeOfTransportation"
          render={({field}) => (
            <Input
              {...field}
              type="select"
              id="modeOfTransportation"
              data-test="modeOfTransportation"
              invalid={!!errors.modeOfTransportation}
            >
              <option key="none" value="">
                Select
              </option>
              {modesOfTransportations?.map((mode) => (
                <option key={mode.motName} value={mode.motName}>
                  {mode.motName}
                </option>
              ))}
            </Input>
          )}
        />
      </FormField>
      <FormField
        label="Name of recipient"
        errorMessage={errors.recipientName?.message}
        className="p-4 text-[#11111191]"
      >
        <Controller
          control={control}
          name="recipientName"
          render={({field}) => (
            <Input
              {...field}
              type="text"
              id="recipientName"
              data-test="recipientName"
              placeholder="Enter"
              invalid={!!errors.recipientName}
              className="bg-[#fafafa] !text-[#11111180]"
            />
          )}
          disabled
        />
      </FormField>
      <FormField
        label="Contract number"
        errorMessage={errors.contractNumber?.message}
        className="p-4"
      >
        <Controller
          name="contractNumber"
          render={({field}) => (
            <Input
              {...field}
              type="text"
              id="contractNumber"
              data-test="contractNumber"
              placeholder="Please enter GRN"
              invalid={!!errors.contractNumber}
              maxLength={50}
            />
          )}
        />
      </FormField>
      <FormField
        label="Address of recipient"
        errorMessage={errors.addressOfRecipient?.message}
        className="p-4 text-[#11111191]"
      >
        <Controller
          name="addressOfRecipient"
          render={({field}) => (
            <Input
              {...field}
              type="text"
              id="addressOfRecipient"
              data-test="addressOfRecipient"
              placeholder="Enter"
              invalid={!!errors.addressOfRecipient}
              className="bg-[#fafafa] !text-[#11111180]"
              disabled
            />
          )}
        />
      </FormField>
      <FormField
        label="Address of receipt/receiving point"
        errorMessage={errors.addressOfReceivingPoint?.message}
        className="p-4 text-[#11111191]"
      >
        <Controller
          name="addressOfReceivingPoint"
          render={({field}) => (
            <Input
              {...field}
              type="text"
              id="addressOfReceivingPoint"
              data-test="addressOfReceivingPoint"
              placeholder="Enter"
              invalid={!!errors.addressOfReceivingPoint}
              className="bg-[#fafafa] !text-[#11111180]"
              disabled
            />
          )}
        />
      </FormField>
    </div>
  );
};

GenerateCertificateForm.propTypes = {
  rowData: PropTypes.object,
  formData: PropTypes.object,
};

export default GenerateCertificateForm;
