import moment from "moment";
import {
  DOC_TYPE,
  DOC_PROCESSED_STATUS,
  feedstockHeaders,
} from "../constants/receipts";

const getLatestDate = (date, previousDate) => {
  if (moment(date).isAfter(moment(previousDate)) || !previousDate) {
    return date;
  }
  return previousDate;
};

const retrieveTasksData = (tasksData, taskType) => {
  if (!tasksData) return {};
  const reviewErrors = [];
  const missingCofaIdErrors = [];
  const missingC14IdErrors = [];
  const spreadsheetFailures = [];
  let spreadsheetFailuresLatest = null;
  const recordsUpdated = [];
  let recordsUpdatedLatest = null;
  tasksData.forEach((data) => {
    console.info(
      `taskData: ${data}, ${data.doc_type}, ${data.file_processed_status}`,
    );
    if (
      data.doc_type.toUpperCase() === "PDF" &&
      data.file_processed_status.toUpperCase() !== "SUCCEEDED" &&
      data.file_processed_status.toUpperCase() !== "UPDATED"
    ) {
      // import errors
      if (data.resource_id?.cofa_id == null && taskType === "receipts") {
        missingCofaIdErrors.push(data);
      } else if (data.resource_id?.c14_id == null && taskType === "batches") {
        missingC14IdErrors.push(data);
      } else {
        reviewErrors.push(data);
      }
    }
    if (
      data.doc_type === DOC_TYPE.XLSX &&
      data.file_processed_status === DOC_PROCESSED_STATUS.FAILED
    ) {
      // spreadsheet failure
      spreadsheetFailures.push(data);
      spreadsheetFailuresLatest = getLatestDate(
        data.bv_audit_changed_datez,
        spreadsheetFailuresLatest,
      );
    }

    if (data.file_processed_status.toUpperCase() === "UPDATED") {
      // records updated
      recordsUpdated.push(data);
      recordsUpdatedLatest = getLatestDate(
        data.bv_audit_changed_datez,
        recordsUpdatedLatest,
      );
    }
  });

  return {
    review: {
      items: reviewErrors,
    },
    updated: {
      items: recordsUpdated,
      latestUpdate: recordsUpdatedLatest,
    },
    failures: {
      items: spreadsheetFailures,
      latestUpdate: spreadsheetFailuresLatest,
    },
    missingCofaIdErrors,
    missingC14IdErrors,
  };
};

export default retrieveTasksData;

const toEasternTime = (dateString) => {
  const date = moment.utc(dateString);
  const offset = date.isDST() ? 4 : 5;
  return date.subtract(offset, "hours").format("MM/DD/YYYY");
};

export const updatedRecordsFormatter = (data) => {
  const result = [];

  data?.items?.forEach((item) => {
    if (item.doc_type === "xlsx" && item.resource_id?.bol_ids) {
      const bolIdsStr = item.resource_id.bol_ids;
      const pattern =
        /bol_number=(\d+), field=([^,]+), old_value=[^,]+, new_value=[^}]+/g;
      const matches = bolIdsStr.matchAll(pattern);
      const bolIds = [...matches].map((match) => ({
        bol_number: match[1],
        field: match[2],
      }));

      bolIds.forEach((record) => {
        const formattedDate = toEasternTime(item.bv_audit_changed_datez);
        result.push(
          `The ${feedstockHeaders[record.field]} for BOL# ${
            record.bol_number
          } was updated on ${formattedDate}`,
        );
      });
    } else if (item.doc_type === "pdf" && item.resource_id.cofa_id) {
      const formattedDate = toEasternTime(item.bv_audit_changed_datez);

      result.push(`CofA ${item.doc_name} was updated on ${formattedDate}`);
    } else if (item.doc_type === "pdf" && item.resource_id.c14_id) {
      const formattedDate = toEasternTime(item.bv_audit_changed_datez);

      result.push(`C14 ${item.doc_name} was updated on ${formattedDate}`);
    }
  });

  return result;
};
