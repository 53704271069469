import * as XLSX from "xlsx";
import {
  feedstockContractHeaders,
  feedstockHeaders,
  feedstockOWBHeaders,
  feedstockReceiptsHeaders,
  feedstockSheets,
} from "../constants/receipts";

const feedstockTypeSheets = {
  sheet_3: feedstockOWBHeaders,
  sheet_2: feedstockContractHeaders,
  sheet_4: feedstockReceiptsHeaders,
};

// Currently maintaining the following order for displaying sheets
// Bio Feedstock OWB Info, Bio Feedstock Contracts, Bio Feedstock Receipts, Feedstock Tracking Table
const sheetOrder = ["sheet_3", "sheet_2", "sheet_4"];

const areNoneNull = (obj) =>
  Object.keys(obj).length > 0 && !Object.values(obj).includes(null);

const buildExportFn = (data, exportSheets, fileName) => {
  const buildWorksheetValues = (dataObject, headers) => {
    const values = dataObject.map((x) => {
      return Object.fromEntries(
        Object.entries(x).filter(([key]) => {
          return Object.keys(headers).includes(key);
        }),
      );
    });
    return values || "-";
  };

  const sheetsNotNull = areNoneNull(exportSheets);

  const exportExcelData = () => {
    const workbook = XLSX.utils.book_new();

    if (Array.isArray(data) && data?.length > 0) {
      if (sheetsNotNull) {
        sheetOrder.forEach((obj) => {
          const values = buildWorksheetValues(
            exportSheets[obj],
            feedstockTypeSheets[`${obj}`],
          );

          const ws = XLSX.utils.json_to_sheet([feedstockTypeSheets[`${obj}`]], {
            header: Object.keys(feedstockTypeSheets[`${obj}`]),
            skipHeader: true,
            origin: 0,
          });
          XLSX.utils.sheet_add_json(ws, values, {
            header: Object.keys(feedstockTypeSheets[`${obj}`]),
            skipHeader: true,
            origin: -1,
          });

          // Logic to loop and replace empty cells with "-" in the excel file
          const range = XLSX.utils.decode_range(ws["!ref"]);

          for (let R = range.s.r; R <= range.e.r; R += 1) {
            for (let C = range.s.c; C <= range.e.c; C += 1) {
              const cell_address = XLSX.utils.encode_cell({r: R, c: C});
              if (!ws[cell_address]) {
                ws[cell_address] = {v: "-", t: "s"};
              }
            }
          }

          XLSX.utils.book_append_sheet(workbook, ws, feedstockSheets[`${obj}`]);
        });
      }
      const values = buildWorksheetValues(data, feedstockHeaders);
      const ws = XLSX.utils.json_to_sheet([feedstockHeaders], {
        header: Object.keys(feedstockHeaders),
        skipHeader: true,
        origin: 0,
      });
      XLSX.utils.sheet_add_json(ws, values, {
        header: Object.keys(feedstockHeaders),
        skipHeader: true,
        origin: -1,
      });
      XLSX.utils.book_append_sheet(workbook, ws, feedstockSheets.sheet_1);
    } else {
      throw new Error("No data supplied.");
    }
    XLSX.writeFile(workbook, fileName);
  };
  return exportExcelData;
};

export default buildExportFn;
