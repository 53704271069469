const {gql} = require("@apollo/client");

const GENERATE_PDF_SUBSCRIPTION_NEW = gql`
  subscription onBiolccertgenpdfcomplete($transactionId: String!) {
    onBiolccertgenpdfcomplete(transactionId: $transactionId) {
      transactionId
      statusCode
      error
      certificateOutboundData {
        certificateOutboundEuId
        sdNumber
        status
      }
    }
  }
`;

export default GENERATE_PDF_SUBSCRIPTION_NEW;
