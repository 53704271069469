import {startCase} from "lodash";
import {useAppSetting} from "providers/appSetting";
import React from "react";
import {useParams} from "react-router-dom";
import {setCountryDetails} from "utils/helpers/getAppSetting";
import Layout from "../components/Layout";
import NabisyForm from "../components/NabisyForm";

const NabisyDocumentRetrievalPage = () => {
  const {appSetting} = useAppSetting();
  const {country, division} = useParams();

  setCountryDetails(country);
  const divisionCode = division.toUpperCase();

  const breadcrumbItems = [
    {text: "BioVerse", link: "/"},
    {text: divisionCode, link: "/"},
    {
      text: startCase(appSetting?.currentCountry?.toLowerCase() ?? ""),
      link: "/",
    },
    {text: "Documents"},
  ];
  return (
    <Layout title="Documents" breadcrumbs={breadcrumbItems}>
      <div className="flex flex-col justify-stretch w-full items-start px-7">
        <NabisyForm />
      </div>
    </Layout>
  );
};

export default NabisyDocumentRetrievalPage;
