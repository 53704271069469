import * as XLSX from "xlsx";
import React, {useCallback, useMemo} from "react";
import PropTypes from "prop-types";
import {Button} from "reactstrap";
import {DownloadDocumentGeneric24} from "@bphxd/ds-core-react/lib/icons";
import {toast} from "react-toastify";
import {useLazyQuery} from "@apollo/client";
import {COPRO_US_GET_SHIPMENT_BOLS} from "graphql/coprocessing/shipments";
import {displayDate} from "modules/co-processing/helpers/dateHelper";

const replaceNullWithHyphen = (value) =>
  value === "" || value == null ? "-" : value;

const FILE_NAME = "outgoing_bols.xlsx";

const BOL_COLUMNS = [
  {
    header: "Shipment ID",
    key: "shipment_id",
    fmt: replaceNullWithHyphen,
  },
  {
    header: "Date",
    key: "start_load_date",
    fmt: (value) => (value ? displayDate(value) : replaceNullWithHyphen(value)),
  },
  {
    header: "Bill of Lading (BOL)",
    key: "outgoing_bol_number",
    fmt: replaceNullWithHyphen,
  },
  {
    header: "SAP Material Number",
    key: "sap_material_id",
    fmt: replaceNullWithHyphen,
  },
];

// Volume column with different keys
const VOLUME_COLUMNS = {
  truckRack: {
    header: "Truck Lifting Volume (gal)",
    key: "truck_lifting_volume",
    fmt: (value) => (value == null ? 0 : -Number(value)),
    isVolume: true,
  },
  unallocated: {
    header: "Truck Lifting Volume (gal)",
    key: "truck_lifting_volume_gal",
    fmt: (value) => (value == null ? 0 : -Number(value)),
    isVolume: true,
  },
};

const TRUCK_RACK_BOL_COLUMNS = [...BOL_COLUMNS, VOLUME_COLUMNS.truckRack];
const UNALLOCATED_BOL_COLUMNS = [...BOL_COLUMNS, VOLUME_COLUMNS.unallocated];

export const generateExcel = ({
  data,
  columns,
  fileName = "co-pro-export.xlsx",
  sheetName = "Sheet1",
}) => {
  const headers = columns.map((col) => col.header);

  const formattedData = data.map((row) =>
    columns?.map((col) => col.fmt(row[col.key])),
  );

  const worksheet = XLSX.utils.aoa_to_sheet([headers, ...formattedData]);

  // Format columns (like volume data)
  columns.forEach((col, columnIndex) => {
    if (col.isVolume) {
      formattedData.forEach((_, rowIndex) => {
        const cellAddress = XLSX.utils.encode_cell({
          c: columnIndex,
          r: rowIndex + 1,
        });

        if (worksheet?.[cellAddress]) {
          worksheet[cellAddress].s = {alignment: {horizontal: "right"}};
          worksheet[cellAddress].z =
            '_(* #,##0.00_);_(* (#,##0.00);_(* "-"??_);_(@_)';
          worksheet[cellAddress].t = "n";
        }
      });
    }
  });

  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, sheetName);
  XLSX.writeFile(workbook, fileName);
};

export const generateBOLExcel = (bolData) => {
  generateExcel({
    data: bolData,
    columns: UNALLOCATED_BOL_COLUMNS,
    fileName: FILE_NAME,
    sheetName: "Unallocated BOLs",
  });
};

export const generateTruckRackBOLExcel = (bolData) => {
  generateExcel({
    data: bolData,
    columns: TRUCK_RACK_BOL_COLUMNS,
    fileName: FILE_NAME,
    sheetName: "Truck Rack BOLs",
  });
};

const ExportBOLButton = ({truckRackShipments}) => {
  const [fetchShipmentBOLs, {loading}] = useLazyQuery(
    COPRO_US_GET_SHIPMENT_BOLS,
  );
  const shipmentIds = useMemo(
    () => truckRackShipments.map((item) => item.shipment_id),
    [truckRackShipments],
  );

  const handleExportBOLs = useCallback(async () => {
    try {
      const {data, error} = await fetchShipmentBOLs({
        variables: {shipmentIds},
      });

      if (error) {
        throw new Error(error.message);
      }

      const shipmentBOLs =
        data.bioLcCoproUsShipmentsApi?.body?.shipment_bols ?? [];

      if (shipmentBOLs.length === 0) {
        throw new Error("No BOLs found for truck rack shipments.");
      }

      const BOLs = shipmentBOLs.flatMap((shipment) =>
        shipment?.bols?.map((bol) => ({
          ...bol,
          shipment_id: shipment?.shipment_id,
        })),
      );
      generateTruckRackBOLExcel(BOLs);
      toast.success("BOLs exported successfully");
    } catch (error) {
      console.error("Error exporting BOLs:", error);
      toast.error(`Export failed: ${error.message}`);
    }
  }, [shipmentIds, fetchShipmentBOLs]);

  return (
    <Button
      color="standard-secondary"
      className="rounded-0 ml-auto mr-1"
      data-test="bol-export-btn"
      onClick={handleExportBOLs}
      disabled={!shipmentIds.length || loading}
    >
      {loading ? "Exporting..." : "Truck rack BOLs"}
      <DownloadDocumentGeneric24 className="btn-icon-suffix" />
    </Button>
  );
};

ExportBOLButton.propTypes = {
  truckRackShipments: PropTypes.arrayOf(
    PropTypes.shape({
      shipment_id: PropTypes.string.isRequired,
    }),
  ).isRequired,
};

export default ExportBOLButton;
