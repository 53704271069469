import moment from "moment";

const {DIV_CODE_SAF} = require("constants/divisionDetails");
const {getColumnsSAF} = require("./components/MandateAllocateTable/columnsSAF");

export const formatDate = (date, dateFormat) => {
  const formattedDate = date.split(" ")[0];
  return moment(formattedDate).format(dateFormat);
};

const getMandateAllocationColumns = (
  country,
  division,
  dateFormat,
  decimalFormat,
  siteReferenceId,
  divisionId,
  backToAllocationPage,
) => {
  switch (division) {
    case DIV_CODE_SAF:
      return getColumnsSAF(
        dateFormat,
        decimalFormat,
        country,
        siteReferenceId,
        divisionId,
        backToAllocationPage,
      );
    default:
      return getColumnsSAF(
        dateFormat,
        decimalFormat,
        country,
        siteReferenceId,
        divisionId,
        backToAllocationPage,
      );
  }
};

export const mandateActionItems = {
  ALLOCATE: "Allocate",
  REMOVE_ALLOCATION: "Remove allocation",
  GENERATE_DOCUMENT: "Generate document",
  REMOVE_DOCUMENT: "Remove document",
};

export const mandateCertStatus = {
  AVAILABLE: "AVAILABLE",
  ALLOCATED: "ALLOCATED",
  ALLOCATED_AND_SENT: "ALLOCATED AND SENT",
};

const {ALLOCATE, GENERATE_DOCUMENT, REMOVE_ALLOCATION, REMOVE_DOCUMENT} =
  mandateActionItems;
const {AVAILABLE, ALLOCATED, ALLOCATED_AND_SENT} = mandateCertStatus;

export const mandateCertActionConfig = {
  [AVAILABLE + DIV_CODE_SAF]: {
    visible: [ALLOCATE],
    enabled: [ALLOCATE],
  },
  [ALLOCATED + DIV_CODE_SAF]: {
    visible: [REMOVE_ALLOCATION, GENERATE_DOCUMENT],
    enabled: [REMOVE_ALLOCATION, GENERATE_DOCUMENT],
  },
  [ALLOCATED_AND_SENT + DIV_CODE_SAF]: {
    visible: [REMOVE_ALLOCATION, REMOVE_DOCUMENT],
    enabled: [REMOVE_ALLOCATION, REMOVE_DOCUMENT],
  },
};

export const mandateLinkAction = (
  division,
  setModal,
  actionName,
  row,
  navigateToAllocation,
) => {
  if (actionName === ALLOCATE) {
    navigateToAllocation();
    setModal("");
    return;
  }
  setModal(division + actionName);
};

export default getMandateAllocationColumns;
