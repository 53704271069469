import React from "react";
import PropTypes from "prop-types";
import {Controller} from "react-hook-form";
import {FormFeedback, FormGroup, Input, Label} from "reactstrap";

const WasteStatus = ({control, name, error, rules, disabled = false}) => {
  return (
    <Controller
      rules={rules}
      render={({field: {onChange, value, ref}}) => {
        const postConsumerMaterial = {
          checked: value?.toUpperCase() === "POST_CONSUMER_MATERIAL",
        };
        const preConsumerMaterial = {
          checked: value?.toUpperCase() === "PRE_CONSUMER_MATERIAL",
        };
        const mixedUnspecified = {
          checked: value?.toUpperCase() === "MIXED_UNSPECIFIED",
        };

        return (
          <>
            <div>
              <FormGroup
                check
                inline
                className="form-check-alt form-check-lg !mb-0"
              >
                <Input
                  id={`${name}postConsumerMaterial`}
                  data-test={`${name}postConsumerMaterial`}
                  type="checkbox"
                  invalid={!!error && !disabled}
                  disabled={disabled}
                  onChange={(e) => {
                    // Uncheck other checkboxes when this is checked
                    if (e.target.checked) {
                      onChange("POST_CONSUMER_MATERIAL");
                    } else {
                      onChange("");
                    }
                  }}
                  {...postConsumerMaterial}
                />
                <Label check for={`${name}postConsumerMaterial`}>
                  Post-consumer material
                </Label>
              </FormGroup>
              <FormGroup
                check
                inline
                className="form-check-alt form-check-lg !mb-0"
              >
                <Input
                  id={`${name}preConsumerMaterial`}
                  data-test={`${name}preConsumerMaterial`}
                  type="checkbox"
                  invalid={!!error && !disabled}
                  disabled={disabled}
                  onChange={(e) => {
                    // Uncheck other checkboxes when this is checked
                    if (e.target.checked) {
                      onChange("PRE_CONSUMER_MATERIAL");
                    } else {
                      onChange("");
                    }
                  }}
                  {...preConsumerMaterial}
                />
                <Label check for={`${name}preConsumerMaterial`}>
                  Pre-consumer material
                </Label>
              </FormGroup>
              <FormGroup
                check
                inline
                className="form-check-alt form-check-lg !mb-0"
              >
                <Input
                  innerRef={ref}
                  id={`${name}MixedUnspecified`}
                  data-test={`${name}MixedUnspecified`}
                  type="checkbox"
                  invalid={!!error && !disabled}
                  disabled={disabled}
                  onChange={(e) => {
                    // Uncheck other checkboxes when this is checked
                    if (e.target.checked) {
                      onChange("MIXED_UNSPECIFIED");
                    } else {
                      onChange("");
                    }
                  }}
                  {...mixedUnspecified}
                />
                <Label check for={`${name}MixedUnspecified`}>
                  Mixed/unspecified
                </Label>
              </FormGroup>
            </div>
            {error && !disabled && (
              <FormFeedback style={{display: "block"}}>
                {error.message}
              </FormFeedback>
            )}
          </>
        );
      }}
      control={control}
      name={name}
      defaultValue=""
    />
  );
};

WasteStatus.propTypes = {
  control: PropTypes.object,
  name: PropTypes.string.isRequired,
  error: PropTypes.object,
  rules: PropTypes.object,
  disabled: PropTypes.bool,
};

export default WasteStatus;
