import {INPUT_METRIC} from "modules/co-processing/constants/shipments";
import PropTypes from "prop-types";
import {useEffect, useState} from "react";
import {Input, InputGroup} from "reactstrap";
import NetChangeChip from "../Shipments/BulkAdjustment/NetChangeChip";

const MetricInputField = ({
  value,
  key,
  shipment,
  onError,
  handleInputChange,
  error,
  hasNetChange,
}) => {
  const [updatedValue, setUpdatedValue] = useState(null);

  const shipmentId = shipment?.shipment_id || "";
  const estimatedVolume = shipment?.estimated_volume ?? 0;
  const updatedShipmentVolume = shipment?.updatedVolume;

  useEffect(() => {
    if (
      updatedShipmentVolume == null ||
      Number.isNaN(Number(updatedShipmentVolume))
    ) {
      setUpdatedValue(value);
    } else {
      setUpdatedValue(updatedShipmentVolume);
    }
  }, [updatedShipmentVolume, value]);

  const formatValue = (value) => {
    return Number.isInteger(parseFloat(value))
      ? value
      : parseFloat(value).toFixed(2);
  };

  const updateInput = ({target: {value}}) => {
    const regex = /^\d*\.?\d{0,2}$/;
    if (!regex.test(value) || Number.isNaN(Number(value))) return;

    if (value === "") {
      setUpdatedValue(0);
      return;
    }

    setUpdatedValue(value);
  };

  const handleInputBlur = () => {
    const numericValue = parseFloat(updatedValue);

    if (Number.isNaN(numericValue)) return;

    if (numericValue > estimatedVolume) {
      const errMessage = `Entered actual shipped volume for shipment ${shipmentId} exceeds OWB total estimated volume`;
      onError(shipmentId, errMessage);
    } else {
      onError(shipmentId, null);
    }

    handleInputChange(shipmentId, numericValue);
    setUpdatedValue(formatValue(updatedValue));
  };

  const isCommitted = shipment?.shipment_status === "Committed";

  return (
    <div className="flex flex-row w-[280px] justify-between">
      {!!hasNetChange && (
        <NetChangeChip prevValue={value} currentValue={updatedValue} />
      )}
      <InputGroup
        data-test="copro-metric-input-group"
        className={` ${
          !error ? "" : "border-2 border-red-500"
        } max-w-[205px] input-group-merge`}
      >
        <Input
          data-test="copro-metric-actual-input"
          className={`min-w-[205px] text-xs text-right ${
            isCommitted ? "!text-gray-400" : ""
          }`}
          name={shipmentId}
          id={key}
          value={updatedValue ?? ""}
          onChange={(event) => updateInput(event)}
          onBlur={handleInputBlur}
          valid={!error && error != null}
          disabled={isCommitted}
        />
        <div data-test="copro-metric-appended" className="input-group-append">
          <div className="input-group-text">{INPUT_METRIC}</div>
        </div>
      </InputGroup>
    </div>
  );
};

MetricInputField.propTypes = {
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  key: PropTypes.string,
  shipment: PropTypes.shape({
    shipment_id: PropTypes.string,
    estimated_volume: PropTypes.number,
    updatedVolume: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    shipment_status: PropTypes.string,
  }).isRequired,
  onError: PropTypes.func,
  handleInputChange: PropTypes.func.isRequired,
  error: PropTypes.bool,
  hasNetChange: PropTypes.bool,
};

export default MetricInputField;
