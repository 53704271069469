import React, {useRef, useCallback} from "react";
import {
  Controller,
  FormProvider,
  useForm,
  useFormContext,
} from "react-hook-form";
import PropTypes from "prop-types";
import {FormFeedback, FormGroup, Input, Label} from "reactstrap";
import {Datepicker} from "@bphxd/ds-core-react";
import {ADD_BATCH_FORM, TOGGLE} from "modules/co-processing/constants/batches";

const Form = ({onSubmit, children, defaultValues}) => {
  const {handleSubmit, ...methods} = useForm({defaultValues, mode: "onSubmit"});

  return (
    <FormProvider {...methods}>
      <form
        className="addBatch__form"
        id="add_batch_form"
        onSubmit={handleSubmit(onSubmit)}
        noValidate
      >
        {children}
      </form>
    </FormProvider>
  );
};

const FormField = ({children, className, error, label, name}) => {
  return (
    <FormGroup className={["addBatch__field", "col-sm-6", className].join(" ")}>
      <Label for={name}>{label}</Label>

      {children}

      {error && (
        <FormFeedback style={{display: "block"}} invalid="true">
          {error.message || ADD_BATCH_FORM.messages.MISSING_INFO}
        </FormFeedback>
      )}
    </FormGroup>
  );
};

const DateTimePicker = ({onChange, value, error, label, name}) => {
  const datepickerRef = useRef(null);

  const handleDateTimeChange = useCallback(
    (selectedDates, dateStr, instance) => {
      // Check if user is interacting with time picker
      const flatpickrActiveClasses =
        datepickerRef.current?.flatpickr?.element?.classList;
      const timepickerActiveClassList = [
        "flatpickr-hour",
        "flatpickr-time",
        "flatpickr-am-pm",
      ];

      const isTimePickerActive = timepickerActiveClassList.some((className) =>
        flatpickrActiveClasses?.contains(className),
      );

      if (isTimePickerActive) {
        instance.open(); // Prevent calendar dropdown from closing
      }
      onChange(dateStr);
    },
    [onChange],
  );

  return (
    <FormField className="col-sm-12" label={label} name={name} error={error}>
      <Datepicker
        innerRef={datepickerRef}
        data-test={name ?? "datePicker-input"}
        className="datePicker"
        invalid={!!error}
        name={name}
        required
        options={{
          allowInput: false,
          altFormat: "m/j/Y h:i K",
          dateFormat: "Z",
          defaultDate: value,
          enableTime: true,
          minuteIncrement: 1,
          onClose: handleDateTimeChange,
        }}
        value={value ?? ""}
      />
    </FormField>
  );
};

const STATUS_TEXT = {
  DEFAULT: "-- Select an option --",
  LOADING: "Loading...",
  NO_OPTIONS: "No options available",
};

export const getStatusText = (loading, noOptions) => {
  return loading
    ? STATUS_TEXT.LOADING
    : noOptions
    ? STATUS_TEXT.NO_OPTIONS
    : STATUS_TEXT.DEFAULT;
};

const Select = ({label, name, options = [], isLoading, ...props}) => {
  const {
    control,
    formState: {errors},
  } = useFormContext();
  const noOptions = options.length === 0;
  const placeholderText = getStatusText(isLoading, noOptions);

  return (
    <FormField
      label={label}
      name={name}
      className={props.className}
      error={errors?.[name]}
      isLoading={isLoading}
    >
      <Controller
        {...props}
        control={control}
        name={name}
        rules={{required: !props?.isOptional}}
        render={({field}) => {
          return (
            <Input
              type="select"
              className="addBatch__input"
              {...field}
              invalid={!!errors[name]}
              id={name}
            >
              <option disabled={noOptions} hidden={!!options.length} value="">
                {placeholderText}
              </option>

              {options.map((item) => (
                <option key={item.value || item} value={item.value || item}>
                  {item.label || item}
                </option>
              ))}
            </Input>
          );
        }}
      />
    </FormField>
  );
};

const Switch = ({label, name}) => {
  const {control} = useFormContext();

  return (
    <FormField label={label} name={name}>
      <Controller
        control={control}
        name={name}
        render={({field}) => {
          return (
            <FormGroup switch>
              <Input
                id={`input-${name}`}
                type="switch"
                {...field}
                checked={field?.value}
              />
              <Label for={`input-${name}`} className="text-sm/4" color="muted">
                {field?.value ? TOGGLE.YES : TOGGLE.NO}
              </Label>
            </FormGroup>
          );
        }}
      />
    </FormField>
  );
};

const fieldPropTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  name: PropTypes.string,
};

Form.propTypes = {
  children: PropTypes.node.isRequired,
  defaultValues: PropTypes.object,
  onSubmit: PropTypes.func.isRequired,
};

FormField.propTypes = {
  ...fieldPropTypes,
  children: PropTypes.node,
  className: PropTypes.string,
  error: PropTypes.any,
};

DateTimePicker.propTypes = {
  ...fieldPropTypes,
  onChange: PropTypes.func,
  value: PropTypes.any,
  error: PropTypes.any,
};

Switch.propTypes = {...fieldPropTypes, isDisabled: PropTypes.bool};
Select.propTypes = {
  ...fieldPropTypes,
  isLoading: PropTypes.bool,
  option: PropTypes.array,
};

Form.Field = FormField;
Form.DateTimePicker = DateTimePicker;
Form.Select = Select;
Form.Toggle = Switch;

export default Form;
