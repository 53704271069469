const outboundTypes = {
  VOLUNTARY: "Voluntary",
  EXPORT: "Export",
  LOCATION_TRANSFER: "Location transfer",
  SALE: "Sale",
};

export const ALL_MANDATES = [
  "Mandate1 hub",
  "Mandate1 no SAF",
  "Mandate1 unspecified",
  "Mandate2 hub",
  "Mandate2 no SAF",
  "Mandate2 unspecified",
];

export default outboundTypes;
