import {gql} from "@apollo/client";

export const GET_RULE_BLOCK_WRITE_DATASETS = gql`
  query GetWriteDatasets {
    bioLcComplianceRuleEngineGetWriteDataSets {
      error
      statusCode
      writeDataSets {
        dataset
        schema {
          column
          optional
          type
        }
      }
    }
  }
`;

export default GET_RULE_BLOCK_WRITE_DATASETS;
