import {gql} from "@apollo/client";
import {client} from "providers/Apollo";

export const NABISY_RETRIEVAL_MUTATION = gql`
  mutation bioLcIngestNabisyDocument($event: IngestNabisyDocumentRequest!) {
    bioLcIngestNabisyDocument(event: $event) {
      error
      message
      statusCode
    }
  }
`;

export const NabisyRetrievalMutationAPI = (data) =>
  client.mutate({
    mutation: NABISY_RETRIEVAL_MUTATION,
    variables: {
      ...data,
    },
  });

export default {
  NABISY_RETRIEVAL_MUTATION,
  NabisyRetrievalMutationAPI,
};
