import {useQuery, useSubscription} from "@apollo/client";
import {useAccount, useMsal} from "@azure/msal-react";
import {
  Add24,
  Alert24,
  CircleCheck24,
  Remove24,
} from "@bphxd/ds-core-react/lib/icons";
import GET_NABISY_ACCOUNTS from "graphql/docManager/bioLcGetNabisyAccounts";
import {NabisyRetrievalMutationAPI} from "graphql/docManager/bioLcIngestNabisyDocument";
import NABISY_RETRIEVAL_SUBSCRIPTION from "graphql/docManager/onBiolcingestnabisydocumentcomplete";
import {useAppSetting} from "providers/appSetting";
import React, {useEffect, useMemo, useState} from "react";
import {
  Controller,
  FormProvider,
  useFieldArray,
  useForm,
} from "react-hook-form";
import {useNavigate, useParams} from "react-router-dom";
import {
  Button,
  Form,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Spinner,
} from "reactstrap";
import {getDivisionData, getSiteDetails} from "utils/helpers/getAppSetting";

const NabisyForm = () => {
  const {appSetting} = useAppSetting();
  const {country, division} = useParams();
  const navigate = useNavigate();
  const {accounts} = useMsal();
  const account = useAccount(accounts[0]);

  const countryId = appSetting?.currentCountryMappingData?.countryId;
  const siteReferenceData = getSiteDetails(countryId);
  const divisionCode = division.toUpperCase();
  const divisionData = getDivisionData(divisionCode);

  const [userId, setUserId] = useState();
  const [isSendAnother, setIsSendAnother] = useState();

  const {data: subscriptionData} = useSubscription(
    NABISY_RETRIEVAL_SUBSCRIPTION,
    {
      variables: {userId},
      skip: !userId,
    },
  );

  const {data, loading, refetch} = useQuery(GET_NABISY_ACCOUNTS, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "network-only",
    variables: {
      siteReferenceId: siteReferenceData?.siteReferenceId,
      divisionId: divisionData?.divisionId,
    },
    skip:
      siteReferenceData?.siteReferenceId === undefined ||
      divisionData?.divisionId === undefined,
  });

  const accountsList = data?.bioLcGetNabisyAccounts?.accounts ?? [];

  const defaultValues = {
    accountId: "",
    documents: [{documentId: ""}, {documentId: ""}, {documentId: ""}],
  };

  const methods = useForm({
    mode: "onChange",
    defaultValues,
  });

  const {
    control,
    getValues,
    handleSubmit,
    reset,
    formState: {errors},
  } = methods;

  const {fields, append, remove} = useFieldArray({
    control,
    name: "documents",
  });

  const [loadingStates, setLoadingStates] = useState(fields.map(() => false));
  const [successStates, setSuccessStates] = useState(fields.map(() => null));
  const [errorStates, setErrorStates] = useState(fields.map(() => null));
  const [isError, setIsError] = useState(false);

  useEffect(() => {
    if (subscriptionData?.onBiolcingestnabisydocumentcomplete) {
      const {statusCode, sdNumberIndex, message} =
        subscriptionData.onBiolcingestnabisydocumentcomplete;

      if (statusCode === 500) {
        setIsError(true);
        setUserId(null);
      }

      if (statusCode === 400) {
        setErrorStates((prevErrorStates) => {
          const updatedErrorStates = [...prevErrorStates];
          updatedErrorStates[sdNumberIndex] = message;
          return updatedErrorStates;
        });
      }

      if (statusCode === 201) {
        setSuccessStates((prevSuccessStates) => {
          const updatedSuccessStates = [...prevSuccessStates];
          updatedSuccessStates[sdNumberIndex] = message;
          return updatedSuccessStates;
        });
      }

      setLoadingStates((prevLoadingStates) => {
        const updatedLoadingStates = [...prevLoadingStates];
        updatedLoadingStates[sdNumberIndex] = false;
        return updatedLoadingStates;
      });
    }
  }, [subscriptionData]);

  const incomingDocUrl = useMemo(() => {
    return `/doc-manager/${country}?page=incoming&divisionId=${divisionData?.divisionId}`;
  }, [country, divisionData?.divisionId]);

  const validateDuplicate = (value, index) => {
    const values = getValues("documents");
    const duplicate = values.some(
      (doc, i) => doc.documentId === value && i !== index,
    );
    return duplicate
      ? "A document with the same ID has already been entered"
      : true;
  };

  const onSubmit = async (data) => {
    setUserId(account?.username);
    const updatedLoadingStates = [...loadingStates];
    updatedLoadingStates.fill(true);
    setLoadingStates(updatedLoadingStates);
    const nabisyData = {
      event: {
        divisionId: divisionData?.divisionId,
        siteReferenceId: siteReferenceData?.siteReferenceId,
        documentNumbers: data.documents.map((doc) => doc.documentId),
        nabisyAccountNumber: data.accountId,
        userId: account?.username,
      },
    };
    const response = await NabisyRetrievalMutationAPI(nabisyData);
    if (response.data.bioLcIngestNabisyDocument.statusCode === 500) {
      setIsError(true);
      setUserId(null);
    } else {
      setIsSendAnother(true);
      setTimeout(() => {
        setUserId(null);
      }, 2000);
    }
  };

  const isLoading =
    loading ||
    siteReferenceData?.siteReferenceId === undefined ||
    divisionData?.divisionId === undefined;

  const isAccountsDataAvailable = !isError && accountsList.length > 0;

  return (
    <div className="mt-[32px]">
      <h1 className="text-[20px] font-light">
        Log into your Nabisy account to retrieve documents
      </h1>
      <FormProvider {...methods}>
        <Form onSubmit={handleSubmit(onSubmit)}>
          {isLoading && (
            <div className="text-center mt-[120px] mb-[312px]">
              <Spinner />
            </div>
          )}
          {!isLoading && isAccountsDataAvailable && (
            <>
              <FormGroup>
                <Label for="accountId" className="fw-normal">
                  Account ID
                </Label>
                <Controller
                  name="accountId"
                  control={control}
                  rules={{required: "Account ID is required"}}
                  disabled={userId}
                  render={({field}) => (
                    <Input
                      type="select"
                      id="accountId"
                      data-test="accountId"
                      placeholder="Select account ID"
                      className="!w-[218px] !placeholder-[#111111a3] bg-white"
                      {...field}
                    >
                      <option value="">Select account ID</option>
                      {accountsList.map((account) => (
                        <option key={account} value={account}>
                          {account}
                        </option>
                      ))}
                    </Input>
                  )}
                />
                {errors.accountId && (
                  <FormFeedback className="mt-0 !block">
                    {errors.accountId.message}
                  </FormFeedback>
                )}
              </FormGroup>
              <FormGroup className="mt-[56px]">
                <Label for="documentId" className="fw-normal">
                  Document
                </Label>
                {fields.map((field, index) => (
                  <div key={field.id} className="mb-[32px]">
                    <div className="flex items-center">
                      <Controller
                        name={`documents.${index}.documentId`}
                        control={control}
                        rules={{
                          required: "Please enter document Id",
                          validate: (value) => validateDuplicate(value, index),
                        }}
                        disabled={userId}
                        render={({field}) => (
                          <Input
                            {...field}
                            type="text"
                            id={`documentId-${index}`}
                            invalid={!!errors.documents?.[index]?.documentId}
                            className="bg-white !placeholder-[#111111a3] !w-[600px]"
                            placeholder="Enter document ID"
                          />
                        )}
                      />
                      {index > 0 && !loadingStates[index] && (
                        <div
                          className="ml-[11px] cursor-pointer"
                          onClick={() => remove(index)}
                          // This is a temporary fix, will remove in future commits
                          onKeyDown={() => {}}
                        >
                          <Remove24 color="#cccccc" />
                        </div>
                      )}
                      {loadingStates[index] && (
                        <div className="ml-[11px]">
                          <Spinner size="sm" className="!border-[0.15rem]" />
                        </div>
                      )}
                      {successStates[index] && (
                        <div className="ml-[11px]">
                          <CircleCheck24 />
                        </div>
                      )}
                      {errorStates[index] && (
                        <div className="ml-[11px]">
                          <Alert24 color="#e64949" />
                        </div>
                      )}
                    </div>
                    {errors.documents?.[index]?.documentId && (
                      <FormFeedback className="mt-0 !block">
                        {errors.documents[index].documentId.message}
                      </FormFeedback>
                    )}
                    {(errorStates[index] || successStates[index]) && (
                      <FormFeedback
                        className={`mt-0 !block ${
                          successStates[index] && "!text-[#111111a3]"
                        }`}
                      >
                        {errorStates[index] || successStates[index]}
                      </FormFeedback>
                    )}
                  </div>
                ))}

                <Button
                  type="button"
                  color="transparent"
                  className="mt-[-12px] !border-none focus:border-none"
                  onClick={() => append({documentId: ""})}
                >
                  <Add24 className="mr-[6px]" /> Add document
                </Button>
              </FormGroup>
            </>
          )}
          {!isLoading && !isAccountsDataAvailable && (
            <div className="mt-[120px] mb-[300px]">
              <p className="text-left">
                We are currently unable to retrieve documents from Nabisy.
                Please try again later.
                <br /> If the issue persists, contact our support team for
                assistance.
              </p>
            </div>
          )}

          <div className="mt-[40px] flex justify-between w-[600px]">
            <Button
              color="tertiary"
              outline
              type="secondary"
              size="md"
              className="show link-btn rounded-0"
              onClick={() => navigate(incomingDocUrl)}
            >
              Back to incoming documents
            </Button>
            {!isLoading && !isAccountsDataAvailable ? (
              <Button
                type="button"
                color="primary"
                className="!rounded-none"
                onClick={() => {
                  refetch();
                  setIsError(false);
                  setLoadingStates(defaultValues.documents.map(() => false));
                  setErrorStates(defaultValues.documents.map(() => null));
                  setSuccessStates(defaultValues.documents.map(() => null));
                }}
              >
                Try again
              </Button>
            ) : isSendAnother ? (
              <Button
                type="button"
                color="primary"
                className="!rounded-none"
                onClick={() => {
                  reset(defaultValues, {
                    keepErrors: false, // Clear the errors to avoid validation trigger
                    keepDirty: false, // Reset the dirty fields
                    keepTouched: false, // Reset the touched fields
                    keepIsSubmitted: false, // Reset the isSubmitted state
                    keepSubmitCount: false, // Reset the submit count
                    keepValues: false, // Reset the values to default
                    keepDefaultValues: true, // Keep the default values
                  });
                  setLoadingStates(defaultValues.documents.map(() => false));
                  setErrorStates(defaultValues.documents.map(() => null));
                  setSuccessStates(defaultValues.documents.map(() => null));
                  setIsSendAnother(false);
                }}
              >
                Send another
              </Button>
            ) : (
              <Button
                type="submit"
                color="primary"
                data-test="submit"
                className="!rounded-none"
                disabled={isLoading || !isAccountsDataAvailable || userId}
              >
                Send request
              </Button>
            )}
          </div>
        </Form>
      </FormProvider>
    </div>
  );
};

export default NabisyForm;
