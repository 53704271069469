import {Alert24} from "@bphxd/ds-core-react/lib/icons";
import {INCOMING_DOC_STATUS} from "modules/DocManager/constants";
import PropTypes from "prop-types";
import "./index.scss";

const Subtitle = ({
  status,
  invalidTabs,
  onClick,
  className,
  errors,
  formData,
}) => {
  return (
    <div
      className={`flex flex-col justify-between items-start gap-2 small pb-6 ${className}`}
    >
      {status === INCOMING_DOC_STATUS.ATTENTION && (
        <>
          <span className="mb-2">
            Based on this declaration we have been unable to gather some
            expected information.
          </span>
          <span className="mb-2">
            If you find the information is available from the PDF please confirm
            and add a note to explain your decision. This will help us learn and
            improve this feature in the future.
          </span>
        </>
      )}
      {status === INCOMING_DOC_STATUS.REQUIRES_ATTENTION && (
        <>
          <span className="mb-2">
            Based on this declaration we have been unable to gather some
            expected information.
          </span>
          <span className="mb-2">
            If you find the information is available from the PDF please confirm
            and add a note to explain your decision. This will help us learn and
            improve this feature in the future.
          </span>
        </>
      )}
      {status === INCOMING_DOC_STATUS.READY_FOR_MB && (
        <>
          <span className="mb-2">
            We have successfully captured all information, and this declaration
            meets criteria for acceptance.
          </span>
          <span className="mb-2">
            You now have the opportunity to review, add additional information
            if required
          </span>
        </>
      )}
      {status === INCOMING_DOC_STATUS.ATTENTION_SPLIT && (
        <>
          <span className="mb-2">
            Please be aware that declaration has multiple GHG entries. Please
            review and confirm.
          </span>
          <span className="mb-2">
            Any required splits can be managed from the incoming document
            manager page once this document has been accepted.
          </span>
        </>
      )}
      {status === INCOMING_DOC_STATUS.REJECTED && (
        <span className="mb-2">
          You have rejected this document on {formData?.bvAuditChangedDatez}
        </span>
      )}

      {status === INCOMING_DOC_STATUS.REQUIRES_ATTENTION && (
        <>
          {" "}
          <span className="fw-bold mt-6">Key points to review:</span>
          <div className="flex flex-col gap-0">
            <div className="flex flex-col gap-0 w-full">
              {errors &&
                Object?.keys(errors).map((key, index) => {
                  if (key === "splitDetails") {
                    const splitErrors = errors?.splitDetails;
                    return (
                      splitErrors &&
                      splitErrors.length > 0 &&
                      splitErrors.map((item, itemIndex) => {
                        return (
                          item &&
                          Object?.keys(item).map((key, index) => {
                            return (
                              <div
                                key={index}
                                className="flex items-center ml-3"
                              >
                                <Alert24
                                  color="#e64949"
                                  className="w-8 h-8 mr-[5px]"
                                />
                                <span
                                  className="text-base underline cursor-pointer"
                                  onClick={() =>
                                    onClick(`splitDetails.${itemIndex}.${key}`)
                                  }
                                  onKeyDown={() =>
                                    onClick(`splitDetails.${itemIndex}.${key}`)
                                  }
                                >
                                  {item[key].message}
                                </span>
                              </div>
                            );
                          })
                        );
                      })
                    );
                  }

                  return (
                    <div key={index} className="flex items-center ml-3">
                      <Alert24 color="#e64949" className="w-8 h-8 mr-[5px]" />
                      <span
                        className="text-base underline cursor-pointer"
                        onClick={() => onClick(key)}
                        onKeyDown={() => onClick(key)}
                      >
                        {errors[key].message}
                      </span>
                    </div>
                  );
                })}
            </div>
          </div>
        </>
      )}
    </div>
  );
};
Subtitle.propTypes = {
  status: PropTypes.string,
  invalidTabs: PropTypes.object,
  onClick: PropTypes.func,
  className: PropTypes.string,
  errors: PropTypes.object,
  formData: PropTypes.object,
};
export default Subtitle;
