import {gql} from "@apollo/client";

// Fetch query for tax plant registry table on Config center(/configuration-center/tax-plant-registry)
export const GET_TAX_PLANT_REGISTRY_DETAIL = gql`
  query bioLcGetTaxPlantRegistryStable {
    bioLcGetTaxPlantRegistryStable {
      error
      statusCode
      taxPlantRegistryStableResponse {
        companycode
        delete
        deletereason
        edgroup_code_name
        id
        legalentity
        maincustomsoffice
        mot
        plant_werks_name
        registrationnumber
        taxliability
        taxplantregistrystable_id
      }
    }
  }
`;

export default {GET_TAX_PLANT_REGISTRY_DETAIL};
