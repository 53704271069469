import {gql} from "@apollo/client";

export const GET_OUTGOING_PDF = gql`
  query getOutgoingCertificate(
    $siteReferenceId: String
    $divisionId: String
    $certificateOutboundEuId: String
  ) {
    bioLcGetGeneratedCertificate(
      event: {
        divisionId: $divisionId
        certificateOutboundEuId: $certificateOutboundEuId
        siteReferenceId: $siteReferenceId
      }
    ) {
      downloadUrl
      statusCode
      certificateNumber
      status
      certificateOutboundEuId
    }
  }
`;

export default GET_OUTGOING_PDF;
