import {Close} from "@bphxd/ds-core-react";
import React from "react";

import * as XLSX from "xlsx";

import {Modal, ModalBody, ModalHeader} from "reactstrap";

import PropTypes from "prop-types";

import ExecutionResultsPage from "./ExecutionResultsPage";

import "./styles.scss";

export const exportExcelData = (data, exportData) => {
  // Format data using the provided headerMapping
  const ws = XLSX.utils.json_to_sheet(exportData);
  const wb = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, "Results");
  XLSX.writeFile(wb, "results_export.xlsx");
};

const ExecutionResultsPopup = ({
  ruleName,
  ruleVersionId,
  ExecutionResultsPopupVisible,
  setExecutionResultsPopupVisible,
  indexForExecute,
  selectedTemplateItems,
}) => {
  return (
    <Modal
      size="xl"
      isOpen={ExecutionResultsPopupVisible}
      className="modal-dialog-centered"
      style={{maxWidth: "1600px", width: "100%", minHeight: "80vh"}}
    >
      <ModalHeader
        className="text-xl !pb-0"
        close={
          <Close
            onClick={() =>
              setExecutionResultsPopupVisible(!ExecutionResultsPopupVisible)
            }
          />
        }
      ></ModalHeader>
      <ModalBody className="text-center !pt-0">
        <ExecutionResultsPage
          ruleName={ruleName}
          ruleVersionId={ruleVersionId}
          indexForExecute={indexForExecute}
          selectedTemplateItems={selectedTemplateItems}
        />
      </ModalBody>
    </Modal>
  );
};

ExecutionResultsPopup.propTypes = {
  ruleName: PropTypes.string,
  ruleVersionId: PropTypes.string,
  ExecutionResultsPopupVisible: PropTypes.bool,
  setExecutionResultsPopupVisible: PropTypes.func,
  indexForExecute: PropTypes.number,
  selectedTemplateItems: PropTypes.array,
};

export default ExecutionResultsPopup;
