import {
  getCoreRowModel,
  getExpandedRowModel,
  getFilteredRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import PropTypes from "prop-types";
import {useEffect, useMemo} from "react";
import {Table} from "reactstrap";
import {customDateFilterFn, customSelectFilterFn} from "../../utils";
import "./document-table.css";
import {
  getColumns,
  renderEmptyStates,
  renderTableBody,
  renderTableHeaders,
} from "./tableUtils";

const DocumentTable = ({
  columns,
  incomingCols,
  purchaseCols,
  data,
  enableRowSelection,
  rowSelection,
  setRowSelection,
  loading,
  filtering,
  setFiltering,
  columnFilter,
  setColumnFilter,
  type,
  otherRows,
  otherData,
  setFilteredIncomingRows,
  setFilteredPurchaseRows,
  showFilter,
  contractNumbers,
  dealNumbers,
  division,
}) => {
  const incomingColumnsList = useMemo(
    () => incomingCols?.[0]?.columns?.map((item) => item.key),
    [incomingCols],
  );

  const purchaseColumnsList = useMemo(
    () => purchaseCols?.[0]?.columns?.map((item) => item.key),
    [purchaseCols],
  );

  const isIncomingFilter = useMemo(() => {
    if (type === "Incoming") {
      return columnFilter.some((obj) => incomingColumnsList?.includes(obj.id));
    }
    return false;
  }, [columnFilter, incomingColumnsList, type]);

  const isPurchaseFilter = useMemo(() => {
    if (type === "Purchase") {
      return columnFilter.some((obj) => purchaseColumnsList?.includes(obj.id));
    }
    return false;
  }, [columnFilter, purchaseColumnsList, type]);

  // Adding checkbox column
  const newColumns = useMemo(
    () =>
      getColumns(
        columns,
        enableRowSelection,
        type,
        otherRows,
        otherData,
        division?.toUpperCase(),
      ),
    [columns, enableRowSelection, type, otherRows, otherData, division],
  );

  const table = useReactTable({
    data,
    columns: newColumns,
    enableRowSelection,
    filterFns: {
      dateRangeFilterFn: customDateFilterFn,
      selectFilterFn: customSelectFilterFn,
    },
    state: {
      rowSelection,
      globalFilter: filtering,
      columnFilters: columnFilter,
      columnPinning: {
        left:
          type === "Incoming"
            ? ["checkbox", "Incoming document"]
            : ["Purchase"],
        right: type === "Incoming" ? [] : ["checkbox"],
      },
    },
    onColumnFiltersChange: setColumnFilter,
    onGlobalFilterChange: setFiltering,
    onRowSelectionChange: setRowSelection,
    getSubRows: (row) => row.splitDetails ?? [],
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
  });

  useEffect(() => {
    if (table && type === "Incoming") {
      setFilteredIncomingRows(table.getPrePaginationRowModel().rows);
    }
    if (table && type === "Purchase") {
      setFilteredPurchaseRows(table.getPrePaginationRowModel().rows);
    }
  }, [
    table,
    type,
    filtering,
    columnFilter,
    setFilteredIncomingRows,
    setFilteredPurchaseRows,
    loading,
    data,
  ]);

  const selectedRow = (cell) => cell.row.getIsSelected();

  const totalData = table.getPrePaginationRowModel().rows.length;

  return (
    <Table className="global-link-to-purchase-table" hover>
      <thead>
        {renderTableHeaders(
          table,
          showFilter,
          contractNumbers,
          dealNumbers,
          data,
          setRowSelection,
        )}
      </thead>
      <tbody>
        {renderTableBody(table, loading, totalData, selectedRow, type)}
        {renderEmptyStates(
          loading,
          totalData,
          type,
          isIncomingFilter,
          isPurchaseFilter,
          setColumnFilter,
          otherData,
        )}
      </tbody>
    </Table>
  );
};

DocumentTable.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.object),
  incomingCols: PropTypes.arrayOf(PropTypes.object),
  purchaseCols: PropTypes.arrayOf(PropTypes.object),
  data: PropTypes.arrayOf(PropTypes.object),
  enableRowSelection: PropTypes.bool,
  rowSelection: PropTypes.object,
  setRowSelection: PropTypes.func,
  loading: PropTypes.bool.isRequired,
  filtering: PropTypes.string,
  setFiltering: PropTypes.func,
  columnFilter: PropTypes.string,
  setColumnFilter: PropTypes.func,
  type: PropTypes.string,
  otherRows: PropTypes.object,
  otherData: PropTypes.array,
  setFilteredIncomingRows: PropTypes.func,
  setFilteredPurchaseRows: PropTypes.func,
  showFilter: PropTypes.bool,
  contractNumbers: PropTypes.array,
  dealNumbers: PropTypes.array,
  division: PropTypes.string,
};

export default DocumentTable;
