import {isNull} from "lodash";
import moment from "moment";
import PropTypes from "prop-types";
import {formatNumber, useUserSettings} from "providers/userSettings";
import {Fragment, useEffect, useRef, useState} from "react";
import {Spinner, Table} from "reactstrap";
import parseAndRoundToTwoDecimal, {
  parseAndRoundToThreeDecimal,
} from "utils/numberUtil";
import ConsumedQuantityPopover from "../Popover/ConsumedQuantityPopover";
import "./index.css";

const SplitTable = ({
  defaultValues,
  consumedQuantityData,
  consumedQuantityDataLabel,
  calculationBreakdownData,
  totalCoprocessingCostData,
  lastEditedInfo,
  totalCoprocessingCostDataLabel,
  currencySymbol,
  loading,
}) => {
  const METRIC_TON_UNIT = "mt";
  const DEFAULT_NO_CONSUMPTION_dATA_REFINERY_HEADER =
    "Please reach out to the P&O Refinery Finance Team";
  const DefaultSettings = () => {
    return useUserSettings();
  };
  const formatNumberIfExists = (value, digits = 2) => {
    const {
      userSettings: {decimalFormat},
    } = DefaultSettings();
    if (digits === 3)
      return formatNumber(parseAndRoundToThreeDecimal(value), decimalFormat, 3);
    return formatNumber(parseAndRoundToTwoDecimal(value), decimalFormat, 2);
  };

  const getFinalVal = (value) => {
    if (isNull(value)) return "";
    return formatNumberIfExists(value);
  };

  const tableRef = useRef(null);
  const containerRef = useRef(null);

  const [needsHorizontalScroll, setNeedsHorizontalScroll] = useState(false);

  useEffect(() => {
    if (tableRef.current && containerRef.current) {
      const tableWidth = tableRef.current.offsetWidth;
      const containerWidth = containerRef.current.offsetWidth;
      setNeedsHorizontalScroll(tableWidth > containerWidth);
    }
  }, [tableRef, containerRef]);

  const trRef = useRef(null);
  const thRef = useRef(null);

  useEffect(() => {
    const updateWidth = () => {
      if (trRef.current && thRef.current) {
        const trWidth = trRef.current.clientWidth;
        thRef.current.style.width = `${trWidth}px`;
        const tableWidth = thRef.current.closest("table").clientWidth;
        const thWidth = thRef.current.clientWidth;
        thRef.current.style.marginLeft = `${(tableWidth - thWidth) / 2}px`;
      }
    };

    window.addEventListener("resize", updateWidth);
    updateWidth();

    return () => window.removeEventListener("resize", updateWidth);
  }, [consumedQuantityData]);

  const tableStyle = {
    width: needsHorizontalScroll ? "100%" : "fit-content",
    tableLayout: consumedQuantityData?.length === 0 ? "Fixed" : "",
    height:
      !loading &&
      totalCoprocessingCostDataLabel !== "" &&
      consumedQuantityData?.length === 0
        ? "540px"
        : "fit-content",
    backgroundColor:
      loading || totalCoprocessingCostDataLabel === ""
        ? "transparent"
        : "white",
  };

  const renderCalculationRows = (scrolledToRight) => {
    return calculationBreakdownData.map((item, index) => {
      return (
        <>
          <tr key={item.isTotal}>
            <td className={item.isTotal === true ? "table-data-row" : ""}>
              {item.label}
            </td>
            {item.values.map((dataItem, dataIndex) => (
              <Fragment key={dataItem.unit}>
                <td className={item.isTotal === true ? "table-data-row" : ""}>
                  {getFinalVal(dataItem.unit)}
                </td>
                <td
                  className={`${
                    dataIndex === 0 && scrolledToRight
                      ? "table-shadow-effect"
                      : ""
                  } ${item.isTotal === true ? "table-data-row" : ""}`}
                >
                  {getFinalVal(dataItem.abs)}
                </td>
              </Fragment>
            ))}
          </tr>
          {item.isTotal === true && (
            <tr
              style={{
                height: "16px",
              }}
            >
              <td className="table-shadow-empty-row"> </td>
              {item.values.map((dataItem, dataIndex) => (
                <>
                  <td className="table-shadow-empty-row"> </td>
                  <td className="table-shadow-empty-row"> </td>
                </>
              ))}
            </tr>
          )}
        </>
      );
    });
  };

  const getTotalRows = () => {
    if (!consumedQuantityData) return 0;

    return (consumedQuantityData.length - 1) * 2;
  };

  const [scrolledToRight, setScrolledToRight] = useState(false);

  useEffect(() => {
    const container = containerRef.current;

    let prevScrollLeft = container.scrollLeft;

    const handleScroll = () => {
      const isScrollingRight = container.scrollLeft > prevScrollLeft;
      prevScrollLeft = container.scrollLeft;
      const scrolledLeft = container.scrollLeft === 0;

      if (isScrollingRight) {
        setScrolledToRight(true);
      } else if (scrolledLeft) {
        setScrolledToRight(false);
      }
    };

    container.addEventListener("scroll", handleScroll);

    return () => {
      container.removeEventListener("scroll", handleScroll);
    };
  }, [containerRef]);

  const [popoverIndex, setPopoverIndex] = useState(null);

  const handlePopoverToggle = (index) => {
    setPopoverIndex(popoverIndex === index ? null : index);
  };

  const lastEditedInfoMsg = lastEditedInfo?.updatedAt
    ? `Last calculated on ${lastEditedInfo?.updatedAt} by ${lastEditedInfo?.updatedBy}`
    : "";

  return (
    <div
      ref={containerRef}
      className={`cost-calculation-feedstock-table items-start ${
        scrolledToRight ? "scrolled-right" : ""
      } ${
        loading || totalCoprocessingCostDataLabel === ""
          ? " cost-calc-spinner-background"
          : ""
      }`}
    >
      <Table
        ref={tableRef}
        className="bp-core table cost-calculation-table"
        style={tableStyle}
      >
        {(loading || totalCoprocessingCostDataLabel === "") && (
          <tbody>
            <tr>
              <td colSpan="100%" className="cost-calc-table-spinner">
                <div className="flex items-center justify-center">
                  {/* <SpinnerLoading /> */}
                  <Spinner />
                </div>
              </td>
            </tr>
          </tbody>
        )}
        {!loading &&
          totalCoprocessingCostDataLabel !== "" &&
          consumedQuantityData.length === 0 && (
            <>
              <thead>
                <tr>
                  <th
                    aria-label="Empty Header"
                    style={{borderBottom: "none"}}
                  ></th>
                  {/* <th colSpan="2">Summary</th> */}
                  <th
                    aria-label="Empty Header"
                    style={{width: "100%", borderBottom: "none"}}
                  ></th>
                </tr>
              </thead>
              <tbody style={{height: "540px"}}>
                <tr ref={trRef}>
                  <th
                    ref={thRef}
                    style={{
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <span className="no-consumption-data">
                      No consumption data available for{" "}
                      {moment(defaultValues?.periodMonth, "MM").format("MMMM")}{" "}
                      {defaultValues?.periodYear}
                    </span>
                    <span className="no-consumption-refinery-data">
                      {DEFAULT_NO_CONSUMPTION_dATA_REFINERY_HEADER}
                    </span>
                  </th>
                </tr>
              </tbody>
            </>
          )}
        {!loading &&
          calculationBreakdownData.length !== 0 &&
          consumedQuantityData.length !== 0 &&
          totalCoprocessingCostData.length !== 0 && (
            <>
              <thead>
                <tr>
                  <th aria-label="Empty Header"></th>
                  <th
                    className={scrolledToRight ? `table-shadow-effect` : ""}
                    colSpan="2"
                  >
                    Summary
                  </th>
                  <th colSpan={getTotalRows()}>Consumed receipts</th>
                </tr>
                <tr>
                  <th>{consumedQuantityDataLabel}</th>
                  {consumedQuantityData.map((item, index) => {
                    return (
                      <Fragment key={item.value}>
                        <th
                          className={`${
                            scrolledToRight ? "table-shadow-effect" : ""
                          } ${popoverIndex === index ? "popover-open" : ""}`}
                          colSpan="2"
                        >
                          <div className={index !== 0 ? "cursor-pointer" : ""}>
                            <div id={`consumedQty${index}`}>
                              {formatNumberIfExists(item.value, 3)}{" "}
                              {METRIC_TON_UNIT}
                            </div>
                            {index !== 0 && (
                              <ConsumedQuantityPopover
                                defaultValues={defaultValues}
                                consumedQuantityData={formatNumberIfExists(
                                  item.value,
                                )}
                                moreDetails={item.moreDetails}
                                popoverId={`consumedQty${index}`}
                                index={index}
                                onPopoverToggle={handlePopoverToggle}
                              />
                            )}
                          </div>
                        </th>
                      </Fragment>
                    );
                  })}
                </tr>
                <tr className="table-unit-row">
                  <th className="table-unit-row" aria-label="Empty Header"></th>
                  {consumedQuantityData.map((item, index) => {
                    return (
                      <Fragment key={item.receiptType}>
                        <th className="table-unit-row">
                          Unit rate {currencySymbol}/{METRIC_TON_UNIT}
                        </th>
                        <th
                          className={`table-unit-row ${
                            index === 0 && scrolledToRight
                              ? "table-shadow-effect"
                              : ""
                          }`}
                        >
                          Absolute {currencySymbol}
                        </th>
                      </Fragment>
                    );
                  })}
                </tr>
              </thead>
              <tbody>
                {renderCalculationRows(scrolledToRight)}
                <tr className="table-last-row">
                  <td className="table-last-row">
                    {totalCoprocessingCostDataLabel}
                  </td>
                  {totalCoprocessingCostData.map((item, index) => (
                    <Fragment key={item.unit}>
                      <td className="table-last-row">
                        {getFinalVal(item.unit)}
                      </td>
                      <td
                        className={`${
                          index === 0 && scrolledToRight
                            ? "table-shadow-effect-last-row"
                            : ""
                        } table-last-row`}
                      >
                        {getFinalVal(item.abs)}
                      </td>
                    </Fragment>
                  ))}
                </tr>
              </tbody>
            </>
          )}
      </Table>
      <span className="last-calc-user">{lastEditedInfoMsg}</span>
    </div>
  );
};

SplitTable.propTypes = {
  defaultValues: PropTypes.array.isRequired,
  consumedQuantityData: PropTypes.array.isRequired,
  consumedQuantityDataLabel: PropTypes.string.isRequired,
  calculationBreakdownData: PropTypes.array.isRequired,
  totalCoprocessingCostData: PropTypes.array.isRequired,
  lastEditedInfo: PropTypes.shape({
    updatedBy: PropTypes.string.isRequired,
    updatedAt: PropTypes.string.isRequired,
  }),
  totalCoprocessingCostDataLabel: PropTypes.string.isRequired,
  currencySymbol: PropTypes.string.isRequired,
  loading: PropTypes.bool.isRequired,
};

export default SplitTable;
