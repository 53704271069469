// Feature.js
import PropTypes from "prop-types";
import {useFeatureFlag} from "./FeatureFlagContext";

const Feature = ({flag, children, fallback = null}) => {
  const isEnabled = useFeatureFlag(flag);
  return isEnabled ? children : fallback;
};

Feature.propTypes = {
  flag: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  fallback: PropTypes.node,
};

export default Feature;
