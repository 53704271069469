import {gql} from "@apollo/client";

export const OUTGOING_DOCS = gql`
  query bioLcGetCertificateOutboundData(
    $divisionId: String
    $siteReferenceId: String
    $certificateOutboundEuId: String
    $sdNumber: String
  ) {
    bioLcGetCertificateOutboundData(
      event: {
        divisionId: $divisionId
        certificateOutboundEuId: $certificateOutboundEuId
        sdNumber: $sdNumber
        siteReferenceId: $siteReferenceId
      }
    ) {
      statusCode
      error
      total
      documents {
        additionalInfo
        agriculturalBiomassIntermediateCropFlag
        agriculturalBiomassLowRiskFlag
        allocatedElectricity
        allocatedHeat
        bioProductProducer
        bioProductUser
        certificateNumber
        certificateOutboundEuId
        certificateVersionNo
        certificationSystem
        chainOfCustodyOption
        contractNumber
        dateOfIssuance
        defaultValueAppliedFlag
        euRedCompliantFlag
        ghgEccr
        ghgEccs
        ghgEec
        ghgEl
        ghgEmissionPercentageIn
        ghgEp
        ghgEsca
        ghgEtd
        ghgEu
        ghgTotal
        isccCompliantFlag
        isccIssuerLicense
        materialDispatchDatez
        materialSustainabilityCriteriaFlag
        mbBalanceGroupId
        mbBalanceGroupName
        mbBalanceTypeCode
        mbBalanceTypeId
        mbLocationGroupId
        mbLocationGroupName
        mbPeriodFrom
        mbPeriodId
        mbPeriodName
        nuts2Region
        mbPeriodTo
        producerInstallationDate
        productEnergyContent
        productType
        quantityAllocatedM3
        quantityAllocatedMt
        rawMaterialOriginCountryName
        rawMaterialType
        rawMaterialWasteOrResidueFlag
        recipientAddress
        recipientName
        recipientReceiptAddress
        recipientReceiptAddressSameFlag
        sdNumber
        status
        supplierAddress
        supplierDispatchAddress
        supplierDispatchAddressSameFlag
        supplierName
        userInstallationDate
        mbOutboundQtyEuId
      }
    }
  }
`;

export const SEND_CERTIFICATE = gql`
  mutation SendCertificateMutation($event: sendCertificateRequest!) {
    sendCertificate(event: $event) {
      statusCode
      message
    }
  }
`;
export default {OUTGOING_DOCS, SEND_CERTIFICATE};
