import {useLazyQuery} from "@apollo/client";
import {Close} from "@bphxd/ds-core-react";
import {
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import {COPRO_US_GET_WATERFALL_BATCHES} from "graphql/coprocessing/shipments";
import {BUTTONS} from "modules/co-processing/constants/coProcessing";
import {ITEMS_PER_PAGE} from "modules/co-processing/constants/shipments";
import {displayDate} from "modules/co-processing/helpers/dateHelper";
import useModal from "modules/co-processing/helpers/useModal";
import SpinnerLoading from "modules/common/SpinnerLoading";
import React, {useCallback, useEffect, useMemo, useState} from "react";
import {useNavigate} from "react-router-dom";
import {
  Button,
  Input,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Spinner,
  Table,
} from "reactstrap";
import {roundDecimal} from "utils/helpers/uihelper";
import "./index.scss";

const AdjustmentCols = (selectedBatches, handleSelectBatch) => [
  {
    accessorKey: "batch_id",
    header: "Batch ID",
    cell: ({row}) => (
      <div>
        <div>{row.original.batch_id}</div>
        <div className="remaining-volume-cell">
          {`Remaining volume: ${new Intl.NumberFormat("en-US").format(
            roundDecimal(row.original.remaining_volume),
          )} bbl`}
        </div>
      </div>
    ),
    size: 220,
  },
  {
    accessorKey: "tank_certified_date",
    header: "Tank certified date",
    cell: (info) => {
      const tankCertDate = info.getValue();
      return (
        <div className="tank-certified-cell">
          {tankCertDate ? displayDate(tankCertDate, true) : "N/A"}
        </div>
      );
    },
    size: 170,
  },
  {
    id: "select",
    cell: ({row}) => (
      <Input
        type="checkbox"
        checked={selectedBatches[row.original.batch_id] || false}
        onChange={() => handleSelectBatch(row.original.batch_id)}
      />
    ),
    size: 50,
  },
];

const BulkAdjustmentModal = () => {
  const navigate = useNavigate();
  const {isModalOpen, toggleModal} = useModal("bulkAdjustmentModal");
  const [selectedBatches, setSelectedBatches] = useState({});
  const [offset, setOffset] = useState(0);
  const [batches, setBatches] = useState([]);
  const [hasMoreBatches, setHasMoreBatches] = useState(true);

  const [getWaterfallBatches, {loading, fetchMore}] = useLazyQuery(
    COPRO_US_GET_WATERFALL_BATCHES,
    {
      fetchPolicy: "network-only",
      onCompleted: (data) => {
        if (data && data.bioLcCoproUsShipmentsApi.statusCode === 200) {
          const newBatches =
            data.bioLcCoproUsShipmentsApi.body.waterfall_batches;
          setBatches((prevBatches) => [...prevBatches, ...newBatches]);

          if (newBatches?.length === 0) {
            setHasMoreBatches(false);
          }
        }
      },
    },
  );

  const [isRefetching, setIsRefetching] = useState(false);

  useEffect(() => {
    if (isModalOpen && !batches.length) {
      setOffset(0); // Reset offset when modal is opened
      setHasMoreBatches(true);
      getWaterfallBatches({variables: {offset: 0}});
    }
  }, [batches, isModalOpen, setOffset, setBatches, getWaterfallBatches]);

  const loadMoreBatches = () => {
    if (!hasMoreBatches || isRefetching) return;

    const newOffset = offset + ITEMS_PER_PAGE;
    setOffset(newOffset);
    setIsRefetching(true);

    fetchMore({
      variables: {offset: newOffset},
      updateQuery: (prevResult, {fetchMoreResult}) => {
        setIsRefetching(false);

        const newBatches =
          fetchMoreResult.bioLcCoproUsShipmentsApi?.body?.waterfall_batches;
        if (!newBatches?.length) {
          setHasMoreBatches(false);
          return prevResult;
        }

        setBatches((prevBatches) => [...prevBatches, ...newBatches]);
        return {
          ...prevResult,
          bioLcCoproUsShipmentsApi: {
            ...prevResult.bioLcCoproUsShipmentsApi,
            body: {
              ...prevResult.bioLcCoproUsShipmentsApi.body,
              waterfall_batches: [
                ...prevResult.bioLcCoproUsShipmentsApi.body.waterfall_batches,
                ...newBatches,
              ],
            },
          },
        };
      },
    });
  };

  const handleSelectBatch = useCallback((batchId) => {
    setSelectedBatches((state) => ({
      ...state,
      [batchId]: !state[batchId],
    }));
  }, []);

  const totalVolumeToAdjust = useMemo(() => {
    const total = Object.entries(selectedBatches).reduce(
      (total, [batchId, isSelected]) => {
        if (isSelected) {
          const batch = batches.find((b) => b.batch_id === batchId);
          return total + (batch?.remaining_volume ?? 0);
        }
        return total;
      },
      0,
    );

    return roundDecimal(total);
  }, [selectedBatches, batches]);

  const columns = useMemo(
    () => AdjustmentCols(selectedBatches, handleSelectBatch),
    [selectedBatches, handleSelectBatch],
  );

  const table = useReactTable({
    data: batches,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    state: {
      rowSelection: selectedBatches,
    },
    onRowSelectionChange: setSelectedBatches,
  });

  const handleCancel = () => {
    setSelectedBatches({});
    toggleModal();
    setBatches([]);
    setOffset(0);
    setHasMoreBatches(true);
    setIsRefetching(false);
  };

  return (
    <>
      <Button
        className="bulk-adjustment__btn rounded-0 bg-secondary"
        onClick={toggleModal}
      >
        Bulk adjustment
      </Button>
      <Modal className="bulk-adjustment__modal" size="lg" isOpen={isModalOpen}>
        <ModalHeader close={<Close onClick={handleCancel} large />}>
          <div className="fs-5">Bulk Adjustment</div>
        </ModalHeader>
        <ModalBody className="bulk-adjustment__modal-body">
          <span className="bulk-adjustment__enter-total-text">
            Enter the total amount to adjust and select the batches to be
            adjusted. The corresponding shipments will also be available for
            adjustments.
          </span>
          <div className="bulk-adjustment__layout">
            {loading && (
              <div className="bulk-adjustment__spinner">
                <SpinnerLoading />
              </div>
            )}

            {!loading && batches?.length === 0 && (
              <span className="m-auto">No batches available</span>
            )}

            {!loading && batches.length > 0 && (
              <>
                <div className="bulk-adjustment__table-container">
                  <Table>
                    <thead>
                      {table.getHeaderGroups().map((headerGroup) => (
                        <tr key={headerGroup.id}>
                          {headerGroup.headers.map((header) => (
                            <th
                              key={header.id}
                              className={`bulk-adjustment__table-header-${header.column.id}`}
                            >
                              {flexRender(
                                header.column.columnDef.header,
                                header.getContext(),
                              )}
                            </th>
                          ))}
                        </tr>
                      ))}
                    </thead>
                    <tbody>
                      {table.getRowModel().rows.map((row) => (
                        <tr key={row.id}>
                          {row.getVisibleCells().map((cell) => (
                            <td key={cell.id}>
                              {flexRender(
                                cell.column.columnDef.cell,
                                cell.getContext(),
                              )}
                            </td>
                          ))}
                        </tr>
                      ))}
                    </tbody>
                    <tfoot>
                      <tr>
                        <td colSpan="3">
                          <Button
                            className="bg-secondary rounded-0 bulk-adjustment__btn"
                            disabled={!hasMoreBatches || isRefetching}
                            onClick={loadMoreBatches}
                          >
                            {isRefetching && (
                              <Spinner size="sm" className="btn-icon-prefix" />
                            )}
                            Load more batches
                          </Button>
                        </td>
                      </tr>
                    </tfoot>
                  </Table>
                </div>
                <div
                  className="bulk-adjustment__volume-container"
                  data-test="total-remaining-volume"
                >
                  <div className="text">Total remaining volume to adjust</div>
                  <span className="bbl">
                    {totalVolumeToAdjust &&
                      `${new Intl.NumberFormat("en-US").format(
                        totalVolumeToAdjust,
                      )} bbl`}
                  </span>
                </div>
              </>
            )}
          </div>
        </ModalBody>
        <ModalFooter>
          <div className="bulk-adjustment__actions">
            <Button
              className="btn btn-light rounded-0 mr-2"
              onClick={handleCancel}
            >
              {BUTTONS.CANCEL}
            </Button>
            <Button
              className="btn btn-primary rounded-0"
              disabled={!Object.values(selectedBatches).some((batch) => batch)}
              onClick={() =>
                navigate("/co-processing/shipments/bulk-adjustment", {
                  state: {
                    batches: selectedBatches,
                    remaining_volume: totalVolumeToAdjust,
                  },
                })
              }
            >
              {BUTTONS.CONTINUE}
            </Button>
          </div>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default BulkAdjustmentModal;
