import {useMutation, useQuery} from "@apollo/client";
import {Download24, Filter24, Info24} from "@bphxd/ds-core-react/lib/icons";
import React, {useCallback, useEffect, useMemo, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {Button, Spinner} from "reactstrap";

import {useAccount, useMsal} from "@azure/msal-react";
import {NUMBER_OF_DECIMALS} from "constants/common";
import {COUNTRY_SPAIN} from "constants/countryDetails";
import {
  DIV_CODE_COPRO,
  DIV_CODE_GF,
  DIV_CODE_SAF,
} from "constants/divisionDetails";
import {ReversalMutationAPI} from "graphql/MassBalance/Actions/reversalMutation";
import ADD_MB_STOCK_INVENTORY from "graphql/MassBalance/AddMbStockInventory";
import CLOSING_MASS_BALANCE from "graphql/MassBalance/ClosingMassBalance";
import MASS_BALANCE_COUNTS from "graphql/MassBalance/MassBalanceCounts";
import {
  MassBalanceExportApi,
  MassBalanceExportCoproApi,
} from "graphql/MassBalance/MassBalanceExport";
import MASS_BALANCE_TOP_FILTERS from "graphql/MassBalance/TopFilters";
import TRANSACTION_API from "graphql/MassBalance/TransactionApi";
import TRANSACTION_API_V2 from "graphql/MassBalance/TransactionApi-V2";
import {findIndex, isEmpty, startCase, toLower} from "lodash";
import {useFeatureFlag} from "modules/common/FeatureFlag/FeatureFlagContext";
import FEATURE_FLAGS from "modules/common/FeatureFlag/featureFlagConstants";
import {client} from "providers/Apollo";
import {useAppSetting} from "providers/appSetting";
import {useUserSettings} from "providers/userSettings";
import {toast} from "react-toastify";
import appendParamsToUrl from "utils/helpers/appendParamsToUrl";
import downloadFile from "utils/helpers/downloadFile";
import {
  getDivisionData,
  getSiteDetails,
  setCountryDetails,
} from "utils/helpers/getAppSetting";
import {getFormattedNumberWithCheck} from "utils/numberUtil";
import MassBalanceFilterContent from "../MassBalanceFilterContent";
import ClosingPopover from "../components/ClosingPopover";
import CustomizeTable from "../components/CustomizeTable/CustomizeTable";
import Layout from "../components/Layout";
import ClosingStockAndApiModal from "../components/Modals/ClosingStockAndApiModal";
import ClosingStockTotalAdd from "../components/Modals/ClosingStockTotalAdd";
import MassBalanceCloseConfirmationModal from "../components/Modals/MassBalanceCloseConfirmationModal";
import MassBalanceCloseResponseModal from "../components/Modals/MassBalanceCloseResponseModal";
import DocumentTable from "../components/Table";
import getColumns from "../components/Table/columns/getColumns";
import {
  MASS_BALANCE_CLOSE_ERROR_HEADER,
  MASS_BALANCE_CLOSE_SUCCESS_BODY,
  MASS_BALANCE_CLOSE_SUCCESS_HEADER,
  MASS_BALANCE_CLOSING_API_MODAL,
  MASS_BALANCE_CLOSING_CONFIRMATION_MODAL,
  MASS_BALANCE_CLOSING_PHYSICAL_WITH_API_MODAL,
  MASS_BALANCE_REOPEN_ERROR_HEADER,
  massBalanceType,
  reversalEventNames,
  reversalEventTypes,
} from "../constants";
import {isInTolerance} from "../utils";

const MassBalance = () => {
  const [columnFilter, setColumnFilter] = useState([]);
  const [showFilter, setShowFilter] = useState(false);
  const [open, setOpen] = useState(false);
  const [confirmCloseMassBalance, setConfirmCloseMassBalance] = useState("");
  const [closeMassBalanceHeader, setCloseMassBalanceHeader] = useState(
    MASS_BALANCE_CLOSE_SUCCESS_HEADER,
  );
  const [closeMassBalanceBody, setCloseMassBalanceBody] = useState(
    MASS_BALANCE_CLOSE_SUCCESS_BODY,
  );
  const [currentPhysicalStock, setCurrentPhysicalStock] = useState();
  const [isClosingMassBalance, setIsClosingMassBalance] = useState(false);
  const [periodName, setPeriodName] = useState();
  const [mbTypeCode, setMbTypeCode] = useState();
  const [locationGroupName, setLocationGroupName] = useState();
  const [balanceGroupName, setBalanceGroupName] = useState();

  const [exportLoading, setExportLoading] = useState(false);
  const [reopenLoading, setReopenLoading] = useState(false);

  const [toggleExpandOnTopFilter, setToggleExpandOnTopFilter] = useState(false);

  const {accounts} = useMsal();
  const account = useAccount(accounts[0]);

  const SAF_GEN_DOC_FLOW_CHANGE = useFeatureFlag(
    FEATURE_FLAGS.SAF_GEN_DOC_FLOW_CHANGE,
  );

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const {
    userSettings: {dateFormat, decimalFormat},
  } = useUserSettings();
  const {appSetting} = useAppSetting();

  const {country, division, type, location, period, balance} = useParams();

  const mbType = mbTypeCode?.toLowerCase();

  setCountryDetails(country);
  const countryId = appSetting?.currentCountryMappingData?.countryId;
  const siteReferenceData = getSiteDetails(countryId);
  const divisionCode = division.toUpperCase();
  const divisionData = getDivisionData(divisionCode);
  const [showModalAdd, setShowModalAdd] = useState(false);

  const breadcrumbItems = [
    {text: "BioVerse", link: "/"},
    {text: divisionCode, link: "/"},
    {
      text: startCase(appSetting?.currentCountry?.toLowerCase() ?? ""),
      link: "/",
    },
    {text: "Mass balance", link: "/mass-balance"},
  ];

  const [defaultValues, setFilterDefaultValues] = useState({});
  const [currentFilterValues, setCurrentFilterValues] = useState(defaultValues);
  const navigate = useNavigate();

  const {data: mb_period_instances} = useQuery(MASS_BALANCE_TOP_FILTERS, {
    fetchPolicy: "network-only",
    variables: {
      siteReferenceId: siteReferenceData?.siteReferenceId,
      divisionId: divisionData?.divisionId,
    },
    skip:
      siteReferenceData?.siteReferenceId === undefined ||
      divisionData?.divisionId === undefined,
  });

  const {data: transactionData, loading: tableLoading} = useQuery(
    SAF_GEN_DOC_FLOW_CHANGE ? TRANSACTION_API_V2 : TRANSACTION_API,
    {
      fetchPolicy: "network-only",
      notifyOnNetworkStatusChange: true,
      variables: {
        siteReferenceId: siteReferenceData?.siteReferenceId,
        divisionId: divisionData?.divisionId,
        mbBalanceTypeId: currentFilterValues?.mbBalanceTypeId,
        mbLocationGroupId: currentFilterValues?.mbLocationGroupId,
        mbPeriodStatusId: currentFilterValues?.mbPeriodId,
        mbBalanceGroupId: currentFilterValues?.mbBalanceGroupId,
      },
      skip:
        siteReferenceData?.siteReferenceId === undefined ||
        divisionData?.divisionId === undefined ||
        !currentFilterValues?.mbBalanceTypeId ||
        !currentFilterValues?.mbBalanceTypeId ||
        !currentFilterValues?.mbLocationGroupId ||
        !currentFilterValues?.mbPeriodId,
    },
  );

  const {data: massBalanceCounts, refetch: refetchCounts} = useQuery(
    MASS_BALANCE_COUNTS,
    {
      fetchPolicy: "network-only",
      variables: {
        siteReferenceId: siteReferenceData?.siteReferenceId,
        divisionId: divisionData?.divisionId,
        mbBalanceTypeId: currentFilterValues?.mbBalanceTypeId,
        mbLocationGroupId: currentFilterValues?.mbLocationGroupId,
        mbPeriodStatusId: currentFilterValues?.mbPeriodId,
        mbBalanceGroupId: currentFilterValues?.mbBalanceGroupId,
      },
      skip:
        siteReferenceData?.siteReferenceId === undefined ||
        divisionData?.divisionId === undefined ||
        !currentFilterValues?.mbBalanceTypeId ||
        !currentFilterValues?.mbBalanceTypeId ||
        !currentFilterValues?.mbLocationGroupId ||
        !currentFilterValues?.mbPeriodId,
    },
  );

  const mbTableLoading = useMemo(() => {
    return (
      tableLoading ||
      siteReferenceData?.siteReferenceId === undefined ||
      divisionData?.divisionId === undefined ||
      !currentFilterValues?.mbBalanceTypeId ||
      !currentFilterValues?.mbBalanceTypeId ||
      !currentFilterValues?.mbLocationGroupId ||
      !currentFilterValues?.mbPeriodId
    );
  }, [
    tableLoading,
    siteReferenceData?.siteReferenceId,
    divisionData?.divisionId,
    currentFilterValues?.mbBalanceTypeId,
    currentFilterValues?.mbLocationGroupId,
    currentFilterValues?.mbPeriodId,
  ]);

  const filterDynamicData = useMemo(
    () => mb_period_instances?.bioLcGetMBFilterOptionsApi?.mbPeriodInstances,
    [mb_period_instances?.bioLcGetMBFilterOptionsApi?.mbPeriodInstances],
  );

  const openingCount = useMemo(
    () =>
      getFormattedNumberWithCheck(
        massBalanceCounts?.bioLcGetMassBalanceCounts?.openingCount,
        NUMBER_OF_DECIMALS,
      ),
    [massBalanceCounts?.bioLcGetMassBalanceCounts?.openingCount],
  );

  const incomingCount = useMemo(
    () =>
      getFormattedNumberWithCheck(
        massBalanceCounts?.bioLcGetMassBalanceCounts?.incomingCount,
        NUMBER_OF_DECIMALS,
      ),
    [massBalanceCounts?.bioLcGetMassBalanceCounts?.incomingCount],
  );

  const outgoingCount = useMemo(
    () =>
      getFormattedNumberWithCheck(
        massBalanceCounts?.bioLcGetMassBalanceCounts?.outgoingCount,
        NUMBER_OF_DECIMALS,
      ),
    [massBalanceCounts?.bioLcGetMassBalanceCounts?.outgoingCount],
  );

  const currentCount = useMemo(
    () =>
      getFormattedNumberWithCheck(
        massBalanceCounts?.bioLcGetMassBalanceCounts?.currentCount,
        NUMBER_OF_DECIMALS,
      ),
    [massBalanceCounts?.bioLcGetMassBalanceCounts?.currentCount],
  );

  const physicalStock = useMemo(
    () =>
      getFormattedNumberWithCheck(
        massBalanceCounts?.bioLcGetMassBalanceCounts?.physicalStock,
        NUMBER_OF_DECIMALS,
      ),
    [massBalanceCounts?.bioLcGetMassBalanceCounts?.physicalStock],
  );

  const periodStatus = useMemo(
    () => massBalanceCounts?.bioLcGetMassBalanceCounts?.periodStatus,
    [massBalanceCounts?.bioLcGetMassBalanceCounts?.periodStatus],
  );

  const navigateToAllocation = useCallback(
    (selectedId) => {
      let redirectUrl = `/allocation/${country}/${divisionCode}/${mbTypeCode}/${locationGroupName}/${periodName}/${balanceGroupName}`;
      if (selectedId) {
        redirectUrl = `/allocation/${country}/${divisionCode}/${mbTypeCode}/${locationGroupName}/${periodName}/${balanceGroupName}?selectedId=${selectedId}`;
      }
      navigate(redirectUrl);
    },
    [
      country,
      divisionCode,
      mbTypeCode,
      locationGroupName,
      periodName,
      balanceGroupName,
      navigate,
    ],
  );

  const tableCols = useMemo(
    () =>
      getColumns(
        country,
        divisionCode,
        dateFormat,
        decimalFormat,
        navigateToAllocation,
        currentFilterValues,
        siteReferenceData?.siteReferenceId,
        divisionData?.divisionId,
        periodStatus,
      ),
    [
      country,
      divisionCode,
      dateFormat,
      decimalFormat,
      navigateToAllocation,
      currentFilterValues,
      siteReferenceData?.siteReferenceId,
      divisionData?.divisionId,
      periodStatus,
    ],
  );
  const [columns, setColumns] = useState(tableCols);
  const firstSelectionItem = useMemo(() => {
    const index = findIndex(filterDynamicData ?? [], (item) => {
      return (
        toLower(item?.mbBalanceType?.mbBalanceTypeCode) === toLower(type) &&
        toLower(item?.mbLocationGroup?.mbLocationGroupName) ===
          toLower(location) &&
        toLower(item?.mbPeriod?.mbPeriodName) === toLower(period) &&
        toLower(item?.mbBalanceGroup?.mbBalanceGroupName) === toLower(balance)
      );
    });
    return index === -1 ? filterDynamicData?.[0] : filterDynamicData?.[index];
  }, [balance, filterDynamicData, location, period, type]);

  useEffect(() => {
    if (period) {
      setPeriodName(period);
    }
    if (type) {
      setMbTypeCode(type);
    }
    if (location) {
      setLocationGroupName(location);
    }
    if (balance) {
      setBalanceGroupName(balance);
    }
  }, [balance, location, period, type]);

  useEffect(() => {
    if (!isEmpty(firstSelectionItem)) {
      setFilterDefaultValues((defaultValuesItem) => {
        return {
          ...defaultValuesItem,
          mbBalanceTypeId: firstSelectionItem?.mbBalanceType?.mbBalanceTypeId,
          mbLocationGroupId:
            firstSelectionItem?.mbLocationGroup?.mbLocationGroupId,
          mbPeriodId: firstSelectionItem?.mbPeriod?.mbPeriodId,
          mbBalanceGroupId:
            firstSelectionItem?.mbBalanceGroup?.mbBalanceGroupId,
        };
      });

      setCurrentFilterValues((defaultValuesItem) => {
        return {
          ...defaultValuesItem,
          mbBalanceTypeId: firstSelectionItem?.mbBalanceType?.mbBalanceTypeId,
          mbBalanceTypeCode:
            firstSelectionItem?.mbBalanceType?.mbBalanceTypeCode,
          mbLocationGroupId:
            firstSelectionItem?.mbLocationGroup?.mbLocationGroupId,
          mbLocationGroupName:
            firstSelectionItem?.mbLocationGroup?.mbLocationGroupName,
          mbPeriodId: firstSelectionItem?.mbPeriod?.mbPeriodId,
          mbPeriodName: firstSelectionItem?.mbPeriod?.mbPeriodName,
          mbBalanceGroupId:
            firstSelectionItem?.mbBalanceGroup?.mbBalanceGroupId,
          mbBalanceGroupName:
            firstSelectionItem?.mbBalanceGroup?.mbBalanceGroupName,
        };
      });
    }
  }, [firstSelectionItem]);

  useEffect(() => {
    if (tableCols) {
      setColumns(tableCols);
    }
  }, [tableCols]);

  const filteredColumns = useMemo(() => {
    return columns.map((column) => ({
      ...column,
      columns: column.columns.filter(({visible}) => visible),
    }));
  }, [columns]);

  const extractKeys = useCallback((filteredColumns) => {
    return filteredColumns.flatMap((group) =>
      group.columns.map((col) => col.key),
    );
  }, []);

  const updateColumnFilter = useCallback(
    (filteredColumns, columnFilter) => {
      const keys = extractKeys(filteredColumns);
      // Filter the columnFilter to include only items whose id is in the extracted keys
      return columnFilter.filter((filterItem) => keys.includes(filterItem.id));
    },
    [extractKeys],
  );

  // useEffect hook to update the column filter state whenever filteredColumns or updateColumnFilter changes
  useEffect(() => {
    setColumnFilter((prevColumnFilter) => {
      // Update the column filter using the updateColumnFilter function
      const updatedColumnFilter = updateColumnFilter(
        filteredColumns,
        prevColumnFilter,
      );

      // Only update the state if the filters have actually changed
      if (
        JSON.stringify(prevColumnFilter) !== JSON.stringify(updatedColumnFilter)
      ) {
        return updatedColumnFilter; // Return the updated column filter
      }

      // Return the previous state if nothing has changed to avoid re-render
      return prevColumnFilter;
    });
  }, [filteredColumns, updateColumnFilter]);

  const handleFilterSubmit = async (
    mbBalanceTypeId,
    mbLocationGroupId,
    mbPeriodId,
    mbBalanceGroupId,
  ) => {
    setToggleExpandOnTopFilter((prev) => !prev);
    const period = filterDynamicData.find(
      (item) => item.mbPeriod.mbPeriodId === mbPeriodId,
    );
    const location = filterDynamicData.find(
      (item) => item.mbLocationGroup.mbLocationGroupId === mbLocationGroupId,
    );
    const balance = filterDynamicData.find(
      (item) => item.mbBalanceGroup.mbBalanceGroupId === mbBalanceGroupId,
    );
    const type = filterDynamicData.find(
      (item) => item.mbBalanceType.mbBalanceTypeId === mbBalanceTypeId,
    );

    const redirectURL = appendParamsToUrl("/mass-balance", [
      country,
      divisionCode,
      type?.mbBalanceType?.mbBalanceTypeCode,
      location?.mbLocationGroup?.mbLocationGroupName,
      period?.mbPeriod?.mbPeriodName,
      balance?.mbBalanceGroup?.mbBalanceGroupName,
    ]);
    navigate(redirectURL);
  };

  const unitOfMeasure = division.toUpperCase() === DIV_CODE_COPRO ? "MT" : "m³";

  useEffect(() => {
    if (physicalStock !== null && physicalStock !== undefined) {
      setCurrentPhysicalStock(physicalStock);
    }
  }, [physicalStock]);

  const [closingMassBalanceApi, {loading: closeApiLoading}] = useMutation(
    CLOSING_MASS_BALANCE,
    {
      onCompleted: ({bioLcMassBalanceClosing}) => {
        if (bioLcMassBalanceClosing.statusCode === 500) {
          setCloseMassBalanceHeader(MASS_BALANCE_CLOSE_ERROR_HEADER);
          setCloseMassBalanceBody(bioLcMassBalanceClosing.error);
          setConfirmCloseMassBalance(MASS_BALANCE_CLOSING_API_MODAL);
        }
        if (bioLcMassBalanceClosing.statusCode === 200) {
          setCloseMassBalanceHeader(MASS_BALANCE_CLOSE_SUCCESS_HEADER);
          setCloseMassBalanceBody(MASS_BALANCE_CLOSE_SUCCESS_BODY);
          setConfirmCloseMassBalance(MASS_BALANCE_CLOSING_API_MODAL);
        }
      },
      refetchQueries: [
        "bioLcGetMassBalanceTransactionData",
        "bioLcGetMassBalanceCounts",
      ],
    },
  );

  const [closeBalance, {loading: physicalStockLoading}] = useMutation(
    ADD_MB_STOCK_INVENTORY,
    {
      onCompleted: ({bioLcUpdateMBPhysicalStockApi}) => {
        if (bioLcUpdateMBPhysicalStockApi?.statusCode === 200) {
          if (
            ((mbType === massBalanceType.TRS &&
              currentCount <= currentPhysicalStock) ||
              mbType === massBalanceType.CPP) &&
            isClosingMassBalance
          ) {
            closingMassBalanceApi({
              variables: {
                siteReferenceId: siteReferenceData?.siteReferenceId,
                divisionId: divisionData?.divisionId,
                mbBalanceTypeId: currentFilterValues?.mbBalanceTypeId,
                mbLocationGroupId: currentFilterValues?.mbLocationGroupId,
                mbPeriodStatusId: currentFilterValues?.mbPeriodId,
                mbBalanceGroupId: currentFilterValues?.mbBalanceGroupId,
                createdBy: account.username,
              },
            });
            setIsClosingMassBalance(false);
            return;
          }
          refetchCounts();
          setShowModalAdd(false);
        }
        if (bioLcUpdateMBPhysicalStockApi?.statusCode === 500) {
          setShowModalAdd(false);
          setConfirmCloseMassBalance("");
          toast.error(
            bioLcUpdateMBPhysicalStockApi?.error ||
              "Something went wrong, please try later.",
          );
        }
      },
    },
  );

  const handleClosingMassBalance = async () => {
    if (periodStatus === "CLOSED") {
      setReopenLoading(true);
      const {data} = await ReversalMutationAPI({
        event: {
          siteReferenceId: siteReferenceData?.siteReferenceId,
          divisionId: divisionData?.divisionId,
          mbLocationGroupId: currentFilterValues?.mbLocationGroupId,
          mbBalanceGroupId: currentFilterValues?.mbBalanceGroupId,
          mbPeriodId: currentFilterValues?.mbPeriodId,
          eventType: reversalEventTypes.REVERT,
          eventName: reversalEventNames.REOPEN_MASS_BALANCE,
          eventId: currentFilterValues?.mbPeriodId,
        },
      });

      const statusCode = data?.bioLcGlobalMassBalanceReversal?.statusCode;

      if (statusCode === 400) {
        setCloseMassBalanceHeader(MASS_BALANCE_REOPEN_ERROR_HEADER);
        setCloseMassBalanceBody(data?.bioLcGlobalMassBalanceReversal?.error);
        setConfirmCloseMassBalance(MASS_BALANCE_CLOSING_API_MODAL);
      }
      if (statusCode === 500) {
        toast.error("An error occurred. Please try again later.");
        setConfirmCloseMassBalance("");
      }
      if (statusCode === 200) {
        toast.success("Successfully re-opened mass balance.");
        client.refetchQueries({
          include: [
            "bioLcGetMassBalanceTransactionData",
            "bioLcGetMassBalanceCounts",
          ],
        });
        setConfirmCloseMassBalance("");
      }
      setReopenLoading(false);

      return;
    }
    if (
      (mbType === massBalanceType.TRS &&
        (physicalStock === null || currentCount > currentPhysicalStock)) ||
      (mbType === massBalanceType.CPP &&
        (physicalStock === null || !isInTolerance(currentCount, physicalStock)))
    ) {
      setConfirmCloseMassBalance(MASS_BALANCE_CLOSING_PHYSICAL_WITH_API_MODAL);
      return;
    }
    closingMassBalanceApi({
      variables: {
        siteReferenceId: siteReferenceData?.siteReferenceId,
        divisionId: divisionData?.divisionId,
        mbBalanceTypeId: currentFilterValues?.mbBalanceTypeId,
        mbLocationGroupId: currentFilterValues?.mbLocationGroupId,
        mbPeriodStatusId: currentFilterValues?.mbPeriodId,
        mbBalanceGroupId: currentFilterValues?.mbBalanceGroupId,
        createdBy: account.username,
      },
    });
  };

  const handleClosingStockAndApi = (physicalStockValue, reason) => {
    setCurrentPhysicalStock(physicalStockValue);
    setIsClosingMassBalance(true);
    closeBalance({
      variables: {
        physicalStockM3:
          unitOfMeasure === "MT" ? undefined : physicalStockValue,
        physicalStockMT:
          unitOfMeasure === "MT" ? physicalStockValue : undefined,
        mbPeriodId: currentFilterValues?.mbPeriodId,
        reason,
      },
    });
  };

  const handleAllocate = async () => {
    navigateToAllocation();
  };

  const handleDownload = async () => {
    if (divisionCode === DIV_CODE_GF) {
      setExportLoading(true);
      const response = await MassBalanceExportApi({
        siteReferenceId: siteReferenceData?.siteReferenceId,
        divisionId: divisionData?.divisionId,
        mbBalanceTypeId: currentFilterValues?.mbBalanceTypeId,
        mbLocationGroupId: currentFilterValues?.mbLocationGroupId,
        mbPeriodStatusId: currentFilterValues?.mbPeriodId,
        mbBalanceGroupId: currentFilterValues?.mbBalanceGroupId,
      });

      if (
        response?.data?.bioLcGetMassBalanceExportReport &&
        response?.data?.bioLcGetMassBalanceExportReport?.statusCode === 200
      ) {
        downloadFile(response?.data?.bioLcGetMassBalanceExportReport?.url);
      } else {
        toast.error("Something went wrong, please try later.");
      }
      setExportLoading(false);
    }
    if (divisionCode === DIV_CODE_COPRO) {
      setExportLoading(true);
      const response = await MassBalanceExportCoproApi({
        siteReferenceId: siteReferenceData?.siteReferenceId,
        divisionId: divisionData?.divisionId,
        mbBalanceTypeId: currentFilterValues?.mbBalanceTypeId,
        mbLocationGroupId: currentFilterValues?.mbLocationGroupId,
        mbPeriodStatusId: currentFilterValues?.mbPeriodId,
        mbBalanceGroupId: currentFilterValues?.mbBalanceGroupId,
      });

      if (
        response?.data?.bioLcGetMassBalanceExportReportNew &&
        response?.data?.bioLcGetMassBalanceExportReportNew?.statusCode === 200
      ) {
        downloadFile(response?.data?.bioLcGetMassBalanceExportReportNew?.url);
      } else {
        toast.error("Something went wrong, please try later.");
      }
      setExportLoading(false);
    }
  };

  const HIDE_DOWNLOAD_REPORT_BUTTON = (() => {
    if (DIV_CODE_COPRO === divisionCode) {
      return false;
    }
    return true;
  })();

  const HIDE_ALLOCATE_OUTGOING_BUTTON = (() => {
    if (DIV_CODE_GF !== divisionCode && DIV_CODE_SAF !== divisionCode) {
      return true;
    }
    if (DIV_CODE_GF === divisionCode && COUNTRY_SPAIN !== country) {
      return true;
    }
    return false;
  })();

  const tableData = useMemo(
    () =>
      transactionData?.bioLcGetMassBalanceTransactionData?.mbTransactionData ||
      [],
    [transactionData?.bioLcGetMassBalanceTransactionData?.mbTransactionData],
  );

  const notTR = mbType !== massBalanceType.TR;

  return (
    <Layout title="Mass balance" breadcrumbs={breadcrumbItems}>
      {/* Top Filters */}
      <div className="flex flex-col justify-stretch w-full items-start px-7 pt-[32px]">
        <div className=" flex flex-row justify-between w-full mb-[24px]">
          <div className="flex flex-col justify-stretch w-full items-start">
            <MassBalanceFilterContent
              country={country}
              defaultValues={defaultValues}
              filterDynamicData={filterDynamicData}
              handleFilterSubmit={handleFilterSubmit}
            ></MassBalanceFilterContent>
          </div>

          <div className="flex flex-none flex-row ml-5 mb-[8px] items-end">
            {divisionCode === DIV_CODE_SAF && (
              <Button
                className="mr-[15px]"
                data-test="mandate-certificates-button"
                color="standard-tertiary rounded-0"
                type="button"
                onClick={() =>
                  navigate(
                    `/mandate-certificate/${country}/${divisionCode}?location=${locationGroupName}`,
                  )
                }
              >
                Mandate certificates
              </Button>
            )}
            <Button
              className="download-report-button"
              data-test="download-report-button"
              color="standard-tertiary rounded-0"
              type="button"
              hidden={HIDE_DOWNLOAD_REPORT_BUTTON}
              onClick={handleDownload}
            >
              {exportLoading && (
                <Spinner size="sm" className="btn-icon-prefix" />
              )}
              {divisionCode === DIV_CODE_GF && !exportLoading && (
                <Download24 className="btn-icon-prefix" />
              )}
              Download report
            </Button>
            <Button
              className="allocate-outgoing-button ml-[8px]"
              data-test="allocate-outgoing-button"
              color="standard-tertiary rounded-0"
              type="button"
              hidden={HIDE_ALLOCATE_OUTGOING_BUTTON}
              onClick={handleAllocate}
            >
              Allocate outgoing
            </Button>
          </div>
        </div>
      </div>
      {/* Customise columns, table, aggregate view */}
      <div className="flex flex-col justify-stretch w-full items-start px-7">
        <div className=" flex flex-row justify-between w-full p-4 bg-white">
          <div>
            <CustomizeTable
              columns={columns}
              onColumnChange={(cols) => setColumns(cols)}
            />
          </div>
          <div className="flex flex-none flex-row items-center">
            {columnFilter.length > 0 && (
              <div className="mr-2">
                <Button
                  data-test="copro-filter-btn"
                  color="standard-tertiary rounded-0"
                  onClick={() => setColumnFilter([])}
                >
                  Clear all
                </Button>
              </div>
            )}
            <div>
              <Button
                className={showFilter ? "border-black" : ""}
                data-test="copro-filter-btn"
                color="standard-tertiary rounded-0"
                onClick={() => setShowFilter(!showFilter)}
              >
                Filters
                <Filter24 className="btn-icon-suffix" />
              </Button>
            </div>
          </div>
        </div>
        <div className="w-full h-[calc(100vh-595px)] bg-white overflow-y-auto overflow-x-auto">
          <DocumentTable
            data={tableData}
            columns={filteredColumns}
            loading={mbTableLoading}
            type={mbType}
            showFilter={showFilter}
            columnFilter={columnFilter}
            setColumnFilter={setColumnFilter}
            toggleExpandOnTopFilter={toggleExpandOnTopFilter}
          />
        </div>
        <div className="w-full flex flex-grow flex-col justify-center h-20 items-center border-t-2 border-t-[#eee] bg-white">
          <div className="w-full text-end mr-50 h-7">
            <span className="text-gray-700 mr-[10px]">Opening</span>
            <span className="mr-[3px]">{openingCount}</span>
            <span className="mr-[50px]">{unitOfMeasure}</span>
            <span className="text-gray-700 mr-[10px]">Incoming</span>
            <span className="mr-[3px]">{incomingCount}</span>
            <span className="mr-[50px]"> {unitOfMeasure}</span>
            <span className="text-gray-700 mr-[10px]">Outgoing</span>
            <span className="mr-[3px]">{outgoingCount}</span>
            <span className="mr-[50px]"> {unitOfMeasure}</span>
            <span className="text-gray-700 mr-[10px]">
              {periodStatus === "CLOSED" ? "Closing" : "Current"}
            </span>
            <span className="mr-[3px]">{currentCount}</span>
            <span className="mr-[14px]"> {unitOfMeasure}</span>
          </div>
          {notTR && (
            <div className="w-full text-end mt-[-8px]">
              <span className="text-gray-700 text-[12px] h-5 mr-4 pb-4">
                Closing stock total:{" "}
                <button
                  type="button"
                  className="!bg-white !text-gray-700 !border-none !underline"
                  onClick={() =>
                    setShowModalAdd((showModalAdd) => !showModalAdd)
                  }
                >
                  {physicalStock === null || physicalStock === undefined
                    ? "Add"
                    : `${physicalStock} ${unitOfMeasure}`}
                </button>
              </span>
            </div>
          )}
        </div>
      </div>
      {/* Bottom button */}
      <div className="flex flex-col justify-stretch w-full items-start px-7 mt-6">
        <div className=" flex flex-row justify-end w-full mb-5">
          <span className="flex items-center">
            {periodStatus === "CLOSED"
              ? "How to re-open mass balance"
              : "How to close mass balance"}
          </span>
          <div className="flex items-center mr-[17px]">
            <Info24
              id="mass-balance-close-btn"
              onMouseEnter={() => setOpen(true)}
              onMouseLeave={() => setOpen(false)}
            />
            <ClosingPopover
              open={open}
              type={mbType}
              periodStatus={periodStatus}
            />
          </div>
          <Button
            color="primary"
            type="secondary"
            className="float-right rounded-0 text-gray-400"
            size="md"
            onClick={() =>
              setConfirmCloseMassBalance(
                MASS_BALANCE_CLOSING_CONFIRMATION_MODAL,
              )
            }
          >
            {periodStatus === "CLOSED" ? "Re-open balance" : "Close balance"}
          </Button>
        </div>
      </div>
      <ClosingStockTotalAdd
        setShowModalAdd={setShowModalAdd}
        title={
          physicalStock ? "Confirm stock inventory" : "Add closing stock total"
        }
        closeBalance={closeBalance}
        mbPeriodId={currentFilterValues?.mbPeriodId}
        showModalAdd={showModalAdd}
        physicalStock={physicalStock}
        loading={physicalStockLoading}
        unitOfMeasure={unitOfMeasure}
      />
      {confirmCloseMassBalance !== "" && (
        <>
          <ClosingStockAndApiModal
            showResponse={
              confirmCloseMassBalance ===
              MASS_BALANCE_CLOSING_PHYSICAL_WITH_API_MODAL
            }
            setShowResponse={setConfirmCloseMassBalance}
            onSubmit={handleClosingStockAndApi}
            closingStock={currentCount}
            physicalStock={physicalStock}
            loading={physicalStockLoading || closeApiLoading}
            mbType={mbType}
            unitOfMeasure={unitOfMeasure}
          />
          <MassBalanceCloseConfirmationModal
            showResponse={
              confirmCloseMassBalance ===
              MASS_BALANCE_CLOSING_CONFIRMATION_MODAL
            }
            setShowResponse={setConfirmCloseMassBalance}
            period={periodName}
            onSubmit={handleClosingMassBalance}
            loading={closeApiLoading || reopenLoading}
            periodStatus={periodStatus}
          />
          <MassBalanceCloseResponseModal
            showResponse={
              confirmCloseMassBalance === MASS_BALANCE_CLOSING_API_MODAL
            }
            setShowResponse={setConfirmCloseMassBalance}
            headerData={closeMassBalanceHeader}
            bodyData={closeMassBalanceBody}
          />
        </>
      )}
    </Layout>
  );
};

export default MassBalance;
