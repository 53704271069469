/* eslint-disable eqeqeq */
import {useLazyQuery} from "@apollo/client";
import {COPRO_US_SHIPMENTS_API_EDIT_MULTI_SHIPMENT_ID} from "graphql/coprocessing/shipments";
import {Close, Datepicker} from "@bphxd/ds-core-react";
import {Button, Modal, ModalBody, ModalHeader} from "reactstrap";
import {useState, useEffect, useMemo} from "react";
import PropTypes from "prop-types";
import {
  toUTC,
  formatDatePickerValue,
  invalidStartDate,
  invalidEndDate,
} from "modules/co-processing/helpers/dateHelper";
import {toast} from "react-toastify";
import {
  ERRORS,
  SUCCESS_SAVE,
} from "modules/co-processing/constants/coProcessing";
import {
  BUTTONS,
  dtnShipmentKeys,
  SHIPMENT_DATE_MODAL_LABELS,
  WARNINGS,
} from "modules/co-processing/constants/shipments";

const EditShipmentDateModal = ({
  details,
  dataFields,
  modal,
  setModal,
  refetch,
}) => {
  const [startTime, setStartTime] = useState(null);
  const [endTime, setEndTime] = useState(null);
  const [startTimeError, setStartTimeError] = useState("");
  const [endTimeError, setEndTimeError] = useState("");

  const initialStartTime =
    details[dataFields[1]?.dateKey] != null
      ? formatDatePickerValue(details[dataFields[1]?.dateKey])
      : null;
  const initialEndTime =
    details[dataFields[1]?.dateKey] != null
      ? formatDatePickerValue(details[dataFields[2]?.dateKey])
      : null;

  useEffect(() => {
    if (details && dataFields?.length > 2) {
      setStartTime(initialStartTime);
      setEndTime(initialEndTime);
    }
  }, [details, dataFields, modal, initialStartTime, initialEndTime]);

  const isValidDateTimes = useMemo(
    () => startTime !== initialStartTime || endTime !== initialEndTime,
    [startTime, endTime, initialStartTime, initialEndTime],
  );

  const handleStartTimeChange = (selectedDates, dateStr) => {
    setStartTime(dateStr);
    setStartTimeError("");
  };

  const handleEndTimeChange = (selectedDates, dateStr) => {
    setEndTime(dateStr);
    setEndTimeError("");
  };

  const [editShipmentMulti, {data: shipmentDataMulti}] = useLazyQuery(
    COPRO_US_SHIPMENTS_API_EDIT_MULTI_SHIPMENT_ID,
    {
      fetchPolicy: "network-only",
      notifyOnNetworkStatusChange: true,
      onCompleted: () => {
        if (shipmentDataMulti?.bioLcCoproUsShipmentsApi?.error) {
          toast.error(ERRORS.FAILED_EDIT);
          return setModal(false);
        }
        toast.success(SUCCESS_SAVE);
        refetch();
        return setModal(false);
      },
    },
  );

  const handleSave = () => {
    let valid = true;

    if (!startTime) {
      setStartTimeError(WARNINGS.MISSING_SHIPMENT_START_TIME);
      valid = false;
    }

    if (!endTime) {
      setEndTimeError(WARNINGS.MISSING_SHIPMENT_END_TIME);
      valid = false;
    }

    if (
      startTime &&
      endTime &&
      invalidStartDate(startTime, details?.shipment_date)
    ) {
      setStartTimeError(WARNINGS.START_TIME_BEFORE_SHIPMENT_DATE);
      valid = false;
    }

    if (startTime && endTime && invalidEndDate(startTime, endTime)) {
      setEndTimeError(WARNINGS.END_TIME_BEFORE_START_TIME);
      valid = false;
    }

    if (!valid) return;

    const updatedDetails = {
      allocated_shipment_id: details?.allocated_shipment_id ?? null,
      update_values: [
        {
          field: dtnShipmentKeys.SHIPMENT_START_DATE,
          value: toUTC(startTime),
        },
        {
          field: dtnShipmentKeys.SHIPMENT_END_DATE,
          value: toUTC(endTime),
        },
      ],
    };

    const variables = {
      shipmentId: details?.shipment_id,
      update_field_list: updatedDetails,
    };

    editShipmentMulti({variables});
  };

  const handleClose = () => {
    setStartTimeError("");
    setEndTimeError("");
    setModal(false);
  };

  return (
    <Modal size="sm" unmountOnClose centered isOpen={modal}>
      <ModalHeader
        className="border border-b"
        close={<Close className="custom-close" onClick={() => handleClose()} />}
      >
        {SHIPMENT_DATE_MODAL_LABELS.HEADER}
      </ModalHeader>
      <ModalBody className="p-0">
        <div className="px-4 pt-6">
          <div className="mb-4">
            <label
              data-test="multi-date-start-time-label"
              className="text-gray-700 text-sm mb-2"
              htmlFor="startTime"
            >
              {SHIPMENT_DATE_MODAL_LABELS.START_TIME}
            </label>
            <Datepicker
              data-test="multi-date-start-time-picker"
              id="startTime"
              name="startTime"
              className="datePicker"
              options={{
                enableTime: true,
                altFormat: "m/j/Y h:i K",
                dateFormat: "Z",
                defaultDate: startTime,
                onClose: handleStartTimeChange,
              }}
            />
            {startTimeError && (
              <p className="text-red-500 text-xs">{startTimeError}</p>
            )}
          </div>

          <div className="mb-4">
            <label
              data-test="multi-date-end-time-label"
              className="text-gray-700 text-sm mb-2"
              htmlFor="endTime"
            >
              {SHIPMENT_DATE_MODAL_LABELS.END_TIME}
            </label>
            <Datepicker
              data-test="multi-date-end-time-picker"
              id="endTime"
              name="endTime"
              className="datePicker"
              options={{
                enableTime: true,
                altFormat: "m/j/Y h:i K",
                dateFormat: "Z",
                defaultDate: endTime,
                onClose: handleEndTimeChange,
              }}
            />
            {endTimeError && (
              <p className="text-red-500 text-xs">{endTimeError}</p>
            )}
          </div>
        </div>
        <div className="flex flex-row w-full border border-t mt-10">
          <Button
            color=""
            className="w-1/2 !text-gray-400"
            onClick={handleClose}
          >
            {BUTTONS.CANCEL}
          </Button>
          <span>
            <div
              style={{
                width: "1px",
                height: "100%",
                backgroundColor: "#ededed",
              }}
            />
          </span>
          <Button
            color=""
            className="w-1/2 border-0"
            disabled={!isValidDateTimes}
            onClick={handleSave}
          >
            {BUTTONS.SAVE}
          </Button>
        </div>
      </ModalBody>
    </Modal>
  );
};

EditShipmentDateModal.propTypes = {
  details: PropTypes.object.isRequired,
  dataFields: PropTypes.array.isRequired,
  modal: PropTypes.bool.isRequired,
  setModal: PropTypes.func.isRequired,
  refetch: PropTypes.func.isRequired,
};

export default EditShipmentDateModal;
