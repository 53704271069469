const {gql} = require("@apollo/client");

const NABISY_RETRIEVAL_SUBSCRIPTION = gql`
  subscription onBiolcingestnabisydocumentcomplete($userId: String!) {
    onBiolcingestnabisydocumentcomplete(userId: $userId) {
      error
      message
      sdNumberIndex
      statusCode
      userId
    }
  }
`;

export default NABISY_RETRIEVAL_SUBSCRIPTION;
