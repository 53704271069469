import {useMemo} from "react";
import {Outlet, useLocation} from "react-router-dom";
import Layout from "./massBalance/Layout";

const ComplianceDashboard = () => {
  const {pathname} = useLocation();

  // Find page name dinamically in tabs data
  const currentPageName = "Compliance monitor";

  const breadcrumbItems = useMemo(
    () => [{text: "BioVerse", link: "/"}, {text: "Compliance monitor"}],
    [],
  );

  return (
    <Layout title={currentPageName} breadcrumbs={breadcrumbItems}>
      <Outlet />
    </Layout>
  );
};

export default ComplianceDashboard;
