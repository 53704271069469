import {Edit24} from "@bphxd/ds-core-react/lib/icons";
import PropTypes from "prop-types";
import {Card, CardBody} from "reactstrap";
import {useState} from "react";
import {
  BATCH_LABELS,
  BATCH_SUMMARY_LABELS,
  METRICS,
} from "modules/co-processing/constants/batches";
import {displayDate} from "modules/co-processing/helpers/dateHelper";
import {formatNumber} from "providers/userSettings";
import BatchSummaryDisplay from "./BatchSummaryDisplay";
import EditTankCertifiedModal from "./EditTankCertifiedModal";

const BatchSummarySection = ({data, daysRemaining, refetch}) => {
  const [modalOpen, setModalOpen] = useState(false);
  const hasInventoryTransfers = data?.transfers && data.transfers.length > 0;
  const inventoryTransferLabel = hasInventoryTransfers
    ? data.transfers.some(
        (transfer) => transfer.source_batch_code === data.batch_id,
      )
      ? BATCH_SUMMARY_LABELS.INVENTORY_TRANS_SOURCE
      : data.transfers.some(
          (transfer) => transfer.destination_batch_code === data.batch_id,
        )
      ? BATCH_SUMMARY_LABELS.INVENTORY_TRANS_DESTINATION
      : null
    : null;

  return (
    <div className="pb-8 w-3/4" data-test="batch-summary-wrapper">
      <Card className="card-border rounded-6">
        <CardBody>
          <div className="flex flex-row justify-between">
            <div className="text-[26px] pb-10">{BATCH_LABELS.SUMMARY}</div>
          </div>
          <div>
            <div className="grid grid-cols-3 justify-items-center text-center border-b-[1.5px]">
              <div data-test="batch-detail-header-item" className="pb-7 pt-3">
                <div className="text-xs pb-3">
                  {BATCH_SUMMARY_LABELS.DAYS_SUBMISSION}
                </div>
                <div className="text-3xl">{daysRemaining ?? "N/A"}</div>
                <div className="text-secondary text-xs">{METRICS.DAYS}</div>
              </div>
              <div
                data-test="batch-detail-header-item"
                className="bg-gray-50 rounded mb-3 px-5 pt-3"
              >
                <div className="text-xs pb-3">
                  {BATCH_SUMMARY_LABELS.TANK_CERTIFICATION}
                </div>
                <div className="text-3xl">
                  {formatNumber(data?.tank_volume, "Imperial Style")}{" "}
                  {METRICS.BBL}
                </div>
                {inventoryTransferLabel && (
                  <div className="text-secondary text-xs pt-3.5">
                    {inventoryTransferLabel}
                  </div>
                )}
              </div>
              <div data-test="batch-detail-header-item" className="pt-3">
                <div className="text-xs pb-3 justify-center text-center flex">
                  {BATCH_SUMMARY_LABELS.TANK_CERTIFIED}
                  <Edit24
                    data-test="batch-tank-certified-edit-icon"
                    className="cursor-pointer"
                    onClick={() => setModalOpen(true)}
                  />
                </div>
                <div className="text-xl">
                  {displayDate(data?.tank_certified_datez)}
                </div>
              </div>
            </div>
            <BatchSummaryDisplay batchDetails={data} refetch={refetch} />
          </div>
        </CardBody>
        <EditTankCertifiedModal
          details={data}
          setModal={setModalOpen}
          modal={modalOpen}
          refetch={refetch}
          onClose={() => setModalOpen(false)}
        />
      </Card>
    </div>
  );
};

BatchSummarySection.propTypes = {
  data: PropTypes.object,
  daysRemaining: PropTypes.number,
  refetch: PropTypes.func,
};

export default BatchSummarySection;
