import {Kebab24} from "@bphxd/ds-core-react/lib/icons";
import PropTypes from "prop-types";
import {useCallback, useMemo, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from "reactstrap";
import {
  mandateActionItems,
  mandateCertActionConfig,
  mandateCertStatus,
  mandateLinkAction,
} from "../utils";
import MandateModalToShow from "./MandateModalToShow";

const ActionDropdown = ({row, currentFilterValues}) => {
  const [actionModalVisible, setActionModalVisible] = useState("");

  const {division, country} = useParams();
  const divisionCode = division.toUpperCase();
  const navigate = useNavigate();

  const navigateToMandateAllocation = useCallback(() => {
    navigate(
      `/mandate-allocation/${country}/${division}/${currentFilterValues?.mbLocationGroupName}/${currentFilterValues?.periodName}/${currentFilterValues?.outboundTypeName}?selectedId=${row.original.inboundRecords[0].mbOutboundQtyEuId}`,
    );
  }, [
    country,
    currentFilterValues?.mbLocationGroupName,
    currentFilterValues?.outboundTypeName,
    currentFilterValues?.periodName,
    division,
    navigate,
    row,
  ]);

  const rowStatus = row?.original.outboundRecord?.status;
  let finalRowStatus = rowStatus;

  const isDocumentGenerated = useMemo(() => {
    return row?.original?.outboundRecord?.documentName !== null;
  }, [row?.original?.outboundRecord?.documentName]);

  if (isDocumentGenerated) {
    finalRowStatus = mandateCertStatus.ALLOCATED_AND_SENT;
  }

  const ACTIONS = useMemo(
    () =>
      Object.values(mandateActionItems).map((action) => ({
        name: action,
        isVisible:
          mandateCertActionConfig[
            finalRowStatus + divisionCode
          ]?.visible.includes(action),
        isDisabled:
          !mandateCertActionConfig[
            finalRowStatus + divisionCode
          ]?.enabled.includes(action),
        action: () =>
          mandateLinkAction(
            divisionCode,
            setActionModalVisible,
            action,
            row,
            navigateToMandateAllocation,
          ),
      })),
    [finalRowStatus, divisionCode, row, navigateToMandateAllocation],
  );

  return (
    <>
      <UncontrolledDropdown direction="start">
        <DropdownToggle
          color="standard-quartenary"
          className="!px-0"
          caret
          onClick={() => setActionModalVisible("")}
        >
          <Kebab24 />
        </DropdownToggle>
        <DropdownMenu container="body">
          {ACTIONS.filter((action) => action.isVisible).map((action) => (
            <DropdownItem
              key={action.name}
              onClick={action.action}
              disabled={action.isDisabled}
              data-test={`data-test-mandate-table-action-${action.name}`}
            >
              {action.name}
            </DropdownItem>
          ))}
        </DropdownMenu>
      </UncontrolledDropdown>
      {actionModalVisible && (
        <MandateModalToShow
          modal={actionModalVisible}
          setModal={setActionModalVisible}
          rowData={row}
          divisionCode={divisionCode}
          currentFilterValues={currentFilterValues}
        />
      )}
    </>
  );
};

ActionDropdown.propTypes = {
  row: PropTypes.object,
  currentFilterValues: PropTypes.object,
};

export default ActionDropdown;
