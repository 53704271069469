import PropTypes from "prop-types";
import React, {useState} from "react";
import {ReactTable} from "@bphxd/ds-core-react";
import {useQuery} from "@apollo/client";
import {COPRO_US_BATCH_ALLOCATION_INFO} from "graphql/coprocessing/batches";
import {allocationHeaders} from "modules/co-processing/constants/batches";
import {Spinner} from "reactstrap";
import buildColumns from "modules/co-processing/helpers/buildColumns";

const BatchAllocationTable = ({batchResourceId}) => {
  const [allocationInfo, setAllocationInfo] = useState([]);

  const {loading} = useQuery(COPRO_US_BATCH_ALLOCATION_INFO, {
    fetchPolicy: "network-only",
    variables: {
      batch_resource_id: batchResourceId,
    },
    onCompleted: (data) => {
      setAllocationInfo(
        data?.bioLcCoproUsBatchesApi?.body?.batch_allocation ?? [],
      );
    },
  });

  const dataColumns = buildColumns(allocationHeaders);

  return (
    <div
      data-test="batch-allocation-details-table"
      className="w-11/12 batches-table-container"
    >
      <div className="batches-table">
        {loading ? (
          <div className="flex mx-auto">
            <Spinner className="flex mx-auto" />
          </div>
        ) : (
          <ReactTable
            responsive
            columns={dataColumns}
            data={allocationInfo}
            enableSorting
            rowSelection={false}
          />
        )}
      </div>
    </div>
  );
};

BatchAllocationTable.propTypes = {
  batchResourceId: PropTypes.string.isRequired,
};

export default BatchAllocationTable;
