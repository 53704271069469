import {gql} from "@apollo/client";

export const GET_PRODUCT_DEFAULT_VALUES = gql`
  query bioLcDocManagerGetProductDefaultValues {
    bioLcDocManagerGetProductDefaultValues(event: {}) {
      productDefaultValuesData {
        productDvId
        materialId
        materialCode
        materialShortName
        defaultValue
        defaultValueType
        uomCode
        validFrom
        validTo
        valid_status
        divisionId
        siteReferenceId
        countryCode
        countryId
        divisionCode
      }
    }
  }
`;

export default {GET_PRODUCT_DEFAULT_VALUES};
