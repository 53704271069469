import {useQuery} from "@apollo/client";
import {
  Alert24,
  Checklist24,
  CircleClose24,
  Info24,
  Right24,
} from "@bphxd/ds-core-react/lib/icons";
import {useNavigate, useParams} from "react-router-dom";
import {Spinner} from "reactstrap";

import {DIV_CODE_COPRO} from "constants/divisionDetails";
import DASHBOARD_LIST_API from "graphql/MassBalance/DashboardListApi";
import {groupBy, mapValues, startCase} from "lodash";
import {useAppSetting} from "providers/appSetting";
import {useEffect, useMemo, useState} from "react";
import appendParamsToUrl from "utils/helpers/appendParamsToUrl";
import {
  getDivisionData,
  getSiteDetails,
  setCountryDetails,
} from "utils/helpers/getAppSetting";
import {GET_NOTIFICATION} from "../../../graphql/saf/GetNotification";
import Layout from "../../es-co-processing/components/Layout";
import MBSummaryCard from "../components/MBSummaryCard.js";
import TaskComponent from "../components/TaskComponent/TaskComponent";
import {saf_urls} from "../constants/common";
import {
  FAILED_TO_PROCESS_MESSAGES,
  READY_TO_ACCEPT_MESSAGES,
  READY_TO_ASSIGN_MESSAGES,
  REQUIRE_ATTENTION_MESSAGES,
} from "../constants/documents";
import {convertToDate, generateMessage} from "../utils";

const DivisionDashboard = () => {
  const {appSetting} = useAppSetting();
  const {country, division} = useParams();
  setCountryDetails(country);

  const countryId = appSetting?.currentCountryMappingData?.countryId;
  const siteReferenceData = getSiteDetails(countryId);
  const divisionData = getDivisionData(division?.toUpperCase());

  const [cardData, setCardData] = useState({});

  const navigate = useNavigate();

  const {data, loading} = useQuery(GET_NOTIFICATION, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "no-cache",
    variables: {
      siteReferenceId: siteReferenceData?.siteReferenceId,
      divisionId: divisionData?.divisionId,
    },
    skip:
      siteReferenceData?.siteReferenceId === undefined ||
      divisionData?.divisionId === undefined,
  });

  const isLoading =
    loading ||
    siteReferenceData?.siteReferenceId === undefined ||
    divisionData?.divisionId === undefined;

  const {data: dashboardData, loading: dashboardLoading} = useQuery(
    DASHBOARD_LIST_API,
    {
      fetchPolicy: "no-cache",
      notifyOnNetworkStatusChange: true,
      variables: {
        siteReferenceId: siteReferenceData?.siteReferenceId,
        divisionId: divisionData?.divisionId,
      },
      skip:
        siteReferenceData?.siteReferenceId === undefined ||
        divisionData?.divisionId === undefined,
    },
  );

  const isDashboardLoading =
    dashboardLoading ||
    siteReferenceData?.siteReferenceId === undefined ||
    divisionData?.divisionId === undefined;

  const originalData = useMemo(
    () => dashboardData?.bioLcGetMassBalanceDashboardCounts?.data,
    [dashboardData],
  );

  const isApiFailed = useMemo(
    () => dashboardData?.bioLcGetMassBalanceDashboardCounts?.statusCode === 500,
    [dashboardData],
  );

  const isDataAvailable = originalData && originalData.length > 0;

  useEffect(() => {
    if (originalData) {
      const transformedData = mapValues(
        groupBy(originalData, "mbLocationGroupName"),
        (items) =>
          items
            .map(
              ({
                mbPeriodStatusName,
                mbPeriodStatus,
                openingCount,
                incomingCount,
                outgoingCount,
                currentCount,
                mbPeriodValidityFlag,
                mbLocationGroupId,
                mbBalanceTypeId,
                mbBalanceTypeCode,
                mbBalanceGroupName,
              }) => ({
                mbPeriodStatusName,
                mbPeriodStatus,
                openingCount,
                incomingCount,
                outgoingCount,
                currentCount,
                mbPeriodValidityFlag,
                mbLocationGroupId,
                mbBalanceTypeId,
                mbPeriodDate: convertToDate(mbPeriodStatusName),
                mbBalanceTypeCode,
                mbBalanceGroupName,
              }),
            )
            .sort((a, b) => b.mbPeriodDate - a.mbPeriodDate)
            .slice(0, 4),
      );
      setCardData(transformedData);
    }
  }, [originalData]);

  const records = data?.bioLcIncomingTaskManagerApi?.data;
  const filteredRecords = (targetState) =>
    records?.filter((record) => record.state === targetState);

  const allTasksDone = !isLoading && records?.every((obj) => obj.total === 0);

  const targetStates = {
    REQUIRE_ATTENTION: "requires_attention",
    READY_TO_ASSIGN: "ready_to_assign",
    READY_TO_ACCEPT: "ready_to_accept",
    FAILED_TO_PROCESS: "failed_to_process",
  };

  const breadcrumbItems = [
    {text: "BioVerse", link: "/"},
    {
      text: startCase(appSetting?.currentCountry?.toLowerCase() ?? ""),
      link: "/",
    },

    {
      text: `${division?.toUpperCase()} ${
        country.charAt(0).toUpperCase() + country.slice(1)
      }`,
      link: `/${division}/${country}/dashboard`,
    },
  ];

  const landingPage =
    country === "sweden"
      ? saf_urls.swedenLandingPage
      : saf_urls.norwayLandingPage;
  const requireAttentionTotal =
    data && records && filteredRecords(targetStates.REQUIRE_ATTENTION)[0].total;
  const readyToAccept =
    data && records && filteredRecords(targetStates.READY_TO_ACCEPT)[0].total;
  const readyToAssign =
    data && records && filteredRecords(targetStates.READY_TO_ASSIGN)[0].total;
  const failedToProcess =
    data && records && filteredRecords(targetStates.FAILED_TO_PROCESS)[0].total;

  const taskNotification = data &&
    records && [
      {
        id: 1,
        text: generateMessage(
          requireAttentionTotal,
          REQUIRE_ATTENTION_MESSAGES,
        ),
        link: landingPage,
        icon: <Alert24 color="#e64949" />,
        taskItems: filteredRecords(targetStates.REQUIRE_ATTENTION)[0].documents,
        header: filteredRecords(targetStates.REQUIRE_ATTENTION)[0].state,
        latestDocument: filteredRecords(targetStates.REQUIRE_ATTENTION)[0]
          .latestDocument,
        total: filteredRecords(targetStates.REQUIRE_ATTENTION)[0].total,
        state: targetStates.REQUIRE_ATTENTION,
      },
      {
        id: 2,
        text: generateMessage(readyToAccept, READY_TO_ACCEPT_MESSAGES),
        link: landingPage,
        icon: <Info24 color="#ffc000" />,
        taskItems: filteredRecords(targetStates.READY_TO_ACCEPT)[0].documents,
        header: filteredRecords(targetStates.READY_TO_ACCEPT)[0].state,
        latestDocument: filteredRecords(targetStates.READY_TO_ACCEPT)[0]
          .latestDocument,
        total: filteredRecords(targetStates.READY_TO_ACCEPT)[0].total,
        state: targetStates.READY_TO_ACCEPT,
      },
      {
        id: 3,
        text: generateMessage(readyToAssign, READY_TO_ASSIGN_MESSAGES),
        link: landingPage,
        icon: <Info24 color="#218dcc" />,
        taskItems: filteredRecords(targetStates.READY_TO_ASSIGN)[0].documents,
        header: filteredRecords(targetStates.READY_TO_ASSIGN)[0].state,
        latestDocument: filteredRecords(targetStates.READY_TO_ASSIGN)[0]
          .latestDocument,
        total: filteredRecords(targetStates.READY_TO_ASSIGN)[0].total,
        state: targetStates.READY_TO_ASSIGN,
      },
      {
        id: 4,
        text: generateMessage(failedToProcess, FAILED_TO_PROCESS_MESSAGES),
        link: landingPage,
        icon: <CircleClose24 color="#111111" />,
        taskItems: filteredRecords(targetStates.FAILED_TO_PROCESS)[0].documents,
        header: filteredRecords(targetStates.FAILED_TO_PROCESS)[0].state,
        latestDocument: filteredRecords(targetStates.FAILED_TO_PROCESS)[0]
          .latestDocument,
        total: filteredRecords(targetStates.FAILED_TO_PROCESS)[0].total,
        state: targetStates.FAILED_TO_PROCESS,
      },
    ];

  // Code to loop through the taskNotification array and display the text and link
  const taskDisplay =
    !isLoading && data && records
      ? taskNotification.map((item) => {
          return (
            <TaskComponent
              item={item}
              key={item.id}
              targetStates={targetStates}
            />
          );
        })
      : "";

  const pageTitle = useMemo(() => {
    if (!divisionData || !siteReferenceData) {
      return "Dashboard";
    }
    return `${divisionData?.divisionCode} ${siteReferenceData?.siteName} Dashboard`;
  }, [divisionData, siteReferenceData]);

  return (
    <Layout title={pageTitle} breadcrumbs={breadcrumbItems}>
      <section className="flex flex-col justify-stretch items-start w-full pb-6 px-7">
        <h4 className="text-[23px] pb-3">Tasks</h4>
        {isLoading && (
          <div className="flex flex-row items-center justify-center flex-1">
            <Spinner size="sm" />
          </div>
        )}
        {!isLoading && !allTasksDone ? (
          <div className="flex flex-row flex-wrap flex-1">{taskDisplay}</div>
        ) : (
          !isLoading && (
            <div className="flex flex-row">
              <div className="pt-0">
                <Checklist24 className="text-gray-900" />
              </div>
              <div className="drop-down-name">
                All done! You have no outstanding tasks
              </div>
            </div>
          )
        )}
      </section>
      <section className="flex flex-col px-7 ">
        <div className="flex items-center">
          <h3 className="text-[29px] mb-0">Mass balance</h3>
          {isDataAvailable && (
            <span
              className="flex items-center justify-center text-[14px] w-[109px] h-[40px] !text-[#111111d6] pt-[5px] cursor-pointer"
              onClick={() =>
                navigate(`/mass-balance/list/${country}/${division}`)
              }
              onKeyDown={() => {}}
            >
              View all <Right24 />
            </span>
          )}
        </div>
        <hr className="border-1 mb-[42px]" />
      </section>
      {division !== DIV_CODE_COPRO && (
        <>
          {isDashboardLoading && (
            <div className="px-7">
              <Spinner size="sm" />
            </div>
          )}
          {!isDashboardLoading && isApiFailed && (
            <div className="px-7">
              <p>
                Due to a system error, we are unable to display the mass balance
                information at the moment.
                <br /> Please try again later.
              </p>
            </div>
          )}
          {Object.keys(cardData).map((locationGroupName) => (
            <section
              className="flex flex-col px-7 mb-[42px]"
              key={locationGroupName}
            >
              <div className="flex items-center mb-[12px]">
                <h3 className="text-[23px] mb-0">{locationGroupName}</h3>
                <span
                  className="flex items-center justify-center text-[14px] w-[109px] h-[40px] !text-[#111111d6] pt-[3px] cursor-pointer"
                  onClick={() =>
                    navigate(
                      `/mass-balance/list/${country}/${division}?type=${cardData[locationGroupName]?.[0]?.mbBalanceTypeId}&location=${cardData[locationGroupName]?.[0]?.mbLocationGroupId}`,
                    )
                  }
                  onKeyDown={() => {}}
                >
                  View all <Right24 />
                </span>
              </div>
              <div className="flex flex-wrap gap-x-5 gap-y-6">
                {cardData[locationGroupName].map((item, index) => (
                  <div
                    key={index}
                    className="flex-1 min-w-[510px] max-w-[calc(25%-1.25rem)]"
                  >
                    <MBSummaryCard
                      period={item.mbPeriodStatusName}
                      divisionName={divisionData?.divisionCode}
                      periodStatus={item.mbPeriodStatus}
                      incoming={item.incomingCount}
                      outgoing={item.outgoingCount}
                      opening={item.openingCount}
                      available={item.currentCount}
                      mbPeriodValidityFlag={item.mbPeriodValidityFlag}
                      redirectTo={() =>
                        navigate(
                          appendParamsToUrl("/mass-balance", [
                            country,
                            division,
                            item.mbBalanceTypeCode,
                            locationGroupName,
                            item.mbPeriodStatusName,
                            item.mbBalanceGroupName,
                          ]),
                        )
                      }
                    />
                  </div>
                ))}
              </div>
            </section>
          ))}
        </>
      )}
    </Layout>
  );
};

export default DivisionDashboard;
