import {gql} from "@apollo/client";

export const MANDATE_ALLOCATE_INCOMING = gql`
  query bioLcCustomerMandateIncomingApi(
    $siteReferenceId: String
    $divisionId: String
    $mbLocationGroupId: String
    $outMovementType: String
    $period: String
  ) {
    bioLcCustomerMandateIncomingApi(
      event: {
        divisionId: $divisionId
        siteReferenceId: $siteReferenceId
        mbLocationGroupId: $mbLocationGroupId
        outMovementType: $outMovementType
        period: $period
      }
    ) {
      error
      statusCode
      documents {
        physicalReceiptDate
        documentName
        originCountryName
        ghgTotal
        actualizedQty
        retiredQty
        rawMaterial
        mbOutboundQtyEuId
        certificateInboundEuId
        certificateOutboundEuId
      }
    }
  }
`;

export default MANDATE_ALLOCATE_INCOMING;
