import moment from "moment";

const getConvertedNumber = (value, decimalFormat, decimalPointNumbers) => {
  const convertedNumber =
    decimalFormat === "European Style"
      ? value.toLocaleString("de-DE", {
          minimumFractionDigits: decimalPointNumbers,
        })
      : value.toLocaleString("en-GB", {
          minimumFractionDigits: decimalPointNumbers,
        });

  return convertedNumber;
};

export const applyDateFormat = (
  data,
  dateProps,
  dateFormat,
  baseFormat,
  decimalProps,
  decimalFormat,
  decimalPointNumbers,
) => {
  if (!data) {
    return [];
  }
  return data.map((item) => {
    const formatedDates = {};
    dateProps?.forEach((dateProp) => {
      formatedDates[dateProp] =
        item && item[dateProp] && item[dateProp] !== "N/A"
          ? moment(item[dateProp], baseFormat).format(dateFormat)
          : item && item[dateProp];
    });
    const formatedDecimals = {};
    decimalProps?.forEach((decimalprop) => {
      formatedDecimals[decimalprop] =
        item && item[decimalprop] && item[decimalprop] !== "N/A"
          ? getConvertedNumber(
              item[decimalprop],
              decimalFormat,
              decimalPointNumbers,
            )
          : item && item[decimalprop];
    });
    return {...item, ...formatedDates, ...formatedDecimals};
  });
};

export const formatNumber = (value, decimalFormat, decimalPointNumbers) => {
  if (
    value !== undefined &&
    value !== null &&
    value !== "None" &&
    value !== "" &&
    value !== "NA" &&
    value !== "N/A"
  ) {
    const formattedNumber =
      decimalFormat === "European Style"
        ? value?.toLocaleString("de-DE", {
            minimumFractionDigits: decimalPointNumbers,
          })
        : value?.toLocaleString("en-GB", {
            minimumFractionDigits: decimalPointNumbers,
          });

    return formattedNumber;
  }
  return "0";
};

export const convertToNumber = (inputValue, decimalFormat) => {
  const value = inputValue?.toString();
  if (
    value !== undefined &&
    value !== null &&
    value !== "None" &&
    value !== "" &&
    value !== "NA" &&
    value !== "N/A" &&
    value !== "-" &&
    value !== "TDV" &&
    value !== "DDV"
  ) {
    const {format} = new Intl.NumberFormat(
      decimalFormat === "European Style" ? "de-DE" : "en-GB",
    );
    const [, decimalSign] = /^0(.)1$/.exec(format(0.1));
    const convertedNumber = +value
      .replace(new RegExp(`[^-${decimalSign}\\d]`, "g"), "")
      .replace(decimalSign, ".");

    return convertedNumber;
  }
  if (value === "TDV" || value === "DDV") {
    return value;
  }
  return 0;
};

export default {applyDateFormat};
